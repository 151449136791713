import { ActivityHeatMap, useListMultipleActivities } from "../../../../api_v2/hooks/useHeatMap";
import { subHours } from "date-fns";
import { Id } from "../../../../api_v2/types/custom";
import { DateInterval } from "../../../../utils/DateInterval";
import { useMemo } from "react";
import {
    applyGaussianFilter,
    DayAndNightSeparatedActivityData,
    getNormalizedDayAndNightSeparatedActivityData,
    getTransitionEntries,
} from "../../data/useActivityDataHelpers";

export const useMonthlyBedActivityData = (
    bedId: Id | null,
    interval: DateInterval,
    days: number
): Array<DayAndNightSeparatedActivityData> => {
    const bedActivitiesQueries = useListMultipleActivities(
        bedId === null
            ? []
            : new Array(days).fill(0).flatMap((_, i) => {
                  const before = subHours(interval.end, i * 24);

                  return {
                      type: "bed",
                      bedId: bedId,
                      params: { before },
                  };
              })
    );

    return useMemo(() => {
        const bedActivities: Array<ActivityHeatMap> = bedActivitiesQueries.flatMap(
            // @ts-expect-error UseQueryResult ist not correctly typed, we don't know why though
            (query): Array<ActivityHeatMap> => query.data ?? []
        );

        const sortedBedActivityData = getNormalizedDayAndNightSeparatedActivityData(
            bedActivities,
            interval.end,
            days * 24,
            1
        ).reverse();
        const smoothBedActivityData = applyGaussianFilter(sortedBedActivityData, 6, 2);
        return getTransitionEntries(smoothBedActivityData, true, true);
    }, [
        // This is a bit of a workaround to get use memo to recalculate when the queries change. We can be sure that, when the number of
        // successfully loaded queries changes, there are new data available
        bedActivitiesQueries.reduce((numSuccess, query) => (query.isSuccess ? numSuccess + 1 : numSuccess), 0),
        interval.end,
        bedId,
    ]);
};
