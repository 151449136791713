import { Slider } from "../../../UI/Slider";
import _ from "lodash";
import { Label } from "../../../UI/Label";
import { useTranslation } from "react-i18next";
import { InputGroup } from "../../../UI/InputGroup";
import { getDurationRangeAndDefault } from "../../settingsSchemaUtils";
import { Settings } from "../../../../api_v2/types/custom";
import { DeviceSettingsSchema } from "../../../../api_v2/hooks/useDeviceSettings";
import styles from "../../DeviceDetailsModal.module.css";

interface PauseSwitchProps {
    settings: Settings;
    onSettingsChange: (settings: Settings) => void;
    readonly: boolean;
    settingsSchema: DeviceSettingsSchema;
}

export const PauseSwitch = (props: PauseSwitchProps) => {
    const { t } = useTranslation();
    const pauseSwitchDurations = getDurationRangeAndDefault("PauseSwitchButtonTimeout", props.settingsSchema);

    return (
        <InputGroup>
            <strong className={styles.groupTitle}>{t("labelMiscPresenceKey", "Nurse presence button")}</strong>
            <Label text={t("pauseSwitchButtonTimeout", "Reactivate after ")} additionalClassNames={styles.label}>
                {
                    <Slider
                        value={props.settings.pauseSwitchButtonTimeout ?? pauseSwitchDurations.default ?? 15}
                        min={pauseSwitchDurations.min}
                        max={pauseSwitchDurations.max}
                        onChangeDone={(value) => {
                            const newSettings = _.cloneDeep(props.settings);
                            newSettings.pauseSwitchButtonTimeout = value;
                            props.onSettingsChange(newSettings);
                        }}
                        label={(value) => t("labelDurationSlider", "{{value}} min", { value })}
                        readonly={props.readonly}
                        id="PauseSwitchButtonTimeoutSlider"
                    />
                }
            </Label>
        </InputGroup>
    );
};
