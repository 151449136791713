import {
    differenceInMilliseconds,
    endOfDay,
    endOfDecade,
    endOfHour,
    endOfMinute,
    endOfMonth,
    endOfSecond,
    endOfWeek,
    endOfYear,
} from "date-fns";
import { useEffect, useState } from "react";
import { isNumber } from "lodash";
import { useDemoDataContext } from "../context/DemoDataContext";

type IntervalType = "second" | "minute" | "hour" | "day" | "week" | "month" | "year" | "decade";

const intervalTypeFn: Record<IntervalType, (date: Date) => Date> = {
    second: endOfSecond,
    minute: endOfMinute,
    hour: endOfHour,
    day: endOfDay,
    week: endOfWeek,
    month: endOfMonth,
    year: endOfYear,
    decade: endOfDecade,
};

export const useNow = (updateInterval: IntervalType | number): Date => {
    const demoDataContext = useDemoDataContext();
    const [date, setDate] = useState(() => new Date());

    useEffect(() => {
        const nextUpdateIn = isNumber(updateInterval)
            ? updateInterval
            : differenceInMilliseconds(intervalTypeFn[updateInterval](date), date);
        const timeout = window.setTimeout(() => {
            setDate(new Date());
        }, nextUpdateIn);

        return () => window.clearTimeout(timeout);
    }, [date, updateInterval]);

    if (demoDataContext.enabled && "useNow" in demoDataContext.data && typeof demoDataContext.data.useNow == "string") {
        return new Date(demoDataContext.data.useNow);
    }

    return date;
};
