import { AxiosRequestConfig } from "@bitperfect-packages/react-query-axios";

export const demoDataInterceptor = (demoData: Record<string, unknown>) => {
    return (config: AxiosRequestConfig) => {
        const queryParams = new URLSearchParams(config.params);
        const url = config.url + (queryParams.size > 0 ? "?" + queryParams.toString() : "");

        if (url && url in demoData) {
            config.adapter = (config) => {
                return new Promise((resolve) => {
                    resolve({
                        data: demoData[url],
                        status: 200,
                        statusText: "OK",
                        headers: {},
                        config,
                    });
                });
            };
        }

        return config;
    };
};
