import { SceneLabel } from "../../../../api_v2/hooks/useSceneLabels";
import { Settings } from "../../../../api_v2/types/custom";
import _ from "lodash";
import { DeviceSettingsSchema } from "../../../../api_v2/hooks/useDeviceSettings";
import { getDefaultValue, getDefaultValueForItems } from "../../settingsSchemaUtils";

/**
 * Adds all relevant default settings to the given settings object, for a new scene label.
 *
 * This is relevant because, scene labels and settings are separate things in the system.
 * So whenever a user adds a new scene label, there are not automatically settings added.
 * A Scene Label without settings is inactive though.
 *
 * So for the happy path, when the customer adds a new scene label, we automatically add the default settings
 * for that scene label.
 *
 * In the not so happy path, where the user does not save the added default settings, we show a warning that the
 * label is inactive and give the user the option of adding the default settings.
 */
export const addDefaultSettingsForNewSceneLabel = (
    settings: Settings,
    newSceneLabel: SceneLabel,
    settingsType: "bed-monitoring" | "activity-monitoring" | "bed-restlessness",
    settingsSchema: DeviceSettingsSchema
): Settings => {
    const newSettings = _.cloneDeep(settings);
    switch (newSceneLabel.type) {
        case "bed-point":
            if (settingsType === "bed-monitoring") {
                newSettings.getupDetectionLocations = [
                    ...(newSettings.getupDetectionLocations ?? []),
                    getGetUpDetectionLocationsDefault(newSceneLabel.id, settingsSchema),
                ];
            } else if (settingsType === "activity-monitoring") {
                newSettings.bedActivityLocations = [
                    ...(newSettings.bedActivityLocations ?? []),
                    getBedActivityLocationDefault(newSceneLabel.id),
                ];
                newSettings.bedActivityEnable = newSettings.bedActivityEnable ?? true;
            } else if (settingsType === "bed-restlessness") {
                newSettings.bedRestlessnessLocations = [
                    ...(newSettings.bedRestlessnessLocations ?? []),
                    getBedRestlessnessLocationDefault(newSceneLabel.id, settingsSchema),
                ];
                newSettings.bedRestlessnessEnable = newSettings.bedRestlessnessEnable ?? true;
            }

            break;
        case "line-2d":
            newSettings.bedRails = [
                ...(newSettings.bedRails ?? []),
                getBedRailDefault(newSceneLabel.id, settingsSchema),
            ];
            break;
        default:
            // eslint-disable-next-line no-console
            console.error(`Unknown scene label type`, newSceneLabel);
    }
    return newSettings;
};

export type AbsenceDetectionLocation = Exclude<Settings["absenceDetectionLocations"], undefined>[0];
export const getAbsenceDetectionLocationsDefault = (
    id: string,
    settingsSchema: DeviceSettingsSchema
): AbsenceDetectionLocation => ({
    id: id,
    duration: getDefaultValueForItems("absenceDetectionLocations", "duration", settingsSchema, 15),
    timeWindows: [
        getDefaultValueForItems(
            "absenceDetectionLocations",
            "timeWindows",
            settingsSchema,
            {
                from: "00:00",
                until: "23:59",
            },
            "items.default"
        ),
    ],
});

export type GetUpDetectionLocation = Exclude<Settings["getupDetectionLocations"], undefined>[0];
export const getGetUpDetectionLocationsDefault = (
    id: string,
    settingsSchema: DeviceSettingsSchema
): GetUpDetectionLocation => ({
    id: id,
    mode: getDefaultValueForItems("getupDetectionLocations", "mode", settingsSchema, "situp"),
    sensitivity: getDefaultValueForItems("getupDetectionLocations", "sensitivity", settingsSchema, "medium"),
    timeWindows: [
        getDefaultValueForItems(
            "getupDetectionLocations",
            "timeWindows",
            settingsSchema,
            {
                from: "00:00",
                until: "23:59",
            },
            "items.default"
        ),
    ],
});

export type BedRail = Exclude<Settings["bedRails"], undefined>[0];
export const getBedRailDefault = (id: string, settingsSchema: DeviceSettingsSchema): BedRail => ({
    id: id,
    directional: getDefaultValueForItems("bedRails", "directional", settingsSchema, false),
    timeWindows: [
        getDefaultValue("properties.bedRails.items.properties.timeWindows.items.default", settingsSchema, {
            from: "00:00",
            until: "23:59",
        }),
    ],
});

export type BedActivityLocation = Exclude<Settings["bedActivityLocations"], undefined>[0];
export const getBedActivityLocationDefault = (id: string): BedActivityLocation => ({
    activityAnalysis: false,
    id: id,
    sensitivity: "medium",
    timeWindows: [
        {
            from: 0,
            until: 0,
        },
    ],
});

export type BedRestlessnessLocation = Exclude<Settings["bedRestlessnessLocations"], undefined>[0];
export const getBedRestlessnessLocationDefault = (
    id: string,
    settingsSchema: DeviceSettingsSchema
): BedRestlessnessLocation => ({
    id: id,
    sensitivity: getDefaultValue(
        "properties.bedRestlessnessLocations.items.properties.sensitivity.default",
        settingsSchema,
        "medium"
    ),
    durations: [
        getDefaultValue(
            "properties.bedRestlessnessLocations.items.properties.durations.default",
            settingsSchema,
            "medium"
        ),
    ],
    timeWindows: [
        getDefaultValue(
            "properties.bedRestlessnessLocations.items.properties.timeWindows.items.default",
            settingsSchema,
            {
                from: "00:00",
                until: "23:59",
            }
        ),
    ],
});
