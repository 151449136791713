import { useLocationContext } from "../../../context/LocationContextProvider";
import { useGetFacility } from "../../../api_v2/hooks/useFacilities";
import {
    Device,
    useListAnalysisModuleStatuses,
    useListDevices,
    useListDeviceStatuses,
} from "../../../api_v2/hooks/useDevices";
import { getRoomsForArea } from "./getRoomsForArea";
import { buildRoomTree } from "./buildRoomTree";
import { Id } from "../../../api_v2/types/custom";
import { useLocaleAwareCompare } from "../../../utils/useLocaleAwareCompare";

export const useCurrentRoomsTree = (selectedAreaId: Id | null = null, includeEmpty = true) => {
    const locationContext = useLocationContext();
    const localeAwareCompare = useLocaleAwareCompare();

    const facilityQuery = useGetFacility(locationContext.facilityId);
    const devicesQuery = useListDevices(locationContext.facilityId);
    const deviceStatusesQuery = useListDeviceStatuses(locationContext.facilityId);
    const analysisModuleStatusesQuery = useListAnalysisModuleStatuses(locationContext.facilityId);

    if (
        !(
            facilityQuery.isSuccess &&
            devicesQuery.isSuccess &&
            deviceStatusesQuery.isSuccess &&
            analysisModuleStatusesQuery.isSuccess
        )
    ) {
        return [];
    }

    const facility = facilityQuery.data;
    const area = facilityQuery.data?.areas.find((a) => a.id === locationContext.areaId) ?? null;

    const filterByAreaId = locationContext.areaId ?? selectedAreaId ?? null;

    const roomIds = area?.rooms.map((r) => r.id) ?? [];
    const devices = (devicesQuery.data ?? []).filter((device: Device) => {
        return !area || (device.roomId && roomIds.includes(device.roomId));
    });

    const rooms = getRoomsForArea(facility, filterByAreaId);
    const tree = buildRoomTree(rooms, devices, deviceStatusesQuery.data, analysisModuleStatusesQuery.data);

    return tree
        .filter((t) => t.devices.length > 0 || includeEmpty)
        .sort((a, b) => localeAwareCompare(a.room.name, b.room.name));
};
