import React, { createContext, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Device, DeviceStatus, usePollDeviceStatus } from "../../../api_v2/hooks/useDevices";
import { AreaRoom } from "../../../api_v2/types/custom";
import { useDockDevice, useUndockDevice } from "../../../api_v2/hooks/useDockDevice";
import { useSendDeviceCommand } from "../../../api_v2/hooks/useSendDeviceCommand";
import {
    DeviceSettings,
    DeviceSettingsWrite,
    useDeviceSettings,
    useSaveDeviceSettings,
} from "../../../api_v2/hooks/useDeviceSettings";
import { toast } from "react-toastify";
import { Toast } from "../../UI/Toast/Toast";

export type SetupContextTypes = "up" | "move" | "down";

export interface SetupContext {
    type: SetupContextTypes | null;

    sensor: Device | null;
    room: AreaRoom | null;
    dockingStation: Device | null;
    config: DeviceSettingsWrite | null;

    originalConfig: DeviceSettings | null;
    originalDockingStation: Device | null;
    originalRoom: AreaRoom | null;

    isValid: boolean;
    isAttached: boolean;
    isOnline: boolean;
    isBackOnline: boolean;
    isSaving: boolean;
    isSaved: boolean;
    hasFailedSaving: boolean;

    sensorStatusPollingStartedAt: Date | null;
    sensorStatus: DeviceStatus | null;

    steps: number;
    step: number;

    goNext: (() => void) | null;
    goPrev: (() => void) | null;

    setType: (type: SetupContextTypes) => void;
    setSensor: (s: Device | null) => void;
    setRoom: (r: AreaRoom | null) => void;
    setDockingStation: (ds: Device | null) => void;
    setConfig: (config: DeviceSettingsWrite) => void;

    setOriginalConfig: (config: DeviceSettings) => void;
    setOriginalDockingStation: (ds: Device) => void;
    setOriginalRoom: (loc: AreaRoom) => void;

    setIsValid: (state: boolean) => void;

    setSteps: (steps: number) => void;
    setStep: (steps: number) => void;

    setGoNext: (fn: (() => void) | null) => void;
    setGoPrev: (fn: (() => void) | null) => void;

    attach: () => Promise<boolean>;
    restart: () => Promise<boolean>;
    save: () => Promise<boolean>;
    reset: () => void;
}

const Context = createContext<SetupContext | null>(null);

export const useSetupContext = (): SetupContext => {
    const ctx = useContext(Context);
    if (!ctx) throw new Error("Setup context missing!");
    return ctx;
};

export interface SetupContextProviderProps {
    children?: JSX.Element;
}

export const SetupContextProvider = (props: SetupContextProviderProps) => {
    const { t } = useTranslation();
    const sendDeviceCommand = useSendDeviceCommand();

    const [type, setType] = useState<SetupContextTypes | null>(null);

    const [sensor, setSensor] = useState<Device | null>(null);
    const [room, setRoom] = useState<AreaRoom | null>(null);
    const [dockingStation, setDockingStation] = useState<Device | null>(null);

    const [config, setConfig] = useState<DeviceSettingsWrite | null>(null);

    const [originalConfig, setOriginalConfig] = useState<DeviceSettings | null>(null);
    const [originalDockingStation, setOriginalDockingStation] = useState<Device | null>(null);
    const [originalRoom, setOriginalRoom] = useState<AreaRoom | null>(null);

    const [sensorStatusPollingStartedAt, setSensorPollingStartedAt] = useState<Date | null>(null);
    const [isRestartTriggered, setIsRestartTriggered] = useState(false);

    const [isValid, setIsValid] = useState(true);
    const [isAttached, setIsAttached] = useState(true);
    const [isSaved, setIsSaved] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [hasFailedSaving, setHasFailedSaving] = useState(false);

    const [steps, setSteps] = useState(0);
    const [step, setStep] = useState(0);

    const [goPrev, setGoPrev] = useState<null | (() => void)>(null);
    const [goNext, setGoNext] = useState<null | (() => void)>(null);

    const saveDeviceSettings = useSaveDeviceSettings();
    const sensorStatusQuery = usePollDeviceStatus(sensor?.id, sensorStatusPollingStartedAt !== null);

    const originalDeviceSettings = useDeviceSettings(sensor?.id);

    const dockDevice = useDockDevice();
    const undockDevice = useUndockDevice();

    const isOnline = sensorStatusQuery.isSuccess && sensorStatusQuery.data.status !== "offline";

    // The reason for the 30-second delay here is to allow the sensor to shut down
    // and the status in the backend to actually update to offline.
    // Otherwise, we might still get "online" from the api and would immediately jump into the "back online" state,
    // without actually waiting through the entire restart cycle, leading to an outdated visualization
    const isBackOnline =
        isRestartTriggered && dayjs(sensorStatusPollingStartedAt).isBefore(dayjs().subtract(30, "seconds")) && isOnline;

    const attach = useCallback(async () => {
        if (!sensor || !dockingStation) {
            throw new Error("Select sensor and docking station");
        }

        try {
            if (!isAttached) {
                if (sensor.dockedTo) {
                    await undockDevice.mutateAsync({ dockId: sensor.dockedTo, deviceId: sensor.id });
                }

                setSensorPollingStartedAt(new Date());
                await dockDevice.mutateAsync({ dockId: dockingStation.id, deviceId: sensor.id });
                setIsAttached(true);
            }
        } catch (err) {
            return false;
        }

        return true;
    }, [dockingStation, sensor, isAttached, isRestartTriggered]);

    const restart = useCallback(async () => {
        if (!sensor) {
            throw new Error("Select sensor");
        }

        try {
            if (!isRestartTriggered) {
                setIsRestartTriggered(true);
                await sendDeviceCommand.mutateAsync({ deviceId: sensor.id, type: "restart", args: {} });
            }
        } catch (err) {
            return false;
        }

        return true;
    }, [isRestartTriggered, sensor]);

    const save = useCallback(async (): Promise<boolean> => {
        if (isSaved || !dockingStation || !sensor || !config) {
            return false;
        }

        setIsSaving(true);
        setIsSaved(false);

        try {
            await saveDeviceSettings.mutateAsync({
                deviceId: sensor.id,
                data: config,
            });

            toast.success(
                <Toast
                    title={t("successSensorSetupSavedTitle", "Successfully saved")}
                    description={t("successSensorSetupSavedMessage", "Your settings have been saved successfully!")}
                />
            );

            await sendDeviceCommand.mutateAsync({ deviceId: sensor.id, type: "restart", args: {} });

            setHasFailedSaving(false);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            toast.error(
                <Toast
                    title={t("errorSensorSetupSavedTitle", "Error while saving!")}
                    description={
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        err.errors.map((e: any) => `${e.errorType} : ${e.message}`).join(", ")
                    }
                />
            );
            setHasFailedSaving(true);
            return false;
        } finally {
            setIsSaving(false);
        }

        return true;
    }, [dockingStation, sensor]);

    const reset = useCallback(() => {
        setType(null);
        setSensor(null);
        setRoom(null);
        setDockingStation(null);
        setSensorPollingStartedAt(null);
        setIsRestartTriggered(false);
        setIsAttached(false);
        setIsValid(true);
        setIsSaved(false);
        setSteps(0);
        setStep(0);
        setGoPrev(null);
        setGoNext(null);
    }, []);

    return (
        <Context.Provider
            value={{
                type,
                // stage: currentStage,

                sensor,
                room,
                dockingStation,
                config: originalDeviceSettings.data ?? null,

                originalConfig,
                originalDockingStation,
                originalRoom,

                isValid,
                isAttached,
                isSaving,
                isSaved,
                hasFailedSaving,
                isOnline,
                isBackOnline,
                sensorStatus: sensorStatusQuery.data ?? null,
                sensorStatusPollingStartedAt,

                steps,
                step,

                goNext,
                goPrev,

                setType,

                setSensor,
                setRoom,
                setDockingStation,
                setConfig,

                setOriginalConfig,
                setOriginalDockingStation,
                setOriginalRoom,

                setIsValid,

                setSteps,
                setStep,

                setGoNext: (fn) => setGoNext(() => fn),
                setGoPrev: (fn) => setGoPrev(() => fn),

                attach,
                restart,
                save,
                reset,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};
