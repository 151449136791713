import { Id } from "../../../../api_v2/types/custom";
import { DateInterval } from "../../../../utils/DateInterval";
import { ActivityHeatMap, useListMultipleActivities } from "../../../../api_v2/hooks/useHeatMap";
import { subHours } from "date-fns";
import { useMemo } from "react";
import {
    applyGaussianFilter,
    DayAndNightSeparatedActivityData,
    getNormalizedDayAndNightSeparatedActivityData,
    getTransitionEntries,
} from "../../data/useActivityDataHelpers";

export const useMonthlyRoomyActivityData = (
    deviceId: Id | null,
    interval: DateInterval,
    days: number
): Array<DayAndNightSeparatedActivityData> => {
    const roomActivitiesQueries = useListMultipleActivities(
        deviceId !== null
            ? new Array(days).fill(0).flatMap((_, i) => {
                  const before = subHours(interval.end, i * 24);
                  return { type: "room", deviceId, params: { before } };
              })
            : []
    );

    return useMemo(() => {
        const roomActivities: Array<ActivityHeatMap> = roomActivitiesQueries.flatMap(
            // @ts-expect-error UseQueryResult ist not correctly typed, we don't know why though
            (query): Array<ActivityHeatMap> => query.data ?? []
        );

        const sortedRoomActivityData = getNormalizedDayAndNightSeparatedActivityData(
            roomActivities,
            interval.end,
            days * 24,
            1
        ).reverse();
        const smoothRoomActivityData = applyGaussianFilter(sortedRoomActivityData, 6, 2);
        return getTransitionEntries(smoothRoomActivityData, true, true);
    }, [
        // This is a bit of a workaround to get use memo to recalculate when the queries change. We can be sure that, when the number of
        // successfully loaded queries changes, there are new data available
        roomActivitiesQueries.reduce((numSuccess, query) => (query.isSuccess ? numSuccess + 1 : numSuccess), 0),
        interval.end,
        deviceId,
    ]);
};
