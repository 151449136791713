import { DateInterval } from "../../../utils/DateInterval";
import { c } from "../../../utils/ClassesHelper";
import { CardTitle } from "../../../components/UI/Card/CardTitle";
import { CardDescription } from "../../../components/UI/Card/CardDescription";
import { Card } from "../../../components/UI/Card/Card";
import { CardContent } from "../../../components/UI/Card/CardContent";
import { Trans, useTranslation } from "react-i18next";
import { DataLabel } from "../common/DataLabel";
import { HourlyGradientChart } from "../Charts/HourlyGradientChart/HourlyGradientChart";
import { colors } from "../../../utils/colors";
import { useListEventsForRoom } from "../../../api_v2/hooks/useEvents";
import { isWithinInterval } from "date-fns";
import { Id } from "../../../api_v2/types/custom";
import { DeviceWithStatus } from "../../RoomsView/helper/Nodes";
import { CardsTitlesAndTooltips } from "../CardsTitlesAndTooltips";
import styles from "../DashboardView.module.scss";
import cardStyles from "../Container/CardGrid.module.scss";
import chroma from "chroma-js";

export interface WeeklyFallDetectionCardProps {
    interval: DateInterval;
    roomId: Id;
    selectedDevice: DeviceWithStatus;
}

const gradient = chroma.scale([colors.fallDetectionNoFall, colors.fallDetection]).mode("lrgb");

export const WeeklyFallAlertsByHourCard = ({ interval, roomId, selectedDevice }: WeeklyFallDetectionCardProps) => {
    const { t } = useTranslation();

    const eventsQuery = useListEventsForRoom(roomId, {
        type: "fall",
    });

    const deviceId = selectedDevice.device.id;
    const dockIsNewDevice = selectedDevice?.device?.dockedTo;

    const fallsOfLast7days = eventsQuery.data?.pages.flatMap((page) =>
        page.filter(
            (event) =>
                isWithinInterval(new Date(event.time), interval) &&
                (event.deviceId === deviceId || event.deviceId === dockIsNewDevice)
        )
    );

    // Count falls per hour
    const dataOfEachHour = new Map();
    fallsOfLast7days?.forEach((event) => {
        const hour = new Date(event.time).getHours();
        if (dataOfEachHour.has(hour)) {
            dataOfEachHour.set(hour, dataOfEachHour.get(hour) + 1);
        } else {
            dataOfEachHour.set(hour, 1);
        }
    });

    // Sort and fill in missing hours
    const sortedData = Array.from(dataOfEachHour.entries())
        .sort((a, b) => a[0] - b[0])
        .map(([hour, falls]) => ({
            hour,
            falls,
        }));

    for (let i = 0; i < 24; i++) {
        if (!sortedData.find((d) => d.hour === i)) {
            sortedData.push({ hour: i, falls: 0 });
        }
    }

    const data = new Map((sortedData ?? []).map((d) => [d.hour, d.falls]));

    const min = Math.min(...Array.from(data.values()));
    const max = Math.max(...Array.from(data.values()));
    const range = max - min;

    const getColor = (value: number) => {
        return gradient((value - min) / range).hex();
    };

    return (
        <Card className={c([styles.card, cardStyles.md2, cardStyles.lg2, cardStyles.xl2])}>
            <CardTitle>
                <CardsTitlesAndTooltips
                    title={t("FallDetectionCountsCardTitle", "Fall alerts by hour")}
                    tooltip={t("tooltipWeeklyFallDetectionCountsCard", "Shows the sum of all falls for each hour.")}
                />
            </CardTitle>
            <CardDescription>
                <Trans
                    // t("descriptionWeeklyFallDetectionCard", "Last 7 days total number of <FallDetection>fall detection</FallDetection> alerts")
                    i18nKey="descriptionWeeklyFallDetectionCard"
                    default="Last 7 days total number of <FallDetection>fall detection</FallDetection> alerts"
                    components={{
                        FallDetection: <DataLabel type={"fallDetection"} />,
                    }}
                />
            </CardDescription>
            <CardContent className={styles.verticallyCentered}>
                <HourlyGradientChart
                    values={data}
                    colorForBar={getColor}
                    noValueColor={colors.hourlyGradientChartNoData}
                    showValues={true}
                />
            </CardContent>
        </Card>
    );
};
