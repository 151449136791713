import styles from "./TimeRangeSlider.module.css";
import React, { ReactNode } from "react";
import { TimeInputField } from "./TimeInputField/TimeInputField";
import { secondsToTime, timeToSeconds } from "./TimeInputField/timeConverter";

type TimeRangeSliderProps = {
    from: string;
    to: string;
    onChange?: (from: string, to: string) => void;
    onChangeDone?: (from: string, to: string) => void;
    disabled?: boolean;
    withinDayOnly?: boolean; // limit to "from < to"
    id?: string;
    steps?: number;
    max?: number;
};

type TimeRangeSliderState = {
    from: string;
    to: string;
};

enum Handle {
    None,
    From,
    To,
}

class TimeRangeSlider extends React.Component<TimeRangeSliderProps, TimeRangeSliderState> {
    private readonly min = 0;
    private readonly max = 60 * 60 * 24 - 60; // 23:59 hours
    private readonly step = (this.props.steps ?? 5) * 60; // 5 minutes is default if no step props is given

    private readonly labelMin = 30 * 60;
    private readonly labelMax = this.max - this.labelMin;

    private mouseHandlers = true;
    private moveEventHandler: ((e: MouseEvent | TouchEvent) => void) | undefined;
    private endEventHandler: ((e: MouseEvent | TouchEvent) => void) | undefined;

    private track: React.RefObject<HTMLDivElement>;
    private activeHandle: Handle;
    private initialMouseX: number;
    private trackRect: DOMRect;

    private currentFromValue = this.min;
    private currentToValue = this.max;

    constructor(props: TimeRangeSliderProps) {
        super(props);

        if (props.max !== undefined) {
            this.max = props.max;
        }

        this.initialMouseX = 0;
        this.track = React.createRef();
        this.trackRect = new DOMRect(0, 0, 0, 0);
        this.activeHandle = Handle.None;

        this.state = {
            from: this.getLabelFor(this.valueToSeconds(this.props.from, false)),
            to: this.getLabelFor(Math.min(this.max, this.valueToSeconds(this.props.to, true))),
        };

        this.updateInternalValues();
    }

    componentWillUnmount(): void {
        this.unregisterMouseEvents();
    }

    componentDidMount(): void {
        this.updateInternalValues();
        this.setThumbPositions();
        this.setThumbLabelPositions();
    }

    componentDidUpdate(prevProps: Readonly<TimeRangeSliderProps>): void {
        if (prevProps.from !== this.props.from || prevProps.to !== this.props.to) {
            this.updateInternalValues();
            this.setThumbPositions();
            this.setThumbLabelPositions();
            this.forceUpdate();
        }
    }

    render(): ReactNode {
        this.setThumbPositions();
        this.setThumbLabelPositions();

        const trackStyles = [
            styles.track,
            this.props.disabled ? styles.disabled : undefined,
            this.currentFromValue > this.currentToValue ? styles.overBoundary : undefined,
        ];

        return (
            <div className={trackStyles.join(" ")} onMouseDown={(e) => e.stopPropagation()}>
                <div className={styles.thumbContainer} ref={this.track}>
                    <button
                        className={styles.thumbFrom}
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            this.handleStart(e.nativeEvent, Handle.From);
                        }}
                        onTouchStart={(e) => this.handleStart(e.nativeEvent, Handle.From)}
                        onKeyDown={(e) => this.handleKeyDown(e.nativeEvent, Handle.From)}
                        disabled={this.props.disabled}
                        id={this.props.id ? `${this.props.id}_ThumbFrom` : undefined}
                    >
                        <div className={this.getClassNamesForThumbLabel(Handle.From)}>
                            {this.getLabelFor(this.currentFromValue)}
                        </div>
                    </button>
                    <button
                        className={styles.thumbTo}
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            this.handleStart(e.nativeEvent, Handle.To);
                        }}
                        onTouchStart={(e) => this.handleStart(e.nativeEvent, Handle.To)}
                        onKeyDown={(e) => this.handleKeyDown(e.nativeEvent, Handle.To)}
                        disabled={this.props.disabled}
                        id={this.props.id ? `${this.props.id}_ThumbTo` : undefined}
                    >
                        <div className={this.getClassNamesForThumbLabel(Handle.To)}>
                            {this.getLabelFor(this.currentToValue)}
                        </div>
                    </button>
                </div>
                <ul className={styles.axisLabel}>
                    <li>0</li>
                    <li>6</li>
                    <li>12</li>
                    <li>18</li>
                    <li>24</li>
                </ul>
                <div className={styles.label}>
                    <TimeInputField
                        timeInSeconds={this.currentFromValue}
                        onChange={(value) => this.onTimeBlur(Handle.From, value)}
                        disabled={this.props.disabled}
                        id={this.props.id ? `${this.props.id}_From` : undefined}
                    />
                    <span> - </span>
                    <TimeInputField
                        timeInSeconds={this.currentToValue}
                        onChange={(value) => this.onTimeBlur(Handle.To, value)}
                        disabled={this.props.disabled}
                        id={this.props.id ? `${this.props.id}_To` : undefined}
                    />
                </div>
            </div>
        );
    }

    private onTimeBlur(handle: Handle, timeInSeconds: number) {
        const isFrom = handle === Handle.From;
        this.change(isFrom ? timeInSeconds : this.currentFromValue, !isFrom ? timeInSeconds : this.currentToValue);
        this.changeDone();
    }

    private getClassNamesForThumbLabel(handle: Handle) {
        const classNames = [styles.thumbLabel];

        if (handle === this.activeHandle) classNames.push(styles.visible);

        return classNames.join(" ");
    }

    private setThumbPositions() {
        const thumbFromPos = this.currentFromValue / this.max;
        const thumbToPos = this.currentToValue / this.max;

        this.track.current?.style.setProperty("--fromPos", `${thumbFromPos * 100}%`);
        this.track.current?.style.setProperty("--toPos", `${thumbToPos * 100}%`);
    }

    private setThumbLabelPositions() {
        const thumbFromPos = 0.5;
        const thumbToPos = 0.5;

        this.track.current?.style.setProperty("--fromLabelPos", `${thumbFromPos * 100}%`);
        this.track.current?.style.setProperty("--toLabelPos", `${thumbToPos * 100}%`);
    }

    private valueToSeconds(value: string, isTo: boolean) {
        const seconds = timeToSeconds(value);

        return isTo && seconds == 0 ? this.max : seconds;
    }

    private secondsToValue(seconds: number) {
        return secondsToTime(seconds);
    }

    private change(from: number, to: number) {
        this.currentFromValue = from;
        this.currentToValue = to;

        this.setState({
            from: this.getLabelFor(from),
            to: this.getLabelFor(to),
        });

        if (this.props.onChange) {
            this.props.onChange(this.secondsToValue(from), this.secondsToValue(to));
        }
    }

    private changeDone() {
        this.forceUpdate();

        if (this.props.onChangeDone) {
            this.props.onChangeDone(
                this.secondsToValue(this.currentFromValue),
                this.secondsToValue(this.currentToValue)
            );
        }
    }

    private updateInternalValues() {
        this.currentFromValue = this.valueToSeconds(this.props.from, false);
        this.currentToValue = this.valueToSeconds(this.props.to, true);
    }

    private getLabelFor(seconds: number) {
        return this.secondsToValue(seconds).substr(0, 5);
    }

    private registerEvents() {
        if (!this.moveEventHandler) {
            this.moveEventHandler = (e: MouseEvent | TouchEvent) => this.handleMove(e);
            document.body.addEventListener(this.mouseHandlers ? "mousemove" : "touchmove", this.moveEventHandler, {
                passive: false,
            });
        }

        if (!this.endEventHandler) {
            this.endEventHandler = () => this.handleEnd();
            document.body.addEventListener(this.mouseHandlers ? "mouseup" : "touchend", this.endEventHandler);
        }
    }

    private unregisterMouseEvents() {
        if (this.moveEventHandler) {
            document.body.removeEventListener(this.mouseHandlers ? "mousemove" : "touchmove", this.moveEventHandler);
            this.moveEventHandler = undefined;
        }

        if (this.endEventHandler) {
            document.body.removeEventListener(this.mouseHandlers ? "mouseup" : "touchend", this.endEventHandler);
            this.endEventHandler = undefined;
        }
    }

    private handleStart(e: MouseEvent | TouchEvent, handle: Handle) {
        e.stopImmediatePropagation();
        e.stopPropagation();

        if (this.props.disabled) return;

        const rect = this.track.current?.getBoundingClientRect();

        if (rect) {
            this.mouseHandlers = "clientX" in e;
            const x = this.mouseHandlers ? (e as MouseEvent).clientX : (e as TouchEvent).touches[0].clientX;

            this.registerEvents();

            this.trackRect = rect;
            this.activeHandle = handle;
            this.initialMouseX = x;
        }
    }

    private handleEnd() {
        this.unregisterMouseEvents();
        this.activeHandle = Handle.None;
        this.changeDone();
    }

    private handleMove(e: MouseEvent | TouchEvent) {
        if (e.cancelable) e.preventDefault();

        const x = this.mouseHandlers ? (e as MouseEvent).clientX : (e as TouchEvent).touches[0].clientX;

        const percentage = Math.max(Math.min(x - this.trackRect.x, this.trackRect.width), 0) / this.trackRect.width;
        const value = Math.round((this.max - this.min) * percentage);

        const currentValue = this.activeHandle === Handle.From ? this.currentFromValue : this.currentToValue;
        const min = this.getMinForHandle(this.activeHandle);
        const max = this.getMaxForHandle(this.activeHandle);

        const newValue = Math.min(Math.max(this.roundToStep(value), min), max);

        if (currentValue !== newValue) {
            this.change(
                this.activeHandle === Handle.From ? newValue : this.currentFromValue,
                this.activeHandle === Handle.To ? newValue : this.currentToValue
            );
        }
    }

    private getMinForHandle(handle: Handle) {
        switch (handle) {
            case Handle.To:
                return (this.props.withinDayOnly ? this.currentFromValue : this.min) + this.step;
            default:
                return this.min;
        }
    }

    private getMaxForHandle(handle: Handle) {
        switch (handle) {
            case Handle.From:
                return (this.props.withinDayOnly ? this.currentToValue : this.max) - this.step;
            default:
                return this.max;
        }
    }

    private roundToStep(number: number) {
        return Math.ceil(number / this.step) * this.step;
    }

    private handleKeyDown(e: KeyboardEvent, handle: number) {
        let increment = 0;

        if (e.key == "ArrowLeft") {
            increment = -this.step;
        } else if (e.key == "ArrowRight") {
            increment = this.step;
        } else {
            return;
        }

        const currentValue = handle === Handle.From ? this.currentFromValue : this.currentToValue;
        const min = this.getMinForHandle(handle);
        const max = this.getMaxForHandle(handle);

        const newValue = Math.min(Math.max(this.roundToStep(currentValue + increment), min), max);

        if (newValue !== currentValue) {
            this.change(
                handle === Handle.From ? newValue : this.currentFromValue,
                handle === Handle.To ? newValue : this.currentToValue
            );
            this.changeDone();
        }
    }
}

export { TimeRangeSlider };
