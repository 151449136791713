import { Listbox } from "@headlessui/react";
import { BiChevronDown } from "react-icons/bi";
import { c } from "../../../utils/ClassesHelper";
import { Fragment } from "react";
import styles from "./Select.module.scss";

export interface CommonSelectProps<T> {
    items: Map<T, string>;
    width?: number | string;
}

export interface NullableSelectProps<T> {
    selected: T | null;
    onSelect: (selected: T | null) => void;
    defaultValue: string;
    nullable: true;
}

export interface NonNullableSelectProps<T> {
    selected: T;
    onSelect: (selected: T) => void;
    nullable: false;
}

export type SelectProps<T> = CommonSelectProps<T> & (NullableSelectProps<T> | NonNullableSelectProps<T>);

export const Select = <T,>(props: SelectProps<T>) => {
    return (
        <Listbox value={props.selected} onChange={props.onSelect} as={Fragment}>
            {({ open }) => (
                <div className={c([styles.container], { [styles.open]: open })} style={{ width: props.width }}>
                    <Listbox.Button className={c([styles.button], { [styles.open]: open })}>
                        {!props.nullable ? props.items.get(props.selected) : props.defaultValue}
                        <span className={styles.icon}>
                            <BiChevronDown />
                        </span>
                    </Listbox.Button>
                    <Listbox.Options className={c([styles.options], { [styles.open]: open })}>
                        {Array.from(props.items.entries()).map(([key, label]) => (
                            <Listbox.Option key={label} value={key} as={Fragment}>
                                {({ active, selected }) => (
                                    <li
                                        className={c([styles.option], {
                                            [styles.selected]: selected,
                                            [styles.active]: active,
                                        })}
                                    >
                                        {label}
                                    </li>
                                )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </div>
            )}
        </Listbox>
    );
};
