import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Login } from "./Login";
import { RequestPasswordReset } from "./RequestPasswordReset";
import { ResetPassword } from "./ResetPassword";

import styles from "../Auth.module.css";

export interface AuthViewProps {
    refreshSession: () => void;
}

export const AuthView = (props: AuthViewProps) => {
    return (
        <div className={styles.container}>
            <Routes>
                <Route path="/login" element={<Login {...props} />} />
                <Route path="/request-password-reset" element={<RequestPasswordReset />} />
                <Route path="/reset-password" element={<ResetPassword {...props} />} />
                <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
        </div>
    );
};
