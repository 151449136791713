import React, { PropsWithChildren, ReactNode } from "react";
import styles from "./InputGroup.module.css";

type InputGroupProps = {
    additionalClassNames?: string;
    reduced?: boolean;
};

class InputGroup extends React.Component<PropsWithChildren<InputGroupProps>> {
    render(): ReactNode {
        let classNames = styles.group;

        if ("additionalClassNames" in this.props) classNames += ` ${this.props.additionalClassNames}`;

        if (this.props.reduced) classNames += ` ${styles.reduced}`;

        /* eslint-disable react/prop-types */
        return <div className={classNames}>{this.props.children}</div>;
    }
}

export { InputGroup };
