import React, { PropsWithChildren, ReactNode } from "react";
import styles from "./NavBarDropdown.module.css";

type NavBarDropdownState = {
    open: boolean;
};

type NavBarProps = {
    root?: ReactNode;
    items?: ReactNode[];
    id?: string;
};

/**
 * @deprecated Replace with PopoverMenu
 */
class Dropdown extends React.Component<PropsWithChildren<NavBarProps>, NavBarDropdownState> {
    private clickHandler: null | (() => void) = null;

    constructor(props: NavBarProps) {
        super(props);

        this.state = {
            open: false,
        };
    }

    render(): ReactNode {
        const dropdownClasses = [styles.dropdown];

        if (this.state.open) dropdownClasses.push(styles.open);

        return (
            <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
                <button
                    id={this.props.id}
                    onClick={() => {
                        this.toggleDropdown();
                    }}
                    className={styles.clickWrapper}
                >
                    {this.props.root}
                </button>
                <ul className={dropdownClasses.join(" ")} onClick={() => this.close()}>
                    {this.props.children}
                </ul>
            </div>
        );
    }

    private toggleDropdown() {
        if (this.state.open) {
            this.close();
        } else {
            this.open();
        }
    }

    private open() {
        if (this.clickHandler === null) {
            this.clickHandler = () => {
                this.close();
            };
            window.addEventListener("click", this.clickHandler);
        }

        this.setState({
            open: true,
        });
    }

    private close() {
        if (this.clickHandler !== null) {
            window.removeEventListener("click", this.clickHandler);
            this.clickHandler = null;
        }

        this.setState({
            open: false,
        });
    }
}

export { Dropdown };
