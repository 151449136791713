import { useTranslation } from "react-i18next";
import { Settings, TimeWindow, TimeWindowFullHours } from "../../../../api_v2/types/custom";
import { SensitivityValues } from "../../../UI/SensitivitySlider";
import { BedLocationsSettingsProps } from "../BedMonitoringTab/BedLocationSettings/BedLocationSettings";
import { InputGroup } from "../../../UI/InputGroup";
import { Label } from "../../../UI/Label";
import { TimeWindowsList } from "../../../TimeWindows/TimeWindowsList";
import { SensitivitySliderLabelCombo } from "../../../UI/SensitivitySliderLabelCombo";
import { NoSettingsErrorMessage } from "../Common/NoSettingsErrorMessage";
import { addDefaultSettingsForNewSceneLabel } from "../Common/SceneLabelDefaultSettings";
import { GlobalBedActivitySettings } from "./GlobalBedActivitySettings";
import { ToggleSwitch } from "../../../UI/ToggleSwitch";
import _ from "lodash";
import styles from "./ActivityMonitoringSettings.module.css";

export const BedActivityMonitoringSettings = (props: BedLocationsSettingsProps) => {
    const { t } = useTranslation();

    if (props.selectedBedLocation?.type !== "bed-point") {
        return null;
    }

    const index = props.sceneLabels.filter((l) => l.type === "bed-point").indexOf(props.selectedBedLocation);

    const bedActivitySettings = props.settings.bedActivityLocations?.find((l) => l.id === props.selectedBedLocation.id);

    if (!bedActivitySettings) {
        return (
            <NoSettingsErrorMessage
                settings={props.settings}
                onSettingsChange={props.onSettingsChange}
                addSettingsHandler={(settings: Settings) =>
                    addDefaultSettingsForNewSceneLabel(
                        settings,
                        props.selectedBedLocation,
                        "activity-monitoring",
                        props.settingsSchema
                    )
                }
                readonly={props.readonly}
            />
        );
    }
    const handleSettingsChange = (
        updatedBedActivitySettings: Exclude<Settings["bedActivityLocations"], undefined>[0]
    ) => {
        const newSettings = _.cloneDeep(props.settings);

        if (!Array.isArray(newSettings.bedActivityLocations)) {
            newSettings.bedActivityLocations = [];
        }

        const index = props.settings.bedActivityLocations?.indexOf(bedActivitySettings);
        if (index === undefined || index < 0) {
            throw new Error("Can not change bed activity that does not exist");
        }

        newSettings.bedActivityLocations[index] = updatedBedActivitySettings;
        props.onSettingsChange(newSettings);
    };

    const handleChangeSensitivity = (sensitivity: SensitivityValues) => {
        const newBedActivitySettings = _.cloneDeep(bedActivitySettings);
        newBedActivitySettings.sensitivity = sensitivity;
        handleSettingsChange(newBedActivitySettings);
    };

    const handleActivateActivityAnalysis = (s: boolean) => {
        const newBedActivitySettings = _.cloneDeep(bedActivitySettings);
        newBedActivitySettings.activityAnalysis = s;
        handleSettingsChange(newBedActivitySettings);
    };

    const bedIndexForTitle = " (" + t("labelBedMonitoringBedNumber", "Bed {{index}}", { index: index + 1 }) + ")";
    const disabled = !props.settings.bedActivityEnable;

    return (
        <div>
            <GlobalBedActivitySettings {...props} />

            <strong className={styles.title}>
                {t("labelSelectedBedForActivity", "Selected bed") + bedIndexForTitle}
            </strong>
            <div>
                <InputGroup>
                    <SensitivitySliderLabelCombo
                        text={t("labelBedActivitySensitivity", "Sensitivity")}
                        sensitivity={bedActivitySettings.sensitivity}
                        readonly={props.readonly}
                        onChange={handleChangeSensitivity}
                        id="BedActivitySensitivitySlider"
                        disabled={disabled}
                    />
                </InputGroup>

                <Label text={t("labelBedActivitySchedule", "Analysis period")} additionalClassNames={styles.block}>
                    <TimeWindowsList
                        timeWindows={bedActivitySettings.timeWindows.map(
                            (timeWindowFullHour): TimeWindow => ({
                                from: `${timeWindowFullHour.from}:00`,
                                until: `${timeWindowFullHour.until}:00`,
                            })
                        )}
                        readonly={props.readonly}
                        onChange={(timeWindows) => {
                            const newBedActivitySettings = _.cloneDeep(bedActivitySettings);
                            newBedActivitySettings.timeWindows = timeWindows.map(
                                (timeWindow): TimeWindowFullHours => ({
                                    from: parseInt(timeWindow.from.split(":")[0]),
                                    until: parseInt(timeWindow.until.split(":")[0]),
                                })
                            );
                            handleSettingsChange(newBedActivitySettings);
                        }}
                        id="BedActivityTimeWindows"
                        sliderSteps={60}
                        sliderMax={60 * 60 * 24}
                        disabled={disabled}
                    />
                </Label>
                <Label
                    text={t("labelActivateActivityAnalysis", "Bed rest mobility: visualized sequences")}
                    additionalClassNames={styles.blockWithPadding}
                    noLabelEl={true}
                >
                    <div className={styles.activityAnalysisModeContainer}>
                        <InputGroup>
                            <Label text="">
                                <ToggleSwitch
                                    checkedLabel={t("bedActivitySettings.activityAnalysis.labelActivated", "Activated")}
                                    uncheckedLabel={t(
                                        "bedActivitySettings.activityAnalysis.labelDeactivated",
                                        "Deactivated"
                                    )}
                                    state={bedActivitySettings.activityAnalysis ?? false}
                                    onChange={handleActivateActivityAnalysis}
                                    disabled={disabled}
                                    id="SetActivityAnalysisModeButton_ActivityAnalysis"
                                />
                            </Label>
                        </InputGroup>
                    </div>
                </Label>
            </div>
        </div>
    );
};
