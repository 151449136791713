import React from "react";
import { GetUpDetectionMode } from "../../api_v2/types/custom";
import { useTranslation } from "react-i18next";

import raiseUp0 from "../../images/icons/type/Aufrichten.svg";
import sitUp0 from "../../images/icons/type/Aufsetzen.svg";
import standUp0 from "../../images/icons/type/Aufstehen.svg";
import { IconButton } from "./Button/IconButton";

export interface GetUpDetectionTypeButtonProps {
    type: GetUpDetectionMode;
    active?: boolean;
    onClick?: () => void;
    disabled?: boolean;
    id?: string;
}

export const GetUpDetectionTypeButton = (props: GetUpDetectionTypeButtonProps) => {
    const { t } = useTranslation();

    let title = "Unknown";
    let icon = "unknown";

    switch (props.type) {
        case "raiseup":
            title = t("titleAlertTypeBedRaiseup", "Raise-up");
            icon = raiseUp0;
            break;

        case "situp":
            title = t("titleAlertTypeBedSitup", "Sit-up");
            icon = sitUp0;
            break;

        case "standup":
            title = t("titleAlertTypeBedStandup", "Get-up");
            icon = standUp0;
            break;
    }

    return (
        <IconButton
            active={props.active}
            title={title}
            icon={icon}
            onClick={props.onClick}
            disabled={props.disabled}
            id={props.id}
        />
    );
};
