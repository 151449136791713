import { useMutation, useQuery } from "react-query";
import { useFetch, useMutate } from "@bitperfect-packages/react-query-axios";
import { Id } from "../types/custom";
import { components } from "../types/schema";

const getUrl = (deviceId: Id) => `/devices/${deviceId}/commands`;

type NewDeviceCommandRequest = components["schemas"]["NewDeviceCommandRequest"];
export type DeviceCommand = components["schemas"]["DeviceCommand"];

export type SendDeviceCommandProps = {
    deviceId: string;
} & NewDeviceCommandRequest;

export const useSendDeviceCommand = () => {
    return useMutation<DeviceCommand, void, SendDeviceCommandProps>(
        ["send-device-command"],
        useMutate<DeviceCommand, SendDeviceCommandProps>("POST", (data) => getUrl(data.deviceId), {
            requestConfigTransform: (data, config) => ({ ...config, data: { type: data.type, args: data.args } }),
        })
    );
};

export const useDeviceCommand = (deviceCommandId: Id) => {
    return useQuery<DeviceCommand>(
        ["device-commands", deviceCommandId],
        useFetch<DeviceCommand>(`/device-commands/${deviceCommandId}`)
    );
};
