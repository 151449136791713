import { CognitoRefreshToken, CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { userPool } from "./userPool";

export const getSession = (forceFresh?: boolean): Promise<{ session: CognitoUserSession; user: CognitoUser } | null> =>
    new Promise((resolve, reject) => {
        const user = userPool.getCurrentUser();

        if (!user) {
            resolve(null);
            return;
        }

        user.getSession((err: Error | null, session: CognitoUserSession | null) => {
            if (err) {
                reject(err);
                return;
            }

            if (forceFresh !== true) {
                resolve({ session, user });
                return;
            }

            const refreshToken = session?.getRefreshToken();

            if (!refreshToken) {
                reject("No valid refresh token");
                return;
            }

            user.refreshSession(refreshToken, (err, session: CognitoUserSession) => {
                if (err) {
                    reject(err);
                    return;
                }

                resolve({ session, user });
            });
        });
    });

export const refreshSession = (refreshToken: CognitoRefreshToken) =>
    new Promise<CognitoUserSession | null>((resolve, reject) => {
        const user = userPool.getCurrentUser();

        if (!user) {
            resolve(null);
            return;
        }

        user.refreshSession(refreshToken, (err, session: CognitoUserSession) => {
            if (err) {
                reject(err);
                return;
            }

            resolve(session);
        });
    });
