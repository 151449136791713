import React from "react";
import { RoomTree } from "../helper/Nodes";
import { RoomsListHead } from "./RoomsListHead";
import { EmptyItem } from "./EmptyItem";
import { RoomItem } from "./RoomItem";
import { useTranslation } from "react-i18next";
import styles from "./RoomsList.module.scss";
import { useHasFeatureFlags } from "../../../hooks/useHasFeatureFlags";

export interface RoomsListProps {
    tree: RoomTree;
    className?: string | undefined;
}

export const RoomsList = ({ tree }: RoomsListProps) => {
    const { t } = useTranslation();
    const hasFeatureFlags = useHasFeatureFlags();

    const hasAdditionalButtons = hasFeatureFlags(
        ["enable-bed-activity", "enable-room-activity", "enable-room-dashboard"],
        "any"
    );

    const columnCount = hasAdditionalButtons ? 4 : 3;

    return (
        <table className={styles.table} cellSpacing="0" cellPadding="0" id="RoomsList">
            <RoomsListHead hasAdditionalButtons={hasAdditionalButtons} />
            {tree.length ? (
                tree.map((room) => <RoomItem room={room} columnCount={columnCount} key={room.room.id} />)
            ) : (
                <EmptyItem columnCount={columnCount}>{t("noRooms", "No rooms")}</EmptyItem>
            )}
        </table>
    );
};
