import { Modal, ModalContent, ModalHeader, Title } from "../Modal/Modal";
import { ActivityMonitoring, ActivityMonitoringMode } from "./ActivityMonitoring/ActivityMonitoring";
import { DeviceWithStatus } from "../../views/RoomsView/helper/Nodes";
import { set, subDays, subHours, subMonths } from "date-fns";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useFormat } from "../../hooks/useFormat";
import { DateRange } from "./common/DateRange";
import { useQueryParameter, useQueryParameters } from "../../utils/useQueryParameters";
import { useSearchParams } from "react-router-dom";
import { Id } from "../../api_v2/types/custom";
import { useHasFeatureFlags } from "../../hooks/useHasFeatureFlags";
import { useListSceneLabels } from "../../api_v2/hooks/useSceneLabels";
import { Loading } from "../UI/Loading";

export interface AnalysisModalProps {
    device: DeviceWithStatus;
    onClose: () => void;
}

export const AnalysisModal = ({ device, onClose }: AnalysisModalProps) => {
    const { t } = useTranslation();
    const format = useFormat();
    const checkFeatureFlag = useHasFeatureFlags();

    const enableScrollbars = useMediaQuery({ query: "(max-width: 1192px), (max-height: 850px)" });
    const isSmallScreen = useMediaQuery({ query: "(max-width: 600px)" });

    const [selectedMode, setSelectedMode] = useQueryParameter<ActivityMonitoringMode>("mode", "day");
    const [date, setDate] = useQueryParameter<string>(
        "date",
        set(new Date(), { minutes: 0, seconds: 0, milliseconds: 0 }).getTime().toString()
    );

    const queryParams = useQueryParameters();
    const [, setSearchParams] = useSearchParams();
    const rawSelectedBedId = queryParams.get("bedId");

    const setSelectedBedId = (id: Id | null) => {
        const newParams = new URLSearchParams(queryParams);

        if (id === null) {
            newParams.delete("bedId");
        } else {
            newParams.set("bedId", id);
        }

        setSearchParams(newParams);
    };
    const bedActivityEnabled = checkFeatureFlag("enable-bed-activity");
    const roomActivityEnabled = checkFeatureFlag("enable-room-activity");

    const listSceneLabelsQuery = useListSceneLabels(device.device.id, { enabled: bedActivityEnabled });

    const bedPoints = (listSceneLabelsQuery.data ?? []).filter((labels) => labels.type === "bed-point");

    const selectedBedId = rawSelectedBedId
        ? rawSelectedBedId
        : !roomActivityEnabled
        ? bedPoints[0]?.id // if the user has not selected a bed and the room activity module is not activated, we use the first bed
        : null;

    const selectedDateRange: DateRange = {
        end: new Date(Number(date)),
        start: selectedMode === "day" ? subDays(new Date(Number(date)), 1) : subMonths(new Date(Number(date)), 1),
    };

    const dateRange = isSmallScreen
        ? // On small screens, the date range should never be more than 12 hours or 15 days
          {
              start: selectedMode === "day" ? subHours(selectedDateRange.end, 12) : subDays(selectedDateRange.end, 15),
              end: selectedDateRange.end,
          }
        : selectedDateRange;

    const handleModeChange = (mode: ActivityMonitoringMode, replace = false) => {
        setSelectedMode(mode, { replace });
    };

    const handleDateRangeChange = (dateRange: DateRange, replace = false) => {
        setDate(dateRange.end.getTime().toString(), { replace });
    };

    const titleTimeFormat = selectedMode === "day" ? "PPp" : "PP";

    const formatWithWeekday = () => {
        return format(dateRange.start, `ccc, ${titleTimeFormat}`);
    };

    const title =
        selectedBedId && bedActivityEnabled
            ? t("labelTimePeriodOfHeatMapForBedView", "Bed rest mobility from {{heatmapStartTime}}", {
                  heatmapStartTime: formatWithWeekday(),
              })
            : roomActivityEnabled
            ? t("labelTimePeriodOfHeatMap", "Room activity from {{heatmapStartTime}}", {
                  heatmapStartTime: formatWithWeekday(),
              })
            : null;

    return (
        <Modal
            onClose={onClose}
            showCloseButton={true}
            size="large"
            withHeader={true}
            disableScrollbar={!enableScrollbars}
        >
            <ModalHeader>
                <Title>{title}</Title>
            </ModalHeader>
            <ModalContent>
                {listSceneLabelsQuery.isLoading ? (
                    <Loading text={t("activityMonitoring.Loading", "Loading...")} />
                ) : (
                    <ActivityMonitoring
                        device={device}
                        dateRange={dateRange}
                        onDateRangeChange={handleDateRangeChange}
                        mode={selectedMode ?? "day"}
                        onModeChange={handleModeChange}
                        isSmallScreen={isSmallScreen}
                        selectedBedId={selectedBedId}
                        onSelectedBedIdChange={setSelectedBedId}
                        bedPoints={bedPoints}
                    />
                )}
            </ModalContent>
        </Modal>
    );
};
