import React, { Fragment, ReactElement } from "react";
import { DeviceStatusChangeCount, useStatusChangeCounts } from "../../../api_v2/hooks/useStatistics";
import { colors } from "../../../utils/colors";
import { useTranslation } from "react-i18next";
import { StatCard, StatCardProps, Trend } from "../../../components/UI/Card/StatCard/StatCard";
import { CommonCardProps } from "./common/CommonCardProps";
import { isSameDay, subDays } from "date-fns";
import pauseSwitchIcon from "../../../images/icons/Abwesenheits-Taste.svg";
import styles from "../DashboardView.module.scss";

const IconMap: Record<DeviceStatusChangeCountType, StatCardProps["icon"]> = {
    paused: {
        content: <img src={pauseSwitchIcon} />,
        bg: colors.presenceButtonIconBackground,
        fg: colors.presenceButtonBackground,
    },
    healthy: undefined,
    unhealthy: undefined,
    "shut-down": undefined,
    offline: undefined,
    unknown: undefined,
};

export type DeviceStatusChangeCountType = "healthy" | "paused" | "unhealthy" | "shut-down" | "offline" | "unknown";
export interface StatusChangeCountsCardProps extends CommonCardProps {
    type: DeviceStatusChangeCountType;
    title?: string | ReactElement;
    description?: string;
    day: Date;
}

const useEventCountTypeDetails = (type: DeviceStatusChangeCountType) => {
    const { t } = useTranslation();

    const data: Record<DeviceStatusChangeCountType, Array<{ title: string; type: DeviceStatusChangeCountType }>> = {
        paused: [{ title: t("labelPauseSwitch", "Pause switch"), type: "paused" }],
        healthy: [{ title: t("labelHealthy", "Healthy"), type: "healthy" }],
        unhealthy: [{ title: t("labelUnhealthy", "Unhealthy"), type: "unhealthy" }],
        "shut-down": [{ title: t("labelShutDown", "Shut down"), type: "shut-down" }],
        offline: [{ title: t("labelOffline", "Offline"), type: "offline" }],
        unknown: [{ title: t("labelUnknown", "Unknown"), type: "unknown" }],
    };

    return {
        icon: IconMap[type],
        types: data[type],
        downIsPositive: false,
    };
};

const getCountByEventTypes = (
    data: Array<DeviceStatusChangeCount>,
    eventTypes: Array<DeviceStatusChangeCountType>,
    date: Date
): Map<DeviceStatusChangeCountType, number> => {
    const filteredData = data.filter((d) => eventTypes.includes(d.type) && isSameDay(new Date(d.time), date));

    const countByEventType = new Map(eventTypes.map((t) => [t, 0]));
    filteredData.forEach((entry) =>
        countByEventType.set(entry.type, (countByEventType.get(entry.type) ?? 0) + entry.count)
    );
    return countByEventType;
};

const getCountForEventTypes = (
    data: Array<DeviceStatusChangeCount>,
    eventTypes: Array<DeviceStatusChangeCountType>,
    date: Date
): number => {
    return data
        .filter((d) => eventTypes.includes(d.type) && isSameDay(new Date(d.time), date))
        .reduce((sum, data) => sum + data.count, 0);
};

export const DailyStatusChangeCountsCard = ({
    deviceId,
    type,
    title,
    description,
    day,
}: StatusChangeCountsCardProps) => {
    const { t } = useTranslation();

    const typeDetail = useEventCountTypeDetails(type);
    const eventStatsQuery = useStatusChangeCounts(deviceId);

    const eventTypes = typeDetail.types.map((t) => t.type);

    const count = getCountForEventTypes(eventStatsQuery.data ?? [], eventTypes, day);
    const countYesterday = getCountForEventTypes(eventStatsQuery.data ?? [], eventTypes, subDays(day, 1));

    const countByEventType =
        eventTypes.length > 1 ? getCountByEventTypes(eventStatsQuery.data ?? [], eventTypes, day) : null;

    const trendIsPositive =
        (typeDetail.downIsPositive && count < countYesterday) || (!typeDetail.downIsPositive && count > countYesterday);
    const trend: Trend | undefined =
        countYesterday > 0
            ? {
                  value: `${Math.round((count / countYesterday) * 100).toFixed(0)}%`,
                  type: count === countYesterday ? "neutral" : trendIsPositive ? "positive" : "negative",
                  direction: count < countYesterday ? "down" : count > countYesterday ? "up" : undefined,
              }
            : undefined;

    return (
        <StatCard
            headline={title ?? `${t(type, { count })} events today`}
            description={description}
            value={count}
            trend={trend}
            icon={IconMap[type]}
        >
            {countByEventType ? (
                <p className={styles.emergencyPreventionEventsStatDetails}>
                    {typeDetail.types.map((details) => (
                        <Fragment key={details.title}>
                            {details.title} <strong>{countByEventType.get(details.type) ?? 0}</strong>
                            <br />
                        </Fragment>
                    ))}
                </p>
            ) : null}
        </StatCard>
    );
};
