import React from "react";
import { useTranslation } from "react-i18next";
import { useSetupContext } from "../SetupContext";
import { Navigate } from "react-router-dom";
import { canAccessSettings } from "../../../DeviceDetailsModal/settingsSchemaUtils";
import { PauseSwitch } from "../../../DeviceDetailsModal/Tabs/MiscTab/PauseSwitch";
import presenceKeyImage from "../../../../images/setup/PresenceKey.png";
import styles from "./PresenceKeyStep.module.css";
import {
    DeviceSettingsSchema,
    useDeviceSettingsSchema,
    useSaveDeviceSettings,
} from "../../../../api_v2/hooks/useDeviceSettings";

import { Settings } from "../../../../api_v2/types/custom";
import { useLocationContext } from "../../../../context/LocationContextProvider";

export const PresenceKeyStep = () => {
    const { t } = useTranslation();
    const { room, sensor, config } = useSetupContext();

    if (!sensor || !config) {
        return <Navigate to="./select-sensor" replace />;
    }
    const settingsSchemaQuery = useDeviceSettingsSchema(sensor.id);
    const settingsSchema: DeviceSettingsSchema | undefined = settingsSchemaQuery.data;

    const canAccessPauseButtonSettings = settingsSchema
        ? canAccessSettings("pauseSwitchButton", settingsSchema)
        : false;

    const locationContext = useLocationContext();
    const saveSettingsMutation = useSaveDeviceSettings(locationContext.facilityId);

    const handleSettingsChange = (newSettings: Settings) => {
        config.settings = newSettings;
        saveSettingsMutation.mutate({
            deviceId: sensor.id,
            data: {
                settings: config.settings,
            },
        });
    };

    return (
        <div className={styles.container}>
            <div>
                <strong>{t("stepPresenceKeyIntroTitle", "Configure nurse presence button:")}</strong>
                <br />
                <p>
                    {t(
                        "stepPresenceKeyIntroText",
                        "Please select a presence duration for care activities. Once the selected time elapses, the sensor will reactivate automatically. "
                    )}
                </p>
            </div>
            <div className={styles.infoGrid}>
                <div>
                    <img src={presenceKeyImage} />
                </div>
                <ul className={styles.infoText}>
                    <li>
                        {t("stepPresenceKeyRoomLabel", "Room:")} {room?.name}
                    </li>
                    <li>
                        {t("stepPresenceKeySerialNumberLabel", "Serial number:")}{" "}
                        {sensor?.name ? sensor?.name : sensor?.id}
                    </li>
                </ul>
            </div>
            {canAccessPauseButtonSettings && (
                <PauseSwitch
                    settings={config.settings}
                    onSettingsChange={handleSettingsChange}
                    readonly={false}
                    settingsSchema={
                        settingsSchemaQuery.data ?? {
                            type: "object",
                            properties: {},
                            required: [],
                            additionalProperties: false,
                        }
                    }
                />
            )}
        </div>
    );
};
