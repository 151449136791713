import React from "react";
import { Modal, ModalContent, ModalProps } from "../../components/Modal/Modal";
import { useAuth } from "../AuthContextProvider";
import { DisableMfa } from "../forms/DisableMfa";
import { EnableMfaModalContent } from "./EnableMfaModalContent";
import styles from "../Auth.module.css";

export const MfaModal = (props: ModalProps) => {
    const { mfaEnabled } = useAuth();

    const handleClose = () => {
        props.onClose();
    };

    return (
        <Modal {...props} size="prompt" disableScrollbar={true}>
            <ModalContent className={styles.mfaModalContent}>
                {mfaEnabled ? <DisableMfa onClose={handleClose} /> : <EnableMfaModalContent onClose={handleClose} />}
            </ModalContent>
        </Modal>
    );
};
