import { QueryFunctionContext, useInfiniteQuery } from "react-query";
import { AxiosRequestConfig, useFetch } from "@bitperfect-packages/react-query-axios";
import { Id } from "../types/custom";
import { components } from "../types/schema";
import { getTime, min } from "date-fns";
import { QueryKey } from "react-query/types/core/types";

export type Event = components["schemas"]["Event"];
export type Events = Array<Event>;
export type EventType = components["schemas"]["EventType"];

const requestConfigTransform = (context: QueryFunctionContext<QueryKey, Date>, config: AxiosRequestConfig) => {
    return {
        ...config,
        params: {
            ...config.params,
            before: context.pageParam ? getTime(context.pageParam) : config.params.before,
        },
    };
};

const getNextPageParam = (lastPage: Events): Date | undefined => {
    if (lastPage.length === 0) return undefined;
    return min(lastPage.map((e) => new Date(e.time)));
};

export interface EventsFilter {
    type?: EventType | null;
    before?: Date | null;
    limit?: number;
}

const filterToParams = (filter: EventsFilter | undefined) => {
    return {
        type: filter?.type ? encodeURIComponent(filter.type) : undefined,
        before: filter?.before ? getTime(filter.before) : undefined,
        limit: filter?.limit ?? 25,
    };
};

export const useListEventsForFacility = (facilityId: Id, filter: EventsFilter | undefined = undefined) => {
    return useInfiniteQuery<Events>(
        ["facilities", facilityId, "events", filter],
        useFetch<Events>(`/facilities/${facilityId}/events`, {
            config: { params: filterToParams(filter) },
            requestConfigTransform,
        }),
        {
            getNextPageParam,
        }
    );
};

export const useListEventsForArea = (areaId: Id, filter: EventsFilter | undefined = undefined) => {
    return useInfiniteQuery<Events>(
        ["areas", areaId, "events", filter],
        useFetch<Events>(`/areas/${areaId}/events`, {
            config: { params: filterToParams(filter) },
            requestConfigTransform,
        }),
        {
            getNextPageParam,
        }
    );
};

export const useListEventsForRoom = (roomId: Id, filter: EventsFilter | undefined = undefined) => {
    return useInfiniteQuery<Events>(
        ["rooms", roomId, "events", filter],
        useFetch<Events>(`/rooms/${roomId}/events`, {
            config: { params: filterToParams(filter) },
            requestConfigTransform,
        }),
        {
            getNextPageParam,
        }
    );
};
