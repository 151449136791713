import React from "react";
import { useTranslation } from "react-i18next";
import { TabList } from "../UI/TabList/TabList";
import { useSendDeviceCommand } from "../../api_v2/hooks/useSendDeviceCommand";
import { Dialog } from "@headlessui/react";
import { ModalHeader } from "../Modal/Modal";
import { DeviceWithStatus } from "../../views/RoomsView/helper/Nodes";
import { DeviceStatus } from "../Device/DeviceStatus";
import { useInvalidateSceneVisualization } from "../../api_v2/hooks/useSceneVisualizations";
import { callAfter } from "../../api_v2/hooks/common/useDelayedInvalidateQueries";
import { PopoverMenu } from "../PopoverMenu/PopoverMenu";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { toast } from "react-toastify";

import bellIcon from "../../images/bell.svg";
import restartIcon from "../../images/restart.svg";

import styles from "./DeviceDetailsModal.module.css";
import { PopoverMenuItem } from "../PopoverMenu/PopoverMenuItem";
import { Toast } from "../UI/Toast/Toast";

export interface RoomInfo {
    name: string;
    includesOtherSensors: boolean;
}

interface DeviceDetailsModalHeaderProps {
    device: DeviceWithStatus;
    roomInfo: RoomInfo;
    tabs: Array<string>;
    selectedTab: string;
    onTabSelect: (tab: string) => void;
}

export const DeviceDetailsModalHeader = ({
    device,
    roomInfo,
    tabs,
    selectedTab,
    onTabSelect,
}: DeviceDetailsModalHeaderProps) => {
    const { t } = useTranslation();

    const sendDeviceCommand = useSendDeviceCommand();

    const invalidateSceneVisualization = useInvalidateSceneVisualization();

    const deviceName = device.device.name ?? device.device.id.substring(0, 8);
    const title = !roomInfo.includesOtherSensors
        ? t("titleSensorDetailModal", "Sensor in Room {{roomName}}", { roomName: roomInfo.name })
        : t("titleSensorDetailModalWithSensorName", "Sensor {{sensorName}} in Room {{roomName}}", {
              sensorName: deviceName,
              roomName: roomInfo.name,
          });

    const handleTestEvent = async () => {
        sendDeviceCommand.mutate(
            {
                deviceId: device.device.id,
                type: "trigger-event",
                args: {},
            },
            {
                onSuccess: () => {
                    toast.success(
                        <Toast
                            title={t("alertTestAlertTriggeredTitle", "Test alarm triggered")}
                            description={t(
                                "alertTestAlertTriggeredDescription",
                                "The test alarm was triggered successfully."
                            )}
                        />
                    );
                },
                onError: () => {
                    toast.error(
                        <Toast
                            title={t("alertTestAlertFailedTitle", "Test alarm failed")}
                            description={t("alertTestAlertFailedDescription", "The test alarm could not be triggered.")}
                        />
                    );
                },
            }
        );
    };

    const handleRestart = async () => {
        sendDeviceCommand.mutate(
            {
                deviceId: device.device.id,
                type: "restart",
                args: {},
            },
            {
                onSuccess: () => {
                    toast.success(
                        <Toast
                            title={t("alertRestartTriggeredTitle", "Restart triggered")}
                            description={t(
                                "alertRestartTriggeredDescription",
                                "The restart was triggered successfully."
                            )}
                        />
                    );

                    callAfter(() => invalidateSceneVisualization(device.device.id), [5000]);
                },
                onError: () => {
                    toast.error(
                        <Toast
                            title={t("alertRestartFailedTitle", "Restart failed")}
                            description={t("alertRestartFailedDescription", "The restart failed.")}
                        />
                    );
                },
            }
        );
    };

    return (
        <ModalHeader>
            <div className={styles.header}>
                <Dialog.Title className={styles.titleWrapper}>
                    <strong className={styles.title}>{title}</strong>
                    <DeviceStatus pill={true} status={device.deviceStatus?.status} />
                </Dialog.Title>
                <div>
                    <PopoverMenu
                        Icon={BiDotsVerticalRounded}
                        itemList={true}
                        className={styles.popoverMenu}
                        id="DeviceDetailsModal_KebabMenu"
                    >
                        {(close) => (
                            <>
                                <PopoverMenuItem
                                    icon={restartIcon}
                                    onClick={() => {
                                        handleRestart();
                                        close();
                                    }}
                                    disabled={sendDeviceCommand.isLoading}
                                >
                                    {t("labelRestart", "Restart")}
                                </PopoverMenuItem>
                                <PopoverMenuItem
                                    icon={bellIcon}
                                    onClick={() => {
                                        handleTestEvent();
                                        close();
                                    }}
                                    disabled={sendDeviceCommand.isLoading}
                                >
                                    {t("labelTestAlert", "Test alarm")}
                                </PopoverMenuItem>
                            </>
                        )}
                    </PopoverMenu>
                </div>
            </div>

            <TabList
                items={tabs}
                getLabel={(t) => t}
                getKey={(t) => t}
                selected={selectedTab}
                onSelect={(t: string) => onTabSelect(t)}
                nullable={false}
                id="DeviceDetailsModalTabs"
            />
        </ModalHeader>
    );
};
