import React from "react";
import { Card } from "../../components/UI/Card/Card";
import { PasswordInput } from "../../components/UI/Input";
import { Label } from "../../components/UI/Label";
import { Button } from "../../components/UI/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthViewProps } from "./AuthView";
import { NewPassword } from "./NewPassword";
import styles from "../Auth.module.css";
import logo from "../../images/cogvisAI-bluegreen.svg";
import { SubmitHandler, useForm } from "react-hook-form";
import { EmailField } from "../fields/EmailField";
import { TOTPSetup } from "./TOTPSetup";
import { useAuthenticate } from "../cognito/useAuthenticate";

type Inputs = {
    username: string;
    password: string;
};

export const Login = (props: AuthViewProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const authenticate = useAuthenticate();

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        watch,
    } = useForm<Inputs>({ mode: "onTouched" });

    const username: string = watch("username");

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const username = data.username.toLowerCase();
        setValue<"username">("username", username, { shouldValidate: false, shouldDirty: false });
        authenticate.mutate(
            {
                Username: username,
                Password: data.password,
            },
            {
                onSuccess: () => props.refreshSession(),
                onError: (err) => {
                    if (err.type === "passwordResetRequired") {
                        navigate("/reset-password", {
                            replace: true,
                            state: {
                                Username: username,
                                // in case of admin induced password resets, the temporary password also is the
                                // verification code!
                                verificationCode: data.password,
                            },
                        });
                    }
                },
            }
        );
    };

    if (authenticate.isError && authenticate.error.type === "newPasswordRequired") {
        return (
            <NewPassword
                cognitoUser={authenticate.error.cognitoUser}
                refreshSession={props.refreshSession}
                sessionUserAttributes={authenticate.error.userAttributes}
            />
        );
    }

    if (authenticate.isError && authenticate.error.type === "totpRequired") {
        return (
            <TOTPSetup
                email={username}
                user={authenticate.error.cognitoUser}
                refreshSession={props.refreshSession}
                secret={authenticate.error.secret}
            />
        );
    }

    return (
        <Card className={styles.card}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <img src={logo} alt="CogvisAI" className={styles.logo} />
                {authenticate.isError && authenticate.error.type !== "passwordResetRequired" ? (
                    <p className={styles.error}>{t("textLoginInvalidCredentials", "Incorrect username or password")}</p>
                ) : null}
                <div>
                    <EmailField
                        id="UsernameInput"
                        name="username"
                        register={register}
                        errors={errors}
                        disabled={authenticate.isLoading}
                    />
                </div>
                <div>
                    <Label text={t("labelLoginPassword", "password *")} block={true}>
                        <PasswordInput
                            id="PasswordInput"
                            disabled={authenticate.isLoading}
                            autoComplete="current-password"
                            invalid={Boolean(errors.password)}
                            {...register("password", {
                                required: {
                                    value: true,
                                    message: t("errorRequiredField", "Required field"),
                                },
                                pattern: {
                                    value: /^\S+$/g,
                                    message: t("errorNoWhitespace", "This field cannot contain white spaces"),
                                },
                            })}
                        />
                        {errors.password ? <small className={styles.errorText}>{errors.password.message}</small> : null}
                    </Label>
                </div>

                <small className={styles.resetPassword}>
                    {t("promptForgotPassword", "Forgot password?")}{" "}
                    <Link to="/request-password-reset" id="ForgotPasswordLink">
                        {t("labelForgotPassword", "Reset password")}
                    </Link>
                </small>

                <Button
                    id="LoginButton"
                    color="primary"
                    variant="filled"
                    type="submit"
                    className={styles.button}
                    disabled={authenticate.isLoading || !isValid}
                >
                    {t("labelLogin", "Login")}
                </Button>
            </form>
        </Card>
    );
};
