import { Id } from "../types/custom";
import { useQuery } from "react-query";
import { useFetch } from "@bitperfect-packages/react-query-axios";
import { components } from "../types/schema";

export type FeatureFlag = components["schemas"]["FeatureFlag"];
export type FeatureFlags = Array<FeatureFlag>;
export const useListFeatureFlags = (facilityId: Id) => {
    return useQuery<FeatureFlags>(
        ["facilities", facilityId, "feature-flags"],
        useFetch<FeatureFlags>(`/facilities/${facilityId}/feature-flags`)
    );
};
