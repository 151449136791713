import styles from "./ColorScale.module.scss";

export const ColorScale = () => {
    const ticks = [1, 20, 40, 60, 80, 100];
    return (
        <>
            <div className={styles.scale}>
                {ticks.map((t) => (
                    <span key={t} />
                ))}
            </div>
            <div className={styles.ticks}>
                {ticks.map((t) => (
                    <span key={t}>{t}</span>
                ))}
            </div>
        </>
    );
};
