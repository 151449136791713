import { HTMLProps, PropsWithChildren } from "react";
import styles from "./Card.module.css";
import { c } from "../../../utils/ClassesHelper";

export const CardTitle = ({ children, className, ...props }: PropsWithChildren<HTMLProps<HTMLHeadingElement>>) => {
    return (
        <strong className={c([className, styles.title])} {...props}>
            {children}
        </strong>
    );
};
