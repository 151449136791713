import { useQuery, UseQueryResult } from "react-query";
import { useAuth } from "../AuthContextProvider";
import { minutes } from "../../utils/IntervalHelper";
import { CognitoUser } from "amazon-cognito-identity-js";

/**
 * Returns a secret to be used in the QR code for authenticator apps
 */
export const useAssociateSoftwareToken = (): UseQueryResult<string> => {
    const { user } = useAuth();

    return useQuery(
        "associateSoftwareToken",
        () => {
            return associateSoftwareToken(user);
        },
        {
            refetchOnWindowFocus: false,
            staleTime: minutes(15),
        }
    );
};

const associateSoftwareToken = (user: CognitoUser): Promise<string> =>
    new Promise((resolve, reject) => {
        user.associateSoftwareToken({
            associateSecretCode: (secretCode) => resolve(secretCode),
            onFailure: (err) =>
                reject({
                    type: "unknown",
                    message: err?.message ?? JSON.stringify(err),
                }),
        });
    });
