import React from "react";
import { Card } from "../../../components/UI/Card/Card";
import { Events } from "../../../api_v2/hooks/useEvents";
import { useTranslation } from "react-i18next";
import { EmptyItem } from "./EmptyItem";
import { Loading } from "../../../components/UI/Loading";
import { EventItem } from "./EventItem";
import styles from "./EventsList.module.css";
import { AreaRoom, Id } from "../../../api_v2/types/custom";

export interface EventsListProps {
    events: Events;
    rooms: Array<AreaRoom>;
    onEventSelect?: (eventId: Id | null) => void;
    isLoading?: boolean;
}

export const EventsList = ({ events, onEventSelect, isLoading, rooms }: EventsListProps) => {
    const { t } = useTranslation();

    const isReduced = false;
    const columnCount = isReduced ? 4 : 5;

    const sortedEvents = events.sort((a, b) => {
        const dateA = new Date(a.time).getTime();
        const dateB = new Date(b.time).getTime();

        return dateB - dateA;
    });

    return (
        <Card>
            <table className={styles.table} cellSpacing="0" cellPadding="0" id="EventsList">
                <thead>
                    <tr>
                        <th></th>
                        <th className={styles.roomName}>{t("labelRoom", "Room")}</th>
                        <th>{t("labelAlertsTableType", "Alarm type")}</th>
                        <th>{t("labelAlertsTableTime", "Date")}</th>
                        {!isReduced ? (
                            <>
                                <th className={styles.alertId}>{t("labelAlertsTableAlertId", "Alarm ID")}</th>
                            </>
                        ) : (
                            <></>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? (
                        <EmptyItem columnCount={columnCount}>
                            <Loading text={t("loadingAlarms", "Loading alarms...")} />
                        </EmptyItem>
                    ) : events.length === 0 ? (
                        <EmptyItem columnCount={columnCount}>{t("noAlarms", "No alarms")}</EmptyItem>
                    ) : (
                        sortedEvents.map((event) => {
                            const room = rooms.find((r) => r.id === event.roomId);
                            return (
                                <EventItem
                                    event={event}
                                    room={room}
                                    onSelect={() => onEventSelect?.(event.id)}
                                    key={event.id}
                                />
                            );
                        })
                    )}
                </tbody>
            </table>
        </Card>
    );
};
