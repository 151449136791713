import { useTranslation } from "react-i18next";
import {
    useGetBedActivityVisualizationImage,
    BedActivityVisualization,
} from "../../../../api_v2/hooks/useBedActivities";
import { Id } from "../../../../api_v2/types/custom";
import styles from "../Graph/ActivityGraph.module.css";

export interface ActivityImageProps {
    activityId: Id;
    frameId?: number | null;
    images: BedActivityVisualization;
}

export const ActivityImage = ({ activityId, frameId }: ActivityImageProps) => {
    const { t } = useTranslation();

    const previewImageQuery = useGetBedActivityVisualizationImage(activityId, frameId ?? null);

    const { w, h } = { w: 640, h: 480 };
    const fallbackText = previewImageQuery.isLoading
        ? t("labelEventImageLoading", "Loading")
        : t("labelEventImageNone", "No visualization");
    const fallbackSrc = `https://via.placeholder.com/${w}x${h}.png?text=${encodeURIComponent(fallbackText)}`;
    const src = previewImageQuery.isSuccess ? previewImageQuery.data : fallbackSrc;

    return <img src={src} width={w} height={h} className={styles.imageSeq} />;
};
