import React, { PropsWithChildren } from "react";
import styles from "./EventsList.module.css";

export interface EmptyItemProps {
    columnCount: number;
}

export const EmptyItem = ({ columnCount, children }: PropsWithChildren<EmptyItemProps>) => {
    return (
        <tr>
            <td colSpan={columnCount} className={styles.empty}>
                {children}
            </td>
        </tr>
    );
};
