import { AnalysisModuleStatus, Device, DeviceStatus } from "../../../api_v2/hooks/useDevices";
import { AreaRoom } from "../../../api_v2/types/custom";
import { RoomTree } from "./Nodes";
import { isSensor } from "../../../utils/DeviceHelper";

export const buildRoomTree = (
    rooms: AreaRoom[],
    devices: Device[],
    deviceStatuses: DeviceStatus[],
    analysisModuleStatuses: AnalysisModuleStatus[],
    onlySensors = true
): RoomTree => {
    const tree: RoomTree = rooms.map((room) => ({ room, devices: [] }));
    for (const device of devices) {
        if (!device.roomId) continue;
        if (onlySensors && !isSensor(device)) continue;

        const room = tree.find((room) => room.room.id === device.roomId);
        if (!room) continue;

        const deviceStatus = deviceStatuses.find((status) => status.deviceId === device.id);
        const moduleStatuses = analysisModuleStatuses.filter((s) => s.deviceId === device.id);
        room.devices.push({ device, deviceStatus, analysisModuleStatuses: moduleStatuses });
    }
    return tree;
};
