import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "../UI/Label";
import { Select } from "../UI/Select";
import { EventType } from "../../api_v2/hooks/useEvents";
import { useLocaleAwareCompare } from "../../utils/useLocaleAwareCompare";
import { useHasFeatureFlags } from "../../hooks/useHasFeatureFlags";

export interface EventTypeFilterProps {
    selected: EventType | null;
    onSelect: (type: EventType | null) => void;
    id?: string;
}

export const EventTypeFilter = ({ selected, onSelect, id }: EventTypeFilterProps) => {
    const { t } = useTranslation();
    const compare = useLocaleAwareCompare();
    const hasFeatureFlag = useHasFeatureFlags();

    // @ts-expect-error TS does not correctly infer that the items in fact are [EventType, string] and not string[]
    const options: Array<[EventType, string]> = [
        ["fall", t("alertTypeFall", "Fall")],
        ["getup", t("alertTypeFallPrevention", "Fall prevention")],
        ["absence", t("alertTypeAbsence", "Absence")],
        ["bed-rail", t("alertTypeLightBarrierCrossed", "Crossed bed rail")],
    ].toSorted(([, a], [, b]) => compare(a, b));

    if (hasFeatureFlag("enable-bed-restlessness")) {
        options.push(["bed-restlessness", t("alertTypeBedRestlessness", "Restlessness")]);
    }

    options.sort(([, a], [, b]) => compare(a, b));

    return (
        <Label text={t("labelAlertsFilterType", "Alarm type")}>
            <Select
                id={id}
                default={t("labelAll", "All")}
                items={new Map(options)}
                onSelect={onSelect}
                selected={selected}
            />
        </Label>
    );
};
