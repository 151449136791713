import React from "react";
import { DeviceWithStatus } from "../helper/Nodes";
import { DeviceStatus } from "../../../components/Device/DeviceStatus";
import styles from "./RoomsList.module.scss";

export interface DeviceStatusCellProps {
    device: DeviceWithStatus | null;
}

export const DeviceStatusCell = ({ device }: DeviceStatusCellProps) => {
    return <td className={styles.status}>{device && <DeviceStatus status={device.deviceStatus?.status} />}</td>;
};
