import React from "react";
import { Room } from "../helper/Nodes";
import { DeviceStatusCell } from "./SensorStatusCell";
import { c } from "../../../utils/ClassesHelper";
import { useNavigate } from "react-router-dom";
import styles from "./RoomsList.module.scss";
import { AnalysisModuleStatusCell } from "./AnalysisModuleStatusCell/AnalysisModuleStatusCell";
import { AdditionalButtonsCell } from "./AdditionalButtonCell";

export interface RoomProps {
    room: Room;
    columnCount: number;
}

export const RoomItem = (props: RoomProps) => {
    const deviceCount = props.room.devices.length;
    if (deviceCount === 0) return <EmptyRoomItem {...props} />;
    if (deviceCount === 1) return <CondensedRoomItem {...props} />;
    return <ExpandedRoomItem {...props} />;
};

export const EmptyRoomItem = ({ room, columnCount }: RoomProps) => {
    return (
        <tbody className={c([styles.condensed, styles.unused])} id={`Room_${room.room.id}`}>
            <tr>
                <th colSpan={columnCount}>{room.room.name}</th>
            </tr>
        </tbody>
    );
};

export const CondensedRoomItem = ({ room }: RoomProps) => {
    const navigate = useNavigate();
    const handleClick = () => navigate(`/rooms?device=${encodeURIComponent(room.devices[0].device.id)}&view=settings`);

    return (
        <tbody className={c([styles.condensed])} id={`Room_${room.room.id}`}>
            <tr onClick={handleClick} id={`Device_${room.devices[0].device.id}`}>
                <th>{room.room.name}</th>
                <DeviceStatusCell device={room.devices[0]} />
                <AnalysisModuleStatusCell statuses={room.devices[0].analysisModuleStatuses} device={room.devices[0]} />
                <AdditionalButtonsCell device={room.devices[0]} />
            </tr>
        </tbody>
    );
};

export const ExpandedRoomItem = ({ room, columnCount }: RoomProps) => {
    const navigate = useNavigate();
    const handleClick = (deviceId: string) => navigate(`/rooms?device=${encodeURIComponent(deviceId)}&view=settings`);

    return (
        <tbody className={styles.expanded} id={`Room_${room.room.id}`}>
            <tr>
                <th colSpan={columnCount}>{room.room.name}</th>
            </tr>

            {room.devices.map((device) => (
                <tr
                    className={styles.dockingStation}
                    key={device.device.id}
                    onClick={() => handleClick(device.device.id)}
                    id={`Device_${device.device.id}`}
                >
                    <td>{device.device.name ?? device.device.id.substring(0, 8)}</td>
                    <DeviceStatusCell device={device} />
                    <AnalysisModuleStatusCell statuses={device.analysisModuleStatuses} device={device} />
                    <AdditionalButtonsCell device={device} />
                </tr>
            ))}
        </tbody>
    );
};
