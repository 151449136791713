import React, { useState } from "react";
import { NavBarMenuButton } from "./NavBarMenuButton";
import { NavBarAvatar } from "./NavBarAvatar";
import { Link, useSearchParams } from "react-router-dom";
import { SideBarToggleButton } from "../../components/SideBarToggleButton";
import { Dropdown } from "./Dropdown";
import { DropdownItem } from "./DropdownItem";
import { useTranslation } from "react-i18next";
import { useSetLanguage } from "../../i18n";
import { useAuth } from "../../auth/AuthContextProvider";
import { useMe } from "../../api_v2/hooks/useNurses";

import styles from "./NavBar.module.css";

import logo from "../../images/cogvisAI_darkblue_RGB_V1.png";
import signOutIcon from "../../images/signOut.svg";
import helpIcon from "../../images/help.svg";
import { colors } from "../../utils/colors";
import { MfaModal } from "../../auth/modals/MfaModal";

const defaultHelpLangCode = "de-at";
const languageItems = new Map<string, { label: string; helpLangCode: string }>([
    ["da", { label: "Dansk (Danish)", helpLangCode: "da" }],
    ["de", { label: "Deutsch (German)", helpLangCode: defaultHelpLangCode }],
    ["en", { label: "English (English)", helpLangCode: "en" }],
    ["fr", { label: "Français (French)", helpLangCode: "fr" }],
    ["sv", { label: "Svenska (Swedish)", helpLangCode: "sv" }],
]);

export interface NavBarProps {
    isMenuOpen: boolean;
    setIsMenuOpen: (state: boolean) => void;
}

export const NavBar = (props: NavBarProps) => {
    const { t, i18n } = useTranslation();
    const setLanguage = useSetLanguage();
    const [searchParams, setSearchParams] = useSearchParams();
    const { logout, mfaEnabled } = useAuth();
    const [showMfaModal, setShowMfaModal] = useState(false);

    const userQuery = useMe();

    const showSidebarToggle = (userQuery.data?.facilities.length ?? 0) > 1;

    const avatarUrl = userQuery.isSuccess
        ? "https://eu.ui-avatars.com/api/?name=" + encodeURIComponent(userQuery.data.email.substring(0, 2))
        : "";

    const handleHelpCenter = () => {
        const langCode = languageItems.get(i18n.language)?.helpLangCode ?? defaultHelpLangCode;
        const url = `https://help.cogvis.ai/${langCode}`;
        window.open(url, "_blank")?.focus();
    };

    const handleLanguageChange = (lang: string) => {
        setSearchParams({ ...searchParams, lang: lang });
        setLanguage(lang);
    };

    const handleSignOut = () => {
        logout();
    };

    return (
        <div className={styles.navBar}>
            <div className={styles.left}>
                {showSidebarToggle ? <SideBarToggleButton id="Navbar_ToggleSidebar" {...props} /> : null}
                <Link to="/">
                    <img className={styles.logo} src={logo} alt="" />
                </Link>
            </div>
            <div className={styles.right}>
                <NavBarMenuButton id="RoomsMenuLink" to="/rooms">
                    {t("labelRooms", "Rooms")}
                </NavBarMenuButton>
                <NavBarMenuButton id="EventsMenuLink" to="/events">
                    {t("labelAlarms", "Alarms")}
                </NavBarMenuButton>
                <Dropdown id="Navbar_MenuButton" root={<NavBarAvatar url={avatarUrl} />}>
                    {Array.from(languageItems.entries())
                        .filter(([key]) => key !== i18n.language)
                        .map(([key, { label }]) => (
                            <DropdownItem
                                id={`LanguageSelect_${key}`}
                                key={key}
                                title={label}
                                bigIcon={false}
                                onClick={() => handleLanguageChange(key)}
                            />
                        ))}

                    <DropdownItem id="TwoFactorAuthentication" bigIcon={true} onClick={() => setShowMfaModal(true)}>
                        {t("MFA.Toggle", "Two-factor authentication: ")}
                        {mfaEnabled ? (
                            <span style={{ color: colors.darkGreen }}>{t("MFA.enabled", "Enabled")}</span>
                        ) : (
                            <span style={{ color: colors.orange }}>{t("MFA.disabled", "Disabled")}</span>
                        )}
                    </DropdownItem>

                    <DropdownItem
                        id="HelpCenterButton"
                        icon={helpIcon}
                        title={t("helpCenter", "Help Center")}
                        bigIcon={true}
                        onClick={handleHelpCenter}
                    />

                    <DropdownItem
                        id="LogoutButton"
                        icon={signOutIcon}
                        title={t("logout", "Logout ")}
                        bigIcon={true}
                        onClick={handleSignOut}
                    />
                </Dropdown>
            </div>
            {showMfaModal ? <MfaModal onClose={() => setShowMfaModal(false)} /> : null}
        </div>
    );
};
