import React from "react";
import { Navigate, Route, Routes as RouterRoutes } from "react-router-dom";
import { RoomsView } from "./views/RoomsView/RoomsView";
import { EventsView } from "./views/EventsView/EventsView";
import { EventDetailsView } from "./views/EventDetailsView/EventDetailsView";
import { DashboardView } from "./views/DashboardView/DashboardView";
import { useCanAccessFeature } from "./hooks/useCanAccessFeature";

export const Routes = (): JSX.Element => {
    const canAccessFeature = useCanAccessFeature();

    return (
        <RouterRoutes>
            <Route path="/rooms/*" element={<RoomsView />} />
            <Route path="/events" element={<EventsView />} />
            <Route path="/events/:eventId" element={<EventDetailsView />} />
            {canAccessFeature("Dashboard") ? <Route path="/dashboard/*" element={<DashboardView />} /> : null}
            <Route path="*" element={<Navigate to="/rooms" replace />} />
        </RouterRoutes>
    );
};
