import { TabProps } from "../TabProps";
import { SceneLabelEditView } from "./SceneLabelEditView";
import { MonitoringView } from "../Common/MonitoringView";
import { BedLocationsSettings } from "./BedLocationSettings/BedLocationSettings";
import { BedRailSettings } from "./LightBarrierSettings/LightBarrierSettings";
import { useTranslation } from "react-i18next";

export const BedMonitoringTab = (props: TabProps) => {
    const { t } = useTranslation();
    return props.sceneLabelEditModeActive ? (
        <SceneLabelEditView {...props} />
    ) : (
        <MonitoringView {...props} showBedRails={true} allowEditMode={true} enableRoomSettings={false}>
            {(props, selectedSceneLabel) => {
                if (selectedSceneLabel?.type === "bed-point") {
                    return <BedLocationsSettings {...props} selectedBedLocation={selectedSceneLabel} />;
                } else if (selectedSceneLabel?.type === "line-2d") {
                    return <BedRailSettings {...props} selectedBedRail={selectedSceneLabel} />;
                }

                return (
                    <p>
                        {t(
                            "BedMonitoringTab.WarningEditPointsToActivate",
                            "Edit points to activate fall prevention, absence detection or the virtual bed rail"
                        )}
                    </p>
                );
            }}
        </MonitoringView>
    );
};
