import useLocalStorage from "./useLocalStorage";
import { useUser } from "../context/UserContext";

/**
 * Prefixes any given key be the users' id, making it user specific.
 * Prevents settings from spilling over to other users.
 */
export const useUserSpecificLocalStorage = <T>(key: string, initialValue: T) => {
    const user = useUser();
    const userSpecificKey = `${user.id}-${key}`;
    return useLocalStorage(userSpecificKey, initialValue);
};
