import { useQuery, useQueryClient } from "react-query";
import { useFetch } from "@bitperfect-packages/react-query-axios";
import { Id } from "../types/custom";
import { components } from "../types/schema";
import { UseQueryOptions } from "react-query/types/react/types";
import { minutes } from "../../utils/IntervalHelper";

export type Device = components["schemas"]["Device"];
export type DeviceStatus = components["schemas"]["DeviceStatus"];
export type DeviceStatusType = components["schemas"]["DeviceStatusType"];
export type AnalysisModuleStatus = components["schemas"]["AnalysisModuleStatus"];
export type AnalysisModuleType = components["schemas"]["AnalysisModuleType"];

export const useListDevices = (facilityId: Id) => {
    return useQuery<Array<Device>>(
        ["facilities", facilityId, "devices"],
        useFetch<Array<Device>>(`/facilities/${facilityId}/devices`)
    );
};

export const useInvalidateDevices = () => {
    const queryClient = useQueryClient();
    return (facilityId: Id) => queryClient.invalidateQueries({ queryKey: ["facilities", facilityId, "devices"] });
};

export const useListDeviceStatuses = (facilityId: Id, options?: UseQueryOptions<Array<DeviceStatus>>) => {
    return useQuery<Array<DeviceStatus>>(
        ["facilities", facilityId, "devices-statuses"],
        useFetch<Array<DeviceStatus>>(`/facilities/${facilityId}/device-statuses`),
        {
            refetchInterval: minutes(3),
            ...options,
        }
    );
};

export const useInvalidateDeviceStatuses = () => {
    const queryClient = useQueryClient();
    return (facilityId: Id) =>
        queryClient.invalidateQueries({ queryKey: ["facilities", facilityId, "devices-statuses"] });
};

export const useListAnalysisModuleStatuses = (facilityId: Id) => {
    return useQuery<Array<AnalysisModuleStatus>>(
        ["facilities", facilityId, "analysis-module-statuses"],
        useFetch<Array<AnalysisModuleStatus>>(`/facilities/${facilityId}/analysis-module-statuses`),
        { refetchInterval: minutes(3) }
    );
};

export const useInvalidateAnalysisModuleStatuses = () => {
    const queryClient = useQueryClient();
    return (facilityId: Id) =>
        queryClient.invalidateQueries({ queryKey: ["facilities", facilityId, "analysis-module-statuses"] });
};

export const useInvalidateDeviceStatus = () => {
    const queryClient = useQueryClient();
    return (deviceId: Id) => queryClient.invalidateQueries({ queryKey: ["devices", deviceId, "status"] });
};

export const useDeviceStatus = (deviceId: Id) => {
    return useQuery<DeviceStatus>(
        ["devices", deviceId, "status"],
        useFetch<DeviceStatus>(`/devices/${deviceId}/status`)
    );
};

export const usePollDeviceStatus = (
    deviceId: Id | undefined,
    enabled: boolean,
    refetchInterval: number | ((data: DeviceStatus | undefined) => number) = 15000
) => {
    return useQuery<DeviceStatus>(
        ["devices", deviceId, "status"],
        useFetch<DeviceStatus>(`/devices/${deviceId}/status`),
        {
            refetchInterval,
            enabled: enabled && deviceId !== undefined,
        }
    );
};
