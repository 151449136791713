import React from "react";
import { Card } from "../../components/UI/Card/Card";
import { Label } from "../../components/UI/Label";
import { PasswordInput } from "../../components/UI/Input";
import { Button } from "../../components/UI/Button/Button";
import { useTranslation } from "react-i18next";
import { AuthViewProps } from "./AuthView";
import { CognitoUser } from "amazon-cognito-identity-js";
import styles from "../Auth.module.css";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCompleteNewPasswordChallenge } from "../cognito/useCompleteNewPasswordChallenge";

export interface NewPasswordProps extends AuthViewProps {
    cognitoUser: CognitoUser;
    sessionUserAttributes: unknown;
}

type Inputs = {
    password: string;
    passwordConfirm: string;
};

export const NewPassword = ({ refreshSession, ...props }: NewPasswordProps) => {
    const { t } = useTranslation();

    const newPassword = useCompleteNewPasswordChallenge();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>({ mode: "onTouched" });

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        newPassword.mutate(
            {
                ...props,
                newPassword: data.password,
            },
            {
                onSuccess: () => {
                    refreshSession();
                },
            }
        );
    };

    return (
        <Card className={styles.card}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <strong className={styles.title}>{t("titleRequestPasswordReset", "Reset your password")}</strong>

                {newPassword.isError ? <p className={styles.error}>{t(newPassword.error)}</p> : null}

                <div>
                    <Label text={t("labelPasswordResetNewPassword", "New password *")} block={true}>
                        <PasswordInput
                            id="PasswordInput"
                            placeholder={t("placeholderPasswordResetNewPassword", "Enter your new password")}
                            disabled={newPassword.isLoading}
                            autoComplete="new-password"
                            invalid={Boolean(errors.password)}
                            {...register("password", {
                                required: {
                                    value: true,
                                    message: t("errorRequiredField", "Required field"),
                                },
                                pattern: {
                                    value: /[^A-Za-z0-9]/i,
                                    message: t(
                                        "errorPasswordPattern",
                                        "Your password must be at least 10 characters long and contain at least one symbol."
                                    ),
                                },
                                minLength: {
                                    value: 10,
                                    message: t(
                                        "errorPasswordPattern",
                                        "Your password must be at least 10 characters long and contain at least one symbol."
                                    ),
                                },
                            })}
                        />
                        {errors.password ? <small className={styles.errorText}>{errors.password.message}</small> : null}
                    </Label>
                </div>
                <div>
                    <Label text={t("labelPasswordResetNewPasswordConfirm", "Confirm new password *")} block={true}>
                        <PasswordInput
                            id="NewPasswordInputConfirm"
                            placeholder={t("placeholderPasswordResetNewPasswordConfirm", "Confirm your new password")}
                            required={true}
                            disabled={newPassword.isLoading}
                            autoComplete="new-password"
                            invalid={Boolean(errors.passwordConfirm)}
                            {...register("passwordConfirm", {
                                required: {
                                    value: true,
                                    message: t("errorRequiredField", "Required field"),
                                },
                                validate: {
                                    confirm: (value, formValues) => {
                                        if (value === formValues.password) {
                                            return true;
                                        }

                                        return t("errorPasswordConfirmNotEqual", "Your passwords do not match");
                                    },
                                },
                            })}
                        />
                        {errors.passwordConfirm ? (
                            <small className={styles.errorText}>{errors.passwordConfirm.message}</small>
                        ) : null}
                    </Label>
                </div>
                <div className={styles.bottom}>
                    <Button id="ResetPasswordButton" type="submit" primary={true} className={styles.button}>
                        {t("textResetPasswordButton", "Submit")}
                    </Button>
                </div>
            </form>
        </Card>
    );
};
