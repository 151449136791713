import React from "react";
import { Tooltip } from "../../components/Tooltip/Tooltip";
import { BiSolidInfoCircle } from "react-icons/bi";
import { EventIcon } from "../../components/Events/EventIcon";
import styles from "./CardsTitlesAndTooltips.module.scss";

interface DailyCardsTitleType {
    title: string;
    tooltip: string;
    emergencyPrevention?: boolean;
}

export const CardsTitlesAndTooltips = ({ title, tooltip, emergencyPrevention }: DailyCardsTitleType) => {
    return (
        <span>
            {title}
            <Tooltip
                panel={
                    <div className={styles.panel}>
                        {tooltip}{" "}
                        {emergencyPrevention ? (
                            <div className={styles.icons}>
                                <EventIcon type="getup" colored={false} autoHeight={true} />
                                <EventIcon type="absence" colored={false} autoHeight={true} />
                                <EventIcon type="bed-rail" colored={false} autoHeight={true} />
                            </div>
                        ) : null}
                    </div>
                }
            >
                <BiSolidInfoCircle className={styles.infoIcon} />
            </Tooltip>
        </span>
    );
};
