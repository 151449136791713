import React from "react";
import { Circle, Group, Text } from "react-konva";
import { AugmentedSceneLabel } from "../AugmentedSceneLabel";
import { SceneLabelProps } from "./SceneLabelProps";
import { useSetSceneLabelAndAwaitVerification } from "../../../api_v2/hooks/useSceneLabels";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import { setMouseCursorForStage } from "./SceneLabelHelper";

export const getFillForBedPoint = (augmentedSceneLabel: AugmentedSceneLabel, selected?: boolean) => {
    if (augmentedSceneLabel.isLoading) {
        return "#f18c27ff";
    } else if (!augmentedSceneLabel.isValid) {
        return "#f00";
    } else if (augmentedSceneLabel.isFocused || selected) {
        return "#f15a40";
    } else {
        return "#aeb5bb";
    }
};

export const getStrokeForBedPoint = (augmentedSceneLabel: AugmentedSceneLabel, selected?: boolean) => {
    if (augmentedSceneLabel.isLoading) {
        return "#f3b284";
    } else if (!augmentedSceneLabel.isValid) {
        return "#ff5e5e";
    } else if (augmentedSceneLabel.isFocused || selected) {
        return "#ffb6a9";
    } else {
        return "#ffffff";
    }
};

export const SceneLabelBedPoint = ({
    deviceId,
    index,
    augmentedSceneLabel,
    onAugmentedSceneLabelUpdate,
    readonly,
}: SceneLabelProps) => {
    const setMutation = useSetSceneLabelAndAwaitVerification(deviceId);

    const draggable = readonly !== true && !augmentedSceneLabel.isLoading;

    const handleDragStart = () => {
        onAugmentedSceneLabelUpdate({
            ...augmentedSceneLabel,
            isDragging: true,
        });
    };

    const handleDragMove = (e: Konva.KonvaEventObject<DragEvent>) => {
        onAugmentedSceneLabelUpdate({
            ...augmentedSceneLabel,
            currentX: e.target.x(),
            currentY: e.target.y(),
        });
    };

    const handleDragEnd = async () => {
        onAugmentedSceneLabelUpdate({
            ...augmentedSceneLabel,
            isDragging: false,
            isLoading: true,
        });

        let result: [boolean, string];

        const isNewlyCreated = augmentedSceneLabel.isNew;

        try {
            result = await setMutation.mutateAsync({
                data: {
                    ...augmentedSceneLabel.sceneLabel,
                    type: "bed-point",
                    properties: {
                        x: Math.round(augmentedSceneLabel.currentX),
                        y: Math.round(augmentedSceneLabel.currentY),
                    },
                },
                sceneLabelId: augmentedSceneLabel.sceneLabel.id,
            });
        } catch (err) {
            result = [false, "unknown-error"];
        }

        onAugmentedSceneLabelUpdate(
            {
                ...augmentedSceneLabel,
                isLoading: false,
                isValid: result[0],
                isFocused: false, // unfocus event is not triggered during validation sometimes
                isNew: augmentedSceneLabel.isNew && !result[0],
                error: result[1],
            },
            result[0] && isNewlyCreated
        );
    };

    const handleMouseEnter = (e: KonvaEventObject<MouseEvent>) => {
        setMouseCursorForStage(e, draggable);

        onAugmentedSceneLabelUpdate({
            ...augmentedSceneLabel,
            isFocused: true,
        });
    };

    const handleMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
        setMouseCursorForStage(e, draggable);

        onAugmentedSceneLabelUpdate({
            ...augmentedSceneLabel,
            isFocused: false,
        });
    };

    return (
        <Group
            id={augmentedSceneLabel.id}
            x={augmentedSceneLabel.currentX}
            y={augmentedSceneLabel.currentY}
            draggable={draggable}
            onDragStart={handleDragStart}
            onDragMove={handleDragMove}
            onDragEnd={handleDragEnd}
        >
            <Circle
                id={augmentedSceneLabel.id}
                radius={20}
                fill={getFillForBedPoint(augmentedSceneLabel)}
                stroke={getStrokeForBedPoint(augmentedSceneLabel)}
                opacity={augmentedSceneLabel.isNew ? 0.5 : 1}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
            <Text
                id={augmentedSceneLabel.id}
                x={-5}
                y={-8}
                align="center"
                verticalAlign="middle"
                text={`${index + 1}`}
                fontSize={20}
                fontFamily="Roboto"
                fill={"#fff"}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
        </Group>
    );
};
