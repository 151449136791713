import { AxiosResponse } from "axios";

export const demoDataRecorder = (record: Record<string, unknown>) => {
    return (response: AxiosResponse) => {
        const queryParams = new URLSearchParams(response.config.params);
        const url = response.config.url + (queryParams.size > 0 ? "?" + queryParams.toString() : "");

        record[url] = response.data;

        return response;
    };
};
