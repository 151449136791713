import React, { useState } from "react";
import { c } from "../../../utils/ClassesHelper";
import { CardTitle } from "../../../components/UI/Card/CardTitle";
import { CardDescription } from "../../../components/UI/Card/CardDescription";
import { CardContent } from "../../../components/UI/Card/CardContent";
import { Card } from "../../../components/UI/Card/Card";
import { Id } from "../../../api_v2/types/custom";
import { Trans, useTranslation } from "react-i18next";
import { DateInterval } from "../../../utils/DateInterval";
import { DataLabel } from "../common/DataLabel";
import { CardsTitlesAndTooltips } from "../CardsTitlesAndTooltips";
import { ActivityHeatMap, useListMultipleActivities } from "../../../api_v2/hooks/useHeatMap";
import { subHours } from "date-fns";
import { AreaChartWithDayAndNight } from "../Charts/AreaChartWithDayAndNight";
import { useNavigate } from "react-router-dom";
import { route } from "../../../utils/RouteHelper";
import { colors } from "../../../utils/colors";
import {
    applyGaussianFilter,
    getTransitionEntries,
    getNormalizedDayAndNightSeparatedActivityData,
} from "../data/useActivityDataHelpers";
import styles from "../DashboardView.module.scss";
import cardStyles from "../Container/CardGrid.module.scss";

export interface WeeklyRoomActivityCardProps {
    deviceId: Id;
    interval: DateInterval;
}

export const WeeklyRoomActivityCard = ({ deviceId, interval }: WeeklyRoomActivityCardProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [includeNight, setIncludeNight] = useState<boolean>(true);
    const [includeDay, setIncludeDay] = useState<boolean>(true);

    const days = 8;

    const roomActivitiesQueries = useListMultipleActivities(
        new Array(days).fill(0).flatMap((_, i) => {
            const before = subHours(interval.end, i * 24);
            return { type: "room", deviceId, params: { before } };
        })
    );

    const roomActivities: Array<ActivityHeatMap> = roomActivitiesQueries.flatMap(
        // @ts-expect-error UseQueryResult ist not correctly typed, we don't know why though
        (query): Array<ActivityHeatMap> => query.data ?? []
    );

    const sortedData = getNormalizedDayAndNightSeparatedActivityData(
        roomActivities,
        interval.end,
        days * 24,
        1
    ).reverse();
    const smoothData = applyGaussianFilter(sortedData, 6, 2);
    const data = getTransitionEntries(smoothData, includeDay, includeNight);

    const handleClick = () => {
        navigate(
            route({
                to: "rooms",
                view: {
                    type: "analysis",
                    deviceId,
                    mode: "month",
                },
            })
        );
    };

    return (
        <Card className={c([styles.card, cardStyles.md2, cardStyles.lg2, cardStyles.xl2])}>
            <CardTitle>
                <CardsTitlesAndTooltips
                    title={t("titleWeeklyRoomActivityCard", "Room activity")}
                    tooltip={t(
                        "tooltipWeeklyRoomActivityCard",
                        "Shows the summary of person movement. Night depicts period 7pm to 7am."
                    )}
                />
            </CardTitle>
            <CardDescription>
                <Trans
                    // t("descriptionWeeklyRoomActivityCard", "Room activity divided into <Day>day</Day> and <Night>night</Night>")
                    i18nKey="descriptionWeeklyRoomActivityCard"
                    default="Room activity divided into <Day>day</Day> and <Night>night</Night>"
                    components={{
                        Day: (
                            <DataLabel
                                type="roomActivityDay"
                                active={includeDay}
                                onClick={() => setIncludeDay(!includeDay)}
                            />
                        ),
                        Night: (
                            <DataLabel
                                type="roomActivityNight"
                                active={includeNight}
                                onClick={() => setIncludeNight(!includeNight)}
                            />
                        ),
                    }}
                />
            </CardDescription>
            <CardContent>
                <AreaChartWithDayAndNight
                    data={data}
                    rangeMax={100}
                    customYTicks={true}
                    areaOnClick={handleClick}
                    dayColor={colors.roomActivityDay}
                    nightColor={colors.roomActivityNight}
                />
            </CardContent>
        </Card>
    );
};
