/**
 * ClassNames Helper - Takes an array or object with class names and returns them filtered and stringified
 *
 * Takes:
 * - An array of classes, filters out any non string values and joins them or
 * - An object with classes as keys and booleans as values and uses only the classes with true values
 * - just class names
 * or any combination of it.
 *
 * Example:
 * <p classNames={c(['test', undefined, null])} /> will result in <p class="test" />
 *     or
 * <p classNames={c({ active: true, hovered: false })} /> will result in <p class="active" />
 *     or
 * <p classNames={c(['test', 'null], { active: true, hovered: false })} /> will result in <p class="test active" />
 *     or
 * <p classNames={c('test', null, ['something'], { active: true, hovered: false })} /> will result in <p class="test active" />
 *
 * For dynamic class names you can use this syntax:
 * c({
 *     [styles.myClassName]: true,
 *     [styles.myOtherClassName]: isSomeBoolVariable
 * })
 *
 */
export const c = (
    ...args: Array<string | undefined | null | Array<string | undefined | null> | Record<string, boolean>>
): string =>
    args
        .filter((value) => value !== null && value !== undefined)
        .map((classes) =>
            typeof classes === "string"
                ? classes
                : Array.isArray(classes)
                ? classes.filter((c): c is string => typeof c === "string").join(" ")
                : Object.entries(classes)
                      .filter(([, value]) => value)
                      .map(([key]) => key)
                      .join(" ")
        )
        .join(" ");
