import { useQuery } from "react-query";
import { useFetch } from "@bitperfect-packages/react-query-axios";
import { Id } from "../types/custom";
import { components } from "../types/schema";

export type HourlyDeviceVisibility = components["schemas"]["HourlyDeviceVisibility"];
export const useHourlyVisibility = (deviceId: Id) => {
    const url = `/devices/${deviceId}/hourly-visibilities`;
    return useQuery([url], useFetch<Array<HourlyDeviceVisibility>>(url));
};
