import React, { PropsWithChildren, ReactNode } from "react";
import { Button } from "./Button/Button";
import ReactDOM from "react-dom";

import styles from "./Tooltip.module.css";

type TooltipProps = {
    icon?: unknown;
    title?: string;
    description?: string | ReactNode;
    descriptionCondensed?: boolean;
    additionalClassNames?: string;
    left?: string;
    right?: string;
    top?: string;
    type?: "error" | "info";
    disableMinWidth?: boolean;
    onClose?: () => void;
    portal?: React.RefObject<Element>;
};

/** @deprecated Replace with a tooltip library */
class Tooltip extends React.Component<PropsWithChildren<TooltipProps>> {
    render(): ReactNode {
        const isClosable = this.props.onClose;

        const classNames = [
            styles.tooltip,
            this.props.type ? styles[this.props.type] : null,
            this.props.type ? styles.big : null, // error and info are "big"
            this.props.disableMinWidth ? styles.noMinWidth : null,
            isClosable ? styles.closable : null,
            this.props.additionalClassNames,
        ];

        const descriptionClassNames = this.getDescriptionClassNames();

        const style: React.CSSProperties = {
            top: this.props.top || "calc(100% + 12px)",
        };

        if (this.props.left) {
            style.left = this.props.left;
        } else if (this.props.right) {
            style.right = this.props.right;
            classNames.push(styles.right);
        } else {
            style.left = "50%";
        }

        const tooltip = (
            <div className={classNames.join(" ")} style={style}>
                {this.props.icon ? <span>icon missing</span> : null}

                <strong className={styles.title}>{this.props.title}</strong>

                <div className={styles.content}>
                    <div className={descriptionClassNames.join(" ")}>{this.props.description}</div>
                    {this.props.children}
                </div>

                {isClosable ? (
                    <Button
                        borderless={true}
                        icon="close"
                        className={styles.closeButton}
                        onClick={() => this.close()}
                    />
                ) : null}
            </div>
        );

        if (this.props.portal && this.props.portal?.current) {
            return <>{ReactDOM.createPortal(tooltip, this.props.portal.current)}</>;
        }

        return tooltip;
    }

    private close() {
        this.props.onClose?.();
    }

    private getDescriptionClassNames() {
        const descriptionClassNames = [styles.description];

        if (this.props.descriptionCondensed) {
            descriptionClassNames.push(styles.condensed);
        }

        return descriptionClassNames;
    }
}

export { Tooltip };
