import React from "react";
import { Label } from "../../components/UI/Label";
import { Input, PasswordInput } from "../../components/UI/Input";
import { Button } from "../../components/UI/Button/Button";
import { Card } from "../../components/UI/Card/Card";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";
import { AuthViewProps } from "./AuthView";
import { useAuthenticate } from "../cognito/useAuthenticate";
import { useConfirmPassword } from "../cognito/useConfirmPassword";
import { EmailField } from "../fields/EmailField";
import styles from "../Auth.module.css";

type Inputs = {
    username: string;
    code: string;
    password: string;
    passwordConfirm: string;
};

export const ResetPassword = ({ refreshSession }: AuthViewProps) => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const navigate = useNavigate();

    const confirmPassword = useConfirmPassword();
    const authenticate = useAuthenticate();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<Inputs>({ mode: "onTouched" });

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const username = (state.Username ?? data.username).toLowerCase();
        setValue("username", username, { shouldValidate: false, shouldDirty: false });

        confirmPassword.mutate(
            {
                authenticationData: { Username: username },
                verificationCode: state?.verificationCode ?? data.code,
                newPassword: data.password,
            },
            {
                onSuccess: () => {
                    authenticate.mutate(
                        {
                            Username: username,
                            Password: data.password,
                        },
                        {
                            onSuccess: () => refreshSession(),
                            onError: () => navigate("/login"),
                        }
                    );
                },
            }
        );
    };

    return (
        <Card className={styles.card}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <strong className={styles.title}>{t("titleRequestPasswordReset", "Reset your password")}</strong>

                {confirmPassword.isError ? <p className={styles.error}>{t(confirmPassword.error)}</p> : null}

                {state?.Username ? null : (
                    <div>
                        <EmailField
                            id="UsernameInput"
                            name="username"
                            register={register}
                            errors={errors}
                            disabled={confirmPassword.isLoading || authenticate.isLoading}
                        />
                    </div>
                )}

                {state?.verificationCode ? null : (
                    <div>
                        <Label text={t("labelPasswordResetCode", "verification code *")} block={true}>
                            <Input
                                id="ResetCodeInput"
                                type="text"
                                placeholder={t("placeholderPasswordResetCode", "Enter code")}
                                disabled={confirmPassword.isLoading || authenticate.isLoading}
                                autoComplete="off"
                                invalid={Boolean(errors.code)}
                                {...register("code", {
                                    required: true,
                                })}
                            />
                        </Label>
                    </div>
                )}

                <div>
                    <Label text={t("labelPasswordResetNewPassword", "New password *")} block={true}>
                        <PasswordInput
                            id="NewPasswordInput"
                            placeholder={t("placeholderPasswordResetNewPassword", "Enter your new password")}
                            disabled={confirmPassword.isLoading || authenticate.isLoading}
                            autoComplete="new-password"
                            invalid={Boolean(errors.password)}
                            {...register("password", {
                                required: {
                                    value: true,
                                    message: t("errorRequiredField", "Required field"),
                                },
                                pattern: {
                                    value: /[^A-Za-z0-9]/i,
                                    message: t(
                                        "errorPasswordPattern",
                                        "Your password must be at least 10 characters long and contain at least one symbol."
                                    ),
                                },
                                minLength: {
                                    value: 10,
                                    message: t(
                                        "errorPasswordPattern",
                                        "Your password must be at least 10 characters long and contain at least one symbol."
                                    ),
                                },
                            })}
                        />
                        {errors.password ? <small className={styles.errorText}>{errors.password.message}</small> : null}
                    </Label>
                </div>
                <div>
                    <Label text={t("labelPasswordResetNewPasswordConfirm", "Confirm new password *")} block={true}>
                        <PasswordInput
                            id="NewPasswordInputConfirm"
                            placeholder={t("placeholderPasswordResetNewPasswordConfirm", "Confirm your new password")}
                            disabled={confirmPassword.isLoading || authenticate.isLoading}
                            autoComplete="new-password"
                            invalid={Boolean(errors.passwordConfirm)}
                            {...register("passwordConfirm", {
                                required: {
                                    value: true,
                                    message: t("errorRequiredField", "Required field"),
                                },
                                validate: {
                                    confirm: (value, formValues) => {
                                        if (value === formValues.password) {
                                            return true;
                                        }

                                        return t("errorPasswordConfirmNotEqual", "Your passwords do not match");
                                    },
                                },
                            })}
                        />
                        {errors.passwordConfirm ? (
                            <small className={styles.errorText}>{errors.passwordConfirm.message}</small>
                        ) : null}
                    </Label>
                </div>
                <div className={styles.bottom}>
                    <small className={styles.resetPassword}>
                        <Link to="/login">{t("promptBackToLogin", "Back to login")}</Link>
                    </small>
                    <Button
                        id="ResetPasswordButton"
                        type="submit"
                        primary={true}
                        className={styles.button}
                        disabled={confirmPassword.isLoading || authenticate.isLoading}
                    >
                        {t("textResetPasswordButton", "Submit")}
                    </Button>
                </div>
            </form>
        </Card>
    );
};
