import React from "react";
import _ from "lodash";
import { colors } from "../../../../utils/colors";
import { useNow } from "../../../../hooks/useNow";
import styles from "./ClockFaceChart.module.scss";
import { getHandData, getMarkData } from "./ClockFaceHelpers";
import { isNightShift } from "../../../../utils/DayNightShift";

export interface ClockFaceProps {
    showSecondsHandle?: boolean;
}

export const ClockFace = ({ showSecondsHandle }: ClockFaceProps) => {
    const now = useNow(showSecondsHandle ? "second" : "minute");

    const minuteDegrees = (now.getMinutes() / 60) * 360 - 90;
    const minuteHand = getHandData(minuteDegrees, 25);

    // hours / 12 = time from 0-1 * 360 = degrees for current hour - 90 to rate 90deg ccw, to start on top
    // + minutes / 60 = current minutes * 30 (= 360 / 12 segments) to move the hour handle to the middle of e.g.
    // 11:00 and 12:00 when the minutes are :30
    const hourDegrees = (now.getHours() / 12) * 360 - 90 + (now.getMinutes() / 60) * 30;
    const hourHand = getHandData(hourDegrees, 20);

    let secondHand = null;
    if (showSecondsHandle) {
        const secondDegrees = (now.getSeconds() / 60) * 360 - 90;
        secondHand = getHandData(secondDegrees, 30);
    }

    const mark = getMarkData(120); // 7:00

    return (
        <div className={styles.clockFace}>
            <svg width={100} height={100}>
                {_.range(0, 12, 1).map((i) => {
                    const mark = getMarkData(i * (360 / 12));

                    return <line key={i} {...mark} stroke={colors.clock.text} opacity={0.5} />;
                })}
                {isNightShift(now) ? (
                    <>
                        <line {...minuteHand} stroke={colors.clock.hands} strokeWidth={2} />
                        <line {...hourHand} stroke={colors.clock.hands} strokeWidth={3} />
                        {secondHand ? <line {...secondHand} stroke={colors.clock.hands} strokeWidth={1} /> : null}
                    </>
                ) : null}
                <line {...mark} stroke={colors.clock.mark} strokeWidth={2} />;
            </svg>
            <div className={styles.labels}>
                <span>00</span>
                <span>3</span>
                <span>6</span>
                <span>9</span>
                <span className={styles.mark}>7</span>
            </div>
        </div>
    );
};
