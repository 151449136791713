import React, { Fragment, ReactElement } from "react";
import { EventCount, useDailyEventCounts } from "../../../api_v2/hooks/useStatistics";
import { EventType } from "../../../api_v2/hooks/useEvents";
import { colors } from "../../../utils/colors";
import { useTranslation } from "react-i18next";
import { StatCard, StatCardProps, Trend } from "../../../components/UI/Card/StatCard/StatCard";
import { Button } from "../../../components/UI/Button/Button";
import { Link } from "react-router-dom";
import { EventIcon } from "../../../components/Events/EventIcon";
import { CommonCardProps } from "./common/CommonCardProps";
import { isSameDay, subDays } from "date-fns";
import styles from "../DashboardView.module.scss";
import { route } from "../../../utils/RouteHelper";
import { Id } from "../../../api_v2/types/custom";

const IconMap: Record<EventCountType, StatCardProps["icon"]> = {
    fall: {
        content: <EventIcon type="fall" colored={false} autoHeight={true} />,
        bg: colors.fallDetectionBackground,
        fg: colors.fallDetection,
    },
    emergency: {
        content: <EventIcon type="absence" colored={false} autoHeight={true} />,
        bg: colors.emergencyPreventionBackground,
        fg: colors.emergencyPrevention,
    },
};

export type EventCountType = "fall" | "emergency";

export interface EventCountsCardProps extends CommonCardProps {
    type: EventCountType;
    roomId?: Id;
    title?: string | ReactElement;
    description?: string;
    day: Date;
}

const useEventCountTypeDetails = (type: EventCountType) => {
    const { t } = useTranslation();

    const data: Record<EventCountType, Array<{ title: string; type: EventType }>> = {
        fall: [{ title: t("labelFallDetection", "Fall detection"), type: "fall" }],
        emergency: [
            { title: t("labelFallPreventionEvents", "Fall prevention events"), type: "getup" },
            { title: t("labelAbsenceDetectionEvents", "Absence Detection events"), type: "absence" },
            { title: t("labelBedRailEvents", "Bed Rail events"), type: "bed-rail" },
        ],
    };

    return {
        icon: IconMap[type],
        types: data[type],
        downIsPositive: true,
    };
};

const getCountByEventTypes = (
    data: Array<EventCount>,
    eventTypes: Array<EventType>,
    date: Date
): Map<EventType, number> => {
    const filteredData = data.filter((d) => eventTypes.includes(d.type) && isSameDay(new Date(d.time), date));

    const countByEventType = new Map(eventTypes.map((t) => [t, 0]));
    filteredData.forEach((entry) =>
        countByEventType.set(entry.type, (countByEventType.get(entry.type) ?? 0) + entry.count)
    );
    return countByEventType;
};

const getCountForEventTypes = (data: Array<EventCount>, eventTypes: Array<EventType>, date: Date): number => {
    return data
        .filter((d) => eventTypes.includes(d.type) && isSameDay(new Date(d.time), date))
        .reduce((sum, data) => sum + data.count, 0);
};

export const DailyEventCountsCard = ({ deviceId, roomId, type, title, description, day }: EventCountsCardProps) => {
    const { t } = useTranslation();

    const typeDetail = useEventCountTypeDetails(type);
    const eventStatsQuery = useDailyEventCounts(deviceId, { days: 7 });

    const eventTypes = typeDetail.types.map((t) => t.type);

    const count = getCountForEventTypes(eventStatsQuery.data ?? [], eventTypes, day);
    const countYesterday = getCountForEventTypes(eventStatsQuery.data ?? [], eventTypes, subDays(day, 1));

    const countByEventType =
        eventTypes.length > 1 ? getCountByEventTypes(eventStatsQuery.data ?? [], eventTypes, day) : null;

    const trendIsPositive =
        (typeDetail.downIsPositive && count < countYesterday) || (!typeDetail.downIsPositive && count > countYesterday);
    const trend: Trend | undefined =
        countYesterday > 0
            ? {
                  value: `${Math.round(((count - countYesterday) / countYesterday) * 100).toFixed(0)}%`,
                  type: count === countYesterday ? "neutral" : trendIsPositive ? "positive" : "negative",
                  direction: count < countYesterday ? "down" : count > countYesterday ? "up" : undefined,
              }
            : undefined;

    const alarmsLink =
        typeDetail.types.length > 0
            ? route({ to: "events", filter: { roomId, eventType: typeDetail.types[0].type } })
            : null;

    return (
        <StatCard
            headline={title ?? `${t(type, { count })} events today`}
            description={description}
            value={count}
            trend={trend}
            icon={IconMap[type]}
            footnote={
                alarmsLink ? (
                    <Link to={alarmsLink}>
                        <Button>{t("labelLinkToAlarms", "Go to alarms")}</Button>
                    </Link>
                ) : undefined
            }
        >
            {countByEventType ? (
                <p className={styles.emergencyPreventionEventsStatDetails}>
                    {typeDetail.types.map((details) => (
                        <Fragment key={details.title}>
                            {details.title} <strong>{countByEventType.get(details.type) ?? 0}</strong>
                            <br />
                        </Fragment>
                    ))}
                </p>
            ) : null}
        </StatCard>
    );
};
