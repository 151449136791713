import React, { HTMLProps, MutableRefObject, PropsWithChildren, useRef } from "react";
import { Dialog } from "@headlessui/react";
import { Card } from "../UI/Card/Card";
import { Button } from "../UI/Button/Button";
import { c } from "../../utils/ClassesHelper";
import styles from "./Modal.module.scss";

export interface ModalProps {
    onClose: () => void;
    showCloseButton?: boolean;

    // default = 830px wide (e.g. Device Settings Modal)
    // large = 1440px wide (e.g. AnalysisModal)
    // fullscreen = no max width (unused atm)
    // prompt = 450px wide, height = content size
    // visualization = 640px wide, optimized to show visualizations
    size?: "default" | "large" | "fullscreen" | "prompt" | "visualization";
    borderless?: boolean;
    disableScrollbar?: boolean;

    withHeader?: boolean;
    withFooter?: boolean;

    initialFocus?: MutableRefObject<HTMLElement>;
    id?: string;
}

export const Modal = ({
    onClose,
    showCloseButton,
    size = "default",
    borderless,
    disableScrollbar,
    children,
    withHeader = false,
    withFooter = false,
    initialFocus,
    id,
}: PropsWithChildren<ModalProps>) => {
    const initialFocusRef = useRef(null);

    return (
        <Dialog
            open={true}
            static={true}
            onClose={() => null}
            className={c([styles.dialog, styles[size]], {
                [styles.borderless]: borderless === true,
                [styles.disableScrollbar]: disableScrollbar === true,
            })}
            initialFocus={initialFocus ?? initialFocusRef}
        >
            <div className={styles.backdrop} onClick={onClose}></div>
            <div className={styles.container}>
                <Dialog.Panel className={styles.panel}>
                    <Card
                        className={c([styles.card], {
                            [styles.withHeader]: withHeader,
                            [styles.withFooter]: withFooter,
                        })}
                    >
                        {children}
                        {showCloseButton ? (
                            <Button
                                ref={initialFocusRef}
                                borderless={true}
                                icon="close"
                                className={styles.closeButton}
                                onClick={onClose}
                                id={id}
                            />
                        ) : null}
                    </Card>
                </Dialog.Panel>
            </div>
        </Dialog>
    );
};

export const ModalHeader = ({ className, children, ...props }: HTMLProps<HTMLDivElement>) => {
    return (
        <div className={c([styles.header, className])} {...props}>
            {children}
        </div>
    );
};

export const ModalFooter = ({ className, children, ...props }: HTMLProps<HTMLDivElement>) => {
    return (
        <div className={c([styles.footer, className])} {...props}>
            {children}
        </div>
    );
};

export const ModalContent = ({ className, children, ...props }: HTMLProps<HTMLDivElement>) => {
    return (
        <div className={c([styles.content, className])} {...props}>
            {children}
        </div>
    );
};

export const Title = ({ className, children, ...props }: HTMLProps<HTMLHeadingElement>) => {
    return (
        <Dialog.Title className={c([styles.title, className])} {...props}>
            {/* @ts-ignore Dialog.Title seems to type children differently than React does. This type does work though*/}
            {children}
        </Dialog.Title>
    );
};
