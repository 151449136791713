import { useAuth } from "../AuthContextProvider";
import { useMutation } from "react-query";
import { AuthenticateError } from "./AuthenticateError";
import { CognitoUser } from "amazon-cognito-identity-js";

export interface SetMfaOptions {
    enable: boolean;
}

export const useSetMfa = () => {
    const { user, setMfaEnabled } = useAuth();

    return useMutation<void, AuthenticateError, SetMfaOptions>(
        "set-mfa",
        (data) => {
            return setMfa(user, data.enable);
        },
        {
            onSuccess: (data, variables) => {
                setMfaEnabled(variables.enable);
            },
        }
    );
};

const setMfa = (user: CognitoUser, enabled: boolean): Promise<void> =>
    new Promise((resolve, reject) => {
        const totpMfaSettings = {
            PreferredMfa: enabled,
            Enabled: enabled,
        };

        user.setUserMfaPreference(null, totpMfaSettings, function (err) {
            if (err) {
                reject({
                    type: "unknown",
                    message: err?.message ?? JSON.stringify(err),
                });
                return;
            }

            resolve();
        });
    });
