export const getHandData = (degrees: number, outerRadius = 35) => {
    return {
        x1: "50%",
        y1: "50%",
        x2: `${outerRadius * Math.cos((Math.PI * 2 * degrees) / 360) + 50}%`,
        y2: `${outerRadius * Math.sin((Math.PI * 2 * degrees) / 360) + 50}%`,
    };
};

export const getMarkData = (degrees: number) => {
    const innerRadius = 45;
    const outerRadius = 50;

    return {
        x1: `${Math.cos((Math.PI * 2 * degrees) / 360) * innerRadius + outerRadius}%`,
        y1: `${Math.sin((Math.PI * 2 * degrees) / 360) * innerRadius + outerRadius}%`,
        x2: `${Math.cos((Math.PI * 2 * degrees) / 360) * outerRadius + outerRadius}%`,
        y2: `${Math.sin((Math.PI * 2 * degrees) / 360) * outerRadius + outerRadius}%`,
    };
};
