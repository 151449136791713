export const useGetDayParams = (params: { days?: number }) => {
    const urlSearchParams = new URLSearchParams();

    if (params.days) {
        if (params.days < 1 || params.days > 30) {
            // eslint-disable-next-line no-console
            console.warn("days must be between 1 and 30!");
        }
        if (params.days < 1) {
            params.days = 1;
        }
        if (params.days > 30) {
            params.days = 30;
        }
        urlSearchParams.set("days", params.days.toString());
    }

    return urlSearchParams;
};
