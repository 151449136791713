import React, { useEffect, useRef, useState } from "react";
import { AugmentedSceneLabel } from "./AugmentedSceneLabel";
import { Layer, Stage, Image as KonvaImage, useStrictMode } from "react-konva";
import { SceneLabelLine } from "./Elements/SceneLabelLine";
import { SceneLabelBedPoint } from "./Elements/SceneLabelBedPoint";
import { SceneLabelLightBarrier } from "./Elements/SceneLabelLightBarrier";
import { Id, Settings } from "../../api_v2/types/custom";
import styles from "./SceneLabelSelector.module.css";
import { useImage } from "./useImage";

useStrictMode(true);

export interface CanvasProps {
    deviceId: Id;
    canvasWidth: number;
    canvasHeight: number;
    augmentedSceneLabels: Array<AugmentedSceneLabel>;
    onAugmentedSceneLabelChange: (label: AugmentedSceneLabel, newlyCreated?: boolean) => void;
    visualization: string;
    readonly?: boolean;
    settings?: Settings;
}

export const Canvas = ({
    deviceId,
    augmentedSceneLabels,
    visualization,
    canvasWidth,
    canvasHeight,
    onAugmentedSceneLabelChange,
    readonly,
    settings,
}: CanvasProps) => {
    const [background] = useImage(visualization);
    const containerRef = useRef<HTMLDivElement>(null);
    const [, setWindowSize] = useState<number>(window.innerWidth);

    const containerWidth = containerRef.current ? containerRef.current.getBoundingClientRect().width : canvasWidth;
    const containerHeight = containerRef.current ? containerRef.current.getBoundingClientRect().height : canvasHeight;

    const scale = Math.min(1, Math.min(containerWidth / canvasWidth, containerHeight / canvasHeight));

    useEffect(() => {
        // This is only necessary to trigger a rerender of the component
        const onResize = () => {
            setWindowSize(window.innerWidth);
        };
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    });

    return (
        <div className={styles.canvas} ref={containerRef}>
            <Stage width={canvasWidth} height={canvasHeight} scaleX={scale} scaleY={scale} className="stage">
                <Layer>
                    <KonvaImage image={background} width={canvasWidth} height={canvasHeight} />

                    {augmentedSceneLabels
                        .filter((s) => s.lineTo)
                        .map((sceneLabel) => {
                            const targetSceneLabel = augmentedSceneLabels.find((s) => s.id === sceneLabel.lineTo);
                            if (!targetSceneLabel) return null;

                            const showDirection =
                                settings?.bedRails?.find((r) => r.id === sceneLabel.sceneLabel.id)?.directional ?? true;

                            return (
                                <SceneLabelLine
                                    key={`${sceneLabel.id}-${targetSceneLabel.id}`}
                                    from={sceneLabel}
                                    to={targetSceneLabel}
                                    showDirection={showDirection}
                                />
                            );
                        })}

                    {augmentedSceneLabels
                        .filter((s) => s.sceneLabel.type === "line-2d")
                        .map((augmentedSceneLabel, index) => {
                            const otherAugmentedSceneLabel = augmentedSceneLabels.find((sceneLabel) =>
                                augmentedSceneLabel.lineTo
                                    ? sceneLabel.id === augmentedSceneLabel.lineTo
                                    : augmentedSceneLabel.id === sceneLabel.lineTo
                            );

                            if (!otherAugmentedSceneLabel) {
                                // We want to log this error, if it every occurs!
                                // eslint-disable-next-line no-console
                                console.error("Can not find second point for light barrier");
                                return null;
                            }

                            return (
                                <SceneLabelLightBarrier
                                    deviceId={deviceId}
                                    key={augmentedSceneLabel.id}
                                    index={index}
                                    augmentedSceneLabel={augmentedSceneLabel}
                                    otherAugmentedSceneLabel={otherAugmentedSceneLabel}
                                    onAugmentedSceneLabelUpdate={onAugmentedSceneLabelChange}
                                    readonly={readonly}
                                />
                            );
                        })}

                    {augmentedSceneLabels
                        .filter((s) => s.sceneLabel.type === "bed-point")
                        .map((augmentedSceneLabel, index) => (
                            <SceneLabelBedPoint
                                deviceId={deviceId}
                                key={augmentedSceneLabel.id}
                                index={index}
                                augmentedSceneLabel={augmentedSceneLabel}
                                onAugmentedSceneLabelUpdate={onAugmentedSceneLabelChange}
                                readonly={readonly}
                            />
                        ))}
                </Layer>
            </Stage>
        </div>
    );
};
