import { MouseEvent, PropsWithChildren } from "react";
import { Config, usePopperTooltip } from "react-popper-tooltip";
import { PopperOptions } from "react-popper-tooltip/dist/types";
import styles from "./Tooltip.module.css";
import { c } from "../../utils/ClassesHelper";
import ReactDOM from "react-dom";

export interface PopoverProps {
    panel: JSX.Element;
    config?: Config;
    popperOptions?: PopperOptions;
    className?: string;
    onClick: (e: MouseEvent<HTMLSpanElement>) => void;
}

export const Tooltip = ({
    children,
    panel,
    config,
    popperOptions,
    className,
    onClick,
}: PropsWithChildren<PopoverProps>) => {
    const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip(
        config,
        popperOptions
    );

    return (
        <>
            <span ref={setTriggerRef} className={c([styles.trigger, className])} onClick={onClick}>
                {children}
            </span>

            {visible &&
                ReactDOM.createPortal(
                    <div ref={setTooltipRef} {...getTooltipProps({ className: styles.tooltip })}>
                        <div {...getArrowProps({ className: styles.arrow })} />
                        {panel}
                    </div>,
                    document.body
                )}
        </>
    );
};
