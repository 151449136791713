import { AreaRoom, FacilityArea } from "../../api_v2/types/custom";
import { Event, Events, useListEventsForArea, useListEventsForFacility } from "../../api_v2/hooks/useEvents";
import { UseInfiniteQueryResult } from "react-query";
import { ReducedEventsList } from "../../components/Events/EventsList/ReducedEventsList";
import { FacilityStructure } from "../../api_v2/hooks/useFacilities";

export interface RecentEventsListProps {
    facility: FacilityStructure;
    area: FacilityArea | null;
    className?: string | undefined;
}

export const RecentEventsList = (props: RecentEventsListProps) => {
    return props.area !== null ? (
        <RecentEventsListForArea {...props} area={props.area} />
    ) : (
        <RecentEventsListForFacility {...props} />
    );
};

const RecentEventsListForFacility = (props: RecentEventsListProps) => {
    const eventsQuery = useListEventsForFacility(props.facility.id, { limit: 5 });
    const rooms = props.facility.areas.map((a) => a.rooms).flat();
    return <UnifiedRecentEventsList eventsQuery={eventsQuery} rooms={rooms} {...props} />;
};

const RecentEventsListForArea = (props: RecentEventsListProps & { area: FacilityArea }) => {
    const eventsQuery = useListEventsForArea(props.area.id, { limit: 5 });
    const rooms = props.area.rooms;
    return <UnifiedRecentEventsList eventsQuery={eventsQuery} rooms={rooms} {...props} />;
};

const UnifiedRecentEventsList = ({
    eventsQuery,
    rooms,
}: { eventsQuery: UseInfiniteQueryResult<Events>; rooms: Array<AreaRoom> } & RecentEventsListProps) => {
    const events: Array<Event> = (eventsQuery.data?.pages ?? []).flat();
    return <ReducedEventsList events={events} rooms={rooms} />;
};
