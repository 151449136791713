import React from "react";
import { Card } from "../../UI/Card/Card";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Event } from "../../../api_v2/hooks/useEvents";
import { EventsListItem } from "./EventsListItem";
import { AreaRoom, Id } from "../../../api_v2/types/custom";
import { EventModalNavigation } from "../EventModal/EventModalNavigation";
import styles from "./EventsList.module.css";
import { route } from "../../../utils/RouteHelper";
import { useQueryParameters } from "../../../utils/useQueryParameters";

export interface EventsListProps {
    events: Array<Event>;
    rooms: Array<AreaRoom>;
    className?: string | undefined;
}

export const ReducedEventsList = ({ events, rooms, className }: EventsListProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const queryParams = useQueryParameters();

    const selectedEventId = queryParams.get("event");
    const setSelectedEventId = (eventId: Id | null, replace = false) => {
        navigate(route({ to: "rooms", eventId: eventId ?? undefined }), { replace });
    };

    return (
        <div className={className}>
            <strong className={styles.title}>{t("labelAlertsListLatestAlerts", "Latest alarms")}</strong>
            <Card className={styles.card}>
                <ul className={styles.list} id="ReducedEventsList">
                    {events.map((event) => (
                        <EventsListItem
                            key={event.id}
                            event={event}
                            room={rooms.find((r) => r.id === event.roomId)}
                            onSelect={() => setSelectedEventId(event.id)}
                        />
                    ))}
                </ul>
                <Link to="/events" className={styles.moreLink}>
                    {t("labelAlertsListShowAllAlerts", "Show all alarms")}
                </Link>
            </Card>
            <EventModalNavigation
                rooms={rooms}
                events={events}
                selectedEventId={selectedEventId}
                onEventSelect={setSelectedEventId}
            />
        </div>
    );
};
