import { ReactElement, useState } from "react";
import { Prompt, PromptProps } from "./Prompt";
import { v4 as uuidv4 } from "uuid";

export type PromptOptions<T> = Omit<PromptProps<T>, "callback">;

// TODO figure out the typing situation here, so that the returned createPrompt function uses its generic type instead of unknown
export const usePrompts = (): [ReactElement[], (options: PromptOptions<unknown>) => Promise<unknown>] => {
    const [prompts, setPrompts] = useState<
        Array<{
            id: string;
            props: PromptProps<unknown>;
        }>
    >([]);

    const removePrompt = (uuid: string) => {
        setPrompts((prompts) => prompts.filter((p) => p.id !== uuid));
    };

    const createPrompt = <T,>(options: PromptOptions<T>): Promise<T> => {
        const id = uuidv4();

        return new Promise((resolve) => {
            const prompt: PromptProps<T> = {
                ...options,
                callback: (value) => {
                    removePrompt(id);
                    resolve(value);
                },
            };

            setPrompts((prompts) => [
                ...prompts,
                {
                    id,
                    props: prompt as PromptProps<unknown>,
                },
            ]);
        });
    };

    return [prompts.map((p) => <Prompt key={p.id} {...p.props} />), createPrompt];
};
