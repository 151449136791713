import { DeviceStatusType } from "../../api_v2/hooks/useDevices";
import { useTranslation } from "react-i18next";

export const useDeviceStatusLabel = (status: DeviceStatusType) => {
    const { t } = useTranslation();

    const statusClassNames: Record<DeviceStatusType, string> = {
        healthy: t("dockingStationStatusHealthy", "Active"),
        paused: t("dockingStationStatusPaused", "Paused"),
        unhealthy: t("dockingStationStatusUnhealthy", "Inactive"),
        "shut-down": t("dockingStationStatusShutDown", "Shut down"),
        offline: t("dockingStationStatusOffline", "Offline"),
        unknown: t("dockingStationStatusUnspecified", "Unknown"),
    };

    return statusClassNames[status];
};
