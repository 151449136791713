import { ButtonGroup } from "../../../../UI/Button/ButtonGroup";
import { Button } from "../../../../UI/Button/Button";
import { c } from "../../../../../utils/ClassesHelper";
import { Label as UiLabel } from "../../../../UI/Label";
import { ToggleSwitch } from "../../../../UI/ToggleSwitch";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { addHours, isEqual, isFuture, set, subHours } from "date-fns";
import { DateRange } from "../../../common/DateRange";
import styles from "../ActivityGraph.module.css";
import { Tooltip } from "../../../../Tooltip/Tooltip";

export interface DayFilterProps {
    selectedDate: DateRange;
    onSelectedDateChange: (dateRange: DateRange) => void;
    isSmallScreen: boolean;
    onLastWeekLineActiveChange: (state: boolean) => void;
    lastWeekLineActive: boolean;
}

export const DayFilter = ({
    selectedDate,
    onSelectedDateChange,
    isSmallScreen,
    lastWeekLineActive,
    onLastWeekLineActiveChange,
}: DayFilterProps) => {
    const { t } = useTranslation();

    const [timeStep, setTimeStep] = useState<number>(isSmallScreen ? 12 : 24);
    const timeStepToUse = isSmallScreen ? Math.min(timeStep, 12) : timeStep;
    const isInFuture = isFuture(addHours(selectedDate.end, timeStepToUse));
    const isCurrentHour = isEqual(selectedDate.end, set(new Date(), { minutes: 0, seconds: 0, milliseconds: 0 }));

    const handleNextDay = () => {
        const end = addHours(selectedDate.end, timeStepToUse);
        const start = subHours(end, 24);
        onSelectedDateChange({ start, end });
    };

    const handleGoToNow = () => {
        const end = set(new Date(), { minutes: 0, seconds: 0, milliseconds: 0 });
        const start = subHours(end, 24);
        onSelectedDateChange({ start, end });
    };

    const handleDayBack = () => {
        const end = subHours(selectedDate.end, timeStepToUse);
        const start = subHours(end, 24);
        onSelectedDateChange({ start, end });
    };

    const tooltipContentPrevious = (timeStepToUse: number) =>
        timeStepToUse === 1 ? (
            <>{t("dayFilter.prevHour", "previous hour")}</>
        ) : (
            <>{t("dayFilter.prevHours", "previous {{count}} hours", { count: timeStepToUse })}</>
        );

    const tooltipContentNext = (timeStepToUse: number) =>
        timeStepToUse === 1 ? (
            <>{t("dayFilter.nextHour", "next hour")}</>
        ) : (
            <>{t("dayFilter.nextHours", "next {{count}} hours", { count: timeStepToUse })}</>
        );

    return (
        <>
            <div className={styles.buttonContainer}>
                <div className={styles.buttonGroupContainer}>
                    <ButtonGroup className={styles.buttonGroupArrows}>
                        <Tooltip className={styles.noTrigger} panel={tooltipContentPrevious(timeStepToUse)}>
                            <Button
                                aria-label={t(
                                    "dayFilter.ariaLabel.buttonToGoBack",
                                    "button to go back {{amountOfHour}}",
                                    { amountOfHour: timeStepToUse }
                                )}
                                icon="left"
                                onClick={handleDayBack}
                                style={{ marginLeft: "-13px" }}
                            />
                        </Tooltip>
                        <Tooltip className={styles.noTrigger} panel={tooltipContentNext(timeStepToUse)}>
                            <Button
                                aria-label={t(
                                    "dayFilter.ariaLabel.buttonToGoForward",
                                    "button to go forward {{amountOfHour}}",
                                    { amountOfHour: timeStepToUse }
                                )}
                                icon="right"
                                onClick={isInFuture ? handleGoToNow : handleNextDay}
                                disabled={isInFuture && isCurrentHour}
                            />
                        </Tooltip>
                    </ButtonGroup>

                    <ButtonGroup
                        aria-label={t("ariaLabelChooseTimeStepButtonGroup", "buttons to choose time step")}
                        className={styles.buttonGroup}
                    >
                        <Button
                            tabIndex={0}
                            className={c([styles.buttonGroupBtn, styles.active])}
                            active={timeStepToUse === 1}
                            onClick={() => {
                                setTimeStep(1);
                            }}
                        >
                            ± 1h
                        </Button>
                        <Button
                            className={c([styles.buttonGroupBtn, styles.active])}
                            active={timeStepToUse === 12}
                            onClick={() => {
                                setTimeStep(12);
                            }}
                        >
                            ± 12h
                        </Button>
                        {!isSmallScreen ? (
                            <Button
                                className={c([styles.buttonGroupBtn, styles.active])}
                                active={timeStepToUse === 24}
                                onClick={() => {
                                    setTimeStep(24);
                                }}
                            >
                                ± 24h
                            </Button>
                        ) : null}
                    </ButtonGroup>
                </div>

                <UiLabel text={t("previousWeek", "Previous week")} additionalClassNames={styles.label}>
                    <ToggleSwitch
                        aria-label={t("ariaLabelDayFilterTogglePreviousWeekData", "switch to show previous week")}
                        aria-pressed={"false"}
                        state={lastWeekLineActive}
                        onChange={onLastWeekLineActiveChange}
                        checkedLabel={t("labelOn", "on")}
                        uncheckedLabel={t("labelOff", "off")}
                    />
                </UiLabel>
            </div>
        </>
    );
};
