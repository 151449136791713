import { subWeeks } from "date-fns";
import { ActivityHeatMap, useListHeatMapsForBed } from "../../../../api_v2/hooks/useHeatMap";
import { Id } from "../../../../api_v2/types/custom";
import { ActivityGraph } from "../Graph/ActivityGraph";
import { BedActivity as BedActivityInterface, useGetBedActivities } from "../../../../api_v2/hooks/useBedActivities";
import { MainTitle } from "../../common/MainTitle";
import { HeatMapsGallery } from "../HeatMap/HeatMapsGallery";
import { SequenceSelector } from "./SequenceSelector";
import { useTranslation } from "react-i18next";
import { DayFilter } from "../Graph/Filter/DayFilter";
import { BedMobilityProps } from "./BedMobility";
import styles from "../ActivityMonitoring.module.css";

interface DailyBedMobilityProps extends BedMobilityProps {
    selectedHeatMapId: Id | null;
    selectedSequenceId: Id | null;
    onSelectedHeatMapId: (id: Id | null) => void;
    onSelectedSequenceId: (id: Id | null) => void;
    onChangeHeatmap: (activeHeatMapAfterBarClick: ActivityHeatMap) => void;
    onSequenceSelect: (sequenceId: Id) => void;
    onLastWeekLineActive: (active: boolean) => void;
    lastWeekLineActive: boolean;
    colorsForBars: string[];
}

const bedMobilityForHeatmap = (heatmap: ActivityHeatMap | null) => (bedMobility: BedActivityInterface) => {
    if (heatmap === null) {
        return false;
    }

    const mobilityStart = new Date(bedMobility.startTime);
    const heatmapStart = new Date(heatmap.startTime);
    const heatmapEnd = new Date(heatmap.endTime);

    return mobilityStart >= heatmapStart && mobilityStart <= heatmapEnd;
};

export const DailyBedMobility = ({
    selectedBedId,
    dateRange,
    selectedHeatMapId,
    selectedSequenceId,
    onSelectedHeatMapId,
    onSelectedSequenceId,
    onChangeHeatmap,
    onSequenceSelect,
    onLastWeekLineActive,
    onDateRangeChange,
    lastWeekLineActive,
    isSmallScreen,
    mode,
    colorsForBars,
}: DailyBedMobilityProps) => {
    const { t } = useTranslation();

    const heatMapsQuery = useListHeatMapsForBed(selectedBedId, dateRange.end, {
        onSuccess: (data) => {
            // Only reset the state when the currently selected id is not included in the response data
            // If it still is included, a refresh has happened, not a change of the time window
            if (!data.find((d) => d.id === selectedHeatMapId)) {
                onSelectedHeatMapId(null);
                onSelectedSequenceId(null);
            }
        },
    });

    const heatMaps: ActivityHeatMap[] = (heatMapsQuery?.data ?? []).filter(
        (h) => new Date(h.startTime) >= dateRange.start
    );
    const activeHeatMap: ActivityHeatMap | null =
        heatMaps.find((item) => item.id === selectedHeatMapId) ?? heatMaps[heatMaps.length - 1] ?? null;

    const heatMapsOneWeekBeforeQuery = useListHeatMapsForBed(selectedBedId, subWeeks(dateRange.end, 1), {
        enabled: lastWeekLineActive,
    });
    const heatMapsOneWeekBefore = heatMapsOneWeekBeforeQuery?.data ?? [];

    const bedMobilitySequencesQuery = useGetBedActivities(selectedBedId);
    const bedMobilitySequences: BedActivityInterface[] = bedMobilitySequencesQuery?.data ?? [];

    const currentBedMobilitySequences = bedMobilitySequences.filter(bedMobilityForHeatmap(activeHeatMap));
    const selectedBedMobilitySequence = currentBedMobilitySequences.find((s) => s.id === selectedSequenceId);

    const heatMapsWithBedMobilitySequence = heatMaps
        .filter((heatmap) => bedMobilitySequences.find(bedMobilityForHeatmap(heatmap)) !== undefined)
        .map((h) => h.id);

    return (
        <>
            <div className={styles.mainContainer}>
                <div className={styles.graph}>
                    <DayFilter
                        selectedDate={dateRange}
                        onSelectedDateChange={onDateRangeChange}
                        isSmallScreen={isSmallScreen}
                        onLastWeekLineActiveChange={onLastWeekLineActive}
                        lastWeekLineActive={lastWeekLineActive}
                    />
                    <ActivityGraph
                        heatMaps={heatMaps}
                        heatMapsWithSequences={heatMapsWithBedMobilitySequence}
                        activeHeatMap={activeHeatMap ? activeHeatMap : undefined}
                        dateRange={dateRange}
                        onHeatMapChange={onChangeHeatmap}
                        isSmallScreen={isSmallScreen}
                        heatMapsOneWeekBefore={heatMapsOneWeekBefore}
                        lastWeekLineActive={lastWeekLineActive}
                        mode={mode}
                        colorsForBars={colorsForBars}
                        dateLineFormat="dd/MM"
                    />
                </div>
                <div className={styles.dividerRight}></div>
                <div className={styles.main}>
                    <MainTitle
                        sequence={selectedBedMobilitySequence ?? null}
                        heatmap={activeHeatMap ?? null}
                        selectedBedOrRoomView={"bedView"}
                    />
                    <div className={styles.imageWrapper}>
                        <HeatMapsGallery
                            heatMaps={heatMaps}
                            activeHeatMapId={activeHeatMap?.id ?? null}
                            onActiveHeatMapChange={onSelectedHeatMapId}
                            selectedSequenceId={selectedSequenceId}
                            onSelectedSequenceIdChange={onSelectedSequenceId}
                            previewImageFallbackText={t("infoNoBedActivityDataForSelectedBed", "There is no data available for this bed.")}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.sequenceSelector}>
                <SequenceSelector
                    items={currentBedMobilitySequences}
                    selectedId={selectedSequenceId}
                    onSelectedIndexChange={onSequenceSelect}
                />
            </div>
        </>
    );
};
