import { Events } from "../../../api_v2/hooks/useEvents";
import { AreaRoom, Id } from "../../../api_v2/types/custom";
import styles from "./EventsGrid.module.css";
import { Card } from "../../../components/UI/Card/Card";
import { EventImage } from "../../../components/Events/EventImage";
import React from "react";
import { TimeDisplay } from "../../../components/UI/TimeDisplay/TimeDisplay";
import { EventIcon } from "../../../components/Events/EventIcon";
import { EventId } from "../../../components/Events/EventId";
import { useTranslation } from "react-i18next";

export interface EventsGridProps {
    events: Events;
    rooms: Array<AreaRoom>;
    onEventSelect?: (eventId: Id | null) => void;
    isLoading?: boolean;
}

export const EventsGrid = ({ events, rooms, onEventSelect, isLoading }: EventsGridProps) => {
    const { t } = useTranslation();

    if (isLoading) {
        return (
            <div className={styles.infoCard} key="info">
                <p>{"Lade Alarme"}</p>
            </div>
        );
    }

    if (events.length === 0) {
        return (
            <div className={styles.infoCard} key="info">
                <p>{"Keine Alarme"}</p>
            </div>
        );
    }

    return (
        <div className={styles.grid}>
            {events.map((event) => {
                const room = rooms.find((r) => r.id === event.roomId);

                return (
                    <Card key={event.id} className={styles.card} onClick={() => onEventSelect?.(event.id)}>
                        <EventImage eventId={event.id} className={styles.previewImage} />
                        <div className={styles.info}>
                            <div>
                                <div className={styles.roomName}>
                                    {room?.name ?? t("infoUnknownRoom", "Unknown room")}
                                </div>
                                <div className={styles.time}>
                                    <TimeDisplay time={event.time} relative={true} />
                                </div>
                            </div>
                            <div>
                                <div className={styles.type}>
                                    <EventIcon type={event.type} colored={true} includeLabel={true} />
                                </div>
                                <div className={styles.id}>
                                    <EventId event={event} />
                                </div>
                            </div>
                        </div>
                    </Card>
                );
            })}
        </div>
    );
};
