import { Id } from "../types/custom";
import { useQuery } from "react-query";
import { components } from "../types/schema";
import { useFetch } from "@bitperfect-packages/react-query-axios";
import { useGetDayParams } from "../../hooks/useGetDayParams";

export type EventCount = components["schemas"]["EventCount"];
export type DeviceStatusChangeCount = components["schemas"]["DeviceStatusChangeCount"];

export const useStatusChangeCounts = (deviceId: Id) => {
    const url = `/devices/${deviceId}/daily-status-change-counts`;
    return useQuery([url], useFetch<Array<DeviceStatusChangeCount>>(url));
};

export const useDailyEventCounts = (
    deviceId: Id,
    params: {
        days?: number;
    }
) => {
    const urlSearchParams = useGetDayParams(params);

    return useQuery<Array<EventCount>>(
        ["daily-event-counts", deviceId, params],
        useFetch<Array<EventCount>>(`/devices/${deviceId}/daily-event-counts`, {
            config: {
                params: urlSearchParams,
            },
        })
    );
};

export const useDailyStatusChangeCounts = (
    deviceId: Id,
    params: {
        days?: number;
    }
) => {
    const urlSearchParams = useGetDayParams(params);

    return useQuery<Array<DeviceStatusChangeCount>>(
        ["daily-status-change-counts", deviceId, params],
        useFetch<Array<DeviceStatusChangeCount>>(`/devices/${deviceId}/daily-status-change-counts`, {
            config: {
                params: urlSearchParams,
            },
        })
    );
};
