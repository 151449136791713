import React from "react";
import { FacilityArea, Id } from "../../api_v2/types/custom";
import { useLocationContext } from "../../context/LocationContextProvider";

import styles from "./Sidebar.module.css";

export interface AreaItemProps {
    area: FacilityArea;
    onSelect: (areaId: Id | null) => void;
}

export const AreaItem = ({ area, onSelect }: AreaItemProps) => {
    const context = useLocationContext();

    const selected = context.areaId === area.id;
    const handleSelect = () => onSelect(area.id);

    return (
        <li key={area.id} id={`Area_${area.id}`}>
            <button type="button" onClick={handleSelect} className={selected ? styles.selected : ""}>
                {area.name}
            </button>
        </li>
    );
};
