import { Id } from "../api_v2/types/custom";
import { EventType } from "../api_v2/hooks/useEvents";

export type RouteOptions =
    | {
          to: "rooms";
          view?:
              | {
                    type: "settings";
                    deviceId: Id;
                }
              | {
                    type: "analysis";
                    deviceId: Id;
                    bedId?: Id;
                    mode?: string;
                    date?: string;
                };
          eventId?: Id;
      }
    | {
          to: "events";
          eventId?: Id;
          filter?: {
              roomId?: Id;
              eventType?: EventType;
              startTime?: string;
          };
      };
export const route = (options: RouteOptions, keepExistingParams = false) => {
    const url = `/${options.to}`;
    const params = keepExistingParams ? new URL(document.location.toString()).searchParams : new URLSearchParams();
    const setParam = getParamSetter(params);

    if (options.to === "rooms" && options.view) {
        setParam("device", options.view.deviceId);
        setParam("view", options.view.type);

        if (options.view.type === "analysis") {
            if (options.view.bedId) {
                setParam("bedId", options.view.bedId);
            }
            if (options.view.mode) {
                setParam("mode", options.view.mode);
            }
            if (options.view.date) {
                setParam("date", options.view.date);
            }
        }
    }

    if ("eventId" in options) {
        setParam("event", options.eventId);
    }

    if (options.to == "events" && options.filter) {
        Object.entries(options.filter).forEach(([key, value]) => setParam(key, value));
    }

    if (Array.from(params.keys()).length > 0) {
        return `${url}?${params.toString()}`;
    }

    return url;
};

/**
 * Sets or deletes parameters inline.
 * Values will be deleted if they are undefined or null
 */
export const getParamSetter = (params: URLSearchParams) => (key: string, value: string | undefined | null) => {
    if (value === undefined || value === null) {
        params.delete(key);
    } else {
        params.set(key, value);
    }
};
