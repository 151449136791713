import React, { ReactNode } from "react";
import styles from "./NavBarAvatar.module.css";

type NavBarAvatarProps = {
    url: string;
};

class NavBarAvatar extends React.Component<NavBarAvatarProps> {
    render(): ReactNode {
        return <img className={styles.avatar} src={this.props.url} />;
    }
}

export { NavBarAvatar };
