import { BedLocationsSettingsProps } from "./BedLocationSettings";
import { useTranslation } from "react-i18next";
import { InputGroup } from "../../../../UI/InputGroup";
import { Label } from "../../../../UI/Label";
import { TimeWindowsList } from "../../../../TimeWindows/TimeWindowsList";
import { ButtonGroup } from "../../../../UI/Button/ButtonGroup";
import { GetUpDetectionTypeButton } from "../../../../UI/GetUpDetectionTypeButton";
import { SensitivitySliderLabelCombo } from "../../../../UI/SensitivitySliderLabelCombo";
import { GetUpDetectionMode, Settings } from "../../../../../api_v2/types/custom";
import { SensitivityValues } from "../../../../UI/SensitivitySlider";
import { NoSettingsErrorMessage } from "../../Common/NoSettingsErrorMessage";
import { Alert } from "../../../../UI/Alerts/Alert";
import { getValueForFlag } from "../../../settingsSchemaUtils";
import { addDefaultSettingsForNewSceneLabel } from "../../Common/SceneLabelDefaultSettings";
import _ from "lodash";
import styles from "../BedMonitoringTab.module.css";

export const GetUpSettings = (props: BedLocationsSettingsProps) => {
    const { t } = useTranslation();

    const bedLocationSettings = props.settings.getupDetectionLocations?.find(
        (l) => l.id === props.selectedBedLocation.id
    );

    if (!bedLocationSettings) {
        return (
            <NoSettingsErrorMessage
                settings={props.settings}
                onSettingsChange={props.onSettingsChange}
                addSettingsHandler={(settings: Settings) =>
                    addDefaultSettingsForNewSceneLabel(
                        settings,
                        props.selectedBedLocation,
                        "bed-monitoring",
                        props.settingsSchema
                    )
                }
                readonly={props.readonly}
            />
        );
    }

    const handleSettingsChange = (
        updatedBedLocationSettings: Exclude<Settings["getupDetectionLocations"], undefined>[0]
    ) => {
        const newSettings = _.cloneDeep(props.settings);

        if (!Array.isArray(newSettings.getupDetectionLocations)) {
            newSettings.getupDetectionLocations = [];
        }

        const index = props.settings.getupDetectionLocations?.indexOf(bedLocationSettings);
        if (index === undefined || index < 0) {
            throw new Error("Can not change bed location that does not exist");
        }

        newSettings.getupDetectionLocations[index] = updatedBedLocationSettings;
        props.onSettingsChange(newSettings);
    };

    const handleChangeMode = (mode: GetUpDetectionMode) => {
        const newBedLocationSettings = _.cloneDeep(bedLocationSettings);
        newBedLocationSettings.mode = mode;
        handleSettingsChange(newBedLocationSettings);
    };

    const handleChangeSensitivity = (sensitivity: SensitivityValues) => {
        const newBedLocationSettings = _.cloneDeep(bedLocationSettings);
        newBedLocationSettings.sensitivity = sensitivity;
        handleSettingsChange(newBedLocationSettings);
    };

    const mode = bedLocationSettings.mode;

    if (!getValueForFlag("getupDetectionEnable", props.settings, props.settingsSchema, true)) {
        return <Alert type="info">{t("infoGetUpDetectionDisabled", "Fall prevention is deactivated")}</Alert>;
    }

    return (
        <>
            <InputGroup>
                <Label text={t("titleBedSettingsAlertMode", "Triggered by")} noLabelEl={true}>
                    {props.readonly ? (
                        t(mode)
                    ) : (
                        <ButtonGroup>
                            <GetUpDetectionTypeButton
                                active={mode === "raiseup"}
                                type={"raiseup"}
                                onClick={() => handleChangeMode("raiseup")}
                                id="SetGetUpDetectionModeButton_RaiseUp"
                            />
                            <GetUpDetectionTypeButton
                                active={mode === "situp"}
                                type={"situp"}
                                onClick={() => handleChangeMode("situp")}
                                id="SetGetUpDetectionModeButton_SitUp"
                            />
                            <GetUpDetectionTypeButton
                                active={mode === "standup"}
                                type={"standup"}
                                onClick={() => handleChangeMode("standup")}
                                id="SetGetUpDetectionModeButton_StandUp"
                            />
                        </ButtonGroup>
                    )}
                </Label>
            </InputGroup>

            <InputGroup>
                <SensitivitySliderLabelCombo
                    text={t("labelFallDetectionSensitivity", "Sensitivity")}
                    sensitivity={bedLocationSettings.sensitivity}
                    readonly={props.readonly}
                    onChange={(s) => handleChangeSensitivity(s)}
                    id="FallDetectionSensitivitySlider"
                />
            </InputGroup>

            <Label text={t("labelFallDetectionSchedule", "Alarm period")} additionalClassNames={styles.block}>
                <TimeWindowsList
                    timeWindows={bedLocationSettings.timeWindows}
                    readonly={props.readonly}
                    onChange={(timeWindows) => {
                        const newBedLocationSettings = _.cloneDeep(bedLocationSettings);
                        newBedLocationSettings.timeWindows = timeWindows;
                        handleSettingsChange(newBedLocationSettings);
                    }}
                    id="FallDetectionTimeWindows"
                />
            </Label>
        </>
    );
};
