import { DeviceWithStatus } from "../helper/Nodes";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/UI/Button/Button";
import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useHasFeatureFlags } from "../../../hooks/useHasFeatureFlags";
import { useCanAccessFeature } from "../../../hooks/useCanAccessFeature";
import styles from "./AdditionalButtonsCell.module.css";

export interface AnalysisCellProps {
    device: DeviceWithStatus;
}

export const AdditionalButtonsCell = ({ device }: AnalysisCellProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const canAccessFeature = useCanAccessFeature();
    const checkFeatureFlags = useHasFeatureFlags();

    const handleClickActivity = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        navigate(`/rooms?device=${encodeURIComponent(device.device.id)}&view=analysis`);
    };
    const handleClickDashboard = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        navigate(`/dashboard?device=${encodeURIComponent(device.device.id)}`);
    };

    const canSeeDashboard = canAccessFeature("Dashboard");
    const canSeeAnalysis =
        checkFeatureFlags(["enable-bed-activity", "enable-room-activity"], "any") &&
        device.analysisModuleStatuses.filter((v) => v.module === "bed-activity" || v.module === "room-activity")
            .length > 0;

    return (
        <td>
            {canSeeAnalysis ? (
                <Button className={styles.btnMargin} onClick={handleClickActivity}>
                    {t("labelRoomsTableAnalysis", "Activity")}
                </Button>
            ) : null}

            {canSeeDashboard ? <Button onClick={handleClickDashboard}>{t("labelRoomsTableDashboard", "Dashboard")}</Button> : null}
        </td>
    );
};
