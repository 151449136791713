import { useFormat } from "../hooks/useFormat";

export const getHourOnlyFormat = (h: Date, locale: Locale | undefined, i: number) => {
    const format = useFormat();

    // The format for the hours is different for some languages that use 12 time format (en-GB and en-US for now)
    // In these languages we show 1-12, where for midnight "am" and for noon "pm" is added
    // in all other cases we show 0-24 with an added "h" after each hour

    if (locale?.code === "en-GB" || locale?.code === "en-US") {
        return i === 0 || i === 12 || i === 24 ? format(h, "p-no-minute") : format(h, "h");
    } else {
        if (locale?.code !== "de" && locale?.code !== "fr" && locale?.code !== "sv" && locale?.code !== "da") {
            // eslint-disable-next-line
            console.warn("Locale not supported for HourlyGradientChart, using fallback 24h format.");
        }
        return i === 24 ? format(h, "kk") + "h" : format(h, "H") + "h";
    }
};
