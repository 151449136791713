import React, { PropsWithChildren } from "react";
import styles from "./RoomsList.module.scss";

export interface EmptyTreeProps {
    columnCount: number;
}

export const EmptyItem = ({ columnCount, children }: PropsWithChildren<EmptyTreeProps>) => {
    return (
        <tbody className={styles.empty} key="empty">
            <tr>
                <td colSpan={columnCount}>{children}</td>
            </tr>
        </tbody>
    );
};
