import { useState } from "react";
import { DateInterval } from "../../../utils/DateInterval";
import { c } from "../../../utils/ClassesHelper";
import { CardTitle } from "../../../components/UI/Card/CardTitle";
import { CardDescription } from "../../../components/UI/Card/CardDescription";
import { Card } from "../../../components/UI/Card/Card";
import { CardContent } from "../../../components/UI/Card/CardContent";
import { Trans, useTranslation } from "react-i18next";
import { DataLabel } from "../common/DataLabel";
import { HourlyGradientChart } from "../Charts/HourlyGradientChart/HourlyGradientChart";
import { colors } from "../../../utils/colors";
import { useListEventsForRoom } from "../../../api_v2/hooks/useEvents";
import { isWithinInterval } from "date-fns";
import { Id } from "../../../api_v2/types/custom";
import { DeviceWithStatus } from "../../RoomsView/helper/Nodes";
import { CardsTitlesAndTooltips } from "../CardsTitlesAndTooltips";
import styles from "../DashboardView.module.scss";
import cardStyles from "../Container/CardGrid.module.scss";
import chroma from "chroma-js";

export interface WeeklyEmergencyPreventionPerHourCardProps {
    interval: DateInterval;
    roomId: Id;
    selectedDevice: DeviceWithStatus;
}

const gradient = chroma.scale([colors.emergencyPreventionNoFall, colors.emergencyPrevention]).mode("lrgb");

export const WeeklyEmergencyPreventionPerHourCard = ({
    interval,
    roomId,
    selectedDevice,
}: WeeklyEmergencyPreventionPerHourCardProps) => {
    const { t } = useTranslation();
    const [emergencyPreventionType, setEmergencyPreventionType] = useState<"getup" | "absence" | "bed-rail">("getup");

    const eventsQuery = useListEventsForRoom(roomId, {
        type: emergencyPreventionType,
    });

    const deviceId = selectedDevice.device.id;
    const dockIsNewDevice = selectedDevice?.device?.dockedTo;

    const eventsOfLast7days = eventsQuery.data?.pages.flatMap((page) =>
        page.filter(
            (event) =>
                isWithinInterval(new Date(event.time), interval) &&
                (event.deviceId === deviceId || event.deviceId === dockIsNewDevice)
        )
    );

    // Count events per hour
    const dataOfEachHour = new Map();
    eventsOfLast7days?.forEach((event) => {
        const hour = new Date(event.time).getHours();
        if (dataOfEachHour.has(hour)) {
            dataOfEachHour.set(hour, dataOfEachHour.get(hour) + 1);
        } else {
            dataOfEachHour.set(hour, 1);
        }
    });

    // Sort and fill in missing hours
    const sortedData = Array.from(dataOfEachHour.entries())
        .sort((a, b) => a[0] - b[0])
        .map(([hour, events]) => ({
            hour,
            events,
        }));

    for (let i = 0; i < 24; i++) {
        if (!sortedData.find((d) => d.hour === i)) {
            sortedData.push({ hour: i, events: 0 });
        }
    }

    const data = new Map((sortedData ?? []).map((d) => [d.hour, d.events]));

    const min = Math.min(...Array.from(data.values()));
    const max = Math.max(...Array.from(data.values()));
    const range = max - min;

    const getColor = (value: number) => {
        return gradient((value - min) / range).hex();
    };

    return (
        <Card className={c([styles.card, cardStyles.md2, cardStyles.lg2, cardStyles.xl2])}>
            <CardTitle>
                <CardsTitlesAndTooltips
                    title={t("weeklyEmergencyPreventionPerHourCardTitle", "Emergency prevention by hour")}
                    tooltip={t(
                        "tooltipWeeklyEmergencyPreventionPerHourCard",
                        "Shows the sum of the selected alert for each hour."
                    )}
                />
            </CardTitle>
            <CardDescription>
                <Trans
                    // t("descriptionWeeklyEmergencyPreventionPerHourCard", "Last 7 days total number of <FallPrevention>fall prevention</FallPrevention>, <AbsenceDetection>absence detection</AbsenceDetection> and <BedRail>bed rail</BedRail> alerts")
                    i18nKey="descriptionWeeklyEmergencyPreventionPerHourCard"
                    default="Last 7 days total number of <FallPrevention>fall prevention</FallPrevention>, <AbsenceDetection>absence detection</AbsenceDetection> and <BedRail>bed rail</BedRail> alerts"
                    components={{
                        FallPrevention: (
                            <DataLabel
                                type="emergencyPrevention"
                                index={0}
                                active={emergencyPreventionType === "getup"}
                                onClick={() => setEmergencyPreventionType("getup")}
                            />
                        ),
                        AbsenceDetection: (
                            <DataLabel
                                type="emergencyPrevention"
                                index={1}
                                active={emergencyPreventionType === "absence"}
                                onClick={() => setEmergencyPreventionType("absence")}
                            />
                        ),
                        BedRail: (
                            <DataLabel
                                type="emergencyPrevention"
                                index={2}
                                active={emergencyPreventionType === "bed-rail"}
                                onClick={() => setEmergencyPreventionType("bed-rail")}
                            />
                        ),
                    }}
                />
            </CardDescription>
            <CardContent className={styles.verticallyCentered}>
                <HourlyGradientChart
                    values={data}
                    colorForBar={getColor}
                    noValueColor={colors.hourlyGradientChartNoData}
                    showValues={true}
                />
            </CardContent>
        </Card>
    );
};
