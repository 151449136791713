import chroma from "chroma-js";
import styles from "./BubbleChart.module.scss";
import { useFormat } from "../../../../hooks/useFormat";
import { c } from "../../../../utils/ClassesHelper";
import { getMonth, isFirstDayOfMonth } from "date-fns";

export interface CellProps {
    date: Date;
    month: number;
    isWithinDateRange: boolean;
    level: number | null;
    onClick: () => void;
}

const maxLevel = 100;

export const Cell = ({ date, month, isWithinDateRange, level, onClick }: CellProps) => {
    const format = useFormat();
    const percentage = level !== null ? Math.min(1, level / maxLevel) : null;
    const color = chroma("rgba(35,134,128,0.7)"); // blue: 679bf0

    const hasBubble = isWithinDateRange && percentage !== null;

    // Scale logarithmically
    // Effective maximum size reached at around level 65. After that, the size will grow so little,
    // that it's no longer recognizable. Tweak the -20 to move this limit up or down

    const size = level ? Math.max(Math.min(1 - Math.exp(level / -17), 1), 0) - 0.15 : 0;

    return (
        <div
            className={c([styles.day, isWithinDateRange ? styles.currentMonth : styles.outsideMonth], {
                [styles.hasBubble]: hasBubble,
                [styles.firstMonth]: getMonth(date) === month,
            })}
            onClick={onClick}
        >
            <span
                className={
                    isFirstDayOfMonth(date) && isWithinDateRange ? styles.labelFirstDayOfMonthInDateRange : styles.label
                }
            >
                {isFirstDayOfMonth(date) ? format(date, "dd. MMM") : format(date, "dd")}
            </span>

            {hasBubble ? (
                <div
                    className={styles.bubble}
                    style={{
                        background: color.darken(percentage).hex(),
                        //@ts-ignore
                        "--size": size,
                    }}
                ></div>
            ) : null}
        </div>
    );
};
