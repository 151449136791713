import React, { ReactNode } from "react";

type HoverComponentProps = {
    hoverRoot: ReactNode;
    hoverElement: ReactNode;
    additionalClassNames?: string;
};

type HoverComponentState = {
    isHovering: boolean;
};

class HoverComponent extends React.Component<HoverComponentProps, HoverComponentState> {
    constructor(props: HoverComponentProps) {
        super(props);
        this.state = {
            isHovering: false,
        };
    }

    render(): ReactNode {
        return (
            <div
                className={this.props.additionalClassNames}
                style={{ position: "relative" }}
                onMouseEnter={() => this.handleMouseEnter()}
                onMouseLeave={() => this.handleMouseLeave()}
            >
                {this.props.hoverRoot}
                {this.state.isHovering ? this.props.hoverElement : null}
            </div>
        );
    }

    private handleMouseEnter() {
        this.setState(() => ({
            isHovering: true,
        }));
    }

    private handleMouseLeave() {
        this.setState(() => ({
            isHovering: false,
        }));
    }
}

export { HoverComponent };
