import React from "react";
import { Navigate } from "react-router-dom";
import { useSetupContext } from "../SetupContext";
import { Loading } from "../../../UI/Loading";
import { useTranslation } from "react-i18next";

export const IsSavedGuard = (props: { children: JSX.Element }) => {
    const { t } = useTranslation();
    const { isSaving, isSaved } = useSetupContext();

    if (isSaving) {
        return <Loading text={t("msgSensorSetupDoneSaving", "Saving...")} />;
    }

    if (isSaved) {
        return <Navigate to="../" replace />;
    }

    return props.children;
};
