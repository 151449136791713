import { useQueries, useQuery } from "react-query";
import { useFetch, useMutate } from "@bitperfect-packages/react-query-axios";
import { Id } from "../types/custom";
import { components } from "../types/schema";
import { blobToImageTransformer } from "./common/blobToImageTransformer";

export type BedActivity = components["schemas"]["ActivityAnalysis"];
export type BedActivityVisualization = components["schemas"]["VisualizationFrame"][];

export const useGetBedActivities = (bedId: Id | null) => {
    return useQuery<BedActivity[]>(
        ["beds", bedId, "activities"],
        useFetch<BedActivity[]>(`/beds/${bedId}/activity-analyses`),
        { enabled: bedId !== null }
    );
};

export const useGetBedActivityVisualization = (activityAnalysisId: Id | null) => {
    return useQuery<BedActivityVisualization>(
        ["activities", activityAnalysisId, "visualization"],
        useFetch<BedActivityVisualization>(`/activity-analyses/${activityAnalysisId}/visualizations`),
        {
            retry: 0,
            enabled: activityAnalysisId !== null,
        }
    );
};

export const useGetBedActivityVisualizationImage = (activityAnalysisId: Id | null, frameId: number | null) => {
    return useQuery<string>(
        ["activityVisualization", activityAnalysisId, "visualization", frameId],
        useFetch<string>(`/activity-analyses/${activityAnalysisId}/visualizations/${frameId}`, {
            config: { responseType: "blob" },
            responseTransform: blobToImageTransformer,
        }),
        {
            retry: 0,
            enabled: activityAnalysisId !== null && frameId !== null,
        }
    );
};

export const usePreloadActivityFrames = (activityAnalysisId: Id, frameIds: Array<number>) => {
    const fetch = useMutate<string, number>(
        "GET",
        (frameId) => `/activity-analyses/${activityAnalysisId}/visualizations/${frameId}`,
        {
            config: { responseType: "blob" },
            responseTransform: blobToImageTransformer,
        }
    );

    return useQueries(
        frameIds.map((frameId) => ({
            queryKey: ["activityVisualization", activityAnalysisId, "visualization", frameId],
            queryFn: () => fetch(frameId),
        }))
    );
};
