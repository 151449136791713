import { c } from "../../../utils/ClassesHelper";
import React, { CSSProperties, ReactElement } from "react";
import styles from "./IconButton.module.scss";

export interface IconButtonProps {
    title: string;
    icon: string | ReactElement;
    active?: boolean;
    onClick?: () => void;
    disabled?: boolean;
    id?: string;
}

export const IconButton = (props: IconButtonProps) => {
    const classNames = c([styles.button], {
        [styles.disabled]: props.disabled === true,
        [styles.active]: props.active === true,
    });

    const isIconElement = !(typeof props.icon === "string");
    let style: CSSProperties = {};
    if (!isIconElement) {
        style = {
            "--icon": `url("${props.icon}")`,
        } as CSSProperties;
    }

    return (
        <button className={classNames} style={style} onClick={props.onClick} disabled={props.disabled} id={props.id}>
            {isIconElement ? <div className={styles.icon}>{props.icon}</div> : null}
            <div className={styles.text}>{props.title}</div>
        </button>
    );
};
