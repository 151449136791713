import { c } from "../../../utils/ClassesHelper";
import { CardTitle } from "../../../components/UI/Card/CardTitle";
import { CardDescription } from "../../../components/UI/Card/CardDescription";
import { CardContent } from "../../../components/UI/Card/CardContent";
import { Card } from "../../../components/UI/Card/Card";
import { Id } from "../../../api_v2/types/custom";
import { Trans, useTranslation } from "react-i18next";
import { DateInterval } from "../../../utils/DateInterval";
import { colors } from "../../../utils/colors";
import { SimpleBarChart } from "../Charts/SimpleBarChart";
import { useDailyCareDemands } from "../../../api_v2/hooks/useCareDemand";
import { useMemo } from "react";
import { eachDayOfInterval, isSameDay } from "date-fns";
import { useNow } from "../../../hooks/useNow";
import { DataLabel } from "../common/DataLabel";
import { useFormat } from "../../../hooks/useFormat";
import { CardsTitlesAndTooltips } from "../CardsTitlesAndTooltips";
import styles from "../DashboardView.module.scss";
import cardStyles from "../Container/CardGrid.module.scss";

export interface MonthlyActivityCardProps {
    deviceId: Id;
    interval: DateInterval;
}

export interface CareAcuityDataType {
    time: string;
    demand: number;
    demandLabel: string;
}

export const MonthlyCareAcuityCard = ({ deviceId, interval }: MonthlyActivityCardProps) => {
    const { t } = useTranslation();
    const format = useFormat();
    const now = useNow("day");

    const dailyCareAcuityQuery = useDailyCareDemands(deviceId, { days: 30 });
    const rawData = dailyCareAcuityQuery.data;

    const CareAcuityData30Days = useMemo(() => {
        const dates = eachDayOfInterval(interval);

        const DataWithEvaluationLabel: Array<CareAcuityDataType> = Array.isArray(rawData)
            ? rawData.map((d) => ({
                  time: d.time,
                  demand: d.demand <= 33 ? 1 : d.demand <= 66 ? 2 : 3,
                  demandLabel: d.demand <= 33 ? "low" : d.demand <= 66 ? "moderate" : "high",
              }))
            : [];

        return dates.map((date, i, arr) => {
            const data = DataWithEvaluationLabel.find((r) => isSameDay(new Date(r.time), new Date(date)));
            const dateFormat = i === 0 || i === arr.length - 1 ? "P-no-year" : "EEEEEE";
            const time = new Date(date);

            return {
                ...(data ?? { time, demand: 0, demandLabel: "" }),
                date: format(new Date(data?.time ?? time), dateFormat),
            };
        });
    }, [rawData, interval.start, interval.end, now]);

    const labels: Record<string, { label: string; color: string }> = {
        low: { label: t("labelCareAcuityLow", "low"), color: colors.careAcuity[0] },
        moderate: { label: t("labelCareAcuityModerate", "moderate"), color: colors.careAcuity[1] },
        high: { label: t("labelCareAcuityHigh", "high"), color: colors.careAcuity[2] },
    };

    return (
        <Card className={c([styles.card, cardStyles.md2, cardStyles.lg3, cardStyles.xl4])}>
            <CardTitle>
                <CardsTitlesAndTooltips
                    title={t("CareAcuityCardTitle", "Care acuity")}
                    tooltip={t(
                        "tooltipMonthlyCareAcuityCard",
                        "Shows the amount of care required in the room per day over the last 30 days."
                    )}
                />
            </CardTitle>
            <CardDescription>
                <Trans
                    // t("descriptionMonthlyCareAcuityCard", "Last 4 weeks <Low>low</Low>, <Medium>moderate</Medium> and <High>high</High>")
                    i18nKey="descriptionMonthlyCareAcuityCard"
                    default="Last 4 weeks <Low>low</Low>, <Medium>moderate</Medium> and <High>high</High>"
                    components={{
                        Low: <DataLabel type="careAcuity" index={0} />,
                        Medium: <DataLabel type="careAcuity" index={1} />,
                        High: <DataLabel type="careAcuity" index={2} />,
                    }}
                />
            </CardDescription>
            <CardContent>
                <SimpleBarChart
                    data={CareAcuityData30Days}
                    xAxisKey="date"
                    xAxisLabel={t("yAxisLabelDay", "Day")}
                    yAxisLabel={t("yAxisLabelAcuity", "Acuity")}
                    labelVertical={true}
                    dataKey={"demand"}
                    labelKey={(entry) => labels[entry.demandLabel]?.label}
                    color={(entry) => labels[entry.demandLabel]?.color}
                    yAxisDomainMaxValue={3}
                    yAxisHideTicks={true}
                />
            </CardContent>
        </Card>
    );
};
