import { useQuery } from "react-query";
import { useFetch } from "@bitperfect-packages/react-query-axios";
import { components } from "../types/schema";
import { hours } from "../../utils/IntervalHelper";

export type UserSelf = components["schemas"]["UserSelf"];

export const useMe = () => {
    return useQuery<UserSelf>(["me"], useFetch<UserSelf>("/me"), {
        staleTime: hours(24),
    });
};
