import { Input } from "../../components/UI/Input";
import { Label } from "../../components/UI/Label";
import React, { InputHTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import styles from "../Auth.module.css";

export interface EmailFieldProps<T extends FieldValues> extends Omit<InputHTMLAttributes<HTMLInputElement>, "name"> {
    name: keyof T;
    register: UseFormRegister<T>;
    errors: FieldErrors<T>;
}

export const EmailField = <T extends FieldValues>({ name, register, errors, ...props }: EmailFieldProps<T>) => {
    const { t } = useTranslation();

    return (
        <Label text={t("labelLoginUsername", "E-mail address *")} block={true}>
            <Input
                {...props}
                type="text"
                placeholder={t("placeholderPasswordResetUsername", "Enter your username")}
                autoComplete="username"
                invalid={Boolean(errors[name])}
                // @ts-expect-error
                {...register(name, {
                    required: {
                        value: true,
                        message: t("errorRequiredField", "Required field"),
                    },
                    validate: {
                        validEmail: (value) =>
                            /\S+@\S+\.\S+/.test(value)
                                ? true
                                : t("errorValidEmail", "Please enter a valid email address"),
                        noWhitespace: (value) =>
                            /^\S+$/g.test(value)
                                ? true
                                : t("errorNoWhitespace", "This field cannot contain white spaces"),
                    },
                })}
            />
            {errors[name] ? <small className={styles.errorText}>{errors[name].message}</small> : null}
        </Label>
    );
};
