import { FeatureFlag } from "./api_v2/hooks/useListFeatureFlags";

const urlSearchParams = new URLSearchParams(window.location.search);

export const config = {
    debug: process.env.REACT_APP_DEBUG === "true",

    demoDataModus: (urlSearchParams.get("demo-data-uuid") ?? false) as string | false,
    demoDataRecord:
        process.env.REACT_APP_ENABLE_DEMO_DATA_RECORDER === "true" && urlSearchParams.has("record-demo-data"),

    // Hides the "for all bed points/bed rails" settings parts
    hideGlobalBedPointSettings: true,
    hideGlobalBedRailSettings: false,

    showRemoveBedLocationSettingsAsThirdTab: false,

    enabledFeatureFlags: (process.env.REACT_APP_FEATURE_FLAGS ?? "").split(",").map(
        (flag): FeatureFlag => ({
            key: flag.split(":")[0],
            value: flag.split(":")[1] === "true",
        })
    ),

    nightShift: { start: "18:00", end: "07:00" },

    allowMultipleRestlessnessDetectionDurations: false,
};
