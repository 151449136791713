import React from "react";
import { useTranslation } from "react-i18next";
import { TimeRangeSlider } from "../UI/TimeRangeSlider";
import { TimeWindow, TimeWindows } from "../../api_v2/types/custom";
import styles from "./TimeWindowsList.module.css";
import _ from "lodash";

interface TimeWindowsListProps {
    timeWindows: TimeWindows;
    onChange?: (timeWindows: TimeWindows) => void;
    disabled?: boolean;
    readonly?: boolean;
    id?: string;
    sliderSteps?: number;
    sliderMax?: number;
}

export const TimeWindowsList = (props: TimeWindowsListProps) => {
    const minItems = 1;
    const maxItems = 5;

    const { t } = useTranslation();

    const timeWindows: TimeWindows = _.cloneDeep(props.timeWindows);

    const canRemove = !props.disabled && !props.readonly && timeWindows.length > minItems;
    const canAdd = !props.disabled && !props.readonly && timeWindows.length < maxItems;

    const handleChange = (scheduleList: TimeWindows) => {
        if (props.onChange) {
            props.onChange(scheduleList);
        }
    };

    const addItem = (scheduleList: TimeWindows) => {
        scheduleList.push({
            from: "00:00",
            until: "23:59",
        });
        handleChange(scheduleList);
    };

    const removeItem = (timeWindows: TimeWindows) => {
        if (timeWindows.length > minItems) {
            timeWindows = _.dropRight(timeWindows);
            handleChange(timeWindows);
        }
    };

    const createItems = (scheduleList: TimeWindows) => {
        const items = [];
        for (let i = 0; i < scheduleList.length; i++) {
            const schedule = scheduleList[i];
            if (schedule) {
                const createFn = props.readonly ? createReadonlyItem : createItem;
                items.push(createFn(schedule, i, scheduleList));
            }
        }
        return items;
    };

    const createItem = (timeWindow: TimeWindow, index: number, scheduleList: TimeWindows) => {
        return (
            <li className={styles.item} key={`${props.id ?? "item"}-${index}`}>
                <TimeRangeSlider
                    from={timeWindow.from}
                    to={timeWindow.until}
                    disabled={props.disabled}
                    onChangeDone={(from, until) => {
                        timeWindow.from = from;
                        timeWindow.until = until;
                        handleChange(scheduleList);
                    }}
                    key={`${props.id ?? "item"}-${index}-slider`}
                    id={props.id ? `${props.id}_${index}_Slider` : undefined}
                    steps={props.sliderSteps}
                    max={props.sliderMax}
                />
            </li>
        );
    };

    const createReadonlyItem = (timeWindow: TimeWindow, index: number) => {
        return (
            <li className={styles.item} key={index}>
                {timeWindow.from.substring(0, 5)} - {timeWindow.until.substring(0, 5)}
            </li>
        );
    };

    return (
        <div>
            <ol className={styles.list}>{createItems(timeWindows)}</ol>
            {!props.readonly ? (
                <div className={styles.actions}>
                    <button
                        className={styles.removeButton}
                        onClick={() => removeItem(timeWindows)}
                        disabled={!canRemove}
                        id={props.id ? `${props.id}_Remove` : undefined}
                    >
                        {t("scheduleListRemove", "Remove time interval")}
                    </button>
                    <button
                        className={styles.addButton}
                        onClick={() => addItem(timeWindows)}
                        disabled={!canAdd}
                        id={props.id ? `${props.id}_Add` : undefined}
                    >
                        {t("scheduleListAdd", "Add time interval")}
                    </button>
                </div>
            ) : null}
        </div>
    );
};
