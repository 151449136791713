import { useQuery } from "react-query";
import { useFetch } from "@bitperfect-packages/react-query-axios";
import { Id } from "../types/custom";
import { blobToImageTransformer } from "./common/blobToImageTransformer";
import { useQueryClient } from "react-query";

export const useGetSceneVisualization = (deviceId: Id) => {
    return useQuery<string>(
        ["devices", deviceId, "scene-visualization"],
        useFetch<string>(`/devices/${deviceId}/scene-visualization`, {
            config: { responseType: "blob" },
            responseTransform: blobToImageTransformer,
        }),
        {
            retry: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: "always",
            staleTime: Infinity,
        }
    );
};

export const useInvalidateSceneVisualization = () => {
    const queryClient = useQueryClient();
    return (deviceId: Id) =>
        queryClient.invalidateQueries({
            queryKey: ["devices", deviceId, "scene-visualization"],
        });
};
