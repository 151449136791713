import React, { ChangeEvent, KeyboardEvent, useState } from "react";
import styles from "../TimeRangeSlider.module.css";
import { c } from "../../../utils/ClassesHelper";
import { secondsToTime, tryParseTimeStringToSeconds } from "./timeConverter";

export interface TimeInputFieldProps {
    timeInSeconds: number;
    onChange: (timeInSeconds: number) => void;
    disabled?: boolean;
    id?: string;
}

export const TimeInputField = (props: TimeInputFieldProps) => {
    const [isFocused, setIsFocused] = useState(false);
    const [input, setInput] = useState<string | null>(null);

    const checkAndChange = (input: string) => {
        const result = tryParseTimeStringToSeconds(input);
        if (result !== false) {
            props.onChange(result);
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        if (input) {
            checkAndChange(input);
        }
        setIsFocused(false);
        setInput(null);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && input) {
            checkAndChange(input);
            setInput(null);
        }
    };

    // If the field is focused, we are in "input mode" and want to show what the user inputs, rather than the prop
    const value = isFocused && input ? input : secondsToTime(props.timeInSeconds);

    const isValid = isFocused && input ? tryParseTimeStringToSeconds(input) !== false : true;

    return (
        <input
            type="text"
            value={value}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            disabled={props.disabled}
            id={props.id}
            className={c([styles.input], { [styles.error]: !isValid })}
            size={5}
        />
    );
};
