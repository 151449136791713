import { addDays, isBefore, isSameDay, startOfDay } from "date-fns";
import { DayAndNightSeparatedActivityData } from "../../data/useActivityDataHelpers";

export const generateTicks = (data: Array<DayAndNightSeparatedActivityData>) => {
    const firstTickAt = startOfDay(addDays(new Date(data[0]?.timestamp), 1));

    return data
        .reduce<Array<[Date, number]>>((ticks, current) => {
            const currentDate = new Date(current.timestamp);

            if (isBefore(currentDate, firstTickAt)) return ticks;

            if (ticks.length === 0) {
                return [[currentDate, currentDate.getTime()]];
            }

            if (isSameDay(currentDate, ticks[ticks.length - 1][0])) {
                return ticks;
            }

            return [...ticks, [currentDate, startOfDay(currentDate).getTime()]];
        }, [])
        .map(([, timestamp]) => timestamp);
};
