import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../UI/Button/Button";
import { useSetupContext } from "./Scenarios/SetupContext";
import { useNavigate } from "react-router-dom";
import styles from "../DeviceSetup/DeviceSetupModal.module.css";

export interface DeviceSetupActionsProps {
    onClose: () => void;
    onComplete: () => void;
}

export const DeviceSetupActions = (props: DeviceSetupActionsProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const context = useSetupContext();

    const showRightButtons = context.type !== null;
    const isFirstStep = context.step === 0;
    const isLastStep = context.step === context.steps - 1;
    const hasPrev = context.goPrev && !isFirstStep;

    return (
        <div className={styles.controls}>
            <div>
                <Button onClick={props.onClose}>{t("labelSensorSetupClose", "Close")}</Button>
            </div>

            {showRightButtons ? (
                <div>
                    {!isFirstStep && !isLastStep ? (
                        <Button className={styles.button} disabled={!hasPrev} onClick={() => context.goPrev?.()}>
                            {t("labelSensorSetupBack", "Back")}
                        </Button>
                    ) : (
                        <Button className={styles.button} onClick={() => navigate("./")}>
                            {t("labelSensorSetupBackToScenarioSelect", "Return to setup options")}
                        </Button>
                    )}
                    {!isLastStep ? (
                        <Button primary={true} disabled={!context.isValid} onClick={() => context.goNext?.()}>
                            {t("labelSensorSetupNext", "Next")}
                        </Button>
                    ) : (
                        <Button
                            primary={true}
                            disabled={!context.isValid || context.isSaving}
                            onClick={() => props.onComplete()}
                        >
                            {context.isSaving ? t("labelSensorSetupSaving", "Saving...") : t("labelSensorSetupFinish", "Finish")}
                        </Button>
                    )}
                </div>
            ) : null}
        </div>
    );
};
