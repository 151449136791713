import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./RoomsList.module.scss";

export interface RoomsListHeadProps {
    hasAdditionalButtons: boolean;
}

export const RoomsListHead = ({ hasAdditionalButtons }: RoomsListHeadProps) => {
    const { t } = useTranslation();

    return (
        <thead className={styles.header}>
            <tr>
                <th>{t("labelRoom", "Room")}</th>
                <th>{t("labelRoomsTableStatus", "Sensor status")}</th>
                <th>{t("labelRoomsTableAlertStatus", "Activated modules")}</th>
                {hasAdditionalButtons ? <th></th> : null}
            </tr>
        </thead>
    );
};
