import { useLocationContext } from "../../context/LocationContextProvider";
import { Device, useListDevices } from "../../api_v2/hooks/useDevices";
import { useState } from "react";
import { Combobox } from "../UI/Combobox";
import { useDockDevice, useListCompatibleDocks } from "../../api_v2/hooks/useDockDevice";

export const DebugDevices = () => {
    const locationContext = useLocationContext();
    const devicesQuery = useListDevices(locationContext.facilityId);

    const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);

    const devices = devicesQuery.data ?? [];
    const filter = (device: Device, query: string) => device.name?.toLowerCase().includes(query.toLowerCase()) ?? false;

    return (
        <>
            <strong>Dock Sensor to</strong>
            <Combobox
                selected={selectedDevice}
                onSelect={setSelectedDevice}
                options={devices}
                valueGetter={(d) =>
                    `${d.name ?? d.id} (type: ${d.typeName}, dock support: ${d.dockSupport}, docked to: ${d.dockedTo})`
                }
                keyGetter={(d) => d.id}
                filter={filter}
            />
            {selectedDevice ? <DockableTo device={selectedDevice} /> : null}
        </>
    );
};

const DockableTo = ({ device }: { device: Device }) => {
    const compatibleDocksQuery = useListCompatibleDocks(device.id);
    const dockDevice = useDockDevice();

    const [selectedDock, setSelectedDock] = useState<Device | null>(null);
    const docks = compatibleDocksQuery.data ?? [];

    const filter = (device: Device, query: string) => device.name?.toLowerCase().includes(query.toLowerCase()) ?? false;

    const handleDock = () => {
        if (selectedDock) {
            dockDevice.mutate({
                deviceId: device.id,
                dockId: selectedDock.id,
            });
        }
    };

    return (
        <>
            <span>is dockable to</span>
            <Combobox
                selected={selectedDock}
                onSelect={setSelectedDock}
                options={docks}
                valueGetter={(d) => `${d.name ?? d.id} (type: ${d.typeName}, dock support: ${d.dockSupport})`}
                keyGetter={(d) => d.id}
                filter={filter}
            />
            <button onClick={handleDock} disabled={!selectedDock || dockDevice.isLoading}>
                dock
            </button>
        </>
    );
};
