import React from "react";
import { CardGrid } from "./Container/CardGrid";
import { CardGroup } from "./Container/CardGroup";
import { Id } from "../../api_v2/types/custom";
import { useNow } from "../../hooks/useNow";
import { useFormat } from "../../hooks/useFormat";
import { subWeeks } from "date-fns";
import { WeeklyAlertsCard } from "./Cards/WeeklyAlertsCard";
import { WeeklyAlertCountsCard } from "./Cards/WeeklyAlertCountsCard";
import { WeeklyBedRestMobilityCard } from "./Cards/WeeklyBedRestMobilityCard";
import { WeeklyRoomActivityCard } from "./Cards/WeeklyRoomActivityCard";
import { useTranslation } from "react-i18next";
import { WeeklyFallAlertsByHourCard } from "./Cards/WeeklyFallAlertsByHourCard";
import { WeeklySunlightExposureCard } from "./Cards/WeeklySunlightExposureCard";
import { DeviceWithStatus } from "../RoomsView/helper/Nodes";
import { WeeklyEmergencyPreventionPerHourCard } from "./Cards/WeeklyEmergencyPreventionPerHourCard";
import { useHasFeatureFlags } from "../../hooks/useHasFeatureFlags";

export interface WeeklyDigestProps {
    selectedDevice: DeviceWithStatus;
    roomId?: Id;
}

export const WeeklyDigest = ({ selectedDevice, roomId }: WeeklyDigestProps) => {
    const { t } = useTranslation();
    const now = useNow("day");
    const format = useFormat();
    const start = subWeeks(now, 1);
    const hasFeatureFlag = useHasFeatureFlags();

    return (
        <CardGroup title={t("titleWeeklyDigestSection", "Weekly Digest")} info={`${format(start, "PPP")} - ${format(now, "PPP")}`}>
            <CardGrid>
                {hasFeatureFlag("enable-room-activity") ? (
                    <WeeklyRoomActivityCard deviceId={selectedDevice.device.id} interval={{ start, end: now }} />
                ) : null}
                {hasFeatureFlag("enable-bed-activity") ? (
                    <WeeklyBedRestMobilityCard deviceId={selectedDevice.device.id} interval={{ start, end: now }} />
                ) : null}

                <WeeklyAlertsCard deviceId={selectedDevice.device.id} interval={{ start, end: now }} />
                <WeeklyAlertCountsCard deviceId={selectedDevice.device.id} interval={{ start, end: now }} />

                {roomId ? (
                    <WeeklyFallAlertsByHourCard
                        interval={{ start, end: now }}
                        roomId={roomId}
                        selectedDevice={selectedDevice}
                    />
                ) : null}
                <WeeklySunlightExposureCard deviceId={selectedDevice.device.id} interval={{ start, end: now }} />

                {roomId ? (
                    <WeeklyEmergencyPreventionPerHourCard
                        interval={{ start, end: now }}
                        roomId={roomId}
                        selectedDevice={selectedDevice}
                    />
                ) : null}
            </CardGrid>
        </CardGroup>
    );
};
