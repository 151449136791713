import { addDays, eachHourOfInterval, endOfDay, startOfDay } from "date-fns";
import { useNow } from "../../../../hooks/useNow";
import { c } from "../../../../utils/ClassesHelper";
import { dateFnsLocaleMap } from "../../../../i18n";
import { useTranslation } from "react-i18next";
import { getHourOnlyFormat } from "../../../../utils/getHourOnlyFormat";
import styles from "./HourlyGradientChart.module.scss";

export interface HourlyGradientChartProps {
    values: Map<number, number>; // expects a map with keys ranging from 0 to 23
    colorForBar: (value: number) => string;
    noValueColor?: string;
    showValues?: boolean;
}
export const HourlyGradientChart = (props: HourlyGradientChartProps) => {
    const now = useNow("day");
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const locale = dateFnsLocaleMap.get(i18n.language);

    const hours = eachHourOfInterval({
        start: startOfDay(now),
        end: endOfDay(now),
    });

    const getColor = (value: number | undefined) => {
        if (value === undefined) {
            return props.noValueColor ?? "transparent";
        }

        return props.colorForBar(value);
    };

    return (
        <div className={styles.container}>
            <div className={styles.cellContainer}>
                {[...hours, startOfDay(addDays(now, 1))].map((hour, i) => {
                    return (
                        <div
                            key={hour.toISOString()}
                            className={c([styles.cell], {
                                [styles.first]: i === 0,
                                [styles.last]: i === 24,
                            })}
                            style={{
                                background: i == 24 ? getColor(props.values.get(0)) : getColor(props.values.get(i)),
                            }}
                        >
                            <div className={styles.barValue}>
                                {props.showValues ? (i == 24 ? props.values.get(0) : props.values.get(i)) : null}
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className={styles.labelContainer}>
                {[...hours, startOfDay(addDays(now, 1))].map((hour, i) => {
                    return (
                        <div
                            key={hour.toISOString() + i}
                            className={c([styles.label], {
                                [styles.first]: i === 0,
                                [styles.last]: i === 24,
                            })}
                        >
                            <span>{getHourOnlyFormat(new Date(hour), locale, i)}</span>
                        </div>
                    );
                })}
            </div>

            <div className={styles.xAxisLabel}>{t("xAxisLabelHour", "Hour")}</div>
        </div>
    );
};
