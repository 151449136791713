import { ActivityHeatMap } from "../../../../../api_v2/hooks/useHeatMap";

export const getStandardDeviation = (data: ActivityHeatMap[], mean: number) => {
    if (data.length === 0 || mean === 0) {
        return 0;
    }
    const variance = data.map((x) => Math.pow(x.level - mean, 2)).reduce((a, b) => a + b) / data.length;
    const standardDeviation = Math.sqrt(variance);
    return standardDeviation;
};
