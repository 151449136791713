import { EventType } from "../../../api_v2/hooks/useEvents";

import fallIcon from "../../../images/icons/Sturzerkennung.svg";
import fallIconColor from "../../../images/icons/Sturzerkennung-colored.svg";

import raiseUp0 from "../../../images/icons/type/Aufrichten.svg";
import raiseUp0Color from "../../../images/icons/type/color/Aufrichten.svg";

import bedAbsence0 from "../../../images/icons/type/Abwesenheit.svg";
import bedAbsence0Color from "../../../images/icons/type/color/Abwesenheit.svg";

import barrierIcon from "../../../images/icons/Bettbalken.svg";
import barrierIconColor from "../../../images/icons/Bettbalken-colored.svg";

import restlessnessIcon from "../../../images/icons/Restlessness.svg";
import restlessnessIconColor from "../../../images/icons/Restlessness-colored.svg";

const icons: Record<EventType, [string, string]> = {
    fall: [fallIcon, fallIconColor],
    getup: [raiseUp0, raiseUp0Color],
    absence: [bedAbsence0, bedAbsence0Color],
    "bed-rail": [barrierIcon, barrierIconColor],
    "bed-restlessness": [restlessnessIcon, restlessnessIconColor],
};

export const useEventTypeIcon = () => {
    return (type: EventType, color = true) => icons[type][color ? 1 : 0];
};
