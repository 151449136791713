import React, { PropsWithChildren, useContext } from "react";
import { Id } from "../api_v2/types/custom";
import { useUser } from "./UserContext";
import { useUserSpecificLocalStorage } from "../hooks/useUserSpecificLocalStorage";
import { FeatureFlags, useListFeatureFlags } from "../api_v2/hooks/useListFeatureFlags";
import { LoadingView } from "../views/LoadingView/LoadingView";

export interface LocationContext {
    facilityId: Id;
    setFacilityId: (id: Id) => void;
    areaId: Id | null;
    setAreaId: (id: Id | null) => void;
    featureFlags: FeatureFlags;
}

const LocationContextCtx = React.createContext<LocationContext | null>(null);

export const useLocationContext = (): LocationContext => {
    const ctx = useContext(LocationContextCtx);
    if (ctx === null) throw new Error("Location Context not set!");
    return ctx;
};

export const LocationContextProvider = (props: PropsWithChildren<unknown>) => {
    const me = useUser();

    const [storedFacilityId, setFacilityId] = useUserSpecificLocalStorage<Id>(
        "context_facility_id",
        me.facilities[0].id
    );
    const [storedAreaId, setAreaId] = useUserSpecificLocalStorage<Id | null>("context_area_id", null);

    let facilityId = storedFacilityId;
    let areaId = storedAreaId;

    // Make sure that the stored facility is still accessible by the user
    // Otherwise, fall back to the first allowed facility
    // And reset the area id, because it's probably no longer valid too then
    if (me.facilities.findIndex((f) => f.id === storedFacilityId) === -1) {
        facilityId = me.facilities[0].id;
        areaId = null;
    }

    const featureFlagsQuery = useListFeatureFlags(facilityId);

    if (featureFlagsQuery.isLoading) {
        return <LoadingView />;
    }

    const ctx: LocationContext = {
        facilityId,
        setFacilityId,
        areaId,
        setAreaId,
        featureFlags: featureFlagsQuery.data ?? [],
    };

    return <LocationContextCtx.Provider value={ctx}>{props.children}</LocationContextCtx.Provider>;
};
