import { TabProps } from "../TabProps";
import _ from "lodash";
import { Label } from "../../../UI/Label";
import { useTranslation } from "react-i18next";
import { ButtonGroup } from "../../../UI/Button/ButtonGroup";
import { Button } from "../../../UI/Button/Button";
import { InputGroup } from "../../../UI/InputGroup";
import { Settings } from "../../../../api_v2/types/custom";
import { Input } from "../../../UI/Input";
import { c } from "../../../../utils/ClassesHelper";
import { canAccessSettings } from "../../settingsSchemaUtils";
import { BiCopy } from "react-icons/bi";
import { useState } from "react";
import { PauseSwitch } from "./PauseSwitch";
import styles from "../../DeviceDetailsModal.module.css";
import { writeClipboardText } from "../../../../utils/WriteToClipboard";

export const MiscTab = (props: TabProps) => {
    const { t } = useTranslation();

    const [savedToClipboard, setSavedToClipboard] = useState<boolean>(false);

    const canAccessApiSettings = canAccessSettings("restApi", props.settingsSchema);
    const canAccessPauseButtonSettings = canAccessSettings("pauseSwitchButton", props.settingsSchema);

    const handleRoomType = (type: Settings["roomType"]) => {
        const newSettings = _.cloneDeep(props.settings);
        newSettings.roomType = type;
        props.onSettingsChange(newSettings);
    };

    const handleApiSettings = (property: "restApiUrl" | "restApiToken", value: string) => {
        const newSettings = _.cloneDeep(props.settings);
        newSettings[property] = value;
        props.onSettingsChange(newSettings);
    };

    const handleClipToBoard = () => {
        setSavedToClipboard(true);
        writeClipboardText(`${props.device.device.name}`);
        setTimeout(() => {
            setSavedToClipboard(false);
        }, 800);
    };

    return (
        <div>
            <InputGroup>
                <strong className={styles.groupTitle}>{t("roomType", "Room type")}</strong>

                {props.readonly ? (
                    t(props.settings.roomType ?? "-")
                ) : (
                    <ButtonGroup>
                        <Button
                            active={props.settings.roomType === "single"}
                            onClick={() => handleRoomType("single")}
                            disabled={props.readonly}
                            id="SetRoomTypeButton_Single"
                        >
                            {t("single", "Single room")}
                        </Button>
                        <Button
                            active={props.settings.roomType === "shared"}
                            onClick={() => handleRoomType("shared")}
                            disabled={props.readonly}
                            id="SetRoomTypeButton_Shared"
                        >
                            {t("shared", "Shared room")}
                        </Button>
                    </ButtonGroup>
                )}
            </InputGroup>

            {canAccessPauseButtonSettings ? <PauseSwitch {...props} /> : null}

            {canAccessApiSettings ? (
                <InputGroup>
                    <strong className={styles.groupTitle}>{t("restApi", "REST API")}</strong>
                    <Label
                        text={t("labelRestApiSettingsUrl", "Server URL")}
                        additionalClassNames={c([styles.label, styles.mb])}
                    >
                        <Input
                            id="RestApiUrl"
                            type="text"
                            placeholder={t("placeholderRestApiSettingsUrl", "https://api.example.com")}
                            readOnly={props.readonly}
                            value={props.settings.restApiUrl}
                            onChange={(e) => handleApiSettings("restApiUrl", e.target.value)}
                        />
                    </Label>
                    <Label
                        text={t("labelRestApiSettingsToken", "Authentication token")}
                        additionalClassNames={styles.label}
                    >
                        <Input
                            id="RestApiUrl"
                            type="text"
                            placeholder={t("placeholderRestApiSettingsToken", "Authentication token")}
                            readOnly={props.readonly}
                            value={props.settings.restApiToken}
                            onChange={(e) => handleApiSettings("restApiToken", e.target.value)}
                        />
                    </Label>
                </InputGroup>
            ) : null}
            <div>
                <button className={styles.buttonSN} onClick={handleClipToBoard}>
                    <span className={styles.buttonSNText}>{`S/N: ${props.device.device.name}`}</span>
                </button>
                <BiCopy
                    className={styles.iconSavedToClipboard}
                    style={{ opacity: savedToClipboard ? 1 : 0, transition: "opacity 0.3s ease-in-out" }}
                />
            </div>
        </div>
    );
};
