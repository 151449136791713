import React from "react";
import { c } from "../../../utils/ClassesHelper";
import styles from "./TabList.module.css";

export interface TabListProps<T> {
    items: Array<T>;
    getLabel: (item: T) => string;
    getKey: (item: T) => string;
    className?: string | undefined;
    id?: string;
}

export interface NullableTabListProps<T> {
    nullable: true;
    selected: T | null;
    onSelect: (item: T | null) => void;
    nullItemLabel: string;
}

export interface NonNullableTabListProps<T> {
    nullable: false;
    selected: T;
    onSelect: (item: T) => void;
    nullItemLabel?: never;
}

type TabListPropsType<T> = TabListProps<T> & (NullableTabListProps<T> | NonNullableTabListProps<T>);

export const TabList = <T,>({
    items,
    className,
    getLabel,
    getKey,
    onSelect,
    selected,
    nullable,
    nullItemLabel,
    id,
}: TabListPropsType<T>) => {
    return (
        <ul className={c([styles.list, className])} id={id}>
            {nullable ? (
                <li className={styles.item}>
                    <button
                        className={c({ [styles.button]: true, [styles.selected]: selected === null })}
                        type="button"
                        onClick={() => {
                            onSelect(null);
                        }}
                        data-text={nullItemLabel}
                    >
                        {nullItemLabel}
                    </button>
                </li>
            ) : null}

            {items.map((item, index) => {
                const key = getKey(item);
                const label = getLabel(item);
                const isSelected = item === selected;
                const classNames = { [styles.button]: true, [styles.selected]: isSelected };

                return (
                    <li className={styles.item} key={key}>
                        <button
                            id={`${id}_${index}`}
                            className={c(classNames)}
                            type="button"
                            onClick={() => {
                                onSelect(item);
                            }}
                            data-text={label}
                        >
                            {label}
                        </button>
                    </li>
                );
            })}
        </ul>
    );
};
