import React from "react";
import { Event } from "../../api_v2/hooks/useEvents";
import styles from "./EventId.module.css";

export interface EventIdProps {
    event: Event;
    long?: boolean;
}

export const EventId = ({ event, long }: EventIdProps) => {
    const start = event.id.substring(0, event.id.length - 5);
    const end = event.id.substring(event.id.length - 5);

    if (long) {
        return (
            <span className={styles.long}>
                {start}
                <span className={styles.short}>{end}</span>
            </span>
        );
    }

    return <>{end}</>;
};
