import { subWeeks } from "date-fns";
import { ActivityHeatMap, useListHeatMapsForRoom } from "../../../../api_v2/hooks/useHeatMap";
import { Id } from "../../../../api_v2/types/custom";
import { ActivityGraph } from "../Graph/ActivityGraph";
import { MainTitle } from "../../common/MainTitle";
import { HeatMapsGallery } from "../HeatMap/HeatMapsGallery";
import { useTranslation } from "react-i18next";
import { DayFilter } from "../Graph/Filter/DayFilter";
import { RoomActivityProps } from "./RoomActivity";
import styles from "../ActivityMonitoring.module.css";

interface DailyRoomActivityProps extends RoomActivityProps {
    selectedHeatMapId: Id | null;
    onSelectedHeatMapId: (id: Id | null) => void;
    onChangeHeatmap: (activeHeatMapAfterBarClick: ActivityHeatMap) => void;
    onLastWeekLineActive: (active: boolean) => void;
    lastWeekLineActive: boolean;
    colorsForBars: string[];
}

export const DailyRoomActivity = ({
    dateRange,
    selectedHeatMapId,
    onSelectedHeatMapId,
    onChangeHeatmap,
    onDateRangeChange,
    onLastWeekLineActive,
    lastWeekLineActive,
    isSmallScreen,
    mode,
    device,
    colorsForBars,
}: DailyRoomActivityProps) => {
    const { t } = useTranslation();

    const heatMapsQuery = useListHeatMapsForRoom(device.device.id, dateRange.end, {
        onSuccess: (data) => {
            // Only reset the state when the currently selected id is not included in the response data
            // If it still is included, a refresh has happened, not a change of the time window
            if (!data.find((d) => d.id === selectedHeatMapId)) {
                onSelectedHeatMapId(null);
            }
        },
    });

    const heatMaps: ActivityHeatMap[] = (heatMapsQuery?.data ?? []).filter(
        (h) => new Date(h.startTime) >= dateRange.start
    );
    const activeHeatMap: ActivityHeatMap | null =
        heatMaps.find((item) => item.id === selectedHeatMapId) ?? heatMaps[heatMaps.length - 1] ?? null;

    const heatMapsOneWeekBeforeQuery = useListHeatMapsForRoom(device.device.id, subWeeks(dateRange.end, 1), {
        enabled: lastWeekLineActive,
    });
    const heatMapsOneWeekBefore = heatMapsOneWeekBeforeQuery?.data ?? [];

    return (
        <div className={styles.mainContainer}>
            <div className={styles.graph}>
                <DayFilter
                    selectedDate={dateRange}
                    onSelectedDateChange={onDateRangeChange}
                    isSmallScreen={isSmallScreen}
                    onLastWeekLineActiveChange={onLastWeekLineActive}
                    lastWeekLineActive={lastWeekLineActive}
                />
                <ActivityGraph
                    heatMaps={heatMaps}
                    activeHeatMap={activeHeatMap ? activeHeatMap : undefined}
                    dateRange={dateRange}
                    onHeatMapChange={onChangeHeatmap}
                    isSmallScreen={isSmallScreen}
                    heatMapsOneWeekBefore={heatMapsOneWeekBefore}
                    lastWeekLineActive={lastWeekLineActive}
                    mode={mode}
                    colorsForBars={colorsForBars}
                    dateLineFormat="dd/MM"
                />
            </div>
            <div className={styles.dividerRight}></div>
            <div className={styles.main}>
                <MainTitle sequence={null} heatmap={activeHeatMap ?? null} selectedBedOrRoomView="roomView" />
                <div className={styles.imageWrapper}>
                    <HeatMapsGallery
                        heatMaps={heatMaps}
                        activeHeatMapId={activeHeatMap?.id ?? null}
                        onActiveHeatMapChange={onSelectedHeatMapId}
                        selectedSequenceId={null}
                        onSelectedSequenceIdChange={() => null}
                        previewImageFallbackText={t("infoNoBedActivityDataForSelectedBed", "There is no data available for this bed.")}
                    />
                </div>
            </div>
        </div>
    );
};
