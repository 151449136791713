import { CardsTitlesAndTooltips } from "../../CardsTitlesAndTooltips";
import { CardTitle } from "../../../../components/UI/Card/CardTitle";
import React from "react";
import { useTranslation } from "react-i18next";

export interface MonthlyActivityCardTitleProps {
    enableRoomActivity: boolean;
    enableBedActivity: boolean;
}

export const MonthlyActivityCardTitle = ({ enableRoomActivity, enableBedActivity }: MonthlyActivityCardTitleProps) => {
    const { t } = useTranslation();

    let title = t("titleMonthlyActivityCard");
    let tooltip = t("tooltipMonthlyActivityCard");

    if (enableBedActivity && !enableRoomActivity) {
        title = t("titleMonthlyActivityCard_BedOnly", "Bed rest mobility");
        tooltip = t(
            "tooltipMonthlyActivityCard_BedOnly",
            "Shows the daily summary of person movements for beds over last 30 days."
        );
    } else if (!enableBedActivity && enableRoomActivity) {
        title = t("titleMonthlyActivityCard_RoomOnly", "Room activity");
        tooltip = t(
            "tooltipMonthlyActivityCard_RoomOnly",
            "Shows the daily summary of person movements for the room over last 30 days."
        );
    }

    return (
        <CardTitle>
            <CardsTitlesAndTooltips title={title} tooltip={tooltip} />
        </CardTitle>
    );
};
