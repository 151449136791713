import React from "react";
import { CardTitle } from "../../../components/UI/Card/CardTitle";
import { CardDescription } from "../../../components/UI/Card/CardDescription";
import { Card } from "../../../components/UI/Card/Card";
import { Trans, useTranslation } from "react-i18next";
import { Id } from "../../../api_v2/types/custom";
import { DateInterval } from "../../../utils/DateInterval";
import { CardContent } from "../../../components/UI/Card/CardContent";
import { HourlyGradientChart } from "../Charts/HourlyGradientChart/HourlyGradientChart";
import { colors } from "../../../utils/colors";
import { c } from "../../../utils/ClassesHelper";
import { useHourlyVisibility } from "../../../api_v2/hooks/useHourlyVisibility";
import { DataLabel } from "../common/DataLabel";
import { CardsTitlesAndTooltips } from "../CardsTitlesAndTooltips";
import styles from "../DashboardView.module.scss";
import cardStyles from "../Container/CardGrid.module.scss";

export interface VisibilityCoverageCardProps {
    deviceId: Id;
    interval: DateInterval;
}

const getColor = (value: number) => {
    return value > 80
        ? colors.sunlightExposureLow
        : value < 65
        ? colors.sunlightExposureHigh
        : colors.sunlightExposureMedium;
};

export const WeeklySunlightExposureCard = ({ deviceId }: VisibilityCoverageCardProps) => {
    const { t } = useTranslation();
    const query = useHourlyVisibility(deviceId);

    const data = new Map((query.data ?? []).map((d) => [d.hour, d.visibility]));

    return (
        <Card className={c([styles.card, cardStyles.md2, cardStyles.lg2, cardStyles.xl2])}>
            <CardTitle>
                <CardsTitlesAndTooltips
                    title={t("titleVisibilityCoverageCard", "Sunlight exposure by hour")}
                    tooltip={t(
                        "tooltipSunlightExposureCard",
                        "Shows the amount of direct sunlight in the room. If medium or high shading of the windows is recommended."
                    )}
                />
            </CardTitle>
            <CardDescription>
                <Trans
                    // t("descriptionVisibilityCoverageCard", "Last 7 days sun glare from <Low>low</Low> to <Medium>medium</Medium> to <High>high</High>")
                    i18nKey="descriptionVisibilityCoverageCard"
                    default="Last 7 days sun glare from <Low>low</Low> to <Medium>medium</Medium> to <High>high</High>"
                    components={{
                        Low: <DataLabel type="sunlightExposureLow" />,
                        Medium: <DataLabel type="sunlightExposureMedium" />,
                        High: <DataLabel type="sunlightExposureHigh" />,
                    }}
                />
            </CardDescription>
            <CardContent className={styles.verticallyCentered}>
                <HourlyGradientChart
                    values={data}
                    colorForBar={getColor}
                    noValueColor={colors.hourlyGradientChartNoData}
                />
            </CardContent>
        </Card>
    );
};
