import { TabProps } from "../TabProps";
import { SceneLabelSelector } from "../../../SceneLabelSelector/SceneLabelSelector";
import { canAccessSettings } from "../../settingsSchemaUtils";
import { useHasPermission } from "../../../../hooks/useHasPermission";
import { Alert } from "../../../UI/Alerts/Alert";
import { useTranslation } from "react-i18next";
import styles from "./BedMonitoringTab.module.css";

export const SceneLabelEditView = (props: TabProps) => {
    const { t } = useTranslation();
    const hasPermission = useHasPermission();

    const canAccessBedLocations =
        canAccessSettings("absenceDetection", props.settingsSchema) ||
        canAccessSettings("getupDetection", props.settingsSchema);
    const canAccessBedRails = canAccessSettings("bedRails", props.settingsSchema);

    if (!hasPermission("w:devices")) {
        return <Alert type="error">{t("errorNoPermissionToChangeSceneLabels", "You do not have the permission to change the room setup. ")}</Alert>;
    }

    return (
        <div className={styles.editContainer}>
            <SceneLabelSelector
                device={props.device.device}
                hideBedPoints={!canAccessBedLocations}
                hideBedRails={!canAccessBedRails}
                readonly={!hasPermission("w:devices")}
                onSceneLabelDelete={props.onSceneLabelDelete}
                settings={props.settings}
            />
        </div>
    );
};
