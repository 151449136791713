import React, { PropsWithChildren } from "react";
import { useLocationContext } from "../../../context/LocationContextProvider";
import { useGetFacility } from "../../../api_v2/hooks/useFacilities";
import { Loading } from "../Loading";
import { useTranslation } from "react-i18next";
import { View } from "../../View";

export const LocationContextAwareView = ({ children }: PropsWithChildren<unknown>) => {
    const { t } = useTranslation();

    const locationContext = useLocationContext();
    const facilityQuery = useGetFacility(locationContext.facilityId);

    if (!facilityQuery.isSuccess) return <Loading />;

    const facility = facilityQuery.data;
    const area = facilityQuery.data?.areas.find((a) => a.id === locationContext.areaId);

    const facilityName = facility.name;
    const locationName = area?.name ?? t("titleAllLocations", "All areas");

    return (
        <View title={locationName} subtitle={facilityName}>
            {children}
        </View>
    );
};
