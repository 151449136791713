export const colors = {
    darkGreen: "#238680",
    darkgrey: "#808080",
    orange: "#f15a40",
    lightGreen: "#a6d8c0",
    lightGrey: "#dce5e5",
    secondary: "#14325c",
    white: "#ffffff",
    // new colors
    fallDetection: "#f15a40",
    fallDetectionBackground: "#f15a4014",
    fallDetectionNoFall: "#a8a9a9",
    emergencyPrevention: "#6389af",
    emergencyPreventionNoFall: "#a8a9a9",
    emergencyPreventionBackground: "#6389af14",
    presenceButton: "#a8a9a9",
    presenceButtonBackground: "#d7d7d714",
    presenceButtonIconBackground: "#e6e6e6",
    night: "#6389af",
    day: "#FFD034",
    lastWeek: "#808080",
    clock: {
        bg: "#cbcbcb",
        fg: "#6389af",
        text: "#293B61",
        mark: "#f15a40",
        hands: "#293B61",
    },
    careAcuity: ["#238680", "#14325c", "#f15a40"],
    bedActivity: ["#238680", "#1cada4", "#0dd7cb"],
    roomActivityNight: "#6389af",
    roomActivityDay: "#FFD034",
    sunlightExposureHigh: "#f15a40",
    sunlightExposureMedium: "#FFD034",
    sunlightExposureLow: "#238680",
    hourlyGradientChartNoData: "#f7f7f9",
    bedActivityDay: "#0dd7cb",
    bedActivityNight: "#238680",
    dayAndNightCombinedNight: "#808080",
    dayAndNightCombinedDay: "#808080",
    roomActivityBars: ["#3c5874", "#6389af", "#a4bad0"],
    bedMobilityBars: ["#185e59", "#238680", "#2eafa7"],
};
