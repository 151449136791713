export interface Resolution {
    w: number;
    h: number;
}

export const images = {
    resolution: {
        horizontal: {
            w: 800,
            h: 600,
        } as Resolution,
        vertical: {
            w: 480,
            h: 640,
        } as Resolution,
    },

    eventImages: {
        landscape: {
            w: 640,
            h: 480,
        } as Resolution,
        portrait: {
            w: 480,
            h: 640,
        } as Resolution,
    },
};

export const heatmaps = {
    resolution: {
        w: 640,
        h: 480,
    },
};
