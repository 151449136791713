import React, { useEffect, useState } from "react";
import { c } from "../../../utils/ClassesHelper";
import { CardTitle } from "../../../components/UI/Card/CardTitle";
import { CardDescription } from "../../../components/UI/Card/CardDescription";
import { CardContent } from "../../../components/UI/Card/CardContent";
import { Card } from "../../../components/UI/Card/Card";
import { Id } from "../../../api_v2/types/custom";
import { Trans, useTranslation } from "react-i18next";
import { DateInterval } from "../../../utils/DateInterval";
import { DataLabel } from "../common/DataLabel";
import { useNavigate } from "react-router-dom";
import { route } from "../../../utils/RouteHelper";
import { isObject } from "lodash";
import { CardSettings } from "../../../components/UI/Card/CardSettings";
import { ToggleSwitch } from "../../../components/UI/ToggleSwitch";
import { CardsTitlesAndTooltips } from "../CardsTitlesAndTooltips";
import { subHours } from "date-fns";
import { ActivityHeatMap, useListMultipleActivities } from "../../../api_v2/hooks/useHeatMap";
import { useListSceneLabels } from "../../../api_v2/hooks/useSceneLabels";
import { AreaChartWithDayAndNight } from "../Charts/AreaChartWithDayAndNight";
import { colors } from "../../../utils/colors";
import {
    applyGaussianFilter,
    getTransitionEntries,
    getNormalizedDayAndNightSeparatedActivityData,
} from "../data/useActivityDataHelpers";
import styles from "../DashboardView.module.scss";
import cardStyles from "../Container/CardGrid.module.scss";

export interface WeeklyBedRestMobilityCardProps {
    deviceId: Id;
    interval: DateInterval;
}

export const WeeklyBedRestMobilityCard = ({ deviceId, interval }: WeeklyBedRestMobilityCardProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [_bedIndex, setBedIndex] = useState(0);
    const [includeDay, setIncludeDay] = useState<boolean>(true);
    const [includeNight, setIncludeNight] = useState<boolean>(true);

    const sceneLabelsQuery = useListSceneLabels(deviceId);
    const bedPoints = (sceneLabelsQuery?.data ?? []).filter((s) => s.type === "bed-point");

    const bedIndex = bedPoints.length > _bedIndex ? _bedIndex : null;

    const bedActivitiesQueries = useListMultipleActivities(
        bedIndex === null
            ? []
            : new Array(7).fill(0).flatMap((_, i) => {
                  const before = subHours(interval.end, i * 24);

                  return {
                      type: "bed",
                      bedId: bedPoints[bedIndex].id,
                      params: { before },
                  };
              })
    );

    useEffect(() => {
        setBedIndex(0);
    }, [deviceId]);

    const bedActivities: Array<ActivityHeatMap> = bedActivitiesQueries.flatMap(
        // @ts-expect-error UseQueryResult ist not correctly typed, we don't know why though
        (query): Array<ActivityHeatMap> => query.data ?? []
    );

    const sortedData = getNormalizedDayAndNightSeparatedActivityData(bedActivities, interval.end, 8 * 24, 1).reverse();
    const smoothData = applyGaussianFilter(sortedData, 6, 2);
    const data = getTransitionEntries(smoothData, includeDay, includeNight);

    const numBeds = bedPoints.length;

    const handleClick = (data: unknown) => {
        if (bedIndex === null) return;

        navigate(
            route({
                to: "rooms",
                view: {
                    type: "analysis",
                    deviceId,
                    bedId: bedPoints[bedIndex].id,
                    mode: "day",
                    date: isObject(data) && "timestamp" in data ? (data.timestamp as string) : undefined,
                },
            })
        );
    };
    const bedLabel = numBeds > 1 ? _bedIndex + 1 : undefined;

    return (
        <Card className={c([styles.card, cardStyles.md2, cardStyles.lg2, cardStyles.xl2])}>
            <CardTitle>
                <CardsTitlesAndTooltips
                    title={t("titleWeeklyBedActivityCard", "Bed rest mobility")}
                    tooltip={t(
                        "tooltipWeeklyBedActivityCard",
                        "Shows the summary of person bed mobility. Night depicts period 7pm to 7am."
                    )}
                />
            </CardTitle>
            <CardDescription>
                <Trans
                    // t("descriptionWeeklyBedActivityCard", "Bed {{bedLabel}} rest mobility divided into <Day>day</Day> and <Night>night</Night>")
                    i18nKey="descriptionWeeklyBedActivityCard"
                    default="Bed {{bedLabel}} rest mobility divided into <Day>day</Day> and <Night>night</Night>"
                    values={{ bedLabel: bedLabel }}
                    components={{
                        Day: (
                            <DataLabel
                                type="bedActivityDay"
                                active={includeDay}
                                onClick={() => setIncludeDay(!includeDay)}
                            />
                        ),
                        Night: (
                            <DataLabel
                                type="bedActivityNight"
                                active={includeNight}
                                onClick={() => setIncludeNight(!includeNight)}
                            />
                        ),
                    }}
                />
            </CardDescription>
            {numBeds > 1 ? (
                <CardSettings itemList={true}>
                    {() => (
                        <label>
                            {/* This is a toggle switch because currently only two beds are possible (confirmed with cogvis) */}
                            <ToggleSwitch
                                state={bedIndex === 1}
                                onChange={(newState: boolean) => (newState ? setBedIndex(1) : setBedIndex(0))}
                                checkedLabel={t("labelBedMonitoringBedNumber", "Bed {{index}}", { index: 2 })}
                                uncheckedLabel={t("labelBedMonitoringBedNumber", "Bed {{index}}", { index: 1 })}
                                centered={true}
                                bothAccent={true}
                            />
                        </label>
                    )}
                </CardSettings>
            ) : null}
            <CardContent>
                <AreaChartWithDayAndNight
                    data={data}
                    rangeMax={100}
                    customYTicks={true}
                    // @ts-expect-error
                    areaOnClick={handleClick}
                    dayColor={colors.bedActivityDay}
                    nightColor={colors.bedActivityNight}
                />
            </CardContent>
        </Card>
    );
};
