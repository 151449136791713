import { useTranslation } from "react-i18next";
import { addDays, addMonths, isFuture, isToday, set, subDays, subMonths } from "date-fns";
import { ButtonGroup } from "../../../../UI/Button/ButtonGroup";
import { Button } from "../../../../UI/Button/Button";
import { DateRange } from "../../../common/DateRange";
import { Label as UiLabel } from "../../../../UI/Label";
import { ToggleSwitch } from "../../../../UI/ToggleSwitch";
import styles from "../ActivityGraph.module.css";

export interface MonthFilterProps {
    selectedDateRange: DateRange;
    onSelectedDateRangeChange: (dateRange: DateRange) => void;
    timeWindow: [string, string];
    onTimeWindowChange: (timeWindow: [string, string]) => void;
    isSmallScreen?: boolean;
    showStatistics: boolean;
    onshowStatistics: (showStatistics: boolean) => void;
}

export const MonthFilter = ({
    selectedDateRange,
    onSelectedDateRangeChange,
    isSmallScreen,
    showStatistics,
    onshowStatistics,
}: MonthFilterProps) => {
    const { t } = useTranslation();

    const isInFuture = isFuture(addMonths(selectedDateRange.end, 1));
    const isCurrentDay = isToday(selectedDateRange.end);

    const handleNextMonth = () => {
        const end = isSmallScreen ? addDays(selectedDateRange.end, 15) : addMonths(selectedDateRange.end, 1);
        const start = subMonths(end, 1);
        onSelectedDateRangeChange({ start, end });
    };

    const handleGoToToday = () => {
        const end = set(new Date(), {
            hours: selectedDateRange.end.getHours(),
            minutes: selectedDateRange.end.getMinutes(),
            seconds: 0,
            milliseconds: 0,
        });
        const start = subMonths(end, 1);
        onSelectedDateRangeChange({ start, end });
    };

    const handlePrevMonth = () => {
        const end = isSmallScreen ? subDays(selectedDateRange.end, 15) : subMonths(selectedDateRange.end, 1);
        const start = subMonths(end, 1);
        onSelectedDateRangeChange({ start, end });
    };

    return (
        <div className={styles.buttonContainer}>
            <div className={styles.buttonGroupContainer}>
                <ButtonGroup className={styles.buttonGroupArrows}>
                    <Button
                        aria-label={t("ariaLabelMonthFilterBack", "button to go back a month")}
                        icon="left"
                        onClick={handlePrevMonth}
                        className={styles.prevButton}
                    />
                    <Button
                        aria-label={t("ariaLabelMonthFilterForward", "button to go forward a month")}
                        icon="right"
                        onClick={isInFuture ? handleGoToToday : handleNextMonth}
                        disabled={isInFuture && isCurrentDay}
                    />
                </ButtonGroup>
            </div>
            <UiLabel text={t("showStatistics", "Show statistics")} additionalClassNames={styles.label}>
                <ToggleSwitch
                    aria-label={t("ariaLabelToggleStatisticsButton", "switch to show statistics")}
                    aria-pressed={"true"}
                    state={showStatistics}
                    onChange={() => onshowStatistics(!showStatistics)}
                    checkedLabel={t("labelOn", "on")}
                    uncheckedLabel={t("labelOff", "off")}
                />
            </UiLabel>
        </div>
    );
};
