import React from "react";
import { Combobox as HeadlessCombobox } from "@headlessui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Combobox.module.css";

interface ComboboxProps<T> {
    selected: T | null;
    onSelect: (el: T) => void;
    options: T[];
    valueGetter: (el: T) => string;
    keyGetter: (el: T) => string;
    filter: (element: T, query: string) => boolean;
    placeholder?: string;
    disabled?: boolean;
}

export const Combobox = <T,>(props: ComboboxProps<T>) => {
    const [query, setQuery] = useState("");
    const { t } = useTranslation();

    const filtered = query.length > 0 ? props.options.filter((el) => props.filter(el, query)) : props.options;

    return (
        <HeadlessCombobox value={props.selected} onChange={props.onSelect} disabled={props.disabled} nullable>
            <div className={styles.container}>
                <HeadlessCombobox.Input
                    onChange={(event) => setQuery(event.target.value)}
                    className={styles.input}
                    displayValue={(el) => (el ? props.valueGetter(el as T) : "")}
                    placeholder={props.placeholder}
                />
                <HeadlessCombobox.Button className={styles.toggleOptionsBtn}>&nbsp;</HeadlessCombobox.Button>
                <HeadlessCombobox.Options className={styles.options}>
                    {filtered.length === 0 ? <p className={styles.noResults}>{t("comboboxNoResults", "No items found")}</p> : null}
                    {filtered.map((el) => (
                        <HeadlessCombobox.Option
                            key={props.keyGetter(el)}
                            value={el}
                            className={({ active }) => `${styles.option} ${active ? styles.active : ""}`}
                        >
                            {props.valueGetter(el)}
                        </HeadlessCombobox.Option>
                    ))}
                </HeadlessCombobox.Options>
            </div>
        </HeadlessCombobox>
    );
};
