import { PropsWithChildren } from "react";
import { colors } from "../../../utils/colors";
import styles from "./DataLabel.module.scss";
import { c } from "../../../utils/ClassesHelper";

type LabelType =
    | "day"
    | "night"
    | "lastWeek"
    | "fallDetection"
    | "emergencyPrevention"
    | "sunlightExposureLow"
    | "sunlightExposureMedium"
    | "sunlightExposureHigh"
    | "presenceButton"
    | "bedActivity"
    | "careAcuity"
    | "bedActivityDay"
    | "bedActivityNight"
    | "roomActivityDay"
    | "roomActivityNight"
    | "dayAndNightCombinedNight"
    | "dayAndNightCombinedDay";

export interface DataLabelProps extends PropsWithChildren {
    type: LabelType;
    index?: number;
    active?: boolean;
    onClick?: () => void;
}

const getColor = (type: LabelType, index?: number): string => {
    const color = colors[type];

    if (Array.isArray(color)) {
        if (index === undefined) {
            // We want to keep this console.warn statement
            // eslint-disable-next-line no-console
            console.warn("Index is required for color", type);
        }

        return color[index ?? 0];
    }

    return color;
};

export const DataLabel = ({ type, index, children, onClick, active }: DataLabelProps) => {
    return (
        <span
            className={c([styles.label], {
                [styles.clickable]: onClick !== undefined,
                [styles.inactive]: active === false,
            })}
            style={{ color: getColor(type, index) }}
            onClick={onClick}
        >
            {children}
        </span>
    );
};
