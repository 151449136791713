import React from "react";
import { useTranslation } from "react-i18next";
import { EnableMfaForm, TotpFormInputs } from "../forms/EnableMfaForm";
import { toast } from "react-toastify";
import { Toast } from "../../components/UI/Toast/Toast";
import { c } from "../../utils/ClassesHelper";
import { Loading } from "../../components/UI/Loading";
import styles from "../Auth.module.css";
import { useSetMfa } from "../cognito/useSetMfa";
import { useAssociateSoftwareToken } from "../cognito/useAssociateSoftwareToken";
import { useVerifySoftwareToken } from "../cognito/useVerifySoftwareToken";
import { useUser } from "../../context/UserContext";

export interface EnableMfaModalContentProps {
    onClose: () => void;
}
export const EnableMfaModalContent = (props: EnableMfaModalContentProps) => {
    const { t } = useTranslation();
    const user = useUser();

    const associateToken = useAssociateSoftwareToken();
    const verifyToken = useVerifySoftwareToken();
    const setMfa = useSetMfa();

    const handleEnableMfa = async (data: TotpFormInputs) => {
        try {
            await verifyToken.mutateAsync({
                ...data,
            });

            await setMfa.mutateAsync({ enable: true });

            toast.success(
                <Toast
                    title={t("mfa.successToastTitle", "Two-factor authentication enabled")}
                    description={t(
                        "mfa.successToastDescription",
                        "Two-factor authentication has been successfully enabled!"
                    )}
                />
            );

            props.onClose();
        } catch (err) {
            toast.error(
                <Toast
                    title={t("mfa.errorToastTitle", "Error enabling two-factor authentication")}
                    description={t(
                        "mfa.errorToastDescription",
                        "There has been a problem enabling two-factor authentication for your account. Please try again later."
                    )}
                />
            );
        }
    };

    return (
        <>
            <strong className={styles.mfaTitle}>{t("mfa.titleEnable", "Enable Two-factor authentication")}</strong>

            {verifyToken.isError ? (
                <p className={c([styles.error, styles.errorMb])}>
                    {t("mfa.errorInvalidToken", "The code you entered is invalid!")}
                </p>
            ) : null}

            {setMfa.isError ? (
                <p className={c([styles.error, styles.errorMb])}>
                    {t(
                        "mfa.errorEnablingMfa",
                        "There was an error enabling two-factor authentication. Please try again later."
                    )}
                </p>
            ) : null}

            {associateToken.isLoading ? <Loading text={t("The QR code is loading")} /> : null}

            {associateToken.isSuccess ? (
                <EnableMfaForm
                    onSubmit={handleEnableMfa}
                    totpSecret={associateToken.data}
                    totpUsername={user.email}
                    defaultDeviceName={user.name}
                />
            ) : null}
        </>
    );
};
