import { BiSolidCircle, BiFileBlank } from "react-icons/bi";
import styles from "./DemoDataOverlay.module.scss";
import { c } from "../../utils/ClassesHelper";
import { useState } from "react";
import { useDemoDataContext } from "../../context/DemoDataContext";

export interface DemoDataOverlayProps {
    mode: "playback" | "recording";
}

export const DemoDataOverlay = ({ mode }: DemoDataOverlayProps) => {
    const demoDataContext = useDemoDataContext();

    const [hidden, setHidden] = useState(false);

    const handleRecordClick = () => {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(demoDataContext.record.current));
    };

    if (mode === "recording") {
        return (
            <div className={styles.recording} onClick={handleRecordClick} title="Click to console.log record">
                <BiSolidCircle /> Recording
            </div>
        );
    }

    return (
        <div className={c([styles.playback], { [styles.hidden]: hidden })} onClick={() => setHidden((s) => !s)}>
            <div className={styles.panel}>
                <BiFileBlank /> Demo Data Modus
            </div>
        </div>
    );
};
