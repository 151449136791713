const hours = (hours: number): number => {
    return hours * 60 * 60 * 1000;
};

const minutes = (minutes: number) => {
    return minutes * 60 * 1000;
};

const seconds = (seconds: number) => {
    return seconds * 1000;
};

const at = (hour: number, min: number, sec: number): number => {
    const now = new Date();
    const target = new Date();
    target.setHours(hour, min, sec, 0);

    return target.getTime() - now.getTime();
};

export { hours, minutes, seconds, at };
