import { ActivityHeatMap } from "../../../../api_v2/hooks/useHeatMap";
import { DateRange } from "../../common/DateRange";
import {
    addDays,
    differenceInDays,
    endOfDay,
    endOfWeek,
    formatISO,
    getMonth,
    isLastDayOfMonth,
    isSameDay,
    isWithinInterval,
    startOfDay,
    startOfWeek,
} from "date-fns";
import { chunk, range } from "lodash";
import { useFormat } from "../../../../hooks/useFormat";
import { Cell } from "./Cell";
import { useTranslation } from "react-i18next";
import { dateFnsLocaleMap } from "../../../../i18n";
import { c } from "../../../../utils/ClassesHelper";
import styles from "./BubbleChart.module.scss";

export interface BubbleChartProps {
    heatMaps: ActivityHeatMap[];
    dateRange: DateRange;
    onDaySelect?: (day: Date) => void;
}

export const BubbleChart = ({ heatMaps, dateRange, onDaySelect }: BubbleChartProps) => {
    const format = useFormat();
    const { i18n } = useTranslation();
    const locale = dateFnsLocaleMap.get(i18n.language);

    const start = startOfWeek(dateRange.start, { locale });
    const end = endOfWeek(dateRange.end, { locale });

    const days = range(differenceInDays(end, start) + 1).map((day) => addDays(start, day));
    const weeks = chunk(days, 7);
    const month = getMonth(weeks[0][0]);

    const fullInterval = {
        start: startOfDay(dateRange.start),
        end: endOfDay(dateRange.end),
    };

    return (
        <div className={styles.container}>
            <div className={styles.calendar}>
                <div className={c([styles.week, styles.header])}>
                    {weeks[0].map((day) => (
                        <div key={formatISO(day)} className={c([styles.cell])}>
                            <span className={styles.full}>{format(day, "EEEE")}</span>
                            <span className={styles.short}>{format(day, "EE")}</span>
                        </div>
                    ))}
                </div>
                {weeks.map((days) => {
                    return (
                        <div
                            key={format(days[0], "w")}
                            className={c([styles.week], { [styles.lastWeekOfMonth]: isLastDayOfMonth(days[6]) })}
                        >
                            {days.map((day) => {
                                const inDateRange = isWithinInterval(day, fullInterval);
                                const level = heatMaps.find((h) => isSameDay(new Date(h.endTime), day))?.level ?? null;
                                return (
                                    <Cell
                                        key={formatISO(day)}
                                        date={day}
                                        month={month}
                                        isWithinDateRange={inDateRange}
                                        level={level}
                                        onClick={() => onDaySelect?.(day)}
                                    />
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
