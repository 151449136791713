import { QueriesResults, useQueries, useQuery } from "react-query";
import { useFetch, useMutate } from "@bitperfect-packages/react-query-axios";
import { Id } from "../types/custom";
import { components } from "../types/schema";
import { blobToImageTransformer } from "./common/blobToImageTransformer";
import { UseQueryOptions } from "react-query/types/react/types";
import { dailyActivityToActivityTransformer } from "../../utils/DailyActivityToActivityTransformer";
import { format } from "date-fns";
import { minutes } from "../../utils/IntervalHelper";

export type ActivityHeatMap = components["schemas"]["Activity"];
export type DailyActivity = components["schemas"]["DailyActivity"];

export const useListHeatMapsForBed = (
    bedId: Id | null,
    before?: Date,
    options?: UseQueryOptions<ActivityHeatMap[]>
) => {
    const params = new URLSearchParams();

    if (before) {
        params.set("before", before.getTime().toString());
    }

    return useQuery<ActivityHeatMap[]>(
        ["beds", bedId, "heatMap", before],
        useFetch<ActivityHeatMap[]>(`/beds/${bedId}/activities`, {
            config: {
                params,
            },
        }),
        {
            retry: 0,
            enabled: bedId !== null,
            ...options,
        }
    );
};

export const useGetHeatMapImage = (activityId: Id | null) => {
    return useQuery<string>(
        ["heatMap", activityId, "heatMapImage"],
        useFetch<string>(`/activities/${activityId}/image`, {
            config: { responseType: "blob" },
            responseTransform: blobToImageTransformer,
        }),
        {
            retry: 0,
            enabled: activityId !== null,
        }
    );
};

export const useListHeatMapsForRoom = (
    deviceId: Id | null,
    before?: Date,
    options?: UseQueryOptions<ActivityHeatMap[]>
) => {
    const params = new URLSearchParams();

    if (before) {
        params.set("before", before.getTime().toString());
    }

    return useQuery<ActivityHeatMap[]>(
        ["devices", deviceId, "heatMapRoom", before],
        useFetch<ActivityHeatMap[]>(`/devices/${deviceId}/room-activities`, {
            config: {
                params,
            },
        }),
        {
            retry: 0,
            enabled: deviceId !== null,
            ...options,
        }
    );
};

export const useListDailyBedActivities = (
    bedId: Id | null,
    params: {
        before?: Date;
        window?: [string, string];
    },
    options?: UseQueryOptions<Array<ActivityHeatMap>>
) => {
    const urlSearchParams = getActivityQueryParams(params, "daily");

    return useQuery<Array<ActivityHeatMap>>(
        ["beds", bedId, "daily-activities", params],
        useFetch<Array<ActivityHeatMap>>(`/beds/${bedId}/daily-activities`, {
            responseTransform: (data: Array<DailyActivity>) => data.map(dailyActivityToActivityTransformer),
            config: {
                params: urlSearchParams,
            },
        }),
        {
            retry: 0,
            enabled: bedId !== null,
            ...options,
        }
    );
};

/**
 * Combined query that fetches multiple bed and/or room activities
 * @param options
 */
export const useListMultipleDailyActivities = (
    options: Array<
        {
            params: {
                before?: Date;
                window?: [string, string];
                days?: number;
            };
        } & (
            | {
                  type: "bed";
                  bedId: Id;
              }
            | {
                  type: "room";
                  deviceId: Id;
              }
        )
    >
): QueriesResults<Array<ActivityHeatMap>[]> => {
    const fetchBed = useMutate<Array<ActivityHeatMap>, { bedId: Id; params: URLSearchParams }>(
        "GET",
        (data) => `/beds/${data.bedId}/daily-activities`,
        {
            responseTransform: (data: Array<DailyActivity>) => data.map(dailyActivityToActivityTransformer),
            requestConfigTransform: (data, config) => ({ ...config, params: data.params }),
        }
    );

    const fetchRoom = useMutate<Array<ActivityHeatMap>, { deviceId: Id; params: URLSearchParams }>(
        "GET",
        (data) => `/devices/${data.deviceId}/daily-room-activities`,
        {
            responseTransform: (data: Array<DailyActivity>) => data.map(dailyActivityToActivityTransformer),
            requestConfigTransform: (data, config) => ({ ...config, params: data.params }),
        }
    );

    return useQueries<Array<ActivityHeatMap>>(
        options.map((option) => {
            const urlSearchParams = getActivityQueryParams(option.params, "daily");

            return {
                queryKey:
                    option.type === "bed"
                        ? ["beds", option.bedId, "daily-activities", option.params]
                        : ["devices", option.deviceId, "heatMapMonthData", "daily-room-activities", option.params],
                queryFn: () =>
                    option.type === "bed"
                        ? fetchBed({ bedId: option.bedId, params: urlSearchParams })
                        : fetchRoom({ deviceId: option.deviceId, params: urlSearchParams }),
            };
        })
    );
};

export const useListDailyRoomActivities = (
    deviceId: Id | null,
    params: {
        before?: Date;
        window?: [string, string];
    },
    options?: UseQueryOptions<Array<ActivityHeatMap>>
) => {
    const urlSearchParams = getActivityQueryParams(params, "daily");

    return useQuery<Array<ActivityHeatMap>>(
        ["devices", deviceId, "heatMapMonthData", "daily-room-activities", params],
        useFetch<Array<ActivityHeatMap>>(`/devices/${deviceId}/daily-room-activities`, {
            responseTransform: (data: Array<DailyActivity>) => data.map(dailyActivityToActivityTransformer),
            config: {
                params: urlSearchParams,
            },
        }),
        {
            retry: 0,
            enabled: deviceId !== null,
            ...options,
        }
    );
};

const getActivityQueryParams = (
    params: {
        before?: Date;
        window?: [string, string];
        days?: number;
    },
    type: "daily" | "weekly"
) => {
    const urlSearchParams = new URLSearchParams();

    if (params.before) {
        if (type === "daily") {
            urlSearchParams.set("before", format(params.before, "yyyy-MM-dd"));
        } else {
            urlSearchParams.set("before", params.before.getTime().toString());
        }
    }

    if (params.window) {
        urlSearchParams.set("window", params.window.map((t, i) => getHourString(t, i > 0)).join(","));
    }

    if (params.days) {
        if (params.days < 1 || params.days > 30) {
            // eslint-disable-next-line no-console
            console.warn("days must be between 1 and 30!");
        }
        if (params.days < 1) {
            params.days = 1;
        }
        if (params.days > 30) {
            params.days = 30;
        }
        urlSearchParams.set("days", params.days.toString());
    }

    return urlSearchParams;
};

/**
 * The api expects 0,24 instead of 0,0 for the whole day.
 * Maps time from 12:00 format to hour only (12) and maps end of day 00:00 to 24.
 *
 * @param time
 * @param eod
 */
const getHourString = (time: string, eod: boolean) => {
    const t = time.substring(0, 2);
    if (eod && parseInt(t) === 0) {
        return "24";
    }
    return t;
};

export const useListMultipleActivities = (
    options: Array<
        {
            params: {
                before?: Date;
            };
        } & (
            | {
                  type: "bed";
                  bedId: Id;
              }
            | {
                  type: "room";
                  deviceId: Id;
              }
        )
    >
): QueriesResults<Array<ActivityHeatMap>> => {
    const fetchBed = useMutate<Array<ActivityHeatMap>, { bedId: Id; params: URLSearchParams }>(
        "GET",
        (data) => `/beds/${data.bedId}/activities`,
        {
            requestConfigTransform: (data, config) => ({ ...config, params: data.params }),
        }
    );

    const fetchRoom = useMutate<Array<ActivityHeatMap>, { deviceId: Id; params: URLSearchParams }>(
        "GET",
        (data) => `/devices/${data.deviceId}/room-activities`,
        {
            requestConfigTransform: (data, config) => ({ ...config, params: data.params }),
        }
    );

    return useQueries<Array<ActivityHeatMap>>(
        options.map((option) => {
            const urlSearchParams = getActivityQueryParams(option.params, "weekly");

            return {
                queryKey:
                    option.type === "bed"
                        ? ["beds", option.bedId, "heatMap", option.params.before]
                        : ["devices", option.deviceId, "heatMapRoom", option.params.before],
                queryFn: () =>
                    option.type === "bed"
                        ? fetchBed({ bedId: option.bedId, params: urlSearchParams })
                        : fetchRoom({ deviceId: option.deviceId, params: urlSearchParams }),
                staleTime: minutes(5),
            };
        })
    );
};
