import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next, useTranslation } from "react-i18next";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import en from "date-fns/locale/en-GB";
import fr from "date-fns/locale/fr";
import sv from "date-fns/locale/sv";
import da from "date-fns/locale/da";
import localeDe from "dayjs/locale/de";
import localeEn from "dayjs/locale/en";
import localeFr from "dayjs/locale/fr";
import localeSv from "dayjs/locale/sv";
import localeDa from "dayjs/locale/da";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Locale as DayJsLocale } from "dayjs/locale/*";

registerLocale("de", de);
registerLocale("en", en);
registerLocale("fr", fr);
registerLocale("sv", sv);
registerLocale("da", da);

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const supportedLanguages = ["en", "de", "fr", "sv", "da"];
type SupportedLanguages = (typeof supportedLanguages)[number];

const queryParams = new URLSearchParams(window.location.search);

// TODO we currently seem to use both date-fns and dayjs as date/time library.
//  We should decide for one and remove the other one.

export const dateFnsLocaleMap = new Map<SupportedLanguages, Locale>([
    ["de", de],
    ["en", en],
    ["fr", fr],
    ["sv", sv],
    ["da", da],
]);

export const defaultDayJsLocale = localeDe;
export const dayJslocaleMap = new Map<SupportedLanguages, DayJsLocale>([
    ["de", localeDe],
    ["en", localeEn],
    ["fr", localeFr],
    ["sv", localeSv],
    ["da", localeDa],
]);
dayjs.locale(dayJslocaleMap.get(i18n.language) || defaultDayJsLocale);

const defaultLanguage: SupportedLanguages = "en";

/**
 * The language is determined by the following properties, in this order (higher in list = higher priority):
 * - Query param (?lang=de)
 * - Local storage (what the user selected via menu)
 * - Browser reported languages
 * - Default language
 */
const currentLanguage =
    queryParams.get("lang")?.toLowerCase() ??
    window.localStorage.getItem("lang") ??
    navigator.languages
        .map((language) => {
            const locale = new Intl.Locale(language);
            return locale.language;
        })
        .find((language) => supportedLanguages.includes(language)) ??
    defaultLanguage;

setDefaultLocale(currentLanguage);

export const useSetLanguage = () => {
    const { i18n } = useTranslation();

    return (lang: string) => {
        if (!supportedLanguages.includes(lang)) {
            throw new Error("Invalid language key!");
        }

        i18n.changeLanguage(lang);
        window.localStorage.setItem("lang", lang);
        document.documentElement.lang = lang;
        dayjs.locale(dayJslocaleMap.get(i18n.language) || defaultDayJsLocale);
    };
};

i18n.use(initReactI18next)
    .use(HttpApi)
    .init({
        //resources,
        ns: "nurse-ui",
        defaultNS: "nurse-ui",
        lng: currentLanguage,
        supportedLngs: supportedLanguages,
        fallbackLng: false,
        interpolation: {
            escapeValue: false,
        },
        load: "languageOnly",
    });

document.documentElement.lang = currentLanguage;

export default i18n;
