import { AreaRoom, Id } from "../api_v2/types/custom";
import { Select, SelectProps } from "./UI/Select";
import React from "react";
import { useTranslation } from "react-i18next";

export interface RoomsSelectProps extends Omit<SelectProps<Id>, "items" | "onSelect"> {
    rooms: Array<AreaRoom>;
    selected: Id | null;
    onChange: (id: Id | null) => void;
    id?: string;
}

export const RoomsSelect = ({ rooms, selected, onChange, id, ...props }: RoomsSelectProps) => {
    const { t } = useTranslation();

    const roomsMap = new Map(rooms.map((room) => [room.id, room.name]));

    return (
        <Select
            id={id}
            default={props.default ?? t("labelAll", "All")}
            {...props}
            items={roomsMap}
            onSelect={onChange}
            selected={selected}
        />
    );
};
