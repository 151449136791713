import { Device } from "../../api_v2/hooks/useDevices";
import { useTranslation } from "react-i18next";
import styles from "./DashboardView.module.scss";

export const DeviceNotDockedNotice = ({ device }: { device: Device }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.deviceNotDockedNotice}>
            {t("deviceNotDockedNotice", "The device {{device}} is not docked. Dock it to see the dashboard.", { device: device.name ?? device.id })}
        </div>
    );
};
