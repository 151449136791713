import styles from "../BedMonitoringTab.module.css";
import { InputGroup } from "../../../../UI/InputGroup";
import { Label } from "../../../../UI/Label";
import { ToggleSwitch } from "../../../../UI/ToggleSwitch";
import _ from "lodash";
import React from "react";
import { BedRailSettingsProps } from "./LightBarrierSettings";
import { useTranslation } from "react-i18next";

export const GlobalLightBarrierSettings = (props: BedRailSettingsProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.commonContainer}>
            <strong className={styles.title}>{t("labelAllBedRails", "All bed rails")}</strong>

            <InputGroup>
                <Label text={t("bedRailsEnable", "Active")}>
                    <ToggleSwitch
                        checkedLabel={t("labelActivated", "Activated")}
                        uncheckedLabel={t("labelDeactivated", "Deactivated")}
                        state={props.settings.bedRailsEnable ?? false}
                        onChange={(s) => {
                            const newSettings = _.cloneDeep(props.settings);
                            newSettings.bedRailsEnable = s;
                            props.onSettingsChange(newSettings);
                        }}
                        disabled={props.readonly}
                        readonly={props.readonly}
                        id="BedRailsEnableToggle"
                    />
                </Label>
            </InputGroup>
        </div>
    );
};
