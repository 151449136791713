import useImageFromLibrary from "use-image";
import { useRef } from "react";

/**
 * Wrapper around useImage that keeps returning the previous image, if a new image is currently loaded.
 * Solves scene image flickering when changing tabs.
 *
 * @param url
 * @param crossOrigin
 * @param referrerpolicy
 */
export const useImage = (
    url: string,
    crossOrigin?: "anonymous" | "use-credentials",
    referrerpolicy?:
        | "no-referrer"
        | "no-referrer-when-downgrade"
        | "origin"
        | "origin-when-cross-origin"
        | "same-origin"
        | "strict-origin"
        | "strict-origin-when-cross-origin"
        | "unsafe-url"
): [undefined | HTMLImageElement, "loaded" | "loading" | "failed"] => {
    const previousImage = useRef<HTMLImageElement | undefined>(undefined);
    const [image, status] = useImageFromLibrary(url, crossOrigin, referrerpolicy);

    if (image !== undefined) {
        previousImage.current = image;
    }

    return [previousImage.current, status];
};
