import React, { ChangeEvent } from "react";

import styles from "./ToggleSwitch.module.scss";

interface ToggleSwitchProps {
    state: boolean;
    onChange: (newState: boolean) => void;
    checkedLabel: string;
    uncheckedLabel: string;
    disabled?: boolean;
    small?: boolean;
    readonly?: boolean;
    id?: string;
    centered?: boolean;
    bothAccent?: boolean;
}

export const ToggleSwitch = (props: ToggleSwitchProps) => {
    const disabled = props.disabled === true;
    const readonly = props.readonly === true;

    const classNames = [
        styles.switch,
        props.small ? styles.small : null,
        disabled ? styles.disabled : null,
        readonly ? styles.readonly : null,
        props.centered === true ? styles.centered : null,
    ];

    const offLabelClassName = [styles.label, props.centered && !props.state && props.bothAccent ? styles.active : null];
    const onLabelClassName = [styles.label, props.centered && props.state && props.bothAccent ? styles.active : null];

    const checkboxClassName = [styles.fakeCheckbox, props.bothAccent ? styles.both : null];

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!readonly && !disabled) {
            props.onChange(e.target.checked);
        }
    };

    return (
        <div className={classNames.join(" ")}>
            {props.centered ? <span className={offLabelClassName.join(" ")}>{props.uncheckedLabel}</span> : null}
            <input
                type="checkbox"
                checked={props.state}
                className={styles.cb}
                onChange={handleChange}
                disabled={disabled}
                id={props.id}
            />
            <div className={checkboxClassName.join(" ")} />
            <span className={onLabelClassName.join(" ")}>
                {props.centered ? props.checkedLabel : props.state ? props.checkedLabel : props.uncheckedLabel}
            </span>
        </div>
    );
};
