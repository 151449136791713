import React from "react";
import { useTranslation } from "react-i18next";
import { TabProps } from "../../TabProps";
import _ from "lodash";
import { TimeWindowsList } from "../../../../TimeWindows/TimeWindowsList";
import { Label } from "../../../../UI/Label";
import { SceneLabel } from "../../../../../api_v2/hooks/useSceneLabels";
import { Settings } from "../../../../../api_v2/types/custom";
import styles from "../BedMonitoringTab.module.css";
import { NoSettingsErrorMessage } from "../../Common/NoSettingsErrorMessage";
import { ToggleSwitch } from "../../../../UI/ToggleSwitch";
import { InputGroup } from "../../../../UI/InputGroup";
import { GlobalLightBarrierSettings } from "./GlobalLightBarrierSettings";
import { config } from "../../../../../config";
import { addDefaultSettingsForNewSceneLabel } from "../../Common/SceneLabelDefaultSettings";

export interface BedRailSettingsProps extends TabProps {
    selectedBedRail: SceneLabel;
}

export const BedRailSettings = (props: BedRailSettingsProps) => {
    const { t } = useTranslation();
    const bedRailSettings = props.settings.bedRails?.find((bedRail) => bedRail.id === props.selectedBedRail.id);

    // @ts-expect-error TS is too smart here and detects that the filtered scene labels can only be of type line-2d, selectedBedLocation can still be of type bed-point as well though
    const index = props.sceneLabels.filter((l) => l.type === "line-2d").indexOf(props.selectedBedRail);

    const handleSettingsChange = (updatedBedLocationSettings: Exclude<Settings["bedRails"], undefined>[0]) => {
        if (!bedRailSettings) return;

        const newSettings = _.cloneDeep(props.settings);

        if (!Array.isArray(newSettings.bedRails)) {
            newSettings.bedRails = [];
        }

        const index = props.settings.bedRails?.indexOf(bedRailSettings);
        if (index === undefined || index < 0) {
            throw new Error("Can not change bed location that does not exist");
        }

        newSettings.bedRails[index] = updatedBedLocationSettings;
        props.onSettingsChange(newSettings);
    };

    return (
        <>
            {!config.hideGlobalBedRailSettings ? (
                <>
                    <GlobalLightBarrierSettings {...props} />
                    <strong className={styles.title}>{t("labelSelectedBedRail", "Selected bed rail")}</strong>
                </>
            ) : (
                <strong className={styles.title}>{t("labelBedMonitoringBarrierNumber", "Virtual bed rail {{index}}", { index: index + 1 })}</strong>
            )}

            {bedRailSettings ? (
                <>
                    <InputGroup>
                        <Label text={t("labelPointSelectorBarrierDirection", "Directional bed rail")}>
                            <ToggleSwitch
                                checkedLabel={t("labelPointSelectorBarrierDirectionDirectional", "Directional ")}
                                uncheckedLabel={t("labelPointSelectorBarrierDirectionNonDirectional", "Non-directional")}
                                state={bedRailSettings.directional ?? false}
                                onChange={(s) => {
                                    const newBedLocationSettings = _.cloneDeep(bedRailSettings);
                                    newBedLocationSettings.directional = s;
                                    handleSettingsChange(newBedLocationSettings);
                                }}
                                disabled={false}
                                readonly={props.readonly}
                                id="BedRailDirectionalToggle"
                            />
                        </Label>
                    </InputGroup>

                    <Label text={t("labelFallDetectionSchedule", "Alarm period")} additionalClassNames={styles.block}>
                        <TimeWindowsList
                            timeWindows={bedRailSettings.timeWindows}
                            readonly={props.readonly}
                            onChange={(timeWindows) => {
                                const newBedLocationSettings = _.cloneDeep(bedRailSettings);
                                newBedLocationSettings.timeWindows = timeWindows;
                                handleSettingsChange(newBedLocationSettings);
                            }}
                            id="BedRailTimeWindows"
                        />
                    </Label>
                </>
            ) : (
                <NoSettingsErrorMessage
                    settings={props.settings}
                    onSettingsChange={props.onSettingsChange}
                    addSettingsHandler={(settings: Settings) =>
                        addDefaultSettingsForNewSceneLabel(
                            settings,
                            props.selectedBedRail,
                            "bed-monitoring",
                            props.settingsSchema
                        )
                    }
                    readonly={props.readonly}
                />
            )}
        </>
    );
};
