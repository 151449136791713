import { ActivityHeatMap, DailyActivity } from "../api_v2/hooks/useHeatMap";
import { endOfDay, formatISO, startOfDay } from "date-fns";

export const dailyActivityToActivityTransformer = (dailyActivity: DailyActivity): ActivityHeatMap => {
    const date = new Date(dailyActivity.date);

    return {
        id: dailyActivity.date,
        // TODO if time span within day is limited, should start and end time reflect this too?
        //  e.g. if "9:00 - 15:00" is set, should start and end time be 9:00 and 15:00 respectively as well?
        startTime: formatISO(startOfDay(date)),
        endTime: formatISO(endOfDay(date)),
        level: dailyActivity.level,
    };
};
