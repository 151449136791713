import React, { ReactNode } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { SensitivityValues } from "./SensitivitySlider";
import styles from "./SensitivityLabel.module.css";

type SensitivityLabelProps = {
    sensitivity: SensitivityValues;
    disabled?: boolean;
} & WithTranslation;

class SensitivityLabel extends React.Component<SensitivityLabelProps> {
    private readonly values = new Map([
        ["low", { label: this.props.t("labelSensitivityLow", "Low"), className: styles.stateGreen }],
        ["medium", { label: this.props.t("labelSensitivityMedium", "Medium"), className: styles.stateYellow }],
        ["high", { label: this.props.t("labelSensitivityHigh", "High"), className: styles.stateRed }],
    ]);

    render(): ReactNode {
        const conf = this.values.get(this.props.sensitivity);

        if (!conf) return <span>-</span>;

        const { label, className } = conf;
        const classNames = [styles.label, className, this.props.disabled ? styles.disabled : undefined];
        return <span className={classNames.join(" ")}>{label}</span>;
    }
}

export default withTranslation()(SensitivityLabel);
