import React from "react";
import { CardGrid } from "./Container/CardGrid";
import { DailyEventCountsCard } from "./Cards/DailyEventCountsCard";
import { CardGroup } from "./Container/CardGroup";
import { Id } from "../../api_v2/types/custom";
import { useNow } from "../../hooks/useNow";
import { useFormat } from "../../hooks/useFormat";
import { DailyCareAcuityCard } from "./Cards/DailyCareAcuityCard";
import { useTranslation } from "react-i18next";
import { CardsTitlesAndTooltips } from "./CardsTitlesAndTooltips";
import { DailyStatusChangeCountsCard } from "./Cards/DailyStatusChangeCountsCard";

export interface DailyDigestProps {
    selectedDeviceId: Id;
    roomId?: Id;
}

export const DailyDigest = ({ selectedDeviceId, roomId }: DailyDigestProps) => {
    const { t } = useTranslation();
    const now = useNow("day");
    const format = useFormat();

    return (
        <CardGroup title={t("titleDailyDigestSection", "Daily Digest")} info={format(now, "PPP")}>
            <CardGrid>
                <DailyEventCountsCard
                    deviceId={selectedDeviceId}
                    type="fall"
                    title={
                        <CardsTitlesAndTooltips
                            title={t("titleFallCountCard", "Falls today")}
                            tooltip={t("tooltipFallsTodayCard", "Total number of falls on this day since 00:00h.")}
                        />
                    }
                    description={t("descriptionFallCountCard", "Total of detected falls")}
                    day={now}
                    roomId={roomId}
                />
                <DailyEventCountsCard
                    deviceId={selectedDeviceId}
                    type="emergency"
                    title={
                        <CardsTitlesAndTooltips
                            title={t("titleEmergencyPreventionCard", "Emergency prevention events today")}
                            tooltip={t(
                                "tooltipEmergencyPreventionCard",
                                "Emergency prevention consists of fall prevention, absence detection and bed rails."
                            )}
                            emergencyPrevention={true}
                        />
                    }
                    description={t("descriptionEmergencyPreventionCard", "Detailed breakdown")}
                    day={now}
                    roomId={roomId}
                />
                <DailyStatusChangeCountsCard
                    deviceId={selectedDeviceId}
                    type="paused"
                    title={
                        <CardsTitlesAndTooltips
                            title={t("titlePauseSwitchCountCard", "Presence button events")}
                            tooltip={t(
                                "tooltipPauseSwitchCountCard",
                                "Button at the room entrance pressed by visitors and staff to deactivate the sensor."
                            )}
                        />
                    }
                    description={t("descriptionPauseSwitchCountCard", "Number of times the sensor has been paused")}
                    day={now}
                />
                <DailyCareAcuityCard deviceId={selectedDeviceId} />
            </CardGrid>
        </CardGroup>
    );
};
