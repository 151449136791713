import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "../../../UI/Button/Button";
import { useSetupContext } from "../SetupContext";
import { Navigate } from "react-router-dom";
import { useSendDeviceCommand } from "../../../../api_v2/hooks/useSendDeviceCommand";
import TestAlarmImage from "../../../../images/setup/Testalarm.png";
import styles from "./TestAlarmStep.module.css";
import { sensorDisplayName } from "../../../../utils/sensorDisplayName";

export const TestAlarmStep = () => {
    const { t } = useTranslation();
    const { sensor, room } = useSetupContext();

    const [testSent, setTestSent] = useState(false);
    const [testReceived, setTestReceived] = useState<boolean | null>(null);

    if (!sensor) {
        return <Navigate to="./select-sensor" replace />;
    }

    const deviceCommand = useSendDeviceCommand();

    const send = async () => {
        deviceCommand.mutate({ deviceId: sensor.id, type: "trigger-event", args: {} });
        setTestSent(true);
        setTestReceived(null);
    };

    const received = () => {
        setTestReceived(true);
        setTestSent(true);
    };

    const notReceived = () => {
        setTestReceived(false);
        setTestSent(false);
    };

    return (
        <div>
            <p>
                <Trans
                    i18nKey="stepTestAlarmIntro"
                    values={{
                        sensor: sensorDisplayName(sensor),
                        room: room?.name || "",
                    }}
                >
                    <strong>Send test alert:</strong>
                    <br />
                    Trigger a test alarm to check if you receive an alarm of sensor {{ sensor }} in room {{ room }}.
                    Plug the nurse call receiver into your nurse call system in room {{ room }} and click on test alarm.
                </Trans>
            </p>
            <img src={TestAlarmImage} width={800} height={400} className={styles.image} />
            <p>
                {t(
                    "stepTestAlarmText",
                    "If you want to pair your nurse call receiver with your sensor, put your nurse call receiver into pairing mode. Click on test alarm to send out a pairing command."
                )}
            </p>

            {testReceived === false ? (
                <div className={styles.info}>
                    {t(
                        "stepTestAlarmNotReceivedText",
                        "Check whether your nurse call receiver is plugged into the nurse call system. Then try triggering another test alarm."
                    )}
                </div>
            ) : null}

            {testReceived === true ? (
                <p>{t("stepTestAlarmContinue", "Continue with the next step.")}</p>
            ) : !testSent ? (
                <div className={styles.buttonContainer}>
                    <Button primary={true} onClick={send}>
                        {t("stepTestAlarmSend", "Send test alarm")}
                    </Button>
                </div>
            ) : (
                <div className={styles.buttonContainerAnswer}>
                    <p>{t("stepTestAlarmReceivedQuestion", "Did you receive a test alarm?")}</p>
                    <Button primary={true} onClick={received}>
                        {t("stepTestAlarmReceivedYes", "Yes")}
                    </Button>
                    <Button primary={false} onClick={notReceived}>
                        {t("stepTestAlarmReceivedNo", "No")}
                    </Button>
                </div>
            )}
        </div>
    );
};
