import styles from "./NavBarDropdown.module.css";
import { PropsWithChildren } from "react";

export interface DropdownItemProps {
    title?: string;
    icon?: string;
    bigIcon?: boolean;
    onClick?: () => void;
    disabled?: boolean;
    id?: string;
}

export const DropdownItem = (props: PropsWithChildren<DropdownItemProps>) => {
    const iconClasses = [styles.icon];

    if (props.bigIcon) iconClasses.push(styles.big);

    return (
        <li className={styles.item}>
            <button className={styles.button} onClick={props.onClick} disabled={props.disabled} id={props.id}>
                {props.icon ? <img className={iconClasses.join(" ")} src={props.icon} /> : null}
                {props.title}
                {props.children}
            </button>
        </li>
    );
};
