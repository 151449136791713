import { ActivityHeatMap, useGetHeatMapImage } from "../../../../api_v2/hooks/useHeatMap";
import { heatmaps } from "../../../../config/images";
import { useTranslation } from "react-i18next";
import styles from "./HeatMap.module.scss";

interface HeatMapImageProps {
    heatMap: ActivityHeatMap;
}

export const HeatMapImage = ({ heatMap }: HeatMapImageProps) => {
    const { t } = useTranslation();
    const imageQuery = useGetHeatMapImage(heatMap.id);

    const { w, h } = heatmaps.resolution;
    const fallbackText = imageQuery.isLoading
        ? t("labelEventImageLoading", "Loading")
        : t("labelEventImageNone", "No visualization");
    const fallbackSrc = `https://via.placeholder.com/${w}x${h}.png?text=${encodeURIComponent(fallbackText)}`;
    const src = imageQuery.isSuccess ? imageQuery.data : fallbackSrc;

    return <img src={src} width={w} height={h} className={styles.image} />;
};
