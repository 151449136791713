import React from "react";
import { useSetupContext } from "../SetupContext";
import { useTranslation } from "react-i18next";
import { sensorDisplayName } from "../../../../utils/sensorDisplayName";

export const Subtitle = () => {
    const { t } = useTranslation();
    const { sensor, room, dockingStation, type, originalRoom, originalDockingStation } = useSetupContext();

    if (!type) {
        return null;
    }

    if (!sensor || !room || !dockingStation) {
        return <small>{t("subtitleSelectSensorAndRoom", "Select sensor and room")}</small>;
    }

    if (type === "up") {
        return (
            <small>
                {t("subtitleMountSensor", "set up sensor {{sensor}} in {{dockingStation}} ({{room}})", {
                    sensor: sensorDisplayName(sensor),
                    dockingStation: dockingStation.name,
                    room: room.name,
                })}
            </small>
        );
    }

    return (
        <small>
            {t("subtitleMoveSensor", "move sensor {{sensor}} {{source}} to {{dockingStation}} ({{room}}) ", {
                sensor: sensorDisplayName(sensor),
                dockingStation: dockingStation.name,
                room: room.name,
                source:
                    originalDockingStation && originalRoom
                        ? t("subtitleMoveSensorSourcePartial", "from {{dockingStation}} ({{room}})", {
                              dockingStation: originalDockingStation.name,
                              room: originalRoom.name,
                          })
                        : "",
            })}
        </small>
    );
};
