import React from "react";
import { useTranslation } from "react-i18next";
import { useLocationContext } from "../../context/LocationContextProvider";
import {
    Events,
    Event,
    useListEventsForArea,
    useListEventsForFacility,
    useListEventsForRoom,
    EventType,
} from "../../api_v2/hooks/useEvents";
import { AreaRoom, Id } from "../../api_v2/types/custom";
import { UseInfiniteQueryResult } from "react-query";
import { Loading } from "../../components/UI/Loading";
import { EventsFilter } from "./EventsFilter";
import { EventsList } from "./List/EventsList";
import { EventModalNavigation } from "../../components/Events/EventModal/EventModalNavigation";
import { LoadMoreButton } from "../../components/UI/LoadMoreButton";
import { useCurrentFacility, useCurrentRooms } from "../../hooks/useCurrentFacilityStructure";
import { View } from "../../components/View";
import { ButtonGroup } from "../../components/UI/Button/ButtonGroup";
import { Button } from "../../components/UI/Button/Button";
import { EventsGrid } from "./Grid/EventsGrid";
import { useUserSpecificLocalStorage } from "../../hooks/useUserSpecificLocalStorage";
import styles from "./EventsView.module.css";
import { useLocaleAwareSort } from "../../utils/useLocaleAwareCompare";
import { useNavigate } from "react-router-dom";
import { useQueryParameter, useQueryParameters } from "../../utils/useQueryParameters";
import { route } from "../../utils/RouteHelper";

export const EventsView = () => {
    const { t } = useTranslation();
    const sort = useLocaleAwareSort();

    const locationContext = useLocationContext();
    const facility = useCurrentFacility();
    const rooms = useCurrentRooms();

    const [roomIdFilter, setRoomIdFilter] = useQueryParameter<Id>("roomId");
    const [eventTypeFilter, setEventTypeFilter] = useQueryParameter<EventType>("eventType");
    const [startTimeFilter, setStartTimeFilter] = useQueryParameter("startTime");

    const [viewType, setViewType] = useUserSpecificLocalStorage<"list" | "grid">("events-view-view-type", "list");

    if (!facility || !rooms) return <Loading />;

    const sortedRooms = rooms.sort(sort("name"));

    const startTime = startTimeFilter ? new Date(startTimeFilter) : null;

    return (
        <View
            title={t("labelAlarms", "Alarms")}
            subtitle={facility.name}
            className={styles.container}
            headerChildren={
                <ButtonGroup>
                    <Button
                        id="EventsView_ListView_Button"
                        icon="list"
                        active={viewType === "list"}
                        onClick={() => setViewType("list")}
                    />
                    <Button
                        id="EventsView_GridView_Button"
                        icon="grid"
                        active={viewType === "grid"}
                        onClick={() => setViewType("grid")}
                    />
                </ButtonGroup>
            }
        >
            <EventsFilter
                rooms={sortedRooms}
                selectedRoomId={roomIdFilter}
                onSelectedRoomIdChange={setRoomIdFilter}
                selectedEventType={eventTypeFilter}
                onSelectedEventTypeChange={setEventTypeFilter}
                selectedStartTime={startTime}
                onSelectedStartTimeChange={(date) => setStartTimeFilter(date?.toISOString() ?? null)}
            />

            {roomIdFilter ? (
                <RoomEventsView
                    roomId={roomIdFilter}
                    rooms={rooms}
                    eventTypeFilter={eventTypeFilter}
                    startTimeFilter={startTime}
                    viewType={viewType}
                />
            ) : locationContext.areaId ? (
                <AreaEventsView
                    areaId={locationContext.areaId}
                    rooms={rooms}
                    eventTypeFilter={eventTypeFilter}
                    startTimeFilter={startTime}
                    viewType={viewType}
                />
            ) : (
                <FacilityEventsView
                    facilityId={locationContext.facilityId}
                    rooms={rooms}
                    eventTypeFilter={eventTypeFilter}
                    startTimeFilter={startTime}
                    viewType={viewType}
                />
            )}
        </View>
    );
};

interface CommonEventsViewProps {
    rooms: Array<AreaRoom>;
    eventTypeFilter: EventType | null;
    startTimeFilter: Date | null;
    viewType: "list" | "grid";
}

const FacilityEventsView = ({ facilityId, ...props }: { facilityId: Id } & CommonEventsViewProps) => {
    const eventsQuery = useListEventsForFacility(facilityId, {
        type: props.eventTypeFilter,
        before: props.startTimeFilter,
    });
    return <UnifiedEventsView eventsQuery={eventsQuery} {...props} />;
};

const AreaEventsView = ({ areaId, ...props }: { areaId: Id } & CommonEventsViewProps) => {
    const eventsQuery = useListEventsForArea(areaId, {
        type: props.eventTypeFilter,
        before: props.startTimeFilter,
    });
    return <UnifiedEventsView eventsQuery={eventsQuery} {...props} />;
};

const RoomEventsView = ({ roomId, ...props }: { roomId: Id } & CommonEventsViewProps) => {
    const eventsQuery = useListEventsForRoom(roomId, {
        type: props.eventTypeFilter,
        before: props.startTimeFilter,
    });
    return <UnifiedEventsView eventsQuery={eventsQuery} {...props} />;
};

const UnifiedEventsView = ({
    eventsQuery,
    rooms,
    viewType,
}: {
    eventsQuery: UseInfiniteQueryResult<Events>;
} & CommonEventsViewProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const queryParams = useQueryParameters();

    const events: Array<Event> = (eventsQuery.data?.pages ?? []).flat();

    const selectedEventId = queryParams.get("event");
    const setSelectedEventId = (eventId: Id | null, replace = false) => {
        navigate(route({ to: "events", eventId: eventId ?? undefined }, true), { replace });
    };

    return (
        <>
            {viewType === "list" ? (
                <EventsList
                    events={events}
                    rooms={rooms}
                    isLoading={eventsQuery.isLoading}
                    onEventSelect={setSelectedEventId}
                />
            ) : (
                <EventsGrid
                    events={events}
                    rooms={rooms}
                    isLoading={eventsQuery.isLoading}
                    onEventSelect={setSelectedEventId}
                />
            )}

            {eventsQuery.hasNextPage ? (
                <div className={styles.loadMoreContainer}>
                    <LoadMoreButton
                        loading={eventsQuery.isLoading}
                        onClick={() => {
                            eventsQuery.fetchNextPage();
                        }}
                    />
                </div>
            ) : (
                <p className={styles.noMoreEventsInfo}>{t("infoNoMoreEvents", "No further events available")}</p>
            )}

            <EventModalNavigation
                rooms={rooms}
                events={events}
                selectedEventId={selectedEventId}
                onEventSelect={setSelectedEventId}
            />
        </>
    );
};
