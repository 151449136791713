import React, { ForwardedRef, forwardRef, InputHTMLAttributes, useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { c } from "../../utils/ClassesHelper";
import { useTranslation } from "react-i18next";
import styles from "./Input.module.css";

type InputProps = InputHTMLAttributes<HTMLInputElement> & { invalid?: boolean };

const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
    { invalid, className, ...props }: InputProps,
    ref: ForwardedRef<HTMLInputElement>
) {
    return (
        <input {...props} className={c([styles.input, className], { [styles.invalid]: invalid === true })} ref={ref} />
    );
});

const PasswordInput = forwardRef<HTMLInputElement, InputProps>(function PasswordInput(
    props: InputProps,
    ref: ForwardedRef<HTMLInputElement>
) {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className={styles.container}>
            <Input
                className={c([styles.input, styles.password])}
                {...props}
                ref={ref}
                type={showPassword ? "text" : "password"}
                spellCheck={false}
            />
            <button
                type="button"
                title={showPassword ? t("titleHidePassword", "Hide password") : t("titleShowPassword", "Show password")}
                className={styles.showPasswordToggle}
                onClick={() => setShowPassword((p) => !p)}
                aria-controls={props.id}
                aria-expanded={showPassword ? "true" : "false"}
            >
                {showPassword ? <BiHide /> : <BiShow />}
            </button>
        </div>
    );
});

export { Input, PasswordInput };
