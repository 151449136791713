import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../UI/Loading";
import { useSetupContext } from "../SetupContext";
import { Navigate } from "react-router-dom";
import { sensorDisplayName } from "../../../../utils/sensorDisplayName";

export const FinishStep = () => {
    const { t } = useTranslation();
    const { sensor, room, dockingStation, isSaving, hasFailedSaving, save } = useSetupContext();

    useEffect(() => {
        save();
    }, []);

    if (!sensor) {
        return <Navigate to="./select-sensor" replace />;
    }

    if (isSaving) return <Loading text={t("msgSensorSetupDoneSaving", "Saving...")} />;

    if (hasFailedSaving) return <p>{t("msgSensorSetupFailed", "The sensor set up has failed.")}</p>;

    if (sensor && room && dockingStation)
        return (
            <p>
                {t(
                    "msgSensorSetupDone",
                    "Your cogvisAI sensor {{sensor}} is now setup in {{dockingStation}} ({{room}}).",
                    {
                        sensor: sensorDisplayName(sensor),
                        dockingStation: dockingStation.name,
                        room: room.name,
                    }
                )}
            </p>
        );

    return <p>{t("msgSensorSetupDoneGeneric", "The sensor is set up completed.")}</p>;
};
