import { PropsWithChildren } from "react";
import { c } from "../../../utils/ClassesHelper";
import styles from "./CardGrid.module.scss";

export interface CardGridProps extends PropsWithChildren {
    dense?: boolean;
}

export const CardGrid = ({ children, dense }: CardGridProps) => {
    return <div className={c([styles.container], { [styles.dense]: dense === true })}>{children}</div>;
};
