import React, { ReactElement } from "react";
import { Card, CardProps } from "../Card";
import { c } from "../../../../utils/ClassesHelper";
import styles from "./StatCard.module.scss";

import { BiTrendingDown, BiTrendingUp } from "react-icons/bi";
import { Tooltip } from "../../../Tooltip/Tooltip";
import { useTranslation } from "react-i18next";

export interface Trend {
    value: string;
    type: "positive" | "negative" | "neutral";
    direction?: "up" | "down";
}

export interface StatCardProps extends CardProps {
    headline: string | ReactElement;
    description?: string;
    value: string | number;
    trend?: Trend;
    icon?: { content: ReactElement | string; bg: string; fg: string };
    footnote?: ReactElement | string;
}

export const StatCard = ({
    headline,
    description,
    value,
    trend,
    icon,
    footnote,
    className,
    children,
    ...props
}: StatCardProps) => {
    const { t } = useTranslation();

    return (
        <Card {...props} className={c([styles.card, className])}>
            <div className={styles.content}>
                <div className={styles.value}>{value}</div>
                <h3 className={styles.title}>{headline}</h3>
                {icon ? (
                    <div className={styles.icon} style={{ color: icon.fg, background: icon.bg }}>
                        {icon.content}
                    </div>
                ) : null}
                {description ? <p className={styles.description}>{description}</p> : null}
                {/*Children must be wrapped in a fragment here, otherwise there is a typescript issue with the types the i18n lib overlays globally*/}
                {children ? <>{children}</> : null}
            </div>
            <div className={styles.bottom}>
                {trend ? (
                    <div className={c([styles.trend, styles[trend.type]])}>
                        <Tooltip
                            panel={<div className={styles.tooltipPanel}>{t("statCardTrendTooltip", "Compared to the previous day")}</div>}
                            className={styles.trigger}
                        >
                            <span>{trend.value}</span>

                            {trend.direction === "up" || (!trend.direction && trend.type === "positive") ? (
                                <BiTrendingUp />
                            ) : trend.direction === "down" || (!trend.direction && trend.type === "negative") ? (
                                <BiTrendingDown />
                            ) : null}
                        </Tooltip>
                    </div>
                ) : null}

                {footnote ? <div className={styles.footnote}>{footnote}</div> : null}
            </div>
        </Card>
    );
};
