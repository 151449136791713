import styles from "./ActivityMonitoringSettings.module.css";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { InputGroup } from "../../../UI/InputGroup";
import { ToggleSwitch } from "../../../UI/ToggleSwitch";
import { BedLocationsSettingsProps } from "../BedMonitoringTab/BedLocationSettings/BedLocationSettings";
import { Label } from "../../../UI/Label";

export const GlobalBedActivitySettings = (props: BedLocationsSettingsProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.commonContainer}>
            <strong className={styles.title}>{t("labelAllBedsForBedActivity", "All beds")}</strong>

            <InputGroup>
                <Label text="">
                    <ToggleSwitch
                        checkedLabel={t("labelActivated", "Activated")}
                        uncheckedLabel={t("labelDeactivated", "Deactivated")}
                        state={props.settings.bedActivityEnable ?? false}
                        onChange={(s) => {
                            const newSettings = _.cloneDeep(props.settings);
                            newSettings.bedActivityEnable = s;
                            props.onSettingsChange(newSettings);
                        }}
                        disabled={props.readonly}
                        readonly={props.readonly}
                        id="BedsForBedActivityEnable"
                    />
                </Label>
            </InputGroup>
        </div>
    );
};
