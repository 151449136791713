import { c } from "../../../../utils/ClassesHelper";
import { AnalysisModuleStatus, AnalysisModuleType } from "../../../../api_v2/hooks/useDevices";
import { Tooltip } from "../../../../components/Tooltip/Tooltip";
import { AnalysisModuleStatusCellTooltipPanel } from "./AnalysisModuleStatusCellTooltipPanel";
import { DeviceWithStatus } from "../../helper/Nodes";
import fallDetectionIcon from "../../../../images/icons/Sturzerkennung.svg";
import getupDetectionIcon from "../../../../images/icons/type/Aufsetzen.svg";
import absenceDetectionIcon from "../../../../images/icons/type/Abwesenheit.svg";
import bedRailIcon from "../../../../images/icons/Bettbalken.svg";
import bedActivityIcon from "../../../../images/icons/type/bed_activity.svg";
import roomActivityIcon from "../../../../images/icons/type/room_activity.svg";
import styles from "./AnalysisModuleStatusCell.module.css";
import { useHasFeatureFlags } from "../../../../hooks/useHasFeatureFlags";

export interface AnalysisModuleStatusCellProps {
    statuses: AnalysisModuleStatus[];
    device: DeviceWithStatus;
}

const icons: Record<AnalysisModuleType, string> = {
    "fall-detection": fallDetectionIcon,
    "getup-detection": getupDetectionIcon,
    "absence-detection": absenceDetectionIcon,
    "bed-rail": bedRailIcon,
    "bed-activity": bedActivityIcon,
    "room-activity": roomActivityIcon,
};

const useIconOrder = () => {
    const hasFeatureFlags = useHasFeatureFlags();
    const iconOrder: Array<AnalysisModuleType> = ["fall-detection", "absence-detection", "getup-detection", "bed-rail"];

    if (hasFeatureFlags(["enable-bed-activity"])) {
        iconOrder.push("bed-activity");
    }

    if (hasFeatureFlags(["enable-room-activity"])) {
        iconOrder.push("room-activity");
    }

    return iconOrder;
};

export const AnalysisModuleStatusCell = ({ statuses, device }: AnalysisModuleStatusCellProps) => {
    const iconOrder = useIconOrder();

    if (device.deviceStatus?.status === "shut-down") {
        return <td></td>;
    }

    return (
        <td>
            {statuses
                .filter((s) => iconOrder.includes(s.module))
                .filter((s) => s.status !== "disabled")
                .sort((a, b) => iconOrder.indexOf(a.module) - iconOrder.indexOf(b.module))
                .map((status) => (
                    <Tooltip
                        key={status.module}
                        panel={<AnalysisModuleStatusCellTooltipPanel status={status} />}
                        config={{
                            delayShow: 500,
                            // Prevent tooltip if device is offline
                            visible: device.deviceStatus?.status === "offline" ? false : undefined,
                        }}
                        popperOptions={{}}
                    >
                        <img
                            key={status.module}
                            src={icons[status.module]}
                            className={c([styles.icon], {
                                [styles.filterOrange]: status.status === "error",
                                [styles.hidden]: device.deviceStatus?.status === "paused",
                                [styles.offline]: device.deviceStatus?.status === "offline",
                                [styles.inactive]:
                                    device.deviceStatus?.status !== "offline" && status.status === "inactive",
                            })}
                        />
                    </Tooltip>
                ))}
        </td>
    );
};
