import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSetupContext } from "../SetupContext";

import sensorImage from "../../../../images/setup/Sensor.png";
import styles from "./SensorAttachAndAwaitStatusStep.module.css";

const countdownDuration = 5 * 60 * 1000;

const RestartAndAwaitSensorStatusStep = () => {
    const { t } = useTranslation();
    const { isOnline, isBackOnline, sensorStatusPollingStartedAt, setIsValid, restart } = useSetupContext();

    const [now, setNow] = useState<Date>(new Date());

    // handle countdown
    useEffect(() => {
        const handle = window.setInterval(() => {
            setNow(new Date());
        }, 1000);
        return () => window.clearInterval(handle);
    }, [now]);

    // handle restart
    useEffect(() => {
        if (isOnline) {
            restart();
        }
    }, [isOnline, restart]);

    // update whether next button should be enabled or not
    useEffect(() => {
        setIsValid(isBackOnline);
    }, [isBackOnline]);

    // countdown
    let countdown = "5:00";
    let countdownEnded = false;

    if (sensorStatusPollingStartedAt) {
        const timeElapsed = now.getTime() - sensorStatusPollingStartedAt.getTime();
        const timeLeft = Math.max(0, countdownDuration - timeElapsed);

        const minutes = Math.floor(timeLeft / 60000);
        const seconds = Math.floor((timeLeft % 60000) / 1000);
        countdown = minutes + ":" + (seconds < 10 ? "0" : "") + seconds.toFixed(0);
        countdownEnded = timeLeft === 0;
    }

    return (
        <div>
            <p>{t("stepSensorStatusText", "Wait for the sensor to establish a connection to the platform.")}</p>
            <img src={sensorImage} width={800} height={400} className={styles.image} />
            <div className={styles.statusContainer}>
                {!countdownEnded ? (
                    <>
                        <strong className={styles.label}>{t("stepSensorStatusLabel", "Sensor status:")}</strong>
                        <div className={styles.status}>
                            <div
                                className={[styles.pill, isBackOnline ? styles.statusOk : styles.statusOffline].join(
                                    " "
                                )}
                            >
                                {isBackOnline
                                    ? t("stepSensorStatusConnected", "Connected")
                                    : t("stepSensorStatusWaitingForConnection", "Waiting for connection...")}
                            </div>
                        </div>
                    </>
                ) : (
                    <div className={styles.fail}>
                        <Trans i18nKey="stepSensorStatusConnectionFailed">
                            If you have trouble connecting the sensor, please contact our
                            <a href={`mailto: ${t("supportEmail", "support@cogvis.ai ")}`}></a> at:
                            <a href={`tel: ${t("supportPhone", " +43 1 417 0 714")}`}></a>.
                        </Trans>
                    </div>
                )}

                {!isBackOnline ? <span className={styles.timer}>{countdown}</span> : null}
            </div>
        </div>
    );
};

export default RestartAndAwaitSensorStatusStep;
