import { CognitoUser, IAuthenticationDetailsData } from "amazon-cognito-identity-js";
import { userPool } from "./userPool";

export const getCognitoUserFromAuthDetails = (authenticationData: IAuthenticationDetailsData) => {
    const userData = {
        Username: authenticationData.Username,
        Pool: userPool,
    };

    return new CognitoUser(userData);
};
