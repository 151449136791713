import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../UI/Button/Button";
import styles from "./DeviceDetailsModal.module.css";
import { ModalFooter } from "../Modal/Modal";

interface DeviceDetailsModalFooterProps {
    isDirty: boolean;
    isSaving: boolean;
    onClose: () => void;
    onSave?: () => void;
    sceneLabelEditModeActive: boolean;
    setSceneLabelEditModeActive: (state: boolean) => void;
}

export const DeviceDetailsModalFooter = ({
    onClose,
    onSave,
    isDirty,
    isSaving,
    sceneLabelEditModeActive,
    setSceneLabelEditModeActive,
}: DeviceDetailsModalFooterProps) => {
    const { t } = useTranslation();

    return (
        <ModalFooter className={styles.footer}>
            <div className={styles.actionItem}>
                {sceneLabelEditModeActive ? (
                    <Button
                        id="DeviceDetailsModal_BackToMonitoringButton"
                        onClick={() => setSceneLabelEditModeActive(false)}
                        disabled={isSaving}
                    >
                        {t("actionBackToMonitoring", "Back to bed management")}
                    </Button>
                ) : (
                    <Button id="DeviceDetailsModal_CloseOrCancelButton" onClick={onClose} disabled={isSaving}>
                        {isDirty ? t("labelSensorDetailsCancel", "Cancel") : t("labelSensorDetailsClose", "Close")}
                    </Button>
                )}
            </div>
            <div>
                {onSave && !sceneLabelEditModeActive ? (
                    <Button
                        id="DeviceDetailsModal_SaveButton"
                        onClick={onSave}
                        disabled={isSaving || !isDirty}
                        primary={true}
                    >
                        {isSaving ? t("labelSensorDetailsSaving", "Saving...") : t("labelSensorDetailsSave", "Save")}
                    </Button>
                ) : null}
            </div>
        </ModalFooter>
    );
};
