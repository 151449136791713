import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "../../UI/Button/Button";
import { useSetupContext } from "./SetupContext";

import styles from "./ScenarioSelector.module.css";

export const ScenarioSelector = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const context = useSetupContext();

    useEffect(() => {
        // Reset the context on load
        context.reset();
    }, []);

    return (
        <div className={styles.wrapper}>
            <p>{t("labelScenarioSelect", "Welcome to sensor setup wizard.")}</p>
            <div className={styles.container}>
                <div>
                    <Button
                        className={styles.btn}
                        primary={true}
                        onClick={() => {
                            context.setType("up");
                            navigate("./up");
                        }}
                    >
                        {t("labelScenarioMount", "Set up sensor")}
                    </Button>
                    <div className={styles.text}>{t("textScenarioMount", "I want to set up a new sensor.")}</div>
                </div>

                <div>
                    <Button
                        className={styles.btn}
                        primary={true}
                        onClick={() => {
                            context.setType("move");
                            navigate("./move");
                        }}
                    >
                        {t("labelScenarioMove", "Move sensor")}
                    </Button>
                    <div className={styles.text}>{t("textScenarioMove", "I want to move a sensor (from room A to room B).")}</div>
                </div>
            </div>
        </div>
    );
};
