import React, { MutableRefObject, PropsWithChildren, useContext, useRef } from "react";
import { validate as uuidValidate } from "uuid";
import { config } from "../config";
import { Loading } from "../components/UI/Loading";
import { useQuery } from "react-query";
import { DemoDataOverlay } from "../components/Debug/DemoDataOverlay";

export interface DemoDataContext {
    enabled: boolean;
    enableRecording: boolean;
    data: Record<string, unknown>;
    record: MutableRefObject<Record<string, unknown>>;
}

const DemoDataCtx = React.createContext<DemoDataContext | null>(null);

export const useDemoDataContext = (): DemoDataContext => {
    const ctx = useContext(DemoDataCtx);
    if (ctx === null) {
        throw new Error("Demo data context not set!");
    }
    return ctx;
};

export const DemoDataContextProvider = (props: PropsWithChildren<unknown>) => {
    const record = useRef<Record<string, unknown>>({
        useNow: new Date().toISOString(),
    });

    const enabled = config.demoDataModus !== false && uuidValidate(config.demoDataModus);
    const enableRecording = !enabled && config.demoDataRecord;

    if (enabled && config.demoDataRecord) {
        // eslint-disable-next-line no-console
        console.warn("Can not enable demo data modus and recording at the same time. Recording has been disabled!");
    }

    // This intentionally does not use axios/the react-query-axios adapter package, to not be influenced by the middleware
    const demoDataQuery = useQuery(
        ["demo-data"],
        async () => {
            const response = await fetch(`/demo-data/${config.demoDataModus}.json`);
            return (await response.json()) as Record<string, unknown>;
        },
        {
            enabled,
            staleTime: Infinity,
            refetchOnWindowFocus: false,
            retry: false,
        }
    );

    const isLoaded = !enabled || demoDataQuery.isSuccess;

    return (
        <DemoDataCtx.Provider
            value={{
                enabled,
                enableRecording,
                data: demoDataQuery.data ?? { useNow: record.current.useNow },
                record,
            }}
        >
            {isLoaded ? (
                <>
                    {props.children}
                    {enabled || enableRecording ? <DemoDataOverlay mode={enabled ? "playback" : "recording"} /> : null}
                </>
            ) : (
                <Loading
                    text={
                        demoDataQuery.isLoading
                            ? "Loading demo data"
                            : `Unable to load demo data with uuid ${config.demoDataModus}`
                    }
                />
            )}
        </DemoDataCtx.Provider>
    );
};
