import { DeviceSettingsSchema } from "../../api_v2/hooks/useDeviceSettings";
import _ from "lodash";
import { Settings } from "../../api_v2/types/custom";

export type SettingsGroup =
    | "fallDetection"
    | "fallDetectionAnalysis"
    | "getupDetection"
    | "absenceDetection"
    | "bedRails"
    | "restApi"
    | "pauseSwitchButton"
    | "bedActivity"
    | "fallLight";

export const canAccessSettings = (group: SettingsGroup, schema: DeviceSettingsSchema): boolean => {
    switch (group) {
        case "fallDetection":
            return "fallDetectionEnable" in schema.properties;
        case "fallDetectionAnalysis":
            return "fallDetectionAnalysisEnable" in schema.properties;
        case "getupDetection":
            return "getupDetectionEnable" in schema.properties;
        case "absenceDetection":
            return "absenceDetectionEnable" in schema.properties;
        case "bedRails":
            return "bedRailsEnable" in schema.properties;
        case "restApi":
            return "restApiUrl" in schema.properties;
        case "pauseSwitchButton":
            return "pauseSwitchButtonTimeout" in schema.properties;
        case "bedActivity":
            return "bedActivityEnable" in schema.properties;
        case "fallLight":
            return "fallLightEnable" in schema.properties;
    }

    return true;
};

export type DurationType = "AbsenceDetectionLocationsDuration" | "PauseSwitchButtonTimeout";

export const getDurationRangeAndDefault = (
    setting: DurationType,
    schema: DeviceSettingsSchema
): { min: number; max: number; default: number } => {
    const path: Record<DurationType, string> = {
        AbsenceDetectionLocationsDuration: "properties.absenceDetectionLocations.items.properties.duration",
        PauseSwitchButtonTimeout: "properties.pauseSwitchButtonTimeout",
    };

    // The type assertion is necessary because schema is not fully typed, so _.get does not know the return type
    const obj: { minimum?: number; maximum?: number; default?: number } | null = _.get(schema, path[setting], null) as {
        minimum?: number;
        maximum?: number;
        default?: number;
    } | null;

    return {
        min: obj?.minimum ?? 0,
        max: obj?.maximum ?? 120,
        default: obj?.default ?? 15,
    };
};

/**
 * For boolean values only!
 * Checks and returns the value for the given key.
 *
 * Checks in the following order:
 * - settings,
 * - the default value according to the schema
 * - or the fallback value
 *
 * First value found will be returned.
 *
 * @param key
 * @param settings
 * @param schema
 * @param fallback
 */
export const getValueForFlag = (
    key: keyof Settings,
    settings: Settings,
    schema: DeviceSettingsSchema,
    fallback = false
): boolean => {
    // The device settings schema defines properties as Record<string, never>
    // never can not have any values. In the real world, the settings do have values though
    // @ts-ignore
    return settings[key] ?? schema.properties[key]?.default ?? fallback;
};

export const getDefaultValue = <T>(key: string, settingsSchema: DeviceSettingsSchema, fallback: T): T => {
    return _.get(settingsSchema, key, fallback) as T;
};

export const getDefaultValueForItems = <T>(
    key: keyof Settings,
    itemKey: string,
    settingsSchema: DeviceSettingsSchema,
    fallback: T,
    defaultPath = "default"
): T => {
    return getDefaultValue(`properties.${key}.items.properties.${itemKey}.${defaultPath}`, settingsSchema, fallback);
};
