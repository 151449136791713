import { EventType } from "../../../api_v2/hooks/useEvents";
import { useTranslation } from "react-i18next";

export const useGetEventTypeLabel = () => {
    const { t } = useTranslation();

    const labels: Record<EventType, string> = {
        fall: t("alertTypeFall", "Fall"),
        getup: t("alertTypeFallPrevention", "Fall prevention"),
        absence: t("alertTypeAbsence", "Absence"),
        "bed-rail": t("alertTypeLightBarrierCrossed", "Crossed bed rail"),
        "bed-restlessness": t("alertTypeBedRestlessness", "Restlessness"),
    };

    return (type: EventType) => labels[type];
};
