import React, { PropsWithChildren, ReactNode } from "react";
import styles from "./NavBarMenuButton.module.css";
import { NavLink } from "react-router-dom";

type NavBarMenuButtonProps = {
    to: string;
    id?: string;
};

class NavBarMenuButton extends React.Component<PropsWithChildren<NavBarMenuButtonProps>> {
    render(): ReactNode {
        return (
            <NavLink
                id={this.props.id}
                className={(navData) => [styles.button, navData.isActive ? styles.active : undefined].join(" ")}
                to={this.props.to}
            >
                {this.props.children}
            </NavLink>
        );
    }
}

export { NavBarMenuButton };
