import React, { PropsWithChildren, ReactNode } from "react";
import styles from "./View.module.css";
import { c } from "../utils/ClassesHelper";

export interface ViewProps {
    title?: ReactNode;
    subtitle?: string;
    headerChildren?: ReactNode;
    className?: string | undefined;
    alignHeaderChildren?: "flex-end" | "center" | "flex-start";
    maxWidth?: number;
    standalone?: boolean; // Separates the header from the content
}

export const View = (props: PropsWithChildren<ViewProps>) => {
    return (
        <div className={styles.view} style={{ maxWidth: props.maxWidth }}>
            <div
                className={c([styles.header], { [styles.standalone]: props.standalone === true })}
                style={{ alignItems: props.alignHeaderChildren ?? "flex-end" }}
            >
                <div className={styles.main}>
                    {props.subtitle ? <small className={styles.facilityName}>{props.subtitle}</small> : null}
                    {props.title ? <h1 className={styles.buildingName}>{props.title}</h1> : null}
                </div>
                <div className={styles.childs}>{props.headerChildren}</div>
            </div>
            <div className={props.className}>{props.children}</div>
        </div>
    );
};
