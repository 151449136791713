import React from "react";

interface ToastProps {
    title: string;
    description: string;
}

export const Toast = ({ title, description }: ToastProps) => {
    return (
        <>
            <strong>{title}</strong>
            <div>{description}</div>
        </>
    );
};
