import React, { useState } from "react";
import { DebugModal } from "./DebugModal";
import { config } from "../../config";
import styles from "./Debug.module.css";

export const Debug = () => {
    const [isDebugModalOpen, setIsDebugModalOpen] = useState(false);

    return (
        <>
            {config.debug ? (
                <div className={styles.container}>
                    <button className={styles.btn} onClick={() => setIsDebugModalOpen(true)}>
                        open debug modal
                    </button>
                </div>
            ) : null}
            {isDebugModalOpen ? <DebugModal onClose={() => setIsDebugModalOpen(false)} /> : null}
        </>
    );
};
