import { ToggleSwitch } from "../../../../components/UI/ToggleSwitch";
import styles from "../../DashboardView.module.scss";
import { CardSettings } from "../../../../components/UI/Card/CardSettings";
import React, { SetStateAction } from "react";
import { useTranslation } from "react-i18next";

export interface MonthlyActivityCardSettingsProps {
    numBeds: number;
    splitDayNight: boolean;
    setSplitDayNight: (value: SetStateAction<boolean>) => void;
    bedIndex: number | null;
    setBedIndex: (value: SetStateAction<number>) => void;
    enableBedActivity: boolean;
}

export const MonthlyActivityCardSettings = ({
    numBeds,
    splitDayNight,
    setSplitDayNight,
    bedIndex,
    setBedIndex,
    enableBedActivity,
}: MonthlyActivityCardSettingsProps) => {
    const { t } = useTranslation();

    return (
        <CardSettings itemList={true}>
            {() => (
                <>
                    {numBeds > 1 && enableBedActivity ? (
                        <>
                            <label>
                                <ToggleSwitch
                                    state={splitDayNight}
                                    onChange={setSplitDayNight}
                                    checkedLabel={t("labelDayNightToggle")}
                                    uncheckedLabel={t("")}
                                    centered={true}
                                />
                            </label>
                            <label>
                                <ToggleSwitch
                                    state={bedIndex === 1}
                                    onChange={(newState: boolean) => (newState ? setBedIndex(1) : setBedIndex(0))}
                                    checkedLabel={t("labelBedMonitoringBedNumber", { index: 2 })}
                                    uncheckedLabel={t("labelBedMonitoringBedNumber", { index: 1 })}
                                    centered={true}
                                    bothAccent={true}
                                />
                            </label>
                        </>
                    ) : (
                        <label>
                            <div className={styles.toggleSwitchContainer}>
                                <ToggleSwitch
                                    state={splitDayNight}
                                    onChange={setSplitDayNight}
                                    checkedLabel={t("labelDayNightToggle")}
                                    uncheckedLabel={t("labelDayNightToggle")}
                                />
                            </div>
                        </label>
                    )}
                </>
            )}
        </CardSettings>
    );
};
