import React, { PropsWithChildren, ReactElement, ReactNode } from "react";
import { HoverComponent } from "./HoverComponent";
import { Tooltip } from "./Tooltip";

import helpIcon from "../../images/help.svg";
import styles from "./Label.module.css";

type LabelProps = {
    text: ReactNode;
    additionalClassNames?: string;
    block?: boolean;

    helpTitle?: string;
    helpText?: string;
    helpLabel?: string;

    customTooltip?: ReactElement<Tooltip>;

    noLabelEl?: boolean; // replaces <label> with <div>
};

class Label extends React.Component<PropsWithChildren<LabelProps>> {
    render(): ReactNode {
        const labelTextClassNames = [styles.text];

        if (this.hasHelpLabel()) labelTextClassNames.push(styles.withHelpText);

        return this.getLabelEl(
            <>
                <div className={labelTextClassNames.join(" ")}>
                    <span>{this.props.text}</span>
                    {this.createHelp()}
                </div>
                {this.props.children}
            </>
        );
    }

    private getLabelEl(content: ReactNode) {
        const classNames = [styles.label];

        if (this.props.additionalClassNames) classNames.push(this.props.additionalClassNames);

        if (this.props.block) classNames.push(styles.block);

        return this.props.noLabelEl ? (
            <div className={classNames.join(" ")}>{content}</div>
        ) : (
            <label className={classNames.join(" ")}>{content}</label>
        );
    }

    private hasHelpLabel() {
        return this.props.helpLabel && (this.props.helpText || this.props.customTooltip);
    }

    private createHelp() {
        if (!this.props.helpText && !this.props.customTooltip) return null;

        const hasLabelText = this.props.helpLabel;
        const helpElement = hasLabelText ? (
            <span className={styles.helpText}>{this.props.helpLabel}</span>
        ) : (
            <img src={helpIcon} alt="Hilfe" className={styles.icon} />
        );

        return (
            <HoverComponent
                additionalClassNames={styles.help}
                hoverRoot={helpElement}
                hoverElement={this.createHelpTooltip()}
            />
        );
    }

    private createHelpTooltip() {
        if (this.props.customTooltip) return this.props.customTooltip;

        return (
            <Tooltip
                additionalClassNames={styles.tooltip}
                title={this.props.helpTitle}
                description={this.props.helpText}
                left="5px"
            />
        );
    }
}

export { Label };
