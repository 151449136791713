import React, { ImgHTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { images } from "../../config/images";
import { useGetEventVisualization } from "../../api_v2/hooks/useEventVisualizations";
import { Id } from "../../api_v2/types/custom";

export interface EventImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    eventId: Id;
    visualizationId?: number;
    orientation?: "landscape" | "portrait";
}

export const EventImage = ({ eventId, visualizationId, orientation, ...props }: EventImageProps) => {
    const previewImageQuery = useGetEventVisualization(eventId, visualizationId ?? 0);
    const { w, h } = images.eventImages[orientation ?? "landscape"];

    if (!previewImageQuery.isSuccess)
        return <EventImagePlaceholder type={previewImageQuery.isLoading ? "loading" : "error"} {...props} />;

    const src = previewImageQuery.data;

    return <img id={eventId} width={w} height={h} {...props} src={src} />;
};

export interface EventImagePlaceholderProps extends ImgHTMLAttributes<HTMLImageElement> {
    type: "loading" | "error";
    orientation?: "landscape" | "portrait";
}

export const EventImagePlaceholder = ({ type, orientation, ...props }: EventImagePlaceholderProps) => {
    const { t } = useTranslation();
    const { w, h } = images.eventImages[orientation ?? "landscape"];

    const fallbackText =
        type === "loading" ? t("labelEventImageLoading", "Loading") : t("labelEventImageNone", "No visualization");

    const src = `https://via.placeholder.com/${w}x${h}.png?text=${encodeURIComponent(fallbackText)}`;

    return <img width={w} height={h} style={{ background: "#ccc" }} {...props} src={src} />;
};
