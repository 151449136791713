import React from "react";
import { Card } from "../../../components/UI/Card/Card";
import { c } from "../../../utils/ClassesHelper";
import { CardTitle } from "../../../components/UI/Card/CardTitle";
import { CardDescription } from "../../../components/UI/Card/CardDescription";
import { CardContent } from "../../../components/UI/Card/CardContent";
import { ClockFaceChart } from "../Charts/ClockFaceChart/ClockFaceChart";
import { DataLabel } from "../common/DataLabel";
import { CommonCardProps } from "./common/CommonCardProps";
import { Trans, useTranslation } from "react-i18next";
import { useHourlyCareDemands } from "../../../api_v2/hooks/useCareDemand";
import { config } from "../../../config";
import { CardsTitlesAndTooltips } from "../CardsTitlesAndTooltips";
import { isDayShift } from "../../../utils/DayNightShift";
import { useNow } from "../../../hooks/useNow";
import styles from "../DashboardView.module.scss";

export const DailyCareAcuityCard = ({ deviceId }: CommonCardProps) => {
    const { t } = useTranslation();
    const now = useNow("hour");
    const query = useHourlyCareDemands(deviceId);

    const [startHour] = config.nightShift.start.split(":").map((v) => parseInt(v));
    const [endHour] = config.nightShift.end.split(":").map((v) => parseInt(v));

    const isWithinShift = (hour: number) => {
        if (endHour > startHour) {
            return hour >= startHour && hour <= endHour;
        }

        return hour < endHour || hour > startHour;
    };

    const isDay = isDayShift(now);

    const hours = new Array(24)
        .fill(0)
        .map((_, i) => i)
        .filter(isWithinShift);

    const data = hours
        .map((hour) => {
            let today = query.data?.today.find((a) => a.hour === hour)?.demand ?? null;

            // During night shift, we do only want to show hours from start of night shift until now
            if (!isDay) {
                // if now is before midnight, we only want to show hours between nightShiftStart and now
                if (now.getHours() >= startHour && (hour < startHour || hour >= now.getHours())) {
                    today = null;
                }
                // if now is after midnight, show all hours between nightShiftStart and midnight + all hours smaller than now
                else if (now.getHours() <= endHour && hour >= now.getHours() && hour < startHour) {
                    today = null;
                }
            }

            return {
                hour,
                today,
                average: query.data?.average.find((a) => a.hour === hour)?.demand ?? null,
            };
        })
        .reverse();

    return (
        <Card className={c([styles.card, styles.statCard])}>
            <CardTitle>
                <CardsTitlesAndTooltips
                    title={t("titleDailyCareAcuityCard", "Nightly care acuity")}
                    tooltip={t(
                        "tooltipCareAcuityCard",
                        "Shows nursing effort from 7pm to 7am. Blue bars show actual care acuity during the night, grey line acts as forecast."
                    )}
                />
            </CardTitle>
            <CardDescription>
                {isDayShift(now) ? (
                    <Trans
                        i18nKey="descriptionDailyCareAcuityCardLastNight"
                        components={{
                            NightLabel: <DataLabel type="night" />,
                            LastWeekLabel: <DataLabel type="lastWeek" />,
                        }}
                        defaults="Last <NightLabel>night</NightLabel> versus <LastWeekLabel>weekly</LastWeekLabel> average"
                    />
                ) : (
                    <Trans
                        i18nKey="descriptionDailyCareAcuityCard"
                        components={{
                            NightLabel: <DataLabel type="night" />,
                            LastWeekLabel: <DataLabel type="lastWeek" />,
                        }}
                        defaults="This <NightLabel>night</NightLabel> versus <LastWeekLabel>weekly</LastWeekLabel> average"
                    />
                )}
            </CardDescription>
            <CardContent>
                <ClockFaceChart data={data} />
            </CardContent>
        </Card>
    );
};
