import { Id } from "../types/custom";
import { useQuery } from "react-query";
import { useFetch } from "@bitperfect-packages/react-query-axios";
import { components } from "../types/schema";
import { hours } from "../../utils/IntervalHelper";

export type Facility = components["schemas"]["Facility"];
export type FacilityStructure = components["schemas"]["FacilityStructure"];

export const useGetFacility = (facilityId: Id) => {
    return useQuery<FacilityStructure>(
        ["facilities", facilityId],
        useFetch<FacilityStructure>(`/facilities/${facilityId}`),
        {
            staleTime: hours(1),
        }
    );
};
