import { Trans } from "react-i18next";
import { DataLabel } from "../../common/DataLabel";
import { CardDescription } from "../../../../components/UI/Card/CardDescription";
import React, { SetStateAction } from "react";

export interface MonthlyActivityCardDescriptionProps {
    bedLabel: number | undefined;
    splitDayNight: boolean;
    showRoom: boolean;
    setShowRoom: (value: SetStateAction<boolean>) => void;
    showBed: boolean;
    setShowBed: (value: SetStateAction<boolean>) => void;
    includeDay: boolean;
    setIncludeDay: (value: SetStateAction<boolean>) => void;
    includeNight: boolean;
    setIncludeNight: (value: SetStateAction<boolean>) => void;
    enableRoomActivity: boolean;
    enableBedActivity: boolean;
}

export const MonthlyActivityCardDescription = ({
    bedLabel,
    splitDayNight,
    showRoom,
    setShowRoom,
    showBed,
    setShowBed,
    includeDay,
    setIncludeDay,
    includeNight,
    setIncludeNight,
    enableRoomActivity,
    enableBedActivity,
}: MonthlyActivityCardDescriptionProps) => {
    if (enableRoomActivity && !enableBedActivity) {
        return (
            <CardDescription>
                {splitDayNight ? (
                    <Trans
                        /* t("descriptionMonthlyActivityCard_Split_RoomOnly", "Monthly overview of <RoomLabel>room</RoomLabel> activity divided into <Day>day</Day> and <Night>night</Night>") */
                        i18nKey="descriptionMonthlyActivityCard_Split_RoomOnly"
                        default="Monthly overview of <RoomLabel>room</RoomLabel> activity divided into <Day>day</Day> and <Night>night</Night>"
                        components={{
                            RoomLabel: (
                                <DataLabel
                                    type="roomActivityNight"
                                    active={showRoom}
                                    onClick={() => setShowRoom((i) => !i)}
                                />
                            ),
                            Day: (
                                <DataLabel type="day" active={includeDay} onClick={() => setIncludeDay(!includeDay)} />
                            ),
                            Night: (
                                <DataLabel
                                    type="night"
                                    active={includeNight}
                                    onClick={() => setIncludeNight(!includeNight)}
                                />
                            ),
                        }}
                    />
                ) : (
                    <Trans
                        /* t("descriptionMonthlyActivityCard_RoomOnly", "Monthly overview of <RoomLabel>room</RoomLabel> activity") */
                        i18nKey="descriptionMonthlyActivityCard_RoomOnly"
                        default="Monthly overview of <RoomLabel>room</RoomLabel> activity"
                        components={{
                            RoomLabel: (
                                <DataLabel
                                    type="roomActivityNight"
                                    active={showRoom}
                                    onClick={() => setShowRoom((i) => !i)}
                                />
                            ),
                        }}
                    />
                )}
            </CardDescription>
        );
    }

    if (!enableRoomActivity && enableBedActivity) {
        return (
            <CardDescription>
                {splitDayNight ? (
                    <Trans
                        /* t("descriptionMonthlyActivityCard_Split_BedOnly", "Monthly overview of <BedLabel>bed {{bedLabel}}</BedLabel> rest mobility divided into <Day>day</Day> and <Night>night</Night>") */
                        i18nKey="descriptionMonthlyActivityCard_Split_BedOnly"
                        default="Monthly overview of <BedLabel>bed {{bedLabel}}</BedLabel> rest mobility divided into <Day>day</Day> and <Night>night</Night>"
                        components={{
                            BedLabel: (
                                <DataLabel
                                    type="bedActivityNight"
                                    active={showBed}
                                    onClick={() => setShowBed((i) => !i)}
                                />
                            ),
                            Day: (
                                <DataLabel type="day" active={includeDay} onClick={() => setIncludeDay(!includeDay)} />
                            ),
                            Night: (
                                <DataLabel
                                    type="night"
                                    active={includeNight}
                                    onClick={() => setIncludeNight(!includeNight)}
                                />
                            ),
                        }}
                        values={{ bedLabel: bedLabel }}
                    />
                ) : (
                    <Trans
                        /* t("descriptionMonthlyActivityCard_BedOnly", "Monthly overview of <BedLabel>bed {{bedLabel}}</BedLabel> rest mobility") */
                        i18nKey="descriptionMonthlyActivityCard_BedOnly"
                        default="Monthly overview of <BedLabel>bed {{bedLabel}}</BedLabel> rest mobility"
                        components={{
                            BedLabel: (
                                <DataLabel
                                    type="bedActivityNight"
                                    active={showBed}
                                    onClick={() => setShowBed((i) => !i)}
                                />
                            ),
                        }}
                        values={{ bedLabel: bedLabel }}
                    />
                )}
            </CardDescription>
        );
    }

    return (
        <CardDescription>
            {splitDayNight ? (
                <Trans
                    /* t("descriptionMonthlyActivityCard_Split", "Monthly overview of <RoomLabel>room</RoomLabel> activity and <BedLabel>bed {{bedLabel}}</BedLabel> rest mobility divided into <DayLabel>day</DayLabel> and <NightLabel>night</NightLabel>") */
                    i18nKey="descriptionMonthlyActivityCard_Split"
                    default="Monthly overview of <RoomLabel>room</RoomLabel> activity and <BedLabel>bed {{bedLabel}}</BedLabel> rest mobility divided into <DayLabel>day</DayLabel> and <NightLabel>night</NightLabel>"
                    components={{
                        RoomLabel: (
                            <DataLabel
                                type="roomActivityNight"
                                active={showRoom}
                                onClick={() => setShowRoom((i) => !i)}
                            />
                        ),
                        BedLabel: (
                            <DataLabel type="bedActivityNight" active={showBed} onClick={() => setShowBed((i) => !i)} />
                        ),
                        DayLabel: (
                            <DataLabel type="day" active={includeDay} onClick={() => setIncludeDay(!includeDay)} />
                        ),
                        NightLabel: (
                            <DataLabel
                                type="night"
                                active={includeNight}
                                onClick={() => setIncludeNight(!includeNight)}
                            />
                        ),
                    }}
                    values={{ bedLabel: bedLabel }}
                />
            ) : (
                <Trans
                    /* t("descriptionMonthlyActivityCard", "Monthly overview of <RoomLabel>room</RoomLabel> activity and <BedLabel>bed {{bedLabel}}</BedLabel> rest mobility") */
                    i18nKey="descriptionMonthlyActivityCard"
                    default="Monthly overview of <RoomLabel>room</RoomLabel> activity and <BedLabel>bed {{bedLabel}}</BedLabel> rest mobility"
                    components={{
                        RoomLabel: (
                            <DataLabel
                                type="roomActivityNight"
                                active={showRoom}
                                onClick={() => setShowRoom((i) => !i)}
                            />
                        ),
                        BedLabel: (
                            <DataLabel type="bedActivityNight" active={showBed} onClick={() => setShowBed((i) => !i)} />
                        ),
                    }}
                    values={{ bedLabel: bedLabel }}
                />
            )}
        </CardDescription>
    );
};
