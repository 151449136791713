import { useHasFeatureFlags } from "./useHasFeatureFlags";
import { useUser } from "../context/UserContext";

type Feature = "Dashboard";

/**
 * Generalized hook that checks if the current user can access a certain feature.
 * This is not the same as feature flags, but also takes the users role and other things into account
 */
export const useCanAccessFeature = () => {
    const hasFeatureFlag = useHasFeatureFlags();
    const user = useUser();

    return (feature: Feature) => {
        switch (feature) {
            case "Dashboard":
                return hasFeatureFlag("enable-room-dashboard") && user.roleName !== "nurse";
        }

        return false;
    };
};
