import React from "react";
import styles from "./SideBarToggleButton.module.css";

export interface SideBarToggleButtonProps {
    isMenuOpen: boolean;
    setIsMenuOpen: (state: boolean) => void;
    id: string;
}

export const SideBarToggleButton = ({ isMenuOpen, setIsMenuOpen, id }: SideBarToggleButtonProps) => {
    const classNames = [styles.button];
    classNames.push(isMenuOpen ? styles.open : styles.close);

    return <button id={id} className={classNames.join(" ")} onClick={() => setIsMenuOpen(!isMenuOpen)}></button>;
};
