import React from "react";
import { createContext, PropsWithChildren, useContext } from "react";
import { useMe, UserSelf } from "../api_v2/hooks/useNurses";
import { LoadingView } from "../views/LoadingView/LoadingView";

const UserContext = createContext<null | UserSelf>(null);

export const useUser = (): UserSelf => {
    const user = useContext(UserContext);
    if (user === null) throw new Error("Unknown user");
    return user;
};

export const UserContextProvider = (props: PropsWithChildren<unknown>) => {
    const userQuery = useMe();

    if (!userQuery.isSuccess) {
        return <LoadingView />;
    }

    return <UserContext.Provider value={userQuery.data}>{props.children}</UserContext.Provider>;
};
