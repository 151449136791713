import { useMutation } from "react-query";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";

export interface CompleteNewPasswordChallengeOptions {
    cognitoUser: CognitoUser;
    sessionUserAttributes: unknown;
    newPassword: string;
}
export const useCompleteNewPasswordChallenge = () => {
    return useMutation<CognitoUserSession, string, CompleteNewPasswordChallengeOptions>(
        "complete-new-password",
        (data) => {
            return completeNewPasswordChallenge(data.cognitoUser, data.sessionUserAttributes, data.newPassword);
        }
    );
};

const completeNewPasswordChallenge = (cognitoUser: CognitoUser, sessionUserAttributes: unknown, newPassword: string) =>
    new Promise<CognitoUserSession>((resolve, reject) => {
        // Providing the sessionUserAttributes leads to an error. Works with an empty object :shrug:
        cognitoUser.completeNewPasswordChallenge(
            newPassword,
            {},
            {
                onSuccess: (session) => resolve(session),
                onFailure: (err) => reject(err.message || JSON.stringify(err)),
            }
        );
    });
