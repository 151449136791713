import { useMutation } from "react-query";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { AuthenticateError } from "./AuthenticateError";

export interface SendMFACodeOptions {
    user: CognitoUser;
    code: string;
}

export const useSendMFACode = () => {
    return useMutation<CognitoUserSession, AuthenticateError, SendMFACodeOptions>("sendMFACode", (data) => {
        return sendMFACode(data.user, data.code);
    });
};

const sendMFACode = (user: CognitoUser, code: string) =>
    new Promise<CognitoUserSession>((resolve, reject) => {
        user.sendMFACode(
            code,
            {
                onSuccess: (session) => resolve(session),
                onFailure: reject,
            },
            "SOFTWARE_TOKEN_MFA"
        );
    });
