import { Id } from "../../../../api_v2/types/custom";
import { BiCaretRightCircle } from "react-icons/bi";

interface CustomLabelOverBarProps {
    handleCustomLabelOverBarClick: (bedActivitiesVisualizationList: string[], respectiveHeatMapId: Id) => void;
}

// TODO fix type
// eslint-disable-next-line
export const CustomLabelOverBar = (props: any | CustomLabelOverBarProps) => {
    const { x, y, value, width, id } = props;
    const respectiveHeatMapId = id;

    const handleCustomLabelOverBarClick = () => {
        props.handleCustomLabelOverBarClick(respectiveHeatMapId);
    };

    const playButtonIconSize = width < 25 && (width * 2.2) / 2 < 22 ? (width * 2.2) / 2 : 22; //values found by trial&error

    if (!value) {
        return null;
    }

    return (
        <g
            aria-label={"button to show bed activity sequences"}
            onClick={handleCustomLabelOverBarClick}
            style={{ cursor: "pointer" }}
        >
            <BiCaretRightCircle
                x={x + width / 2 - playButtonIconSize / 2}
                y={y - playButtonIconSize}
                fill={"#14325c"}
                fontSize={playButtonIconSize}
            />
        </g>
    );
};
