import { Modal, ModalContent } from "../Modal/Modal";
import React, { ReactElement } from "react";
import { Button } from "../UI/Button/Button";
import styles from "./Prompts.module.css";

export interface PromptOption<T> {
    label: string;
    primary?: boolean;
    value: T;
}

export interface PromptProps<T> {
    title: string | ReactElement;
    message: string | ReactElement;
    options: Array<PromptOption<T>>;
    callback: (value: T) => void;
    style?: "success" | "error";
}

export const Prompt = <T,>(props: PromptProps<T>) => {
    return (
        <Modal onClose={() => null} showCloseButton={false} size="prompt" disableScrollbar={true}>
            <ModalContent>
                <div className={styles.title}>{props.title}</div>
                <div className={styles.message}>{props.message}</div>
                <div className={styles.options}>
                    {props.options.map((option) => (
                        <Button
                            key={option.label}
                            onClick={() => props.callback(option.value)}
                            primary={option.primary}
                            className={styles.button}
                        >
                            {option.label}
                        </Button>
                    ))}
                </div>
            </ModalContent>
        </Modal>
    );
};
