import { useLocationContext } from "../../context/LocationContextProvider";
import { View } from "../../components/View";
import { useTranslation } from "react-i18next";
import { useGetFacility } from "../../api_v2/hooks/useFacilities";
import { Filter } from "./Filter";
import { useState } from "react";
import { RoomsList } from "./List/RoomsList";
import { useListDevices, useListDeviceStatuses } from "../../api_v2/hooks/useDevices";
import { Id } from "../../api_v2/types/custom";
import { RecentEventsList } from "./RecentEventsList";
import { useQueryParameters } from "../../utils/useQueryParameters";
import { DeviceDetailsModal } from "../../components/DeviceDetailsModal/DeviceDetailsModal";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useCurrentRoomsTree } from "./helper/useCurrentRoomsTree";
import { LoadingView } from "../LoadingView/LoadingView";
import { DeviceSetupModal } from "../../components/DeviceSetup/DeviceSetupModal";
import { useHasPermission } from "../../hooks/useHasPermission";
import { Alert } from "../../components/UI/Alerts/Alert";
import { c } from "../../utils/ClassesHelper";
import { PopoverMenu } from "../../components/PopoverMenu/PopoverMenu";
import { ToggleSwitch } from "../../components/UI/ToggleSwitch";
import { Card } from "../../components/UI/Card/Card";
import { useUserSpecificLocalStorage } from "../../hooks/useUserSpecificLocalStorage";
import { Label } from "../../components/UI/Label";
import { BiCog } from "react-icons/bi";
import { AnalysisModal } from "../../components/AnalysisModal/AnalysisModal";
import { seconds } from "../../utils/IntervalHelper";
import { useLocaleAwareSort } from "../../utils/useLocaleAwareCompare";
import { route } from "../../utils/RouteHelper";
import styles from "./RoomsView.module.css";

export const RoomsView = () => {
    const { t } = useTranslation();
    const locationContext = useLocationContext();
    const hasPermission = useHasPermission();
    const sort = useLocaleAwareSort();

    const navigate = useNavigate();
    const queryParams = useQueryParameters();

    const facilityQuery = useGetFacility(locationContext.facilityId);
    const devicesQuery = useListDevices(locationContext.facilityId);
    const deviceStatusesQuery = useListDeviceStatuses(locationContext.facilityId, {
        refetchInterval: seconds(30),
    });

    const [selectedAreaId, setSelectedAreaId] = useState<Id | null>(null);
    const [showEmptyRooms, setShowEmptyRooms] = useUserSpecificLocalStorage("showEmptyRooms", true);

    const roomTree = useCurrentRoomsTree(selectedAreaId, showEmptyRooms);

    if (facilityQuery.isError && devicesQuery.isError && deviceStatusesQuery.isError) {
        return <Alert type="error">{t("errorLoading", "An error occurred while loading")}</Alert>;
    }

    if (!(facilityQuery.isSuccess && devicesQuery.isSuccess && deviceStatusesQuery.isSuccess)) {
        return <LoadingView />;
    }

    const facility = facilityQuery.data;

    const filterByAreaId = locationContext.areaId ?? selectedAreaId;
    const filterByArea = facility.areas.find((a) => a.id === filterByAreaId) ?? null;

    const areas = locationContext.areaId ? [] : facility.areas.sort(sort("name"));

    const selectedDeviceInfo = queryParams.get("view");
    const selectedDeviceId = queryParams.get("device");
    const selectedDevice = selectedDeviceId
        ? roomTree
              .map((r) => r.devices)
              .flat()
              // selectedDeviceId can either be the sensor id (if selected in rooms list) or the dock id (if selected in events list)
              .find((d) => d.device.id == selectedDeviceId || d.device.dockedTo === selectedDeviceId)
        : undefined;
    const otherDevicesInRoom = selectedDevice?.device.roomId
        ? roomTree
              .map((r) => r.devices)
              .flat()
              .filter(
                  (d) => d.device.roomId === selectedDevice?.device.roomId && d.device.id !== selectedDevice.device.id
              )
        : [];

    const roomInfo = {
        name: roomTree.find((r) => r.room.id === selectedDevice?.device.roomId)?.room.name || "?",
        includesOtherSensors: otherDevicesInRoom.length > 0,
    };

    return (
        <View title={t("labelRooms", "Rooms")} subtitle={facility.name} className={styles.container}>
            <Filter
                areas={areas}
                selectedAreaId={selectedAreaId}
                onAreaIdSelect={setSelectedAreaId}
                className={styles.filter}
            />

            <Card className={c([styles.rooms])}>
                <RoomsList tree={roomTree} className={styles.rooms} />
                <PopoverMenu Icon={BiCog} className={styles.options}>
                    {() => (
                        <Label text={t("labelShowEmptyRooms", "Show empty rooms?")}>
                            <ToggleSwitch
                                state={showEmptyRooms}
                                onChange={(s) => setShowEmptyRooms(s)}
                                checkedLabel={t("labelShow", "Show")}
                                uncheckedLabel={t("labelHide", "Hide")}
                            />
                        </Label>
                    )}
                </PopoverMenu>
            </Card>

            <RecentEventsList facility={facility} area={filterByArea} className={styles.events} />

            {selectedDevice && selectedDeviceInfo === "settings" ? (
                <DeviceDetailsModal
                    device={selectedDevice}
                    roomInfo={roomInfo}
                    onClose={() => navigate(route({ to: "rooms" }))}
                    facilityId={facility.id}
                />
            ) : null}

            {selectedDevice && selectedDeviceInfo === "analysis" ? (
                <AnalysisModal device={selectedDevice} onClose={() => navigate(route({ to: "rooms" }))} />
            ) : null}

            {hasPermission("w:devices") ? (
                <Routes>
                    <Route
                        path="/setup/*"
                        element={<DeviceSetupModal baseRoute="/rooms/setup" onClose={() => navigate("./")} />}
                    />
                </Routes>
            ) : null}
        </View>
    );
};
