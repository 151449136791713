import { useTranslation } from "react-i18next";
import { BsFillInfoCircleFill } from "react-icons/bs";

// TODO fix type
// eslint-disable-next-line
export const CustomLabelInsideBar = (props: any) => {
    const { t } = useTranslation();
    const { x, y, value, width } = props;
    const iconSize = width < 16 && (width * 2.2) / 2 < 35.4 ? 14 : 18; //values found by trial&error

    if (!value) {
        return null;
    }
    return (
        <g aria-label={t("CustomLabelInsideBar.ariaLabel.outlierIcon", "icon to show that this day is an outlier")}>
            <BsFillInfoCircleFill
                x={x + width / 2 - iconSize / 2}
                y={y - iconSize - 1.5}
                fill={"#14325c"}
                fontSize={iconSize}
            />
        </g>
    );
};
