import { useLocationContext } from "../context/LocationContextProvider";
import { FeatureFlags } from "../api_v2/hooks/useListFeatureFlags";
import { config } from "../config";

const getValueFor = (featureFlags: FeatureFlags, key: FeatureFlagKey, fallback = false): boolean =>
    featureFlags.find((f) => f.key === key)?.value ?? fallback;

type FeatureFlagKey =
    | "enable-room-activity"
    | "enable-bed-activity"
    | "enable-room-dashboard"
    | "enable-bed-restlessness";

export const useHasFeatureFlags = () => {
    const { featureFlags } = useLocationContext();

    const extendedFeatureFlags = [...config.enabledFeatureFlags, ...featureFlags];

    return (key: FeatureFlagKey | Array<FeatureFlagKey>, mode: "all" | "any" = "all"): boolean => {
        const keys = Array.isArray(key) ? key : [key];
        return mode === "all"
            ? keys.every((key) => getValueFor(extendedFeatureFlags, key))
            : keys.some((key) => getValueFor(extendedFeatureFlags, key));
    };
};
