import { useQueryClient } from "react-query";
import { useCallback } from "react";
import { InvalidateOptions, InvalidateQueryFilters } from "react-query/types/core/types";

/**
 * Use this in custom hooks like so:
 *
 * export const useInvalidateXXX = () => {
 *     const invalidate = useDelayedInvalidateQueries([1000, 2000, 3000]);
 *     return (myParam: string) => invalidate({ queryKey: ["a", myParam, "c"] });
 * };
 *
 * to generate an invalidate hook that will always invalidate multiple times, after the given delays.
 */
export const useDelayedInvalidateQueries = (afterMilliseconds: Array<number>) => {
    const queryClient = useQueryClient();

    return useCallback(
        <TPageData = unknown>(
            filters: InvalidateQueryFilters<TPageData>,
            options?: InvalidateOptions
        ): Promise<void[]> => callAfter(() => queryClient.invalidateQueries(filters, options), afterMilliseconds),
        [queryClient, afterMilliseconds]
    );
};

/**
 * Use this helper to call something after x milliseconds
 *
 * @param callback
 * @param afterMilliseconds
 */
export const callAfter = <T = void>(callback: () => Promise<T>, afterMilliseconds: Array<number>): Promise<T[]> =>
    Promise.all(
        afterMilliseconds.map(
            (afterMs) =>
                new Promise<T>((resolve) => {
                    window.setTimeout(async () => {
                        resolve(await callback());
                    }, afterMs);
                })
        )
    );
