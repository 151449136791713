import React, { useState } from "react";
import { c } from "../../../utils/ClassesHelper";
import { CardTitle } from "../../../components/UI/Card/CardTitle";
import { CardDescription } from "../../../components/UI/Card/CardDescription";
import { Card } from "../../../components/UI/Card/Card";
import { CardContent } from "../../../components/UI/Card/CardContent";
import { StackedBarChart } from "../Charts/StackedBarChart";
import { colors } from "../../../utils/colors";
import { DataLabel } from "../common/DataLabel";
import { CommonCardProps } from "./common/CommonCardProps";
import { useDailyAlertData } from "../data/useAlertData";
import { DateInterval } from "../../../utils/DateInterval";
import { Trans, useTranslation } from "react-i18next";
import { useFormat } from "../../../hooks/useFormat";
import { CardsTitlesAndTooltips } from "../CardsTitlesAndTooltips";
import cardStyles from "../Container/CardGrid.module.scss";
import styles from "../DashboardView.module.scss";

/**
 * Weekly alert counts chart.
 * Stacked chart of falls and emergency prevention (getup, absence and bed-rail)
 * @param deviceId
 * @constructor
 */

export interface WeeklyAlertsCardProps extends CommonCardProps {
    interval: DateInterval;
}

export const WeeklyAlertsCard = ({ deviceId, interval }: WeeklyAlertsCardProps) => {
    const { t } = useTranslation();
    const format = useFormat();
    const [includeFall, setIncludeFall] = useState(true);
    const [includeEmergency, setIncludeEmergency] = useState(true);

    const data = useDailyAlertData(deviceId, interval).map((d, index, arr) => {
        const dateFormat = index === 0 || index === arr.length - 1 ? "P-no-year" : "EEEEEE";

        return { ...d, name: format(new Date(d.name), dateFormat) };
    });

    const chartColors = [];
    const chartValues = [];

    const maxValue = Math.ceil(data.reduce((max, data) => Math.max(max, data.emergency + data.fall), 0) / 5) * 5;

    if (includeEmergency) {
        chartColors.push(colors.emergencyPrevention);
        chartValues.push("emergency");
    }

    if (includeFall) {
        chartColors.push(colors.fallDetection);
        chartValues.push("fall");
    }

    return (
        <Card className={c([styles.card, cardStyles.lg1])}>
            <CardTitle>
                <CardsTitlesAndTooltips
                    title={t("titleWeeklyAlertsCard", "Alerts")}
                    tooltip={t("tooltipWeeklyAlertsCard", "Shows all events that have alerted caregivers.")}
                />
            </CardTitle>
            <CardDescription>
                <Trans
                    // t("descriptionWeeklyAlertsCard", "Alerts this week by <FallDetection>fall detection</FallDetection> and <EmergencyPrevention>emergency prevention</EmergencyPrevention>")
                    i18nKey="descriptionWeeklyAlertsCard"
                    default="Alerts this week by <FallDetection>fall detection</FallDetection> and <EmergencyPrevention>emergency prevention</EmergencyPrevention>"
                    components={{
                        FallDetection: (
                            <DataLabel
                                type={"fallDetection"}
                                active={includeFall}
                                onClick={() => setIncludeFall((i) => !i)}
                            />
                        ),
                        EmergencyPrevention: (
                            <DataLabel
                                type={"emergencyPrevention"}
                                active={includeEmergency}
                                onClick={() => setIncludeEmergency((i) => !i)}
                            />
                        ),
                    }}
                />
            </CardDescription>
            <CardContent>
                <StackedBarChart
                    data={data}
                    colors={chartColors}
                    valuesForStackedBar={chartValues}
                    xAxisDataKey="name"
                    yAxisDomainMax={maxValue}
                />
            </CardContent>
        </Card>
    );
};
