import { AnalysisModuleStatus } from "../../../../api_v2/hooks/useDevices";
import { DeviceSettings, useDeviceSettings } from "../../../../api_v2/hooks/useDeviceSettings";
import { minutes } from "../../../../utils/IntervalHelper";
import { Loading } from "../../../../components/UI/Loading";
import { Alert } from "../../../../components/UI/Alerts/Alert";
import { PropsWithChildren } from "react";
import { Trans, useTranslation } from "react-i18next";
import { TimeWindowFullHours, TimeWindows } from "../../../../api_v2/types/custom";
import { format } from "date-fns";

import fallDetectionIcon from "../../../../images/icons/Sturzerkennung.svg";
import getupDetectionIcon from "../../../../images/icons/type/Aufsetzen.svg";
import absenceDetectionIcon from "../../../../images/icons/type/Abwesenheit.svg";
import bedRailIcon from "../../../../images/icons/Bettbalken.svg";
import bedActivityIcon from "../../../../images/icons/type/bed_activity.svg";
import roomActivityIcon from "../../../../images/icons/type/room_activity.svg";

import styles from "./AnalysisModuleStatusCell.module.css";

export const stringifyTimeWindows = (timeWindows: TimeWindowFullHours[]) => {
    const stringifiedTimeWindows = [];
    for (const timeWindow of timeWindows) {
        const fromTime = new Date(0, 0, 0, timeWindow.from, 0, 0);
        const untilTime = new Date(0, 0, 0, timeWindow.until, 0, 0);
        stringifiedTimeWindows.push({ from: format(fromTime, "HH:mm"), until: format(untilTime, "HH:mm") });
    }
    return stringifiedTimeWindows;
};

export const AnalysisModuleStatusCellTooltipPanel = ({ status }: { status: AnalysisModuleStatus }) => {
    const { t } = useTranslation();

    const settingsQuery = useDeviceSettings(status.deviceId, {
        keepPreviousData: true,
        staleTime: minutes(5),
    });

    if (settingsQuery.isLoading || settingsQuery.isIdle)
        return <Loading small={true} text={t("tooltipLoading", "Loading ...")} />;
    if (settingsQuery.isError) return <Alert type="error">{t("tooltipErrorTitleUnknown", "Error")}</Alert>;

    switch (status.module) {
        case "fall-detection":
            return <PanelFallDetection settings={settingsQuery.data} />;
        case "getup-detection":
            return <PanelGetUpDetection settings={settingsQuery.data} />;
        case "absence-detection":
            return <PanelAbsenceDetection settings={settingsQuery.data} />;
        case "bed-rail":
            return <PanelBedRail settings={settingsQuery.data} />;
        case "bed-activity":
            return <PanelBedActivity settings={settingsQuery.data} />;
        case "room-activity":
            return <PanelRoomActivity settings={settingsQuery.data} />;
    }

    return null;
};

const getScheduleString = (timeWindows: TimeWindows | undefined) => {
    if (!timeWindows) return null;

    return timeWindows.map((t) => `${t.from} - ${t.until}`).join(", ");
};

interface CommonPanelProps {
    settings: DeviceSettings;
}

const PanelFallDetection = ({ settings }: CommonPanelProps) => {
    const { t } = useTranslation();
    const schedule = getScheduleString(settings.settings?.fallDetectionTimeWindows);

    return (
        <PanelWrapper icon={fallDetectionIcon} title={t("alertTypeFall", "Fall")}>
            <p>
                <Trans
                    i18nKey="tooltipFallDetectionAlarm"
                    values={{
                        schedule,
                    }}
                >
                    Active in the period of <strong>{{ schedule }}</strong>
                </Trans>
            </p>
        </PanelWrapper>
    );
};

const PanelGetUpDetection = ({ settings }: CommonPanelProps) => {
    const { t } = useTranslation();
    const locations = settings.settings.getupDetectionLocations;

    return (
        <PanelWrapper icon={getupDetectionIcon} title={t("alertTypeFallPrevention", "Fall prevention")}>
            {locations?.map((location, index) => {
                const schedule = getScheduleString(location.timeWindows);

                const type = {
                    raiseup: t("raiseup", "Raise up"),
                    situp: t("situp", "Sit up"),
                    standup: t("standup", "Stand up"),
                }[location.mode];

                return (
                    <div className={styles.subPanel} key={index}>
                        {locations?.length > 1 ? (
                            <strong className={styles.subPanelTitle}>
                                {t("labelBedMonitoringBedNumber", "Bed {{index}}", { index: index + 1 })}
                            </strong>
                        ) : null}
                        <p>
                            <Trans
                                i18nKey="tooltipBedMonitoringAlarm"
                                values={{
                                    type,
                                    schedule,
                                }}
                            >
                                Fall prevention at <strong>{{ type }}</strong> during <strong>{{ schedule }}</strong>
                            </Trans>
                        </p>
                    </div>
                );
            })}
        </PanelWrapper>
    );
};

const PanelAbsenceDetection = ({ settings }: CommonPanelProps) => {
    const { t } = useTranslation();
    const locations = settings.settings.absenceDetectionLocations;

    return (
        <PanelWrapper icon={absenceDetectionIcon} title={t("alertTypeAbsence", "Absence")}>
            {locations?.map((location, index) => {
                const schedule = getScheduleString(location.timeWindows);

                return (
                    <div className={styles.subPanel} key={index}>
                        {locations?.length > 1 ? (
                            <strong className={styles.subPanelTitle}>
                                {t("labelBedMonitoringBedNumber", "Bed {{index}}", { index: index + 1 })}
                            </strong>
                        ) : null}
                        <p>
                            <Trans
                                i18nKey="tooltipFallDetectionAlarm"
                                values={{
                                    schedule,
                                }}
                            >
                                Active in the period of <strong>{{ schedule }}</strong>
                            </Trans>
                        </p>
                    </div>
                );
            })}
        </PanelWrapper>
    );
};

const PanelBedRail = ({ settings }: CommonPanelProps) => {
    const { t } = useTranslation();
    const locations = settings.settings.bedRails;

    return (
        <PanelWrapper icon={bedRailIcon} title={t("alertTypeLightBarrierCrossed", "Crossed bed rail")}>
            {locations?.map((location, index) => {
                const schedule = getScheduleString(location.timeWindows);

                return (
                    <div className={styles.subPanel} key={index}>
                        {locations?.length > 1 ? (
                            <strong className={styles.subPanelTitle}>
                                {t("labelBedMonitoringBarrierNumber", "Virtual bed rail {{index}}", {
                                    index: index + 1,
                                })}
                            </strong>
                        ) : null}
                        <p>
                            <Trans
                                i18nKey="tooltipLightBarrierAlarm"
                                values={{
                                    schedule,
                                }}
                            >
                                Active in the period of <strong>{{ schedule }}</strong>
                            </Trans>
                        </p>
                    </div>
                );
            })}
        </PanelWrapper>
    );
};

const PanelBedActivity = ({ settings }: CommonPanelProps) => {
    const { t } = useTranslation();
    const locations = settings.settings.bedActivityLocations;

    return (
        <PanelWrapper icon={bedActivityIcon} title={t("alertTypeBedActivity", "Bed activity")}>
            {locations?.map((location, index) => {
                const schedule = getScheduleString(stringifyTimeWindows(location.timeWindows));

                return (
                    <div className={styles.subPanel} key={index}>
                        {locations?.length > 1 ? (
                            <strong className={styles.subPanelTitle}>
                                {t("labelBedMonitoringBedNumber", "Bed {{index}}", { index: index + 1 })}
                            </strong>
                        ) : null}
                        <p>
                            <Trans
                                i18nKey="tooltipBedActivityAlarm"
                                values={{
                                    schedule,
                                }}
                            >
                                Active in the period of <strong>{{ schedule }}</strong>
                            </Trans>
                        </p>
                    </div>
                );
            })}
        </PanelWrapper>
    );
};

const PanelRoomActivity = ({ settings }: CommonPanelProps) => {
    const { t } = useTranslation();
    const schedule = settings.settings.roomActivityTimeWindows
        ? getScheduleString(stringifyTimeWindows(settings.settings.roomActivityTimeWindows))
        : null;

    return (
        <PanelWrapper icon={roomActivityIcon} title={t("alertTypeRoomActivity", "Room activity")}>
            <p>
                <Trans
                    i18nKey="tooltipRoomActivityAlarm"
                    values={{
                        schedule,
                    }}
                >
                    Active in the period of <strong>{{ schedule }}</strong>
                </Trans>
            </p>
        </PanelWrapper>
    );
};

interface PanelWrapperProps {
    title: JSX.Element | string;
    icon: string;
}

const PanelWrapper = (props: PropsWithChildren<PanelWrapperProps>) => {
    return (
        <div className={styles.panelWrapper}>
            <div className={styles.panelIcon}>
                <img src={props.icon} />
            </div>
            <div className={styles.panelContent}>
                <strong>{props.title}</strong>
                {props.children}
            </div>
        </div>
    );
};
