import React from "react";
import { Button } from "./Button/Button";
import { useTranslation } from "react-i18next";

type LoadMoreButtonProps = {
    loading: boolean;
    onClick: () => void;
};

export const LoadMoreButton = (props: LoadMoreButtonProps) => {
    const { t } = useTranslation();

    return (
        <Button onClick={() => props.onClick()} disabled={props.loading}>
            {props.loading ? t("loading", "Loading...") : t("loadMore", "Load more")}
        </Button>
    );
};
