import { useMutation, useQuery, useQueryClient } from "react-query";
import { useFetch, useMutate } from "@bitperfect-packages/react-query-axios";
import { Id } from "../types/custom";
import { Device } from "./useDevices";

const getUrl = (params: DockDeviceParams) => `/docks/${params.dockId}/devices/${params.deviceId}`;

export interface DockDeviceParams {
    dockId: Id;
    deviceId: Id;
}

export const useDockDevice = () => {
    const invalidateDocks = useInvalidateCompatibleDocks();
    const mutate = useMutate<void, DockDeviceParams>("PUT", getUrl);
    return useMutation<void, void, DockDeviceParams>(["dock-device"], mutate, {
        onSuccess: (_, params) => invalidateDocks(params.deviceId),
    });
};

export const useUndockDevice = () => {
    const invalidateDocks = useInvalidateCompatibleDocks();
    const mutate = useMutate<void, DockDeviceParams>("DELETE", getUrl);
    return useMutation<void, void, DockDeviceParams>(["undock-device"], mutate, {
        onSuccess: (_, params) => invalidateDocks(params.deviceId),
    });
};

export const useInvalidateCompatibleDocks = () => {
    const queryClient = useQueryClient();
    return (deviceId: Id) => queryClient.invalidateQueries({ queryKey: ["devices", deviceId, "compatible-docks"] });
};

export const useListCompatibleDocks = (deviceId: Id | null, freeDocksOnly = true) => {
    return useQuery<Array<Device>>(
        ["devices", deviceId, "compatible-docks"],
        useFetch<Array<Device>>(`/devices/${deviceId}/compatible-docks`, {
            config: {
                params: {
                    free: freeDocksOnly ? undefined : "no",
                },
            },
        }),
        {
            enabled: deviceId !== null,
        }
    );
};
