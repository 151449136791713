import { Area, CartesianGrid, ComposedChart, Label, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { colors } from "../../../utils/colors";
import { c } from "../../../utils/ClassesHelper";
import { DayAndNightSeparatedActivityData } from "../data/useActivityDataHelpers";
import { addDays, isBefore, isSameDay, startOfDay } from "date-fns";
import { useFormat } from "../../../hooks/useFormat";
import { useTranslation } from "react-i18next";
import styles from "../DashboardView.module.scss";

export interface AreaChartWithDayAndNightProps {
    data: Array<DayAndNightSeparatedActivityData>;

    // Y-Axis
    rangeMax?: number;
    yAxisWidth?: number;
    customYTicks?: boolean;

    // Chart
    areaOnClick?: () => void;
    dayColor?: string;
    nightColor?: string;
}

export const AreaChartWithDayAndNight = ({
    data,
    yAxisWidth,
    rangeMax,
    customYTicks,
    areaOnClick,
    dayColor,
    nightColor,
}: AreaChartWithDayAndNightProps) => {
    const format = useFormat();
    const { t } = useTranslation();

    const firstTickAt = startOfDay(addDays(new Date(data[0]?.timestamp), 1));
    const ticks = data
        .reduce<Array<[Date, number]>>((ticks, current) => {
            const currentDate = new Date(current.timestamp);

            if (isBefore(currentDate, firstTickAt)) return ticks;

            if (ticks.length === 0) {
                // First label should appear at first midnight
                return [[currentDate, currentDate.getTime()]];
            }

            if (ticks.length > 0 && isSameDay(currentDate, ticks[ticks.length - 1][0])) {
                return ticks;
            }

            return [...ticks, [currentDate, startOfDay(currentDate).getTime()]];
        }, [])
        .map(([, timestamp]) => timestamp);

    const tickFormatter = (timestamp: number) => {
        const index = ticks.indexOf(timestamp);
        return format(new Date(timestamp), index === 0 || index === ticks.length - 1 ? "P-no-year" : "EEEEEE");
    };

    return (
        <ResponsiveContainer className={styles.responsiveChartContainer}>
            <ComposedChart
                data={data}
                margin={{ top: 0, left: 0, right: 0, bottom: 10 }}
                barGap={0}
                barCategoryGap={"25%"}
            >
                <CartesianGrid stroke={colors.lightGrey} vertical={false} />
                <Area
                    type="monotone"
                    dataKey="day"
                    fill={dayColor}
                    stroke={"none"}
                    dot={false}
                    activeDot={false}
                    isAnimationActive={false}
                    onClick={areaOnClick}
                    className={c({ [styles.clickable]: areaOnClick !== undefined })}
                />
                <Area
                    type="monotone"
                    dataKey="night"
                    fill={nightColor}
                    stroke={"none"}
                    dot={false}
                    activeDot={false}
                    isAnimationActive={false}
                    onClick={areaOnClick}
                    className={c({ [styles.clickable]: areaOnClick !== undefined })}
                />

                <XAxis
                    dataKey="timestamp"
                    type="number"
                    fontSize={12}
                    axisLine={false}
                    tickLine={true}
                    interval="preserveStartEnd"
                    ticks={ticks}
                    tickFormatter={tickFormatter}
                    domain={[ticks[0], data[data.length - 1].timestamp]}
                >
                    <Label value={t("yAxisLabelDay", "Day")} className={styles.label} dy={15} />
                </XAxis>

                <YAxis
                    tick={
                        !customYTicks
                            ? true
                            : (tick) => {
                                  //TODO: move to separate function file
                                  delete tick.tickformatter;
                                  delete tick.visibleTicksCount;
                                  delete tick.verticalanchor;
                                  if (tick.payload.value === 0) {
                                      // "low" and "high" should be hardcoded and not be translated for now,
                                      // as in other languages the word is too long and does not fit in the graph
                                      return <text {...tick}>{"low"}</text>;
                                  } else if (tick.payload.value === 100) {
                                      return <text {...tick}>{"high"}</text>;
                                  }
                                  return <></>;
                              }
                    }
                    width={yAxisWidth ?? 40}
                    fontSize={12}
                    axisLine={false}
                    tickLine={false}
                    domain={[0, rangeMax ? rangeMax : (dataMax: number) => Math.max(4, dataMax)]}
                    allowDecimals={false}
                >
                    <Label
                        value={t("yAxisLabelActivity", "Activity")}
                        angle={-90}
                        className={c([styles.label, styles.labelY])}
                        dx={-13}
                    />
                </YAxis>
            </ComposedChart>
        </ResponsiveContainer>
    );
};
