import { useQuery } from "react-query";
import { components } from "../types/schema";
import { Id } from "../types/custom";
import { useFetch } from "@bitperfect-packages/react-query-axios";
import { differenceInMilliseconds, endOfHour } from "date-fns";
import { useNow } from "../../hooks/useNow";
import { useGetDayParams } from "../../hooks/useGetDayParams";

export type HourlyCareDemands = components["schemas"]["HourlyCareDemands"];
export type DailyCareDemands = components["schemas"]["DailyCareDemand"];

export const useHourlyCareDemands = (deviceId: Id) => {
    const now = useNow("hour");

    const url = `/devices/${deviceId}/hourly-care-demands`;
    return useQuery([url], useFetch<HourlyCareDemands>(url), {
        staleTime: differenceInMilliseconds(endOfHour(new Date()), now),
    });
};

export const useDailyCareDemands = (
    deviceId: Id,
    params: {
        days?: number;
    }
) => {
    const urlSearchParams = useGetDayParams(params);

    return useQuery<Array<DailyCareDemands>>(
        ["daily-care-demand", deviceId, params],
        useFetch<Array<DailyCareDemands>>(`/devices/${deviceId}/daily-care-demands`, {
            config: {
                params: urlSearchParams,
            },
        })
    );
};
