import React from "react";
import { Event } from "../../../api_v2/hooks/useEvents";
import { TimeDisplay } from "../../../components/UI/TimeDisplay/TimeDisplay";
import { EventImage } from "../../../components/Events/EventImage";
import { AreaRoom } from "../../../api_v2/types/custom";
import { useTranslation } from "react-i18next";
import { EventIcon } from "../../../components/Events/EventIcon";
import { EventId } from "../../../components/Events/EventId";
import styles from "./EventsList.module.css";

export interface EventItemProps {
    event: Event;
    room: AreaRoom | undefined;
    onSelect: () => void;
    reduced?: boolean;
}

export const EventItem = ({ event, room, onSelect, reduced }: EventItemProps) => {
    const { t } = useTranslation();

    return (
        <tr className={styles.alert} onClick={onSelect}>
            <td>
                <EventImage eventId={event.id} className={styles.previewImage} />
            </td>
            <td className={styles.roomName}>{room?.name ?? t("infoUnknownRoom", "Unknown room")}</td>
            <td>
                <EventIcon type={event.type} colored={true} includeLabel={true} />
            </td>

            <td className={styles.timestamp}>
                <TimeDisplay time={event.time} relative={true} />
            </td>

            {reduced !== true ? (
                <>
                    <td className={styles.alertId}>
                        <EventId event={event} />
                    </td>
                </>
            ) : null}
        </tr>
    );
};
