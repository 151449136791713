import styles from "./PopoverMenu.module.css";
import React, { PropsWithChildren, ReactElement } from "react";

export interface PopoverMenuItemProps {
    icon?: ReactElement | string;
    onClick?: () => void;
    disabled?: boolean;
    id?: string;
}

export const PopoverMenuItem = (props: PropsWithChildren<PopoverMenuItemProps>) => {
    return (
        <li className={styles.item}>
            <button className={styles.itemButton} onClick={props.onClick} disabled={props.disabled} id={props.id}>
                {typeof props.icon === "string" ? (
                    <img className={styles.icon} src={props.icon} />
                ) : (
                    <span className={styles.icon}>{props.icon}</span>
                )}{" "}
                {props.children}
            </button>
        </li>
    );
};
