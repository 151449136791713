import React from "react";
import { c } from "../../../utils/ClassesHelper";
import { CardTitle } from "../../../components/UI/Card/CardTitle";
import { CardDescription } from "../../../components/UI/Card/CardDescription";
import { CardContent } from "../../../components/UI/Card/CardContent";
import { SimplePieChart } from "../Charts/SimplePieChart";
import { colors } from "../../../utils/colors";
import { Card } from "../../../components/UI/Card/Card";
import { DataLabel } from "../common/DataLabel";
import { useDailyAlertData } from "../data/useAlertData";
import { WeeklyAlertsCardProps } from "./WeeklyAlertsCard";
import { Trans, useTranslation } from "react-i18next";
import { CardsTitlesAndTooltips } from "../CardsTitlesAndTooltips";
import styles from "../DashboardView.module.scss";

// Patterns from https://heropatterns.com/
const patterns = [
    <pattern key="fallPrevention" id="fallPrevention" width="10" height="10" patternUnits="userSpaceOnUse">
        <rect x={0} y={0} width={10} height={10} fill={colors.emergencyPrevention} />
        <g fill="#ffffff" fillOpacity="0.6" fillRule="evenodd">
            <circle cx="1.5" cy="1.5" r="1.5" />
            <circle cx="6.5" cy="6.5" r="1.5" />
        </g>
    </pattern>,
    <pattern key="absenceDetection" id="absenceDetection" width="6" height="6" patternUnits="userSpaceOnUse">
        <rect x={0} y={0} width={6} height={6} fill={colors.emergencyPrevention} />
        <g fill="#ffffff" fillOpacity="1" fillRule="evenodd">
            <path d="M5 0h1L0 6V5zM6 5v1H5z" />
        </g>
    </pattern>,
    <pattern key="bedRails" id="bedRails" width="10" height="10" patternUnits="userSpaceOnUse">
        <rect x={0} y={0} width={12} height={12} fill={colors.emergencyPrevention} />
        <g fillRule="evenodd">
            <g fill="#ffffff" fillOpacity="1" fillRule="evenodd">
                <path d="M 6 3 L 9 6 L 6 9 L 3 6 z M 6 2.25 L 9.75 6 L 6 9.75 L 2.25 6 z " />
            </g>
        </g>
    </pattern>,
];

export const WeeklyAlertCountsCard = ({ deviceId, interval }: WeeklyAlertsCardProps) => {
    const { t } = useTranslation();

    const alertData = useDailyAlertData(deviceId, interval).reduce(
        (sum, cur) => ({
            fall: sum.fall + cur.fall,
            getup: sum.getup + cur.getup,
            absence: sum.absence + cur.absence,
            "bed-rail": sum["bed-rail"] + cur["bed-rail"],
        }),
        {
            fall: 0,
            getup: 0,
            absence: 0,
            "bed-rail": 0,
        }
    );

    const data: Array<{ name: string; value: number; color: string | [string, string] }> = [
        { name: t("alertTypeFall", "Fall"), value: alertData.fall, color: colors.fallDetection },
        {
            name: t("AlertTypeBedRailsMax14Characters", "Bed rails"),
            value: alertData["bed-rail"],
            color: [colors.emergencyPrevention, "url(#bedRails)"] as [string, string],
        },
        {
            name: t("AlertTypeAbsenceMax14Characters", "Absence"),
            value: alertData.absence,
            color: [colors.emergencyPrevention, "url(#absenceDetection)"] as [string, string],
        },
        {
            name: t("AlertTypeFallPreventionMax14Characters", "Fall prev."),
            value: alertData.getup,
            color: [colors.emergencyPrevention, "url(#fallPrevention)"] as [string, string],
        },
    ].filter((d) => d.value > 0);

    return (
        <Card className={c([styles.card])}>
            <CardTitle>
                <CardsTitlesAndTooltips
                    title={t("titleWeeklyAlertCountCard", "Alert counts")}
                    tooltip={t(
                        "tooltipWeeklyAlertsCountsCard",
                        "Shows the distribution of alert types over the last 7 days."
                    )}
                />
            </CardTitle>
            <CardDescription>
                <Trans
                    // t("descriptionWeeklyAlertCountCard", "<FallDetection>Fall detection</FallDetection> and <EmergencyPrevention>emergency prevention</EmergencyPrevention>")
                    i18nKey="descriptionWeeklyAlertCountCard"
                    default="<FallDetection>Fall detection</FallDetection> and <EmergencyPrevention>emergency prevention</EmergencyPrevention>"
                    components={{
                        FallDetection: <DataLabel type="fallDetection" />,
                        EmergencyPrevention: <DataLabel type="emergencyPrevention" />,
                    }}
                />
            </CardDescription>
            <CardContent>
                {data.length !== 0 ? (
                    <SimplePieChart
                        data={data}
                        dataKey="value"
                        labelKey="name"
                        patterns={patterns}
                        colors={data.map((d) => d.color)}
                    />
                ) : (
                    <div className={styles.circle} />
                )}
            </CardContent>
        </Card>
    );
};
