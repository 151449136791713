import React, { useEffect } from "react";
import { InputGroup } from "../../../UI/InputGroup";
import { Label } from "../../../UI/Label";
import { Tooltip } from "../../../UI/Tooltip";
import { useTranslation } from "react-i18next";
import { Combobox } from "../../../UI/Combobox";
import { useSetupContext } from "../SetupContext";
import { useLocationContext } from "../../../../context/LocationContextProvider";
import { Device, useListDevices } from "../../../../api_v2/hooks/useDevices";
import { useListCompatibleDocks } from "../../../../api_v2/hooks/useDockDevice";
import { useGetFacility } from "../../../../api_v2/hooks/useFacilities";
import { AreaRoom, Id } from "../../../../api_v2/types/custom";
import sensorImage from "../../../../images/sensor.png";
import styles from "../../../DeviceSetup/DeviceSetupModal.module.css";
import { sensorDisplayName } from "../../../../utils/sensorDisplayName";

export interface DeviceAndDockSelectProps {
    type: "up" | "move";
}

export const DeviceAndDockSelect = ({ type }: DeviceAndDockSelectProps) => {
    const { t } = useTranslation();
    const { facilityId } = useLocationContext();
    const { sensor: selectedSensor, dockingStation: selectedDockingStation, room, ...context } = useSetupContext();

    const sensorId = selectedSensor?.id ?? null;
    const dockingStationId = selectedDockingStation?.id ?? null;
    const roomId = room?.id ?? null;

    const showCompatibleDocks = context.type !== "down";

    const facilityQuery = useGetFacility(facilityId);
    const rooms = facilityQuery.data?.areas.flatMap((a) => a.rooms) ?? [];

    const sensorsQuery = useListDevices(facilityId);
    const sensors = (sensorsQuery.data ?? [])
        .filter((s) => s.dockSupport === "manual")
        .filter((s) => (type === "up" ? s.dockedTo === undefined : s.dockedTo !== undefined));
    const sensor = sensors.find((s) => s.id === sensorId) ?? null;

    const compatibleDocksQuery = useListCompatibleDocks(sensorId);
    const compatibleDocks = (compatibleDocksQuery.data ?? []).filter((d) => d.id !== sensor?.dockedTo);

    const dockingStation = compatibleDocks.find((s) => s.id === dockingStationId) ?? null;

    const updateContext = (sensorId: string | null, dockingStationId: string | null) => {
        const dockingStationValid = !showCompatibleDocks || dockingStationId !== null;
        const sensorValid = sensorId !== null;

        const sensor = sensors.find((s) => s.id === sensorId);

        const originalDockingStation = compatibleDocks.find((d) => d.id === sensor?.dockedTo);
        if (originalDockingStation) {
            context.setOriginalDockingStation(originalDockingStation);
        }

        context.setIsValid(dockingStationValid && sensorValid);
    };

    useEffect(() => {
        updateContext(sensorId, dockingStationId);
    }, [sensorId, dockingStationId, roomId, showCompatibleDocks]);

    const handleSensorChange = (sensor: Device | null) => {
        context.setSensor(sensor);
        updateContext(sensor?.id ?? null, dockingStationId);
    };

    const handleDockingStationChange = (d: Device) => {
        context.setDockingStation(d);
        context.setRoom(rooms.find((r) => r.id === d.roomId) ?? null);
        updateContext(sensorId, d.id);
    };

    const getRoom = (roomId: Id | undefined): AreaRoom | null => {
        return rooms.find((r) => r.id === roomId) ?? null;
    };

    const sensorsContent = (
        <InputGroup reduced={true}>
            <Label
                text={t("labelSensor", "Sensor ")}
                helpLabel={t("titleSensorSetupSerialNumber", "Where can I find the serial number?")}
                block={true}
                customTooltip={
                    <Tooltip right={"0px"} disableMinWidth={true}>
                        <img src={sensorImage} />
                    </Tooltip>
                }
            >
                <Combobox
                    selected={sensor}
                    onSelect={handleSensorChange}
                    options={sensors}
                    valueGetter={sensorDisplayName}
                    keyGetter={sensorDisplayName}
                    placeholder={t("labelSelectSensor", "Select sensor")}
                    filter={(sensor: Device, query) =>
                        sensor.name?.toLowerCase().includes(query.toLowerCase()) || sensor.id.includes(query)
                    }
                    disabled={context.isAttached}
                />
            </Label>
        </InputGroup>
    );

    let roomsContent = null;

    if (showCompatibleDocks) {
        const getDockingStationName = (dockingStation: Device): string => {
            const room = getRoom(dockingStation.roomId);

            const docksInRoom = compatibleDocks.filter((d) => d.roomId === room?.id);
            const dockIndex = docksInRoom.findIndex((d) => d.id === dockingStation.id);

            const roomName = room?.name ?? room?.id ?? t("labelUnknownRoom", "Unknown room");

            // This is needed to handle an edge case, where we have only one other docking station in the sensors
            // current room. Since the list compatible docks endpoint would only include one dock (the other one)
            // in the room though, it would be displayed by room name only, which is confusing.
            // We imply that the currently used dock is dock #1, therefore the index + 2.
            if (dockingStation.roomId === sensor?.roomId) {
                return t("labelDeviceSetupSelectedDock", "Dock {{dock}} in {{room}}", {
                    dock: dockIndex + 2,
                    room: roomName,
                }).toString();
            }

            return docksInRoom.length > 1
                ? t("labelDeviceSetupSelectedDock", "Dock {{dock}} in {{room}}", {
                      dock: dockIndex + 1,
                      room: roomName,
                  }).toString()
                : roomName;
        };

        const getDockingStationKey = (dockingStation: Device) => {
            return `${dockingStation.id}`;
        };

        roomsContent = (
            <InputGroup reduced={true}>
                <Label text={t("dockingStation", "Dockingstation")} block={true}>
                    <Combobox
                        selected={dockingStation}
                        onSelect={handleDockingStationChange}
                        options={compatibleDocks}
                        valueGetter={getDockingStationName}
                        keyGetter={getDockingStationKey}
                        placeholder={t("labelSelectDockingStation", "Select dockingstation")}
                        filter={(dockingStation: Device, query) =>
                            getDockingStationName(dockingStation).toLowerCase().includes(query.toLowerCase())
                        }
                        disabled={context.isAttached}
                    />
                </Label>
            </InputGroup>
        );
    }

    return (
        <>
            <strong className={styles.contentTitle}>{t("labelSensorAssignment", "Assign sensor")}</strong>

            <p className={styles.info}>
                {t(
                    "sensorAssignmentDescription",
                    "If you want to use one of your sensors in a different room, please select the serial number and the room to which the sensor will be moved."
                )}
            </p>

            {sensorsContent}

            {roomsContent}
        </>
    );
};
