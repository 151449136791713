import React, { useEffect, useState } from "react";
import styles from "./EventModal.module.scss";
import { BiPlay, BiPause } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { Event } from "../../../api_v2/hooks/useEvents";
import { useListEventVisualizations } from "../../../api_v2/hooks/useEventVisualizations";
import { EventImage, EventImagePlaceholder } from "../EventImage";
import { c } from "../../../utils/ClassesHelper";

interface EventModalImageGalleryProps {
    event: Event;
}

export const EventModalImageGallery = ({ event }: EventModalImageGalleryProps) => {
    const { t } = useTranslation();

    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
    const [isPlaying, setIsPlaying] = useState(false);

    const imagesQuery = useListEventVisualizations(event.id);
    const images = (imagesQuery.data ?? []).sort((a, b) => a.id - b.id);

    useEffect(() => {
        if (isPlaying) {
            const handle = window.setInterval(setNextImage, 1500);
            return () => window.clearInterval(handle);
        }
    }, [isPlaying, images]);

    const hasExtraImages = images.length > 1;

    const setNextImage = () => {
        setSelectedImageIndex((selectedImageIndex) => (selectedImageIndex + 1) % images.length);
    };

    const setPlay = () => {
        setIsPlaying(true);
    };

    const setPause = () => {
        setIsPlaying(false);
    };

    const handleSelectImage = (index: number) => {
        setSelectedImageIndex(index);
        setPause();
    };

    const selectedImage = images[selectedImageIndex];

    return (
        <div className={styles.galleryContainer}>
            <div className={styles.imageContainer}>
                <div className={styles.imageWrapper}>
                    {selectedImage ? (
                        <>
                            <EventImage
                                eventId={event.id}
                                visualizationId={selectedImage.id}
                                className={styles.image}
                            />
                            <div
                                className={c([styles.playBar], {
                                    [styles.visible]: isPlaying,
                                })}
                            />
                        </>
                    ) : (
                        <EventImagePlaceholder type="error" className={styles.image} />
                    )}
                    {hasExtraImages ? (
                        <div className={styles.playPauseContainer}>
                            {!isPlaying ? (
                                <button autoFocus onClick={setPlay}>
                                    <BiPlay /> {t("labelPlay", "Play")}
                                </button>
                            ) : (
                                <button onClick={setPause}>
                                    <BiPause /> {t("labelPause", "Pause")}
                                </button>
                            )}
                        </div>
                    ) : null}
                </div>
            </div>
            {hasExtraImages ? (
                <div className={styles.extraImages}>
                    {images.map((image, index) => {
                        const isSelected = index === selectedImageIndex;

                        return (
                            <button
                                key={image.id}
                                className={c(styles.extraButton, {
                                    [styles.selected]: isSelected,
                                })}
                                onClick={() => handleSelectImage(index)}
                            >
                                <EventImage
                                    eventId={event.id}
                                    visualizationId={image.id}
                                    className={styles.extraImage}
                                />
                            </button>
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
};
