import React from "react";
import { EventModal } from "./EventModal";
import { AreaRoom, Id } from "../../../api_v2/types/custom";
import { Event } from "../../../api_v2/hooks/useEvents";

export interface EventModalNavigationProps {
    rooms: Array<AreaRoom>;
    events: Array<Event>;
    selectedEventId: Id | null;
    onEventSelect: (eventId: Id | null, replace?: boolean) => void;
}

export const EventModalNavigation = ({ rooms, events, selectedEventId, onEventSelect }: EventModalNavigationProps) => {
    const selectedEvent = events.find((e) => e.id === selectedEventId);
    const selectedRoom = rooms.find((r) => r.id === selectedEvent?.roomId);

    const handleClose = () => {
        onEventSelect(null);
    };
    const handleNext = () => {
        const index = events.findIndex((e) => e.id === selectedEventId);
        const nextIndex = index + 1;
        if (nextIndex < events.length) {
            onEventSelect(events[nextIndex].id, true);
        }
    };
    const handlePrev = () => {
        const index = events.findIndex((e) => e.id === selectedEventId);
        const prevIndex = index - 1;
        if (prevIndex >= 0) {
            onEventSelect(events[prevIndex].id, true);
        }
    };

    if (!selectedEvent || !selectedRoom) return null;

    return (
        <EventModal
            event={selectedEvent}
            room={selectedRoom}
            onClose={handleClose}
            onButtonNext={handleNext}
            onButtonPrev={handlePrev}
        />
    );
};
