import { ActivityHeatMap } from "../../../../api_v2/hooks/useHeatMap";
import { Id } from "../../../../api_v2/types/custom";
import { ActivityMonitoringMode } from "../ActivityMonitoring";
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    LabelList,
    ReferenceLine,
    Label,
    ResponsiveContainer,
    CartesianGrid,
    Tooltip,
    Customized,
} from "recharts";
import { CustomLabelOverBar } from "./CustomLabelOverBar";
import { useTranslation } from "react-i18next";
import { ActivityIdListItem, useTransformHeatmaps } from "./useTransformHeatmaps";
import { DateRange } from "../../common/DateRange";
import { getTrendLine } from "./Statistics/getTrendLine";
import { CustomLabelInsideBar } from "./CustomLabelInsideBar";
import { GraphStatisticsLegend } from "./GraphStatisticsLegend";
import { colors } from "../../../../utils/colors";
import { CustomTooltipForNoData } from "./CustomTooltipForNoData";
import { CustomDateLineBelowChart } from "./CustomDateLineBelowChart";
import styles from "./ActivityGraph.module.css";

interface ActivityGraphProps {
    isSmallScreen: boolean;
    lastWeekLineActive?: boolean;
    heatMapsOneWeekBefore?: ActivityHeatMap[];
    heatMaps: ActivityHeatMap[];
    heatMapsWithSequences?: Array<Id>;
    activeHeatMap?: ActivityHeatMap | undefined;
    onHeatMapChange: (heatMap: ActivityHeatMap) => void;
    dateRange: DateRange;
    mode: ActivityMonitoringMode;
    showStatistics?: boolean;
    colorsForBars: string[];
    dateLineFormat: string;
}

interface TickProps {
    payload: {
        value: string;
        index: number;
    };
}

export const ActivityGraph = (props: ActivityGraphProps) => {
    const { t } = useTranslation();

    const dataForBarsEdited: Array<ActivityIdListItem> = useTransformHeatmaps(
        props.heatMaps,
        props.dateRange,
        props.mode,
        props.colorsForBars,
        props.heatMapsOneWeekBefore,
        props.activeHeatMap,
        props.heatMapsWithSequences,
        props.showStatistics
    );

    const activeDataItem = dataForBarsEdited.find((i) => i.id === props.activeHeatMap?.id);
    const activeDataItemIndex = activeDataItem ? dataForBarsEdited.indexOf(activeDataItem) : null;

    // statistics for graph
    // using heatmaps not dataForAllBarsEdited, so that for the calculation of the average N is the actual days
    // where there is data recorded, not the days in the date range
    const averageLevelForAllDataPoints =
        props.heatMaps.reduce((acc, curr) => acc + curr.level, 0) / props.heatMaps.length;

    const trendLineData = props.mode === "month" && props.showStatistics ? getTrendLine(dataForBarsEdited) : null;

    const handleBarMouseDown = (e: ActivityIdListItem) => {
        if (e.id !== "") {
            props.onHeatMapChange(e);
        }
    };

    const handleCustomLabelOverBarClick = (heatMapId: Id) => {
        if (props.heatMaps) {
            const heatMapChangedWithCustomLabelOverBarClick = props.heatMaps.find((item) => item.id === heatMapId);
            if (heatMapChangedWithCustomLabelOverBarClick) {
                props.onHeatMapChange(heatMapChangedWithCustomLabelOverBarClick);
            }
        }
    };

    const trendColor =
        trendLineData?.trendProperty === "up"
            ? colors.darkGreen
            : trendLineData?.trendProperty === "down"
            ? colors.orange
            : colors.darkgrey;

    const handleTicks = (tick: TickProps) => {
        if (activeDataItem && tick.payload.index === activeDataItemIndex) {
            return (
                <text
                    {...tick}
                    style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                    }}
                >
                    {activeDataItem?.startTimeForLabel}
                </text>
            );
        } else {
            return <text {...tick}>{tick.payload.value}</text>;
        }
    };

    return (
        <div className={styles.gridContainerGraph}>
            <div className={styles.BoxAroundGraph}>
                <ResponsiveContainer width="100%" height={240}>
                    <ComposedChart
                        data={dataForBarsEdited}
                        barCategoryGap="20%"
                        margin={{
                            left: -30,
                            right: 10,
                            top: 10,
                            bottom: 60,
                        }}
                    >
                        <CartesianGrid stroke={colors.lightGrey} vertical={false} />
                        <XAxis
                            dataKey="startTimeForLabel"
                            interval="preserveStartEnd"
                            axisLine={false}
                            tickLine={true}
                            dy={10}
                            fontSize={13}
                            tick={(tick) => {
                                return handleTicks(tick);
                            }}
                        />
                        <YAxis
                            domain={[0, 100]}
                            tick={(tick) => {
                                delete tick.tickformatter;
                                delete tick.visibleTicksCount;
                                delete tick.verticalanchor;
                                if (tick.payload.value === 0) {
                                    // "low" and "high" should be hardcoded and not be translated for now,
                                    // as in other languages the word is too long and does not fit in the graph
                                    return <text {...tick}>{"low"}</text>;
                                } else if (tick.payload.value === 100) {
                                    return <text {...tick}>{"high"}</text>;
                                }
                                return <></>;
                            }}
                            axisLine={false}
                            fontSize={12}
                            tickLine={false}
                        >
                            <Label
                                value={t("labelBedActivityMonitor", "Activity")}
                                angle={270}
                                dx={10}
                                dy={-7}
                                fill={colors.secondary}
                            />
                        </YAxis>
                        <Tooltip
                            isAnimationActive={false}
                            cursor={false}
                            content={<CustomTooltipForNoData />}
                            position={{ y: 130 }}
                        />
                        <Bar
                            dataKey="level"
                            fill={"fill"}
                            onMouseDown={handleBarMouseDown}
                            isAnimationActive={false}
                            style={{ cursor: "pointer" }}
                            stackId="stack"
                        >
                            <LabelList
                                position="top"
                                dataKey="hasSequences"
                                content={
                                    <CustomLabelOverBar
                                        isAnimationActive={false}
                                        handleCustomLabelOverBarClick={handleCustomLabelOverBarClick}
                                    />
                                }
                            />
                            <LabelList position="top" dataKey="dayIsOutlier" content={<CustomLabelInsideBar />} />
                        </Bar>
                        {/* adds a very thin bar to show that the sensor was on (!= no data)*/}
                        <Bar
                            dataKey="baseBarLevel"
                            fillOpacity={1}
                            onMouseDown={handleBarMouseDown}
                            isAnimationActive={false}
                            style={{ cursor: "pointer" }}
                            stackId="stack"
                        />
                        {/* adds a transparent bar, whenever the level is very low (<7), in order to make it easier to click on the bar*/}
                        <Bar
                            dataKey="transparentBarLevel"
                            fillOpacity={0}
                            onMouseDown={handleBarMouseDown}
                            isAnimationActive={false}
                            style={{ cursor: "pointer" }}
                            stackId="stack"
                        />
                        <Line dataKey="averageLevel" />
                        {props.lastWeekLineActive ? (
                            <Line
                                type="monotone"
                                dataKey="levelAWeekBefore"
                                stroke="#444544"
                                opacity={0.2}
                                strokeWidth={4}
                                dot={false}
                                activeDot={false}
                                isAnimationActive={false}
                            />
                        ) : null}
                        {props.mode === "month" && trendLineData ? (
                            <Line
                                type="linear"
                                data={trendLineData.trendDataPoints}
                                dataKey="level"
                                stroke={trendColor}
                                strokeWidth={2}
                                dot={false}
                                isAnimationActive={false}
                            />
                        ) : null}
                        {props.showStatistics ? (
                            <ReferenceLine
                                y={averageLevelForAllDataPoints}
                                stroke={colors.secondary}
                                strokeDasharray="3 3"
                            />
                        ) : null}
                        {/* @ts-expect-error */}
                        <Customized component={<CustomDateLineBelowChart dateFormat={props.dateLineFormat} />} />
                    </ComposedChart>
                </ResponsiveContainer>
                {props.mode === "month" ? (
                    <GraphStatisticsLegend trendLineProperty={trendLineData?.trendProperty} trendColor={trendColor} />
                ) : null}
            </div>
        </div>
    );
};
