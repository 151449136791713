import { useTranslation } from "react-i18next";
import { useCallback } from "react";

export const useLocaleAwareCompare = (options?: Intl.CollatorOptions) => {
    const { i18n } = useTranslation();
    const locale = i18n.language;

    return useCallback(
        (a: string, b: string) =>
            a.localeCompare(b, locale, {
                numeric: true,
                ...options,
            }),
        [locale, options]
    );
};

// Only return keys that have a string value
type StringKeys<T> = {
    [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

export const useLocaleAwareSort = (
    options?: Intl.CollatorOptions
): (<T extends Record<StringKeys<T>, string>>(
    key: StringKeys<T>,
    direction?: "asc" | "desc"
) => (a: T, b: T) => number) => {
    const localeAwareCompare = useLocaleAwareCompare(options);

    return <T extends Record<StringKeys<T>, string>>(key: StringKeys<T>, direction = "asc") =>
        (a: T, b: T) => {
            return localeAwareCompare(a[key], b[key]) * (direction === "desc" ? -1 : 1);
        };
};
