import React from "react";
import { ButtonGroup } from "../../UI/Button/ButtonGroup";
import { Button } from "../../UI/Button/Button";
import { ModalHeader } from "../../Modal/Modal";
import styles from "./EventModal.module.scss";

export interface AlertModalHeaderProps {
    onButtonPrev?: () => void;
    onButtonNext?: () => void;
    onClose: () => void;
}

export const EventModalHeader = (props: AlertModalHeaderProps) => {
    return (
        <ModalHeader className={styles.header}>
            <ButtonGroup className={styles.navigationButtons}>
                {props.onButtonPrev ? (
                    <Button
                        id="EventModalHeader_PrevEvent_Button"
                        borderless={true}
                        icon="left"
                        onClick={() => props.onButtonPrev?.()}
                    />
                ) : null}
                {props.onButtonNext ? (
                    <Button
                        id="EventModalHeader_NextEvent_Button"
                        borderless={true}
                        icon="right"
                        onClick={() => props.onButtonNext?.()}
                    />
                ) : null}
            </ButtonGroup>
            <Button
                id="EventModalHeader_Close_Button"
                borderless={true}
                icon="close"
                className={styles.closeButton}
                onClick={props.onClose}
            />
        </ModalHeader>
    );
};
