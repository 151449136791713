import React, { ReactNode } from "react";
import styles from "./Loading.module.css";
import logo from "../../images/logo_180.png";

type LoadingProps = {
    text?: string;
    small?: boolean;
};

class Loading extends React.Component<LoadingProps> {
    render(): ReactNode {
        const hasText = "text" in this.props;

        return (
            <div
                className={[
                    styles.container,
                    hasText ? null : styles.large,
                    this.props.small === true ? styles.small : null,
                ].join(" ")}
            >
                <img src={logo} width={180} height={180} className={styles.image} />
                <span className={styles.text}>{this.props.text}</span>
            </div>
        );
    }
}

export { Loading };
