import React from "react";
import { FacilityArea, Id } from "../../api_v2/types/custom";
import { useTranslation } from "react-i18next";
import { TabList } from "../UI/TabList/TabList";

export interface AreaSelectorProps {
    areas: Array<FacilityArea>;
    selected: Id | null;
    onSelect: (areaId: Id | null) => void;
}

export const AreaSelector = ({ areas, selected, onSelect }: AreaSelectorProps) => {
    const { t } = useTranslation();

    const getKey = (area: FacilityArea): string => area.id;
    const getLabel = (area: FacilityArea): string => area.name;

    const selectedArea = areas.find((a) => a.id === selected) ?? null;
    const handleSelect = (area: FacilityArea | null) => onSelect(area ? area.id : null);

    return (
        <TabList<FacilityArea>
            nullable={true}
            items={areas}
            selected={selectedArea}
            onSelect={handleSelect}
            getKey={getKey}
            getLabel={getLabel}
            nullItemLabel={t("titleAllLocations", "All areas")}
        />
    );
};
