import { ActivityHeatMap } from "../../../../api_v2/hooks/useHeatMap";
import { Id } from "../../../../api_v2/types/custom";
import { useState } from "react";
import { ActivityMonitoringProps } from "../ActivityMonitoring";
import { DailyBedMobility } from "./DailyBedMobility";
import { MonthlyBedActivity } from "./MonthlyBedMobility";
import { addHours, startOfDay } from "date-fns";
import { useUserSpecificLocalStorage } from "../../../../hooks/useUserSpecificLocalStorage";
import { colors } from "../../../../utils/colors";

export interface BedMobilityProps extends ActivityMonitoringProps {
    selectedBedId: Id | null;
    showStatistics: boolean;
    onshowStatistics: (showStatistics: boolean) => void;
}

export const BedMobility = (props: BedMobilityProps) => {
    const [lastWeekLineActive, setLastWeekLineActive] = useUserSpecificLocalStorage<boolean>(
        "lastWeekLineActive",
        false
    );
    const [selectedHeatMapId, setSelectedHeatMapId] = useState<Id | null>(null);
    const [selectedSequenceId, setSelectedSequenceId] = useState<Id | null>(null);

    const colorsForBars = colors.bedMobilityBars;

    const handleChangeHeatmap = (heatMap: ActivityHeatMap | Date) => {
        const isHeatMap = "id" in heatMap;
        setSelectedSequenceId(null);
        setSelectedHeatMapId(isHeatMap ? heatMap.id : null);
        props.onModeChange("day");
        if (props.mode === "month") {
            props.onDateRangeChange({
                start: startOfDay(isHeatMap ? new Date(heatMap.startTime) : heatMap),
                end: addHours(startOfDay(isHeatMap ? new Date(heatMap.startTime) : heatMap), 24),
            });
        }
    };

    const handleSequenceSelect = (sequenceId: Id) => {
        setSelectedSequenceId((currentId) => (currentId === sequenceId ? null : sequenceId));
    };

    return (
        <>
            {props.mode === "month" ? (
                <MonthlyBedActivity
                    {...props}
                    selectedHeatMapId={selectedHeatMapId}
                    onSelectedHeatMapId={setSelectedHeatMapId}
                    onChangeHeatmap={handleChangeHeatmap}
                    showStatistics={props.showStatistics}
                    onshowStatistics={props.onshowStatistics}
                    colorsForBars={colorsForBars}
                />
            ) : (
                <DailyBedMobility
                    {...props}
                    selectedHeatMapId={selectedHeatMapId}
                    selectedSequenceId={selectedSequenceId}
                    onSelectedHeatMapId={setSelectedHeatMapId}
                    onSelectedSequenceId={setSelectedSequenceId}
                    onChangeHeatmap={handleChangeHeatmap}
                    onSequenceSelect={handleSequenceSelect}
                    lastWeekLineActive={lastWeekLineActive}
                    onLastWeekLineActive={setLastWeekLineActive}
                    colorsForBars={colorsForBars}
                />
            )}
        </>
    );
};
