import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSetupContext } from "../SetupContext";

import sensorInstallImage from "../../../../images/setup/SensorInstall.png";
import { Navigate } from "react-router-dom";
import styles from "./SensorAttachAndAwaitStatusStep.module.css";
import { sensorDisplayName } from "../../../../utils/sensorDisplayName";

const SensorMountingInfo = () => {
    const context = useSetupContext();
    const { t } = useTranslation();
    const {
        sensor,
        room: targetRoom,
        dockingStation: targetDockingStation,
        originalDockingStation: sourceDockingStation,
        originalRoom: sourceRoom,
    } = context;

    const [attached, setAttached] = useState<boolean | null>(null);

    // start attaching on enter
    useEffect(() => {
        (async () => {
            const result = await context.attach();
            setAttached(result);
            context.setIsValid(true);
        })();
    }, []);

    if (!sensor || !targetRoom || !targetDockingStation) {
        return <Navigate to="./select-sensor" replace />;
    }

    const content =
        sourceDockingStation && sourceRoom ? (
            <p>
                <Trans
                    i18nKey="stepSensorMountInstructions"
                    values={{
                        sensor: sensorDisplayName(sensor),
                        sourceDockingStation: sourceDockingStation.name,
                        sourceRoom: sourceRoom.name,
                        targetDockingStation: targetDockingStation.name,
                        targetRoom: targetRoom.name,
                    }}
                >
                    Now, please remove sensor <strong>{{ sensor }}</strong> from the dockingstation{" "}
                    {{ sourceDockingStation }} in {{ sourceRoom }} and place it in the dockingstation{" "}
                    <strong>{{ targetDockingStation }}</strong> in <strong>{{ targetRoom }}</strong>!
                </Trans>
            </p>
        ) : (
            <p>
                <Trans
                    i18nKey="stepSensorMountInstructionsShort"
                    values={{
                        sensor: sensorDisplayName(sensor),
                        targetDockingStation: targetDockingStation.name,
                        targetRoom: targetRoom.name,
                    }}
                >
                    Now, please place sensor <strong>{{ sensor }}</strong> in the dockingstation{" "}
                    <strong>{{ targetDockingStation }}</strong> in <strong>{{ targetRoom }}</strong>!
                </Trans>
            </p>
        );

    if (attached === false) {
        return (
            <div className={styles.statusContainer}>
                <div className={styles.fail}>
                    {t("errorAttachSensor", "An error occurred while assigning the sensor. Please try again later!")}
                </div>
            </div>
        );
    }

    return (
        <>
            {content}
            <img style={{ width: "100%", height: "auto" }} src={sensorInstallImage} width={800} height={600} />
            <p>
                {t(
                    "stepSensorMountInstructionsReceiverReminder",
                    "Do not forget to move the sensor with its paired nurse call receiver."
                )}
            </p>
            <p>
                {sourceDockingStation
                    ? t(
                          "stepSensorMoveInstructionsContinue",
                          "If you have already moved the sensor, please continue with the next step."
                      )
                    : t(
                          "stepSensorMountInstructionsContinue",
                          "If you have already placed the sensor in the dockingstation, please continue with the next step."
                      )}
            </p>
        </>
    );
};

export default SensorMountingInfo;
