import React from "react";
import { useSetupContext } from "../SetupContext";
import { Navigate } from "react-router-dom";
import { SceneLabelSelector } from "../../../SceneLabelSelector/SceneLabelSelector";

export const PointsSelectionStep = () => {
    const { sensor, config } = useSetupContext();

    if (!sensor || !config) {
        return <Navigate to="./select-sensor" replace />;
    }

    return <SceneLabelSelector device={sensor} readonly={false} />;
};
