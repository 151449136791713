import { useFormat } from "../hooks/useFormat";

interface DateTickFormatterProps {
    timeLabel: number;
    ticks: number[];
}

export const useDateTickFormatter = () => {
    const format = useFormat();

    return ({ timeLabel, ticks }: DateTickFormatterProps) => {
        const index = ticks.indexOf(timeLabel);
        return format(new Date(timeLabel), index === 0 || index === ticks.length - 1 ? "P-no-year" : "EEEEEE");
    };
};
