import React, { ReactNode } from "react";

import styles from "./SensitivitySlider.module.css";

type SliderProps = {
    value: number;
    onChange?: (value: number) => void;
    onChangeDone?: (value: number) => void;
    disabled?: boolean;
    min: number;
    max: number;
    step?: number;
    spaceForLabel?: boolean;
    label?: (value: number) => string;
    readonly?: boolean;
    id?: string;
};

class Slider extends React.Component<SliderProps> {
    private mouseHandlers = true;
    private moveEventHandler: ((e: MouseEvent | TouchEvent) => void) | undefined;
    private endEventHandler: ((e: MouseEvent | TouchEvent) => void) | undefined;

    private track: React.RefObject<HTMLDivElement>;
    private initialMouseX: number;
    private trackRect: DOMRect;

    private currentValue: number = this.props.value;

    constructor(props: SliderProps) {
        super(props);

        this.initialMouseX = 0;
        this.track = React.createRef();
        this.trackRect = new DOMRect(0, 0, 0, 0);
    }

    componentDidMount(): void {
        this.setInternalValue(this.props.value);
    }

    componentDidUpdate(prevProps: Readonly<SliderProps>): void {
        if (prevProps.value !== this.props.value) {
            this.setInternalValue(this.props.value);
        }
    }

    componentWillUnmount(): void {
        this.unregisterEvents();
    }

    render(): ReactNode {
        const pos = `${((this.currentValue - this.props.min) / (this.props.max - this.props.min)) * 100}%`;
        const thumbStyle = { left: pos };

        const classNames = [
            styles.track,
            this.props.disabled ? styles.disabled : undefined,
            this.props.spaceForLabel ? styles.extraMargin : undefined,
            this.props.readonly ? styles.readonly : undefined,
        ];

        if (this.props.readonly) {
            return this.getLabel();
        }

        return (
            <div className={classNames.join(" ")} ref={this.track}>
                <div className={styles.thumbContainer}>
                    <button
                        className={styles.thumb}
                        style={thumbStyle}
                        disabled={this.props.disabled}
                        onMouseDown={(e) => this.handleStart(e.nativeEvent)}
                        onTouchStart={(e) => this.handleStart(e.nativeEvent)}
                        onKeyDown={(e) => this.handleKeyDown(e.nativeEvent)}
                        id={this.props.id}
                    />
                </div>
                <div className={styles.label}>{this.getLabel()}</div>
            </div>
        );
    }

    private getLabel() {
        if (this.props.label) return this.props.label(this.currentValue);
        return this.currentValue;
    }

    private setInternalValue(value: number, done = false) {
        this.currentValue = value;

        this.forceUpdate();

        if (this.props.onChange) this.props.onChange(value);

        if (done && this.props.onChangeDone) this.props.onChangeDone(value);
    }

    private registerEvents() {
        if (!this.moveEventHandler) {
            this.moveEventHandler = (e: MouseEvent | TouchEvent) => this.handleMove(e);
            document.body.addEventListener(this.mouseHandlers ? "mousemove" : "touchmove", this.moveEventHandler, {
                passive: false,
            });
        }

        if (!this.endEventHandler) {
            this.endEventHandler = () => this.handleEnd();
            document.body.addEventListener(this.mouseHandlers ? "mouseup" : "touchend", this.endEventHandler);
        }
    }

    private unregisterEvents() {
        if (this.moveEventHandler) {
            document.body.removeEventListener(this.mouseHandlers ? "mousemove" : "touchmove", this.moveEventHandler);
            this.moveEventHandler = undefined;
        }

        if (this.endEventHandler) {
            document.body.removeEventListener(this.mouseHandlers ? "mouseup" : "touchend", this.endEventHandler);
            this.endEventHandler = undefined;
        }
    }

    private handleStart(e: MouseEvent | TouchEvent) {
        if (this.props.disabled) return;

        const rect = this.track.current?.getBoundingClientRect();

        if (rect) {
            this.mouseHandlers = "clientX" in e;
            const x = this.mouseHandlers ? (e as MouseEvent).clientX : (e as TouchEvent).touches[0].clientX;

            this.registerEvents();

            this.trackRect = rect;
            this.initialMouseX = x;
        }
    }

    private handleEnd() {
        this.unregisterEvents();
        this.setInternalValue(this.currentValue, true);
    }

    private handleMove(e: MouseEvent | TouchEvent) {
        if (e.cancelable) e.preventDefault();

        const x = this.mouseHandlers ? (e as MouseEvent).clientX : (e as TouchEvent).touches[0].clientX;

        const percentage = Math.max(Math.min(x - this.trackRect.x, this.trackRect.width), 0) / this.trackRect.width;
        const value = this.props.min + Math.round((this.props.max - this.props.min) * percentage);

        if (this.currentValue !== value) {
            this.setInternalValue(value);
        }
    }

    private handleKeyDown(e: KeyboardEvent) {
        let newValue = null;

        if (e.key == "ArrowLeft") {
            newValue = Math.max(this.currentValue - this.getStep(), this.props.min);
        } else if (e.key == "ArrowRight") {
            newValue = Math.min(this.currentValue + this.getStep(), this.props.max);
        }

        if (newValue !== null) {
            this.setInternalValue(newValue, true);
        }
    }

    private getStep() {
        return this.props.step || 1;
    }
}

export { Slider };
