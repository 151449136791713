import React, { useState } from "react";
import { Button } from "../../../UI/Button/Button";
import { useTranslation } from "react-i18next";
import { useSetupContext } from "../SetupContext";
import { Navigate } from "react-router-dom";
import styles from "../../../DeviceSetup/DeviceSetupModal.module.css";
import { SceneVisualization } from "../../../SceneVisualization/SceneVisualization";

export const SensorPositionStep = () => {
    const { t } = useTranslation();
    const [sceneImageKey, setSceneImageKey] = useState(1); // workaround to force reload image
    const context = useSetupContext();
    const sensor = context.sensor;

    if (!sensor) {
        return <Navigate to="./select-sensor" replace />;
    }

    return (
        <>
            <div className={styles.contentTitleContainer}>
                <strong className={styles.contentTitle}>{t("labelSensorPosition", "Align sensor")}</strong>
                <Button
                    borderless={true}
                    className={styles.contentTitleButton}
                    onClick={() => setSceneImageKey(sceneImageKey + 1)}
                >
                    {t("actionRefreshSceneImage", "Check sensor orientation")}
                </Button>
            </div>
            <SceneVisualization key={sceneImageKey} deviceId={sensor.id} className={styles.sceneImage} />
            <p className={styles.info}>{t("sensorSetupPosition", "Please check the sensor alignment and make sure that the bed and floor area adjacent to the bed are in the field of view. Adjust the sensor alignment if necessary.")}</p>
        </>
    );
};
