import { ActivityHeatMap } from "../../../api_v2/hooks/useHeatMap";
import { subHours } from "date-fns";

export interface DayAndNightSeparatedActivityData extends ActivityHeatMap {
    day: number | null;
    night: number | null;
    timestamp: number;
}

export const isDaytime = (datapoint: ActivityHeatMap | Date): boolean => {
    const endTime = datapoint instanceof Date ? datapoint : new Date(datapoint.endTime);
    const hours = endTime.getHours();
    return hours >= 7 && hours < 19;
};

// Define the isDaytime function
export const isNightTime = (datapoint: ActivityHeatMap): boolean => {
    const endTime = new Date(datapoint.endTime);
    const hours = endTime.getHours();
    return hours >= 19 || hours < 7;
};

export const getNormalizedDayAndNightSeparatedActivityData = (
    activityData: Array<ActivityHeatMap>,
    endTime: Date,
    length: number,
    hoursPerStep: number
): Array<DayAndNightSeparatedActivityData> =>
    Array.from({ length }, (_, index): DayAndNightSeparatedActivityData => {
        const stepStartTime = subHours(endTime, index * hoursPerStep);
        const stepEndTime = subHours(endTime, (index - 1) * hoursPerStep);

        const dp = activityData.find((dp) => {
            return new Date(dp.startTime) >= stepStartTime && new Date(dp.startTime) < stepEndTime;
        });

        const debugMultiplier = 1;

        const isDayTime = isDaytime(stepEndTime);

        return {
            id: `empty-dp-${index}`,
            ...dp,
            level: dp ? dp.level * debugMultiplier : 0,
            day: isDayTime ? (dp?.level ?? 0) * debugMultiplier : null,
            night: !isDayTime ? (dp?.level ?? 0) * debugMultiplier : null,
            startTime: stepStartTime.toString(),
            endTime: stepEndTime.toString(),
            timestamp: stepStartTime.getTime(),
        };
    });

export const getTransitionEntries = (
    data: DayAndNightSeparatedActivityData[],
    includeDay: boolean,
    includeNight: boolean
) => {
    let lastDataIsDaytime: boolean | null = null;

    return data.map((activityData) => {
        const isDayTime = isDaytime(activityData);

        if (lastDataIsDaytime !== null) {
            if (lastDataIsDaytime != isDayTime) {
                lastDataIsDaytime = isDayTime;
                return {
                    ...activityData,
                    day: includeDay ? activityData.day ?? activityData.night : null,
                    night: includeNight ? activityData.night ?? activityData.day : null,
                };
            }
        } else {
            lastDataIsDaytime = isDayTime;
        }

        return {
            ...activityData,
            day: includeDay ? activityData.day : null,
            night: includeNight ? activityData.night : null,
        };
    });
};

const gaussianKernel = (size: number, sigma: number) => {
    const kernel = [];
    const center = (size - 1) / 2;
    let sum = 0;

    for (let i = 0; i < size; i++) {
        kernel[i] = Math.exp(-0.5 * Math.pow((i - center) / sigma, 2)) / (sigma * Math.sqrt(2 * Math.PI));
        sum += kernel[i];
    }

    // Normalize the kernel to make sure its sum is 1
    return kernel.map((value) => value / sum);
};

export const applyGaussianFilter = (data: DayAndNightSeparatedActivityData[], windowSize: number, sigma: number) => {
    const kernel = gaussianKernel(windowSize, sigma);

    return data.map((datapoint, index, arr) => {
        let smoothedLevelSum = 0;
        let weightSum = 0;

        for (let i = 0; i < windowSize; i++) {
            const offset = i - Math.floor(windowSize / 2);
            const dataIndex = index + offset;

            if (dataIndex >= 0 && dataIndex < arr.length) {
                smoothedLevelSum += arr[dataIndex].level * kernel[i];
                weightSum += kernel[i];
            }
        }

        const smoothedLevel = weightSum > 0 ? smoothedLevelSum / weightSum : datapoint.level;

        return {
            ...datapoint,
            level: smoothedLevel,
            day: datapoint.day !== null ? smoothedLevel : null,
            night: datapoint.night !== null ? smoothedLevel : null,
        };
    });
};

export const applyGaussianFilterOnlyInDayAndNight = (
    data: DayAndNightSeparatedActivityData[],
    windowSize: number,
    sigma: number
) => {
    const kernel = gaussianKernel(windowSize, sigma);

    return data.map((datapoint, index, arr) => {
        if (isDaytime(datapoint)) {
            let smoothedLevelSum = 0;
            let weightSum = 0;

            for (let i = 0; i < windowSize; i++) {
                const offset = i - Math.floor(windowSize / 2);
                const dataIndex = index + offset;

                if (dataIndex >= 0 && dataIndex < arr.length && isDaytime(arr[dataIndex])) {
                    smoothedLevelSum += arr[dataIndex].level * kernel[i];
                    weightSum += kernel[i];
                }
            }

            const smoothedLevel = weightSum > 0 ? smoothedLevelSum / weightSum : datapoint.level;

            return {
                ...datapoint,
                level: smoothedLevel,
                day: datapoint.day !== null ? smoothedLevel : null,
                night: datapoint.night !== null ? smoothedLevel : null,
            };
        } else if (isNightTime(datapoint)) {
            let smoothedLevelSum = 0;
            let weightSum = 0;

            for (let i = 0; i < windowSize; i++) {
                const offset = i - Math.floor(windowSize / 2);
                const dataIndex = index + offset;

                if (dataIndex >= 0 && dataIndex < arr.length && isNightTime(arr[dataIndex])) {
                    smoothedLevelSum += arr[dataIndex].level * kernel[i];
                    weightSum += kernel[i];
                }
            }

            const smoothedLevel = weightSum > 0 ? smoothedLevelSum / weightSum : datapoint.level;

            return {
                ...datapoint,
                level: smoothedLevel,
                day: datapoint.day !== null ? smoothedLevel : null,
                night: datapoint.night !== null ? smoothedLevel : null,
            };
        } else {
            return datapoint;
        }
    });
};
