import React from "react";
import { Card } from "../../components/UI/Card/Card";
import { useTranslation } from "react-i18next";
import { AuthViewProps } from "./AuthView";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useSendMFACode } from "../cognito/useSendMFACode";
import { EnableMfaForm, TotpFormInputs } from "../forms/EnableMfaForm";
import styles from "../Auth.module.css";

export interface TOTPSetupProps extends AuthViewProps {
    email: string;
    user: CognitoUser;
    secret?: string;
}

export const TOTPSetup = ({ user, secret, email, refreshSession }: TOTPSetupProps) => {
    const { t } = useTranslation();

    const sendMfaCode = useSendMFACode();

    const onSubmit = (data: TotpFormInputs) => {
        sendMfaCode.mutate(
            { user, code: data.token },
            {
                onSuccess: () => {
                    refreshSession();
                },
            }
        );
    };

    return (
        <Card className={styles.card}>
            <strong className={styles.title}>{t("mfa.titleSendTotp", "Two-Factor authentication")}</strong>

            {sendMfaCode.isError ? <p className={styles.error}>{t(sendMfaCode.error)}</p> : null}

            <EnableMfaForm defaultDeviceName={email} totpUsername={email} totpSecret={secret} onSubmit={onSubmit} />
        </Card>
    );
};
