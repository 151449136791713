import React, { useEffect } from "react";
import { StepsWrapper } from "../../StepsWrapper";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { DeviceAndDockSelect } from "../common/DeviceAndDockSelect";
import { useSetupContext } from "../SetupContext";
import { useTranslation } from "react-i18next";
import SensorMountingInfo from "../common/SensorMountingInfo";
import RestartAndAwaitSensorStatusStep from "../common/RestartAndAwaitSensorStatusStep";
import { SensorPositionStep } from "../common/SensorPositionStep";
import { PointsSelectionStep } from "../common/PointsSelectionStep";
import { PresenceKeyStep } from "../common/PresenceKeyStep";
import { TestAlarmStep } from "../common/TestAlarmStep";
import { FinishStep } from "../common/FinishStep";
import { IsSavedGuard } from "../helper/IsSavedGuard";

export interface MoveScenarioProps {
    baseRoute: string;
    type: "up" | "move";
}

export const MoveScenario = ({ baseRoute, type }: MoveScenarioProps) => {
    const { t } = useTranslation();
    const context = useSetupContext();
    const navigate = useNavigate();
    const location = useLocation();

    const routes = [
        {
            title: t("labelSelectSensorAndRoom", "Select sensor and room"),
            path: "select-sensor",
            element: (
                <IsSavedGuard>
                    <DeviceAndDockSelect type={type} />
                </IsSavedGuard>
            ),
        },
        {
            title: context.type == "up" ? t("labelMountSensor", "Set up sensor") : t("labelMoveSensor", "Move sensor"),
            path: "mounting-info",
            element: (
                <IsSavedGuard>
                    <SensorMountingInfo />
                </IsSavedGuard>
            ),
        },
        {
            title: t("labelStatusCheck", "Status check"),
            path: "status-check",
            element: (
                <IsSavedGuard>
                    <RestartAndAwaitSensorStatusStep />
                </IsSavedGuard>
            ),
        },
        {
            title: t("labelSensorPosition", "Align sensor"),
            path: "sensor-position",
            element: (
                <IsSavedGuard>
                    <SensorPositionStep />
                </IsSavedGuard>
            ),
        },
        {
            title: t("labelPointsSelector", "Set bed point"),
            path: "points-select",
            element: (
                <IsSavedGuard>
                    <PointsSelectionStep />
                </IsSavedGuard>
            ),
        },
        {
            title: t("labelMiscPresenceKey", "Nurse presence button"),
            path: "presence-key",
            element: (
                <IsSavedGuard>
                    <PresenceKeyStep />
                </IsSavedGuard>
            ),
        },
        {
            title: t("labelTestAlarmStep", "Send test alarm"),
            path: "alarm-test",
            element: (
                <IsSavedGuard>
                    <TestAlarmStep />
                </IsSavedGuard>
            ),
        },
        {
            title: t("labelDone", "Done"),
            path: "save",
            element: <FinishStep />,
        },
    ];

    const matchedRoute = routes.find((r) => location.pathname.startsWith(`${baseRoute}${r.path.replace("/*", "")}`));
    const matchedRouteIndex = matchedRoute ? routes.indexOf(matchedRoute) ?? 0 : 0;

    useEffect(() => {
        context.setSteps(routes.length);
        context.setStep(matchedRouteIndex);

        const prev = matchedRouteIndex > 0 ? routes[matchedRouteIndex - 1] : null;
        context.setGoPrev(prev ? () => navigate(prev?.path) : null);

        const next = matchedRouteIndex < routes.length - 1 ? routes[matchedRouteIndex + 1] : null;
        context.setGoNext(next ? () => navigate(next?.path) : null);
    }, [location]);

    if (context.type !== "up" && context.type !== "move") {
        return <Navigate to=".." replace />;
    }

    return (
        <Routes>
            {routes.map((r, index) => (
                <Route
                    key={r.path}
                    path={`/${r.path}`}
                    element={
                        <StepsWrapper steps={routes} currentStep={index}>
                            {r.element}
                        </StepsWrapper>
                    }
                />
            ))}
            <Route path="*" element={<Navigate to="./select-sensor" replace />} />
        </Routes>
    );
};
