import React from "react";
import { c } from "../../../utils/ClassesHelper";
import { Button } from "../../UI/Button/Button";
import { AugmentedSceneLabel } from "../AugmentedSceneLabel";
import { getAugmentedLabelsForId } from "../AugmentedSceneLabelHelper";
import styles from "../Legend.module.css";
import { Id, Settings } from "../../../api_v2/types/custom";
import { BiError as WarningIcon } from "react-icons/bi";
import { Tooltip } from "../../Tooltip/Tooltip";
import { useTranslation } from "react-i18next";

export interface LegendSectionProps {
    sceneLabels: Array<AugmentedSceneLabel>;
    allSceneLabels: Array<AugmentedSceneLabel>;
    onAugmentedSceneLabelChange: (label: AugmentedSceneLabel, newlyCreated?: boolean) => void;
    getFill: (s: AugmentedSceneLabel, selected: boolean) => string;
    onDelete: (s: AugmentedSceneLabel) => void;
    onAdd: () => void;
    maxLabels: number;
    readonly?: boolean;
    disabled?: boolean; // basically like readonly, but disabled buttons instead of hiding
    style: string;
    title: string;
    addLabel: string;
    getLabelFor: (index: number, total: number) => string;
    id?: string;
    selectedSceneLabelId?: string;
    onSelect?: (sceneLabelId: string) => void;
    settings?: Settings;
}

export const LegendSection = ({
    sceneLabels,
    allSceneLabels,
    onAugmentedSceneLabelChange,
    readonly,
    disabled,
    style,
    onDelete,
    onAdd,
    maxLabels,
    getFill,
    title,
    getLabelFor,
    addLabel,
    id,
    selectedSceneLabelId,
    onSelect,
    settings,
}: LegendSectionProps) => {
    const { t } = useTranslation();

    const handleMouseEnter = (s: AugmentedSceneLabel) => {
        getAugmentedLabelsForId(allSceneLabels, s.sceneLabel.id).forEach((s) => {
            onAugmentedSceneLabelChange({
                ...s,
                isFocused: true,
            });
        });
    };

    const handleMouseLeave = (s: AugmentedSceneLabel) => {
        getAugmentedLabelsForId(allSceneLabels, s.sceneLabel.id).forEach((s) => {
            onAugmentedSceneLabelChange({
                ...s,
                isFocused: false,
            });
        });
    };

    const handleClick = (s: AugmentedSceneLabel) => {
        onSelect?.(s.sceneLabel.id);
    };

    const showNoSettingsWarning = (sceneLabelId: Id): boolean => {
        return (
            settings !== undefined &&
            ![
                ...(settings.bedRails?.map((r) => r.id) ?? []),
                ...(settings.getupDetectionLocations?.map((l) => l.id) ?? []),
                ...(settings.absenceDetectionLocations?.map((l) => l.id) ?? []),
                ...(settings.bedRestlessnessLocations?.map((l) => l.id) ?? []),
            ].includes(sceneLabelId)
        );
    };

    return (
        <>
            <strong className={styles.title}>{title}</strong>
            <ul className={c([styles.list, style])} id={id}>
                {sceneLabels.map((s, i) => (
                    <li
                        className={c([styles.item], {
                            [styles.isValid]: s.isValid,
                            [styles.invalid]: !s.isValid,
                            [styles.loading]: s.isLoading,
                            [styles.focused]: s.isFocused || s.sceneLabel.id === selectedSceneLabelId,
                            [styles.newAndUnsaved]: false,
                        })}
                        key={s.id}
                        style={{ ["--color" as never]: getFill(s, s.sceneLabel.id === selectedSceneLabelId) }}
                        onMouseEnter={() => handleMouseEnter(s)}
                        onMouseLeave={() => handleMouseLeave(s)}
                        onClick={() => handleClick(s)}
                        id={`${id}_${i}`}
                    >
                        <span>{getLabelFor(i, sceneLabels.length)}</span>

                        {showNoSettingsWarning(s.sceneLabel.id) ? (
                            <span className={styles.noSettingsWarning}>
                                <Tooltip
                                    panel={
                                        <>
                                            {t(
                                                "warningInactivity.SceneLabelInactiveDueToMissingSettings",
                                                "This bed setup is inactive due to missing settings."
                                            )}
                                        </>
                                    }
                                >
                                    <WarningIcon />
                                </Tooltip>
                            </span>
                        ) : null}

                        {readonly !== true ? (
                            <Button
                                borderless={true}
                                icon="delete"
                                className={styles.removeButton}
                                disabled={disabled || s.isLoading}
                                onClick={() => onDelete(s)}
                                id={`${id}_${i}_delete`}
                            />
                        ) : null}
                    </li>
                ))}
            </ul>
            {readonly !== true ? (
                <Button
                    id={id ? `${id}_AddButton` : undefined}
                    className={styles.addButton}
                    onClick={onAdd}
                    disabled={disabled || sceneLabels.length >= maxLabels}
                >
                    {addLabel}
                </Button>
            ) : null}
        </>
    );
};
