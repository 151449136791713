import { Modal, ModalContent, ModalProps } from "../Modal/Modal";
import { DebugDevices } from "./DebugDevices";

export const DebugModal = (props: ModalProps) => {
    return (
        <Modal {...props}>
            <ModalContent>
                <DebugDevices />
            </ModalContent>
        </Modal>
    );
};
