import "react-app-polyfill/ie11"; // has to be the very first line!
import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthContextProvider } from "./auth/AuthContextProvider";
import { App } from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { AxiosConfigContextProvider } from "./context/AxiosConfigContextProvider";
import { UserContextProvider } from "./context/UserContext";
import { LocationContextProvider } from "./context/LocationContextProvider";
import "./i18n";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import "./config/reactQueryLogger";
import { DemoDataContextProvider } from "./context/DemoDataContext";

const queryClient = new QueryClient();

const container = document.getElementById("root");

if (container) {
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <DemoDataContextProvider>
                    <Router>
                        <AuthContextProvider>
                            <AxiosConfigContextProvider>
                                <UserContextProvider>
                                    <LocationContextProvider>
                                        <App />
                                    </LocationContextProvider>
                                </UserContextProvider>
                            </AxiosConfigContextProvider>
                        </AuthContextProvider>
                    </Router>
                </DemoDataContextProvider>
            </QueryClientProvider>
        </React.StrictMode>
    );
}
