import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMe } from "../../api_v2/hooks/useNurses";
import { SideBarToggleButton } from "../../components/SideBarToggleButton";
import { FacilityItem } from "./FacilityItem";
import { Id } from "../../api_v2/types/custom";
import { useLocationContext } from "../../context/LocationContextProvider";
import styles from "./Sidebar.module.css";
import logo from "../../images/cogvisAI_darkblue_RGB_V1.png";

export interface SidebarProps {
    open: boolean;
    setOpen: (state: boolean) => void;
}

export const Sidebar = ({ open, setOpen }: SidebarProps) => {
    const { t } = useTranslation();
    const locationContext = useLocationContext();

    const [searchTerm, setSearchTerm] = useState("");

    const userQuery = useMe();
    const facilities = userQuery.isSuccess ? userQuery.data.facilities : [];

    const classNames = [styles.sideBar];
    const overlayClassNames = [styles.overlay];

    if (open) {
        classNames.push(styles.open);
        overlayClassNames.push(styles.visible);
    }

    const handeClose = () => {
        setSearchTerm("");
        setOpen(false);
    };

    const handleSelect = (facilityId: Id, areaId: Id | null) => {
        locationContext.setFacilityId(facilityId);
        locationContext.setAreaId(areaId);
        handeClose();
    };

    return (
        <>
            <div className={classNames.join(" ")}>
                <div className={styles.header}>
                    <img className={styles.logo} src={logo} alt="" width={116} height={30} />
                    <SideBarToggleButton id="Sidebar_ToggleSidebar" isMenuOpen={open} setIsMenuOpen={setOpen} />
                </div>

                <input
                    id="SidebarSearchInput"
                    className={styles.search}
                    value={searchTerm}
                    placeholder={t("labelSidebarSearch", "Search locations...")}
                    onChange={(event) => setSearchTerm(event.target.value)}
                />

                <ul className={styles.facilityList}>
                    {facilities.length > 0 ? (
                        facilities.map((f) => (
                            <FacilityItem
                                key={f.id}
                                facility={f}
                                searchTerm={searchTerm.trim().toLowerCase()}
                                onSelect={handleSelect}
                            />
                        ))
                    ) : (
                        <li>{t("labelSidebarSearchNoResult", "No results")}</li>
                    )}
                </ul>
            </div>

            <div className={overlayClassNames.join(" ")} onClick={handeClose} />
        </>
    );
};
