import React from "react";
import { DeviceStatusType } from "../../api_v2/hooks/useDevices";
import { c } from "../../utils/ClassesHelper";
import styles from "./DeviceStatus.module.css";
import { useDeviceStatusLabel } from "./useDeviceStatusLabel";

export interface DeviceStatusProps {
    status?: DeviceStatusType;
    pill?: boolean;
}

const statusClassNames: Record<DeviceStatusType, string> = {
    healthy: styles.statusOk,
    paused: styles.statusPaused,
    unhealthy: styles.statusUnhealthy,
    "shut-down": styles.statusShutdown,
    offline: styles.statusOffline,
    unknown: styles.statusUnspecified,
};

export const DeviceStatus = ({ status, pill }: DeviceStatusProps) => {
    status = status ?? "unknown";

    const classNames = [styles.status, statusClassNames[status ?? "unknown"]];

    if (pill) {
        classNames.push(styles.pill);
    }

    const label = useDeviceStatusLabel(status);

    return <span className={c(classNames)}>{label}</span>;
};
