import { ActivityIdListItem } from "./useTransformHeatmaps";
import { colors } from "../../../../utils/colors";
import { format } from "date-fns";

interface CustomDateLineBelowChartProps {
    dateFormat: string;
    data: ActivityIdListItem[];
    width: number;
    orderedTooltipTicks: Array<{ coordinate: number }>;
}

export const CustomDateLineBelowChart = (props: CustomDateLineBelowChartProps) => {
    const { data, width, dateFormat } = props;

    let transitionIndex = 0;
    let prevDate: string | null = null;
    let nextDate: string | null = null;

    for (let i = 0; i < data.length - 1; i++) {
        const formattedCurrentDate = format(new Date(data[i].startTime), dateFormat);

        if (prevDate === null) {
            prevDate = formattedCurrentDate;
            continue;
        }

        if (prevDate !== formattedCurrentDate) {
            transitionIndex = i;
            nextDate = formattedCurrentDate;
            break;
        }
    }

    const transitionPosition = props.orderedTooltipTicks[transitionIndex].coordinate;

    const paddingLeft = 30;
    const paddingRight = 10;

    const horizontalLinePos = 200;
    const textYPos = 205;
    const leftTextPos =
        nextDate !== null
            ? (transitionPosition + paddingLeft) / 2
            : (width - paddingLeft - paddingRight) * 0.5 + paddingLeft;
    const rightTextPos = (transitionPosition + width - paddingRight) / 2;
    const textWidth = 50;

    const boxXPadding = 10;
    const boxYPadding = 15;

    return (
        <svg width={width} height={1000}>
            <rect
                x={paddingLeft - boxXPadding}
                y={horizontalLinePos - boxYPadding}
                width={width - paddingLeft - paddingRight + boxXPadding * 2}
                height={boxYPadding * 2}
                fill="#e5e8ebbf"
                rx={6}
            />
            <g>
                <line
                    key="firstVerticalLine"
                    x1={paddingLeft}
                    y1={horizontalLinePos - 10}
                    x2={paddingLeft}
                    y2={horizontalLinePos + 10}
                    stroke={colors.darkgrey}
                    strokeWidth={1.5}
                />

                <line
                    key="horizontalLineLeft"
                    x1={paddingLeft}
                    y1={horizontalLinePos}
                    x2={Math.max(leftTextPos - textWidth * 0.5, paddingLeft)}
                    y2={horizontalLinePos}
                    stroke={colors.darkgrey}
                    strokeWidth={1.5}
                />

                <text
                    x={leftTextPos}
                    y={textYPos}
                    textAnchor="middle"
                    fill={colors.darkgrey}
                    fontSize={transitionIndex == 1 ? 10 : 13}
                >
                    {prevDate}
                </text>

                <line
                    key="horizontalLineCenter"
                    x1={leftTextPos + textWidth * 0.5}
                    y1={horizontalLinePos}
                    x2={nextDate !== null ? rightTextPos - textWidth * 0.5 : width - paddingRight}
                    y2={horizontalLinePos}
                    stroke={colors.darkgrey}
                    strokeWidth={1.5}
                />

                {nextDate !== null ? (
                    <>
                        <line
                            key="middleVerticalLine"
                            x1={transitionPosition}
                            y1={horizontalLinePos - 10}
                            x2={transitionPosition}
                            y2={horizontalLinePos + 10}
                            stroke={colors.darkgrey}
                            strokeWidth={1.5}
                        />
                        <text
                            x={(transitionPosition + width - paddingRight) / 2}
                            y={textYPos}
                            textAnchor="middle"
                            fill={colors.darkgrey}
                            fontSize={transitionIndex >= data.length - 2 ? 10 : 13}
                        >
                            {nextDate}
                        </text>
                        <line
                            key="horizontalLineRight"
                            x1={Math.min(rightTextPos + textWidth * 0.5, width - paddingRight)}
                            y1={horizontalLinePos}
                            x2={width - paddingRight}
                            y2={horizontalLinePos}
                            stroke={colors.darkgrey}
                            strokeWidth={1.5}
                        />
                    </>
                ) : null}

                <line
                    key="lastVerticalLine"
                    x1={width - paddingRight}
                    y1={horizontalLinePos - 10}
                    x2={width - paddingRight}
                    y2={horizontalLinePos + 10}
                    stroke={colors.darkgrey}
                    strokeWidth={1.5}
                />
            </g>
        </svg>
    );
};
