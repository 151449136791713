import React from "react";
import { Facility, useGetFacility } from "../../api_v2/hooks/useFacilities";
import { AreaItem } from "./AreaItem";

import styles from "./Sidebar.module.css";
import { useLocationContext } from "../../context/LocationContextProvider";
import { Id } from "../../api_v2/types/custom";

export interface FacilityItemProps {
    facility: Facility;
    searchTerm: string;
    onSelect: (facilityId: Id, areaId: Id | null) => void;
}

export const FacilityItem = ({ facility, searchTerm, onSelect }: FacilityItemProps) => {
    const context = useLocationContext();
    const facilityQuery = useGetFacility(facility.id);

    const areas = facilityQuery.isSuccess
        ? facilityQuery.data.areas.filter((area) => {
              return area.name.toLowerCase().includes(searchTerm);
          })
        : [];

    const selected = context.facilityId === facility.id;
    const handleSelect = () => {
        onSelect(facility.id, null);
    };

    if (areas.length === 0 && !facility.name.toLowerCase().includes(searchTerm)) {
        return null;
    }

    const handleAreaSelect = (areaId: Id | null) => {
        onSelect(facility.id, areaId);
    };

    return (
        <li key={facility.id} id={`Facility_${facility.id}`}>
            <button type="button" onClick={handleSelect} key={facility.id} className={selected ? styles.selected : ""}>
                {facility.name}
            </button>
            {areas.length > 0 ? (
                <ul className={styles.buildingList}>
                    {areas.map((area) => (
                        <AreaItem key={area.id} onSelect={handleAreaSelect} area={area} />
                    ))}
                </ul>
            ) : null}
        </li>
    );
};
