import { AreaSelector } from "../../components/Areas/AreaSelector";
import { FacilityArea, Id } from "../../api_v2/types/custom";
import { c } from "../../utils/ClassesHelper";
import { Button } from "../../components/UI/Button/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useHasPermission } from "../../hooks/useHasPermission";
import styles from "./RoomsView.module.css";

export interface FilterProps {
    areas: Array<FacilityArea>;
    selectedAreaId: Id | null;
    onAreaIdSelect: (areaId: Id | null) => void;
    className?: string | undefined;
}

export const Filter = ({ areas, selectedAreaId, onAreaIdSelect, className }: FilterProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const hasPermission = useHasPermission();

    return (
        <div className={c([styles.filterBar, className])}>
            {areas.length > 1 ? (
                <AreaSelector areas={areas} selected={selectedAreaId} onSelect={onAreaIdSelect} />
            ) : (
                <div /> /* empty div to prevent the sensor setup button to grow to full width */
            )}

            {hasPermission("w:devices") ? (
                <Button primary={true} onClick={() => navigate("./setup")}>
                    {t("sensorSetupTitle", "Set up sensor")}
                </Button>
            ) : null}
        </div>
    );
};
