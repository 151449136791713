import { Id } from "../../../api_v2/types/custom";
import { useDailyEventCounts } from "../../../api_v2/hooks/useStatistics";
import { differenceInDays, eachDayOfInterval, isSameDay, startOfDay, subDays } from "date-fns";
import { useMemo } from "react";
import { EventType } from "../../../api_v2/hooks/useEvents";
import { isEmergencyPreventionType } from "../../../utils/EmergencyPreventionType";
import { DateInterval } from "../../../utils/DateInterval";

export type DailyAlertData = {
    name: string;
    emergency: number;
} & Record<EventType, number>;

/**
 * Returns alert data (fall detection and emergency prevention (fall prevention, absence detection and bed rail)
 *
 * @param deviceId
 */
export const useDailyAlertData = (
    deviceId: Id,
    interval: DateInterval,
    numberOfDaysForMonthlyChart?: number
): Array<DailyAlertData> => {
    const numberOfDays = differenceInDays(interval.end, interval.start);
    const eventStatsQuery = useDailyEventCounts(deviceId, { days: numberOfDaysForMonthlyChart ?? numberOfDays });
    const rawData = eventStatsQuery.data ?? [];

    return useMemo(() => {
        const end = startOfDay(interval.end);
        const start = subDays(end, numberOfDays);
        const dates = eachDayOfInterval({ end, start });

        return dates.map((date) => {
            const data = rawData.filter((r) => isSameDay(new Date(r.time), date));

            return data.reduce(
                (previousValue, currentValue) => {
                    const newValue = { ...previousValue };

                    newValue[currentValue.type] += currentValue.count;

                    // Everything that is not fall belongs to emergency prevention
                    if (isEmergencyPreventionType(currentValue.type)) {
                        newValue.emergency += currentValue.count;
                    }

                    return newValue;
                },
                {
                    name: date.toString(),
                    fall: 0,
                    getup: 0,
                    absence: 0,
                    "bed-rail": 0,
                    emergency: 0,
                    "bed-restlessness": 0,
                }
            );
        });
    }, [rawData]);
};
