import React from "react";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "../Modal/Modal";
import { useTranslation } from "react-i18next";
import { Route, Routes, useLocation } from "react-router-dom";
import { ScenarioSelector } from "./Scenarios/ScenarioSelector";
import { MoveScenario } from "./Scenarios/MoveScenario/MoveScenario";
import { SetupContextProvider } from "./Scenarios/SetupContext";
import { DeviceSetupActions } from "./DeviceSetupActions";
import { Subtitle } from "./Scenarios/MoveScenario/Subtitle";
import { usePrompts } from "../Prompts/usePrompts";
import styles from "./DeviceSetupModal.module.css";

type DeviceSetupDialogProps = {
    baseRoute: string;
    onClose: () => void;
    onChange?: () => void;
};

export const DeviceSetupModal = ({ baseRoute, ...props }: DeviceSetupDialogProps) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [prompts, showPrompt] = usePrompts();

    const routes = [
        {
            path: "/up/*",
            element: <MoveScenario type="up" baseRoute={`${baseRoute}/up/`} />,
            title: t("labelScenarioMount", "Set up sensor"),
        },
        {
            path: "/move/*",
            element: <MoveScenario type="move" baseRoute={`${baseRoute}/move/`} />,
            title: t("labelScenarioMove", "Move sensor"),
        },
        { path: "/*", element: <ScenarioSelector />, title: t("labelScenarioSelector", "Sensor setup wizard") },
    ];

    const matchedRoute = routes.find((r) => location.pathname.startsWith(`${baseRoute}${r.path.replace("/*", "")}`));

    const modalTitle = matchedRoute?.title ?? t("sensorSetupTitle", "Set up sensor");

    // we want to make sure the user really wants to leave as soon as the user in one of the scenarios
    const dirty = matchedRoute?.path !== "/*";

    const handleClose = async (force = false) => {
        let close = true;

        if (!force && dirty) {
            close = (await showPrompt({
                title: t("titleSensorSetupCloseWarning", "Do you really want to close the setup?"),
                message: t("textSensorSetupCloseWarning", "If you close the setup, all unsaved changes will get lost!"),
                options: [
                    { label: t("labelSensorSetupCloseWarningNo", "No, back"), primary: false, value: false },
                    { label: t("labelSensorSetupCloseWarningYes", "Yes, close"), primary: true, value: true },
                ],
            })) as boolean;
        }

        if (close) {
            props.onClose();
        }
    };

    return (
        <SetupContextProvider>
            <Modal onClose={() => handleClose(false)} withHeader={true} withFooter={true}>
                <ModalHeader>
                    <h2 className={styles.title}>{modalTitle}</h2>
                    <Subtitle />
                </ModalHeader>
                <ModalContent>
                    <>
                        <Routes>
                            {routes.map((r) => (
                                <Route path={r.path} element={r.element} key={r.path} />
                            ))}
                        </Routes>
                        {prompts}
                    </>
                </ModalContent>
                <ModalFooter>
                    <DeviceSetupActions
                        onClose={() => handleClose(false)}
                        onComplete={() => {
                            props.onChange?.();
                            handleClose(true);
                        }}
                    />
                </ModalFooter>
            </Modal>
        </SetupContextProvider>
    );
};
