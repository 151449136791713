import { components } from "../types/schema";
import { useQuery } from "react-query";
import { useFetch } from "@bitperfect-packages/react-query-axios";
import { Id } from "../types/custom";
import { blobToImageTransformer } from "./common/blobToImageTransformer";

export type EventVisualization = components["schemas"]["VisualizationFrame"];
export type EventVisualizations = Array<EventVisualization>;

export const useListEventVisualizations = (eventId: Id) => {
    return useQuery<EventVisualizations>(
        ["events", eventId, "visualizations"],
        useFetch<EventVisualizations>(`/events/${eventId}/visualizations`)
    );
};

export const useGetEventVisualization = (eventId: Id, visualizationId = 0) => {
    return useQuery<string>(
        ["events", eventId, "visualizations", visualizationId],
        useFetch<string>(`/events/${eventId}/visualizations/${visualizationId}`, {
            config: { responseType: "blob" },
            responseTransform: blobToImageTransformer,
        }),
        {
            retry: 0,
            refetchOnWindowFocus: false,
            staleTime: Infinity,
        }
    );
};
