import { BiCog } from "react-icons/bi";
import { PopoverMenu, PopoverMenuProps } from "../../PopoverMenu/PopoverMenu";
import { c } from "../../../utils/ClassesHelper";
import styles from "./Card.module.css";

export const CardSettings = ({
    children,
    ...props
}: Pick<PopoverMenuProps, "children"> & Omit<Partial<PopoverMenuProps>, "children">) => {
    return (
        <PopoverMenu Icon={BiCog} className={c([styles.popoverMenu, props.className])} {...props}>
            {children}
        </PopoverMenu>
    );
};
