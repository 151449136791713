/**
 * Takes a number of seconds (0 - 86400) and converts it to a time hh:mm time string
 *
 * @param seconds
 */
export const secondsToTime = (seconds: number): string => {
    if (seconds < 0 || seconds > 86400) {
        throw Error("Seconds out of range!");
    }

    return new Date(seconds * 1000).toISOString().substr(11, 5);
};

/**
 * Takes an array of numeric strings (e.g. [hh, mm, ss]), parses them and converts them to seconds
 */
export const timeComponentsToSeconds = (components: Array<string>) => {
    const timeMultipliers = [60 * 60, 60, 1]; // hours, minutes, seconds to seconds

    return components
        .filter((v) => v !== undefined)
        .map((v) => parseInt(v))
        .map((v, i) => v * timeMultipliers[i])
        .reduce((accumulator, value) => accumulator + value, 0);
};

/**
 * Takes a time string in hh:mm:ss, hh:mm or hh format and converts it to seconds (from 00:00:00)
 *
 * @param time
 */
export const timeToSeconds = (time: string): number => {
    const timeComponents = time.split(":");

    if (timeComponents.length > 3 || timeComponents.length < 1) {
        throw Error("Invalid time format");
    }

    return timeComponentsToSeconds(timeComponents);
};

/**
 * Tries parsing an input string. Valid formats are:
 * - hh:mm:ss, hh:mm, hh, h:m:s, h:m, h or any combination
 * - hhmm, hhmmss, hmm, hmmss
 *
 * @param input
 */
export const tryParseTimeStringToSeconds = (input: string): number | false => {
    // hh:mm:ss - 24 hour format with optional leading zeroes and optional minutes and seconds
    const regexDefaultTimeFormat = /^([0-1]?[0-9]|2[0-3])(?::([0-5][0-9])(?::([0-5][0-9]))?)?$/;

    // hhmmss - 24 hour format with optional leading zeroes and optional minutes and seconds, without the colons
    const regexWithoutColons = /^([0-1]?[0-9]|2[0-3])(?:([0-5][0-9])([0-5][0-9])?)?$/;

    const defaultTimeFormatResult = regexDefaultTimeFormat.exec(input);

    if (defaultTimeFormatResult !== null) {
        return timeComponentsToSeconds(defaultTimeFormatResult.slice(1, 4));
    }

    const withoutColonsResult = regexWithoutColons.exec(input);

    if (withoutColonsResult !== null) {
        return timeComponentsToSeconds(withoutColonsResult.slice(1, 4));
    }

    return false;
};
