import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGetBedActivityVisualization, usePreloadActivityFrames } from "../../../../api_v2/hooks/useBedActivities";
import { Id } from "../../../../api_v2/types/custom";
import styles from "../Graph/ActivityGraph.module.css";
import { BiPlay, BiPause } from "react-icons/bi";
import { ActivityImage } from "./ActivityImage";
import { c } from "../../../../utils/ClassesHelper";
import { HeatMapPlaceholder } from "../HeatMap/HeatMapPlaceholder";

export interface ActivityImageGalleryProps {
    activityId: Id;
    previewImageFallbackText: string;
}

export const ActivityImageGallery = ({ activityId, previewImageFallbackText }: ActivityImageGalleryProps) => {
    const { t } = useTranslation();

    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
    const [isPlaying, setIsPlaying] = useState(true);

    const bedActivityVisualizationQuery = useGetBedActivityVisualization(activityId);
    const images = bedActivityVisualizationQuery?.data ?? [];

    const frameIds = images.map((i) => i.id);

    // We do not actually need to do anything with the result
    usePreloadActivityFrames(activityId, frameIds);

    useEffect(() => {
        if (isPlaying) {
            const handle = window.setInterval(setNextImage, 1500);
            return () => window.clearInterval(handle);
        }
    }, [isPlaying, images]);

    const hasExtraImages = images.length > 1;

    const setNextImage = () => {
        setSelectedImageIndex((selectedImageIndex) => {
            const nextIndex = (selectedImageIndex + 1) % images.length;
            if (nextIndex === 0) {
                setIsPlaying(false);
            }
            return nextIndex;
        });
    };

    const setPlay = () => {
        setIsPlaying(true);
    };

    const setPause = () => {
        setIsPlaying(false);
    };

    const selectedImage = images[selectedImageIndex];

    return (
        <div style={{ aspectRatio: "640/480" }}>
            {selectedImage ? (
                <ActivityImage frameId={selectedImage.id} activityId={activityId} images={images} />
            ) : (
                <HeatMapPlaceholder isLoading={true} noDataText={previewImageFallbackText} />
            )}

            {hasExtraImages ? (
                <>
                    <div className={styles.playPauseContainer}>
                        {!isPlaying ? (
                            <button
                                aria-label={t(
                                    "ariaLabelPlayBedRestMobilitySequence",
                                    "Play bed rest mobility sequence"
                                )}
                                aria-pressed={"true"}
                                onClick={setPlay}
                            >
                                <BiPlay /> {t("labelPlay", "Play")}
                            </button>
                        ) : (
                            <button
                                aria-label={t(
                                    "ariaLabelPauseBedRestMobilitySequence",
                                    "Pause bed rest mobility sequence"
                                )}
                                aria-pressed={"false"}
                                onClick={setPause}
                            >
                                <BiPause /> {t("labelPause", "Pause")}
                            </button>
                        )}
                    </div>

                    <ul className={styles.indicator}>
                        {frameIds.map((id, index) => (
                            <li
                                key={id}
                                className={c({
                                    [styles.indicatorDot]: true,
                                    [styles.active]: index === selectedImageIndex,
                                })}
                                onClick={() => setSelectedImageIndex(index)}
                            ></li>
                        ))}
                    </ul>
                </>
            ) : null}
        </div>
    );
};
