import { SceneLabel } from "../../../api_v2/hooks/useSceneLabels";
import { Id } from "../../../api_v2/types/custom";
import { BedSelector } from "./BedActivity/BedSelector";
import { DeviceWithStatus } from "../../../views/RoomsView/helper/Nodes";
import { BedMobility } from "./BedActivity/BedMobility";
import { RoomActivity } from "./RoomActivity/RoomActivity";
import { ButtonGroup } from "../../UI/Button/ButtonGroup";
import { Button } from "../../UI/Button/Button";
import { DateRange } from "../common/DateRange";
import { useHasFeatureFlags } from "../../../hooks/useHasFeatureFlags";
import { useUserSpecificLocalStorage } from "../../../hooks/useUserSpecificLocalStorage";
import { useTranslation } from "react-i18next";
import styles from "./ActivityMonitoring.module.css";

export type ActivityMonitoringMode = "day" | "month";

export interface ActivityMonitoringProps {
    device: DeviceWithStatus;
    dateRange: DateRange;
    onDateRangeChange: (dateRange: DateRange, replace?: boolean) => void;
    mode: ActivityMonitoringMode;
    onModeChange: (mode: ActivityMonitoringMode, replace?: boolean) => void;
    isSmallScreen: boolean;
    selectedBedId: Id | null;
    onSelectedBedIdChange: (id: Id | null) => void;
    bedPoints: SceneLabel[];
}

export const ActivityMonitoring = (props: ActivityMonitoringProps) => {
    const { t } = useTranslation();

    const [showStatistics, setShowStatistics] = useUserSpecificLocalStorage<boolean>("showStatistics", false);
    const checkFeatureFlag = useHasFeatureFlags();

    const bedActivityEnabled = checkFeatureFlag("enable-bed-activity");
    const roomActivityEnabled = checkFeatureFlag("enable-room-activity");

    if (!(props.selectedBedId && bedActivityEnabled) && !roomActivityEnabled)
        return (
            <p>
                {t(
                    "infoMonitoringNoPointsSet",
                    "No bed available, please switch to the bed management tab to set bed points. Bed monitoring can then be activated for the respective bed."
                )}
            </p>
        );

    return (
        <div className={styles.container}>
            <div className={styles.bedOrRoomSelector}>
                <BedSelector
                    items={bedActivityEnabled ? props.bedPoints : []}
                    selected={props.selectedBedId}
                    onSelect={props.onSelectedBedIdChange}
                    nullable={roomActivityEnabled}
                />
            </div>

            <div className={styles.dayMonthSelectorContainer}>
                <ButtonGroup className={styles.dayMonthSelector}>
                    <Button active={props.mode === "day"} onClick={() => props.onModeChange("day")}>
                        {t("day", "day")}
                    </Button>

                    <Button active={props.mode === "month"} onClick={() => props.onModeChange("month")}>
                        {t("month", "month")}
                    </Button>
                </ButtonGroup>
            </div>

            {props.selectedBedId && bedActivityEnabled ? (
                <BedMobility showStatistics={showStatistics} onshowStatistics={setShowStatistics} {...props} />
            ) : roomActivityEnabled ? (
                <RoomActivity showStatistics={showStatistics} onshowStatistics={setShowStatistics} {...props} />
            ) : null}
        </div>
    );
};
