import React from "react";
import { EventType } from "../../api_v2/hooks/useEvents";
import { c } from "../../utils/ClassesHelper";
import { useEventTypeIcon } from "./hooks/useEventTypeIcon";
import { useGetEventTypeLabel } from "./hooks/useGetEventTypeLabel";
import styles from "./EventIcon.module.css";

export interface EventIconProps {
    type: EventType;
    colored?: boolean;
    includeLabel?: boolean;
    autoHeight?: boolean;
}

export const EventIcon = ({ type, colored, includeLabel, autoHeight }: EventIconProps) => {
    const getEventTypeLabel = useGetEventTypeLabel();
    const getEventTypeIcon = useEventTypeIcon();

    const iconClassNames = [styles.icon];
    const labelClassNames = [styles.label];

    const icon = getEventTypeIcon(type, colored);
    const label = getEventTypeLabel(type);

    return (
        <>
            <img className={c(iconClassNames, { [styles.autoHeight]: autoHeight === true })} src={icon} />
            {includeLabel ? <span className={c(labelClassNames)}>{label}</span> : null}
        </>
    );
};
