import { Id } from "../types/custom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useFetch, useMutate } from "@bitperfect-packages/react-query-axios";
import { components } from "../types/schema";
import { Timeout } from "../../utils/Timeout";
import { useInvalidateDeviceStatus } from "./useDevices";
import { UseQueryOptions } from "react-query/types/react/types";

export type SceneLabelVerificationStatus = components["schemas"]["SceneLabelVerificationStatus"];
export type SceneLabelVerificationResult = components["schemas"]["SceneLabelVerificationResult"];

export type SceneLabel = Pick<components["schemas"]["SceneLabel"], "id" | "time"> &
    (
        | {
              type: "bed-point";
              properties: {
                  x: number;
                  y: number;
              };
          }
        | {
              type: "line-2d";
              properties: {
                  x1: number;
                  y1: number;
                  x2: number;
                  y2: number;
                  minLength: number;
                  onFloor: boolean;
              };
          }
    );
export type SceneLabels = Array<SceneLabel>;

export const useInvalidateSceneLabels = () => {
    const queryClient = useQueryClient();

    return (deviceId: Id) => queryClient.invalidateQueries({ queryKey: ["devices", deviceId, "scene-labels"] });
};

export const useListSceneLabels = (deviceId: Id, options?: UseQueryOptions<SceneLabels>) => {
    const url = `/devices/${deviceId}/scene-labels`;
    return useQuery<SceneLabels>(["devices", deviceId, "scene-labels"], useFetch<SceneLabels>(url), options);
};

export interface SetSceneLabelsParams {
    sceneLabelId: Id;
    data: SceneLabel;
}

/**
 * There is no validation endpoint anymore:
 * - if label is invalid, endpoint returns 400
 * - if device is unreachable, endpoint returns 502
 * @param deviceId
 */
export const useSetSceneLabel = (deviceId: Id) => {
    const url = (params: SetSceneLabelsParams) => `/devices/${deviceId}/scene-labels/${params.sceneLabelId}`;
    const invalidateSceneLabels = useInvalidateSceneLabels();

    return useMutation<SceneLabelVerificationStatus, void, SetSceneLabelsParams>(
        ["set", "devices", deviceId, "scene-labels"],
        useMutate<SceneLabelVerificationStatus, SetSceneLabelsParams>("PUT", url, {
            requestConfigTransform: (data, config) => ({
                ...config,
                data: data.data,
            }),
        }),
        {
            retry: 0,
            onSuccess: () => invalidateSceneLabels(deviceId),
        }
    );
};

export interface GetSetSceneLabelStatusParams {
    sceneLabelId: Id;
    verificationId: Id;
}

export const useGetSetSceneLabelStatus = (deviceId: Id) => {
    const url = (params: GetSetSceneLabelStatusParams) =>
        `/devices/${deviceId}/scene-labels/${params.sceneLabelId}/status/${params.verificationId}`;
    return useMutation<SceneLabelVerificationStatus, void, GetSetSceneLabelStatusParams>(
        ["set", "devices", deviceId, "scene-labels", "status"],
        useMutate<SceneLabelVerificationStatus, GetSetSceneLabelStatusParams>("GET", url, {
            requestConfigTransform: (data, config) => ({
                ...config,
                data: {},
            }),
        }),
        {
            retry: 0,
        }
    );
};

export const useSetSceneLabelAndAwaitVerification = (deviceId: Id) => {
    const setSceneLabel = useSetSceneLabel(deviceId);
    const getSceneLabelStatus = useGetSetSceneLabelStatus(deviceId);
    const invalidateSceneLabels = useInvalidateSceneLabels();
    const invalidateDeviceStatus = useInvalidateDeviceStatus();

    return useMutation<[boolean, string], string, SetSceneLabelsParams>(
        ["set and verify", "devices", deviceId, "scene-labels"],
        async (params) => {
            let currentVerificationStatus = await setSceneLabel.mutateAsync(params);

            let i = 0;
            do {
                if (["valid", "invalid", "timed-out"].includes(currentVerificationStatus.status))
                    return [currentVerificationStatus.status === "valid", currentVerificationStatus.status];

                await Timeout(3000);

                currentVerificationStatus = await getSceneLabelStatus.mutateAsync({
                    sceneLabelId: params.sceneLabelId,
                    verificationId: currentVerificationStatus.id,
                });

                i++;
            } while (i < 20);

            return [false, "timed-out-in-front-end"];
        },
        {
            onSuccess: ([valid]) => {
                if (valid) {
                    invalidateSceneLabels(deviceId);
                    invalidateDeviceStatus(deviceId);
                }
            },
        }
    );
};

export interface DeleteSceneLabelsParams {
    sceneLabelId: Id;
}

export const useDeleteSceneLabel = (deviceId: Id) => {
    const url = (params: DeleteSceneLabelsParams) => `/devices/${deviceId}/scene-labels/${params.sceneLabelId}`;
    const invalidateSceneLabels = useInvalidateSceneLabels();
    return useMutation<void, void, DeleteSceneLabelsParams>(
        ["delete", "devices", deviceId, "scene-labels"],
        useMutate<void, DeleteSceneLabelsParams>("delete", url, {
            requestConfigTransform: (data, config) => ({
                ...config,
                data: null,
            }),
        }),
        {
            retry: 0,
            onSuccess: () => invalidateSceneLabels(deviceId),
        }
    );
};
