import { KonvaEventObject } from "konva/lib/Node";

export const setMouseCursorForStage = (e: KonvaEventObject<MouseEvent>, draggable: boolean) => {
    const container = e.target?.getStage()?.container();

    if (!container) {
        return;
    }

    if (e.type === "mouseenter") {
        container.style.cursor = draggable ? "grab" : "not-allowed";
    } else if (e.type === "mouseleave") {
        container.style.cursor = draggable ? "default" : "";
    }
};
