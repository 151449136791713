import React, { useState } from "react";
import { NavBar } from "./layout/NavBar/NavBar";
import { Routes } from "./Routes";
import { Sidebar } from "./layout/Sidebar/Sidebar";
import { Debug } from "./components/Debug/Debug";
import { ToastPortalContainer } from "./components/ToastPortalContainer";
import "react-toastify/dist/ReactToastify.css";

export const App = (): JSX.Element => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <div className="App">
            <NavBar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
            <Routes />
            <Sidebar open={isMenuOpen} setOpen={setIsMenuOpen} />
            <ToastPortalContainer />
            <Debug />
        </div>
    );
};
