import { ActivityIdListItem } from "../useTransformHeatmaps";
import createTrend from "trendline";

interface TrendDataPoint extends ActivityIdListItem {
    timeslot: number;
}

const getTimeSlot = (time: string, firstTime: Date): number => {
    const date = new Date(time);
    const timeDiff = date.getTime() - firstTime.getTime();
    const daysDiff = timeDiff / (24 * 60 * 60 * 1000); // Convert milliseconds to days
    return Math.ceil(daysDiff) + 1; // Start timeslot from 1
};

export const getTrendLine = (dataForBarsEdited: ActivityIdListItem[]) => {
    // Get the minimum date among the start times
    const firstStartTime = new Date(
        Math.min(...dataForBarsEdited.map((activity) => new Date(activity.startTime).getTime()))
    );

    const transformedData: TrendDataPoint[] = dataForBarsEdited.map(
        (activity): TrendDataPoint => ({
            ...activity,
            timeslot: getTimeSlot(activity.startTime, firstStartTime),
        })
    );

    const numericDataForTrend = transformedData.map((data) => ({ level: data.level, timeslot: data.timeslot }));

    const trend = createTrend(numericDataForTrend, "timeslot", "level");

    const trendDataPoints = transformedData.map((trendPoint) => ({
        ...trendPoint,
        level: trend.calcY(trendPoint.timeslot),
    }));

    const trendProperty: "up" | "down" | "flat" =
        trendDataPoints[0].level < trendDataPoints[trendDataPoints.length - 1].level
            ? "up"
            : trendDataPoints[0].level > trendDataPoints[trendDataPoints.length - 1].level
            ? "down"
            : "flat";

    return { trendDataPoints, trendProperty };
};
