import React, { ImgHTMLAttributes } from "react";
import { Id } from "../../api_v2/types/custom";
import { useTranslation } from "react-i18next";
import { images } from "../../config/images";
import { useGetSceneVisualization } from "../../api_v2/hooks/useSceneVisualizations";

export interface SceneVisualizationProps extends ImgHTMLAttributes<HTMLImageElement> {
    deviceId: Id;
}

export const SceneVisualization = ({ deviceId, ...props }: SceneVisualizationProps) => {
    const { t } = useTranslation();
    const imageQuery = useGetSceneVisualization(deviceId);

    const { w, h } = images.resolution.horizontal;

    const fallbackText = imageQuery.isLoading ? t("labelEventImageLoading") : t("labelEventImageNone");
    const fallbackSrc = `https://via.placeholder.com/${w}x${h}.png?text=${encodeURIComponent(fallbackText)}`;
    const src = imageQuery.isSuccess ? imageQuery.data : fallbackSrc;

    return <img {...props} src={src} width={w} height={h} />;
};
