import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Tooltip } from "../../components/Tooltip/Tooltip";
import { Label } from "../../components/UI/Label";
import { Input } from "../../components/UI/Input";
import { Button } from "../../components/UI/Button/Button";
import { useForm } from "react-hook-form";
import { TotpQrCode } from "../helpers/TotpQrCode";
import { writeClipboardText } from "../../utils/WriteToClipboard";
import { toast } from "react-toastify";
import { Toast } from "../../components/UI/Toast/Toast";
import styles from "../Auth.module.css";

export type TotpFormInputs = {
    token: string;
    deviceName: string;
};

export interface EnableMfaFormProps {
    totpSecret?: string;
    totpUsername: string;
    defaultDeviceName: string;
    onSubmit: (data: TotpFormInputs) => void;
}

/**
 * Reusable form to use for either TOTP Setup or TOTP challenge.
 *
 * If a secret is given, meaning TOTP setup, it will display:
 * - Instructions
 * - QR Code
 * - Device Name field
 * - Authentication Code field
 *
 * If no secret is given (meaning TOTP login challenge), it will display:
 * - Instructions
 * - Authentication Code field
 *
 */
export const EnableMfaForm = ({ onSubmit, totpSecret, totpUsername, defaultDeviceName }: EnableMfaFormProps) => {
    const { t } = useTranslation();

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<TotpFormInputs>({
        mode: "onTouched",
        defaultValues: {
            deviceName: t("mfa.defaultDeviceName", "{{name}}'s computer", { name: defaultDeviceName }),
        },
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {totpSecret ? (
                <>
                    <p className={styles.totpInstructions}>
                        <Trans
                            // t("mfa.authenticatorAppDescription", "Use a phone app like <googleLink>Google Authenticator</googleLink>, <microsoftLink>Microsoft Authenticator</microsoftLink>, <authyLink>Authy</authyLink>, etc. and scan the QR-Code below. If you are unable to scan, <token>enter this text code</token> instead.")
                            i18nKey="mfa.authenticatorAppDescription"
                            defaults="Use a phone app like <googleLink>Google Authenticator</googleLink>, <microsoftLink>Microsoft Authenticator</microsoftLink>, <authyLink>Authy</authyLink>, etc. and scan the QR-Code below. If you are unable to scan, <token>enter this text code</token> instead."
                            values={{ token: totpSecret }}
                            components={{
                                googleLink: (
                                    <a
                                        href="https://support.google.com/accounts/answer/1066447"
                                        target="_blank"
                                        rel="noreferrer"
                                    />
                                ),
                                microsoftLink: (
                                    <a
                                        href="https://www.microsoft.com/de-at/security/mobile-authenticator-app"
                                        target="_blank"
                                        rel="noreferrer"
                                    />
                                ),
                                authyLink: <a href="https://authy.com/" target="_blank" rel="noreferrer" />,
                                token: (
                                    <Tooltip
                                        className={styles.totpSecretTrigger}
                                        panel={<div className={styles.totpSecret}>{totpSecret}</div>}
                                        onClick={() => {
                                            writeClipboardText(totpSecret);
                                            toast.info(
                                                <Toast
                                                    title={t("mfa.titleSecretCopiedToast", "Text code copied")}
                                                    description={t(
                                                        "mfa.textSecretCopiedToast",
                                                        "The text code has been copied to your clipboard."
                                                    )}
                                                />
                                            );
                                        }}
                                    />
                                ),
                            }}
                        />
                    </p>

                    <div className={styles.qrcode}>
                        <TotpQrCode secret={totpSecret} username={totpUsername} />
                    </div>

                    <div className={styles.inputGroup}>
                        <Label text={t("mfa.LabelDeviceName", "Device name *")} block={true}>
                            <Input
                                id="DeviceNameInput"
                                invalid={Boolean(errors.deviceName)}
                                {...register("deviceName", {
                                    required: {
                                        value: true,
                                        message: t("errorRequiredField", "Required field"),
                                    },
                                })}
                            />
                            {errors.deviceName ? (
                                <small className={styles.errorText}>{errors.deviceName.message}</small>
                            ) : null}
                        </Label>
                    </div>
                </>
            ) : (
                <p className={styles.totpInstructions}>
                    {t(
                        "mfa.descriptionSendTotp",
                        "Enter the 6-digit code generated by your app to confirm your identity."
                    )}
                </p>
            )}

            <div className={styles.inputGroup}>
                <Label text={t("mfa.LabelTotpToken", "Authentication code *")} block={true}>
                    <Input
                        id="TokenInput"
                        invalid={Boolean(errors.token)}
                        autoComplete="off"
                        {...register("token", {
                            required: {
                                value: true,
                                message: t("errorRequiredField", "Required field"),
                            },
                            pattern: {
                                value: /[0-9]{6,}/,
                                message: t("errorMfaTokenChars", "Must be digits only"),
                            },
                            minLength: {
                                value: 6,
                                message: t("errorMfaTokenLength", "Must be at least 6 digits"),
                            },
                        })}
                    />
                    {errors.token ? <small className={styles.errorText}>{errors.token.message}</small> : null}
                </Label>
            </div>
            <div className={styles.btnContainer}>
                <Button id="EnableMfaButton" color="primary" variant="filled" type="submit">
                    {totpSecret ? t("MFA.EnableBtn", "Enable TFA") : t("MFA.VerifyBtn", "Verify")}
                </Button>
            </div>
        </form>
    );
};
