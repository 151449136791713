import React from "react";
import { InputGroup } from "../../../../UI/InputGroup";
import { Slider } from "../../../../UI/Slider";
import { BedLocationsSettingsProps } from "./BedLocationSettings";
import { useTranslation } from "react-i18next";
import { Label } from "../../../../UI/Label";
import { TimeWindowsList } from "../../../../TimeWindows/TimeWindowsList";
import styles from "../BedMonitoringTab.module.css";
import _ from "lodash";
import { Settings } from "../../../../../api_v2/types/custom";
import { NoSettingsErrorMessage } from "../../Common/NoSettingsErrorMessage";
import { Alert } from "../../../../UI/Alerts/Alert";
import { getDurationRangeAndDefault, getValueForFlag } from "../../../settingsSchemaUtils";
import { addDefaultSettingsForNewSceneLabel } from "../../Common/SceneLabelDefaultSettings";

export const AbsenceSettings = (props: BedLocationsSettingsProps) => {
    const { t } = useTranslation();

    const bedLocationSettings = props.settings.absenceDetectionLocations?.find(
        (l) => l.id === props.selectedBedLocation.id
    );

    if (!bedLocationSettings) {
        return (
            <NoSettingsErrorMessage
                settings={props.settings}
                onSettingsChange={props.onSettingsChange}
                addSettingsHandler={(settings: Settings) =>
                    addDefaultSettingsForNewSceneLabel(
                        settings,
                        props.selectedBedLocation,
                        "bed-monitoring",
                        props.settingsSchema
                    )
                }
                readonly={props.readonly}
            />
        );
    }

    const handleSettingsChange = (
        updatedBedLocationSettings: Exclude<Settings["absenceDetectionLocations"], undefined>[0]
    ) => {
        const newSettings = _.cloneDeep(props.settings);

        if (!Array.isArray(newSettings.absenceDetectionLocations)) {
            newSettings.absenceDetectionLocations = [];
        }

        const index = props.settings.absenceDetectionLocations?.indexOf(bedLocationSettings);
        if (index === undefined || index < 0) {
            throw new Error("Can not change bed location that does not exist");
        }

        newSettings.absenceDetectionLocations[index] = updatedBedLocationSettings;
        props.onSettingsChange(newSettings);
    };

    if (!getValueForFlag("absenceDetectionEnable", props.settings, props.settingsSchema, true)) {
        return <Alert type="info">{t("infoAbsenceDetectionDisabled", "Absence detection is deactivated")}</Alert>;
    }

    const absenceDuration = getDurationRangeAndDefault("AbsenceDetectionLocationsDuration", props.settingsSchema);

    return (
        <>
            <InputGroup>
                <Label text={t("titleBedSettingsDuration", "Absence duration")} additionalClassNames={styles.label}>
                    {
                        <Slider
                            value={bedLocationSettings.duration ?? absenceDuration.default}
                            min={absenceDuration.min}
                            max={absenceDuration.max}
                            onChangeDone={(value) => {
                                const newBedLocationSettings = _.cloneDeep(bedLocationSettings);
                                newBedLocationSettings.duration = value;
                                handleSettingsChange(newBedLocationSettings);
                            }}
                            label={(value) => t("labelDurationSlider", "{{value}} min", { value })}
                            readonly={props.readonly}
                            id="AbsenceDetectionDurationSlider"
                        />
                    }
                </Label>
            </InputGroup>

            <Label text={t("labelFallDetectionSchedule", "Alarm period")} additionalClassNames={styles.block}>
                <TimeWindowsList
                    timeWindows={bedLocationSettings.timeWindows}
                    readonly={props.readonly}
                    onChange={(timeWindows) => {
                        const newBedLocationSettings = _.cloneDeep(bedLocationSettings);
                        newBedLocationSettings.timeWindows = timeWindows;
                        handleSettingsChange(newBedLocationSettings);
                    }}
                    id="AbsenceDetectionTimeWindows"
                />
            </Label>
        </>
    );
};
