import { HTMLProps, PropsWithChildren } from "react";
import styles from "./Card.module.css";
import { c } from "../../../utils/ClassesHelper";

export const CardDescription = ({
    children,
    className,
    ...props
}: PropsWithChildren<HTMLProps<HTMLParagraphElement>>) => {
    return (
        <p className={c([className, styles.description])} {...props}>
            {children}
        </p>
    );
};
