import { useRef } from "react";
import { DeviceSettings } from "../api_v2/hooks/useDeviceSettings";
import _ from "lodash";

export const useDetectChangedSettings = (
    settings: DeviceSettings | undefined,
    onChange?: (reset: () => void) => void
) => {
    const changeDetected = useRef(false);
    const originalSettings = useRef<DeviceSettings | undefined>(settings);

    if (settings !== undefined && originalSettings.current === undefined) {
        originalSettings.current = settings;
    }

    const hasChanged = (currentSettings: DeviceSettings | undefined) => {
        return (
            originalSettings.current !== undefined &&
            !_.isEqual(currentSettings?.settings, originalSettings.current?.settings)
        );
    };

    // if the change has been detected, we do not need to make a compare anymore
    const changed = changeDetected.current || hasChanged(settings);

    const reset = (settings: DeviceSettings | undefined = undefined) => {
        originalSettings.current = settings;
        changeDetected.current = false;
    };

    if (!changeDetected.current && changed) {
        window.setTimeout(() => onChange?.(reset), 0);
        changeDetected.current = true;
    }

    return {
        changed,
        hasChanged,
        reset,
    };
};
