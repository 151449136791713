const calculateMedian = (data: number[]): number => {
    const mid = Math.floor(data.length / 2);
    return data.length % 2 !== 0 ? data[mid] : (data[mid - 1] + data[mid]) / 2;
};

// Function to identify outliers using the IQR method
export const findOutliers = (level: number[]): number[] => {
    const sortedData = [...level].sort((a, b) => a - b);
    const medianIndex = Math.floor(sortedData.length / 2);

    const q1 = calculateMedian(sortedData.slice(0, medianIndex));
    const q3 = calculateMedian(sortedData.slice(medianIndex + 1));
    const iqr = q3 - q1;
    const upperFence = q3 + 1.5 * iqr;
    const lowerFence = q1 - 1.5 * iqr;

    const outliers = sortedData.filter((value) => value < lowerFence || value > upperFence);

    return outliers;
};
