import { useEffect, useMemo, useState } from "react";
import { c } from "../../../../utils/ClassesHelper";
import { CardContent } from "../../../../components/UI/Card/CardContent";
import { BarsAndAreasChart } from "../../Charts/BarsAndAreasChart";
import { Card } from "../../../../components/UI/Card/Card";
import { Id } from "../../../../api_v2/types/custom";
import { useTranslation } from "react-i18next";
import { DateInterval } from "../../../../utils/DateInterval";
import { colors } from "../../../../utils/colors";
import { useNavigate } from "react-router-dom";
import { isObject } from "lodash";
import { useListSceneLabels } from "../../../../api_v2/hooks/useSceneLabels";
import { route } from "../../../../utils/RouteHelper";
import { useMonthlyBedActivityData } from "./useMonthlyBedActivityData";
import { useMonthlyRoomyActivityData } from "./useMonthlyRoomyActivityData";
import { generateTicks } from "./TickHelper";
import { MonthlyActivityCardDescription } from "./MonthlyActivityCardDescription";
import { MonthlyActivityCardSettings } from "./MonthlyActivityCardSettings";
import { MonthlyActivityCardTitle } from "./MonthlyActivityCardTitle";
import { useDateTickFormatter } from "../../../../utils/useDateTickFormatter";
import styles from "../../DashboardView.module.scss";
import cardStyles from "../../Container/CardGrid.module.scss";

export interface MonthlyActivityCardProps {
    deviceId: Id;
    interval: DateInterval;

    enableRoomActivity: boolean;
    enableBedActivity: boolean;
}

const days = 30;

export const MonthlyActivityCard = ({
    deviceId,
    interval,
    enableRoomActivity,
    enableBedActivity,
}: MonthlyActivityCardProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [splitDayNight, setSplitDayNight] = useState(false);
    const [_bedIndex, setBedIndex] = useState(0);
    const [_includeDay, setIncludeDay] = useState<boolean>(true);
    const [_includeNight, setIncludeNight] = useState<boolean>(true);
    const [showRoom, setShowRoom] = useState<boolean>(true);
    const [showBed, setShowBed] = useState<boolean>(true);

    const listSceneLabelsQuery = useListSceneLabels(deviceId);
    const bedPoints = (listSceneLabelsQuery.data ?? []).filter((labels) => labels.type === "bed-point");
    const numBeds = bedPoints.length;
    const bedIndex = bedPoints.length > _bedIndex ? _bedIndex : null;

    const includeDay = !splitDayNight || _includeDay;
    const includeNight = !splitDayNight || _includeNight;

    const bedActivityData = useMonthlyBedActivityData(
        !enableBedActivity || bedIndex === null ? null : bedPoints[bedIndex].id,
        interval,
        days
    );

    const roomActivityData = useMonthlyRoomyActivityData(!enableRoomActivity ? null : deviceId, interval, days);

    // TODO this can be removed, the component should require a key to be set instead!
    useEffect(() => {
        setBedIndex(0);
    }, [deviceId]);

    const data = useMemo(() => {
        return bedActivityData.map((bedActivity, index) => {
            const roomActivity = roomActivityData[index];
            return {
                ...bedActivity,
                bedTotal: includeDay && includeNight && showBed && !splitDayNight ? bedActivity.level : null,
                bedDay: includeDay && showBed ? bedActivity.day : null,
                bedNight: includeNight && showBed ? bedActivity.night : null,
                roomTotal: includeDay && includeNight && showRoom && !splitDayNight ? roomActivity.level : null,
                roomDay: includeDay && showRoom ? roomActivity.day : null,
                roomNight: includeNight && showRoom ? roomActivity.night : null,
            };
        });
    }, [bedActivityData, roomActivityData, showBed, showRoom, includeDay, includeNight, splitDayNight]);

    const bedLabel = numBeds > 1 ? _bedIndex + 1 : undefined;

    const handleClick = (type: "bed" | "room") => (data: unknown) => {
        let date = undefined;
        let mode = "day";
        if (type === "room") {
            mode = "month";
        } else {
            date = isObject(data) && "fullDate" in data ? (data.fullDate as string) : undefined;
        }

        navigate(
            route({
                to: "rooms",
                view: {
                    type: "analysis",
                    deviceId,
                    mode: mode,
                    date: date,
                },
            })
        );
    };

    const ticks = generateTicks(data);
    const formatDateTick = useDateTickFormatter();

    const tickFormatter = (timestamp: number) => {
        return formatDateTick({ timeLabel: timestamp, ticks });
    };

    return (
        <Card className={c([styles.card, cardStyles.md2, cardStyles.lg3, cardStyles.xl4])}>
            <MonthlyActivityCardTitle enableRoomActivity={enableRoomActivity} enableBedActivity={enableBedActivity} />
            <MonthlyActivityCardDescription
                bedLabel={bedLabel}
                splitDayNight={splitDayNight}
                showRoom={showRoom}
                setShowRoom={setShowRoom}
                showBed={showBed}
                setShowBed={setShowBed}
                includeDay={includeDay}
                setIncludeDay={setIncludeDay}
                includeNight={includeNight}
                setIncludeNight={setIncludeNight}
                enableRoomActivity={enableRoomActivity}
                enableBedActivity={enableBedActivity}
            />
            <MonthlyActivityCardSettings
                numBeds={numBeds}
                splitDayNight={splitDayNight}
                setSplitDayNight={setSplitDayNight}
                bedIndex={bedIndex}
                setBedIndex={setBedIndex}
                enableBedActivity={enableBedActivity}
            />
            <CardContent>
                <BarsAndAreasChart
                    data={data}
                    xAxisDataKey="timestamp"
                    yAxisLabel={t("yAxisLabelActivity")}
                    customYTicks={true}
                    rangeMax={100}
                    barDataKey={[]}
                    barColor={colors.bedActivity}
                    areaDataKey={
                        splitDayNight ? ["roomDay", "roomNight", "bedDay", "bedNight"] : ["roomTotal", "bedTotal"]
                    }
                    areaColor={
                        splitDayNight
                            ? [colors.day, colors.night, colors.bedActivityDay, colors.bedActivityNight]
                            : [colors.roomActivityNight, colors.bedActivityNight]
                    }
                    areaColorUseGradient={[true, true, true, true]}
                    areaOnClick={handleClick("room")}
                    barOnClick={handleClick("bed")}
                    showBarLabel={false}
                    customXTicks={ticks}
                    tickFormatter={tickFormatter}
                    xDomain={[ticks[0], data[data.length - 1].timestamp]}
                    xAxisType={"number"}
                />
            </CardContent>
        </Card>
    );
};
