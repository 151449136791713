import styles from "./BedLocationSettings.module.css";
import { InputGroup } from "../../../../UI/InputGroup";
import { Label } from "../../../../UI/Label";
import { ToggleSwitch } from "../../../../UI/ToggleSwitch";
import _ from "lodash";
import React from "react";
import { BedLocationsSettingsProps } from "./BedLocationSettings";
import { useTranslation } from "react-i18next";
import { canAccessSettings, getValueForFlag } from "../../../settingsSchemaUtils";

export const GlobalBedLocationSettings = (props: BedLocationsSettingsProps) => {
    const { t } = useTranslation();

    const canAccessGetUpDetection = canAccessSettings("getupDetection", props.settingsSchema);
    const canAccessAbsenceDetection = canAccessSettings("absenceDetection", props.settingsSchema);

    return (
        <div className={styles.commonContainer}>
            <strong className={styles.title}>{t("labelAllBedPoints", "All bed points")}</strong>

            {canAccessGetUpDetection ? (
                <InputGroup>
                    <Label text={t("getupDetection", "Fall prevention ")}>
                        <ToggleSwitch
                            checkedLabel={t("labelActivated", "Activated")}
                            uncheckedLabel={t("labelDeactivated", "Deactivated")}
                            state={getValueForFlag("getupDetectionEnable", props.settings, props.settingsSchema, true)}
                            onChange={(s) => {
                                const newSettings = _.cloneDeep(props.settings);
                                newSettings.getupDetectionEnable = s;
                                props.onSettingsChange(newSettings);
                            }}
                            disabled={props.readonly}
                            readonly={props.readonly}
                            id="GetupDetectionEnableToggle"
                        />
                    </Label>
                </InputGroup>
            ) : null}

            {canAccessAbsenceDetection ? (
                <InputGroup>
                    <Label text={t("absenceDetection", "Absence detection")}>
                        <ToggleSwitch
                            checkedLabel={t("labelActivated", "Activated")}
                            uncheckedLabel={t("labelDeactivated", "Deactivated")}
                            state={getValueForFlag(
                                "absenceDetectionEnable",
                                props.settings,
                                props.settingsSchema,
                                true
                            )}
                            onChange={(s) => {
                                const newSettings = _.cloneDeep(props.settings);
                                newSettings.absenceDetectionEnable = s;
                                props.onSettingsChange(newSettings);
                            }}
                            disabled={props.readonly}
                            readonly={props.readonly}
                            id="AbsenceDetectionEnableToggle"
                        />
                    </Label>
                </InputGroup>
            ) : null}
        </div>
    );
};
