import {
    Area,
    Bar,
    CartesianGrid,
    Cell,
    ComposedChart,
    Label,
    LabelList,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from "recharts";
import { colors } from "../../../utils/colors";
import { c } from "../../../utils/ClassesHelper";
import styles from "../DashboardView.module.scss";
import { useTranslation } from "react-i18next";

export interface BarsAndAreasChartProps<T extends { [key: string]: unknown }, K extends keyof T & string> {
    data: Array<T>;

    // X-Axis
    xAxisDataKey?: K; // defaults to barDataKey

    // Y-Axis
    yAxisLabel?: string;
    rangeMax?: number;
    customYTicks?: boolean;
    customXTicks?: Array<number>;
    xDomain?: [number, number];
    xAxisType?: "number";

    // Area Chart
    areaDataKey: K | Array<K> | false;
    areaLabelKey?: K;
    areaColor?: string | Array<string> | null;
    areaColorUseGradient?: boolean | Array<boolean>;
    areaOnClick?: (entry: T, index: number) => void;

    // Bar Chart
    barDataKey: K | Array<K> | false;
    showBarLabel?: boolean;
    barColor: string | Array<string>;
    barOnClick?: (entry: T, index: number) => void;

    stackId?: string;
    tickFormatter?: (value: number) => string;

    // Misc
    labelVertical?: boolean;
    yAxisWidth?: number;
}

export const BarsAndAreasChart = <T extends { [key: string]: unknown }, K extends keyof T & string>({
    data,
    areaDataKey,
    areaColor,
    areaColorUseGradient,
    areaOnClick,
    barDataKey,
    barColor,
    barOnClick,
    xAxisDataKey,
    xAxisType,
    yAxisWidth,
    yAxisLabel,
    customYTicks,
    customXTicks,
    xDomain,
    rangeMax,
    stackId,
    showBarLabel,
    tickFormatter,
}: BarsAndAreasChartProps<T, K>) => {
    const { t } = useTranslation();

    const useGradient = (index: number) =>
        areaColorUseGradient && Array.isArray(areaColorUseGradient)
            ? areaColorUseGradient[index]
            : areaColorUseGradient !== false;

    const getColor = (colors: string | Array<string> | null | undefined, index: number): string | undefined => {
        return Array.isArray(colors) ? colors[index] : colors ?? undefined;
    };

    return (
        <ResponsiveContainer className={styles.responsiveChartContainer}>
            <ComposedChart
                data={data}
                margin={{ top: 0, left: 0, right: 0, bottom: 10 }}
                barGap={0}
                barCategoryGap={"25%"}
            >
                <CartesianGrid stroke={colors.lightGrey} vertical={false} />
                <defs>
                    {(Array.isArray(areaDataKey) ? areaDataKey : [areaDataKey]).map((areaDataKey, areaIndex) => {
                        if (!areaColor || !useGradient(areaIndex)) return null;
                        const color = typeof areaColor === "string" ? areaColor : areaColor[areaIndex];
                        const id = `area-bg-${areaDataKey}`;
                        return (
                            <linearGradient key={id} id={id} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="75%" stopColor={color} stopOpacity={1} />
                                <stop offset="100%" stopColor={color} stopOpacity={0.25} />
                            </linearGradient>
                        );
                    })}
                </defs>

                {areaDataKey !== false
                    ? (Array.isArray(areaDataKey) ? areaDataKey : [areaDataKey]).map((areaDataKey, areaIndex) => (
                          <Area
                              key={areaDataKey}
                              type="monotone"
                              dataKey={areaDataKey}
                              isAnimationActive={false}
                              fill={
                                  useGradient(areaIndex)
                                      ? `url(#area-bg-${areaDataKey})`
                                      : getColor(areaColor, areaIndex)
                              }
                              fillOpacity={1}
                              stroke={"none"}
                              // @ts-ignore recharts is incorrectly typed
                              onClick={(entry: T) => areaOnClick?.(entry, areaIndex)}
                              className={c({ [styles.clickable]: areaOnClick !== undefined })}
                          />
                      ))
                    : null}

                {barDataKey !== false
                    ? (Array.isArray(barDataKey) ? barDataKey : [barDataKey]).map((barDataKey, barIndex) => (
                          <Bar key={barDataKey} stackId={stackId} dataKey={barDataKey} isAnimationActive={false}>
                              {data.map((entry, index) => (
                                  <Cell
                                      key={`bar-cell-${index}`}
                                      fill={typeof barColor === "string" ? barColor : barColor[barIndex]}
                                      onClick={() => barOnClick?.(entry, barIndex)}
                                      className={c({ [styles.clickable]: barOnClick !== undefined })}
                                  />
                              ))}
                              {showBarLabel ? (
                                  <LabelList
                                      dataKey={barDataKey}
                                      position="inside"
                                      fill={colors.white}
                                      fontSize="0.75rem"
                                      angle={0}
                                      formatter={(label: number) => (label === 0 ? null : label)}
                                  />
                              ) : null}
                          </Bar>
                      ))
                    : null}
                <XAxis
                    type={xAxisType}
                    dataKey={xAxisDataKey ?? "date"}
                    fontSize={12}
                    axisLine={false}
                    tickLine={true}
                    interval="preserveStartEnd"
                    tickFormatter={tickFormatter}
                    ticks={customXTicks}
                    domain={xDomain}
                >
                    <Label value={t("yAxisLabelDay", "Day")} className={styles.label} dy={15} />
                </XAxis>

                <YAxis
                    tick={
                        !customYTicks
                            ? true
                            : (tick) => {
                                  delete tick.tickformatter;
                                  delete tick.visibleTicksCount;
                                  delete tick.verticalanchor;
                                  if (tick.payload.value === 0) {
                                      // "low" and "high" should be hardcoded and not be translated for now,
                                      // as in other languages the word is too long and does not fit in the graph
                                      return <text {...tick}>{"low"}</text>;
                                  } else if (tick.payload.value === 100) {
                                      return <text {...tick}>{"high"}</text>;
                                  }
                                  return <></>;
                              }
                    }
                    width={yAxisWidth ?? 40}
                    fontSize={12}
                    axisLine={false}
                    tickLine={false}
                    domain={[0, rangeMax ?? ((dataMax: number) => Math.max(4, dataMax))]}
                    allowDecimals={false}
                >
                    <Label
                        value={yAxisLabel ?? "Number"}
                        angle={-90}
                        className={c([styles.label, styles.labelY])}
                        dx={-13}
                    />
                </YAxis>
            </ComposedChart>
        </ResponsiveContainer>
    );
};
