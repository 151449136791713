import { config } from "../config";
import { set } from "date-fns";

export const isDayShift = (now: Date = new Date()) => {
    const dayStartTime = config.nightShift.end.split(":").map((n) => parseInt(n, 10));
    const dayEndTime = config.nightShift.start.split(":").map((n) => parseInt(n, 10));

    const dayStart = set(new Date(now), {
        hours: dayStartTime[0],
        minutes: dayStartTime[1],
        seconds: 0,
        milliseconds: 0,
    });
    const dayEnd = set(new Date(now), {
        hours: dayEndTime[0],
        minutes: dayEndTime[1],
        seconds: 0,
        milliseconds: 0,
    });

    return now >= dayStart && now <= dayEnd;
};

export const isNightShift = (now: Date = new Date()) => {
    return !isDayShift(now);
};
