import React from "react";
import { createPortal } from "react-dom";
import { Slide, ToastContainer } from "react-toastify";

/**
 * The Headless UI Dialog marks the React app root [inert](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inert),
 * so no element inside it is interactable. By rendering the toasts outside the root via portal, we can still interact with toasts,
 * no matter if there are dialogs or other blocking ui elements open.
 */
export const ToastPortalContainer = () => {
    return createPortal(
        <ToastContainer position="bottom-right" transition={Slide} style={{ minWidth: "420px" }} />,
        document.body
    );
};
