import { ActivityHeatMap } from "../../../../api_v2/hooks/useHeatMap";
import { Id } from "../../../../api_v2/types/custom";
import { ColorScale } from "./ColorScale";
import { HeatMapImage } from "./HeatMapImage";
import { Button } from "../../../UI/Button/Button";
import { HeatMapPlaceholder } from "./HeatMapPlaceholder";
import { useTranslation } from "react-i18next";
import styles from "./HeatMap.module.scss";
import { ActivityImageGallery } from "../BedActivity/ActivityImageGallery";
import { c } from "../../../../utils/ClassesHelper";

export interface HeatMapsGalleryProps {
    heatMaps: ActivityHeatMap[];
    activeHeatMapId: Id | null;
    onActiveHeatMapChange: (id: Id) => void;
    selectedSequenceId: Id | null;
    onSelectedSequenceIdChange: (id: Id | null) => void;
    previewImageFallbackText: string;
}

/**
 * Displays HeatMaps and ActivityHeatMaps, with prev/next, close buttons and the heatmap scale
 *
 * @param heatMaps
 * @param activeHeatMapId
 * @param onActiveHeatMapChange
 * @param selectedSequenceId
 * @param onSelectedSequenceIdChange
 * @param previewImageFallbackText
 * @constructor
 */
export const HeatMapsGallery = ({
    heatMaps,
    activeHeatMapId,
    onActiveHeatMapChange,
    selectedSequenceId,
    onSelectedSequenceIdChange,
    previewImageFallbackText,
}: HeatMapsGalleryProps) => {
    const { t } = useTranslation();
    const limitReachedPast = heatMaps[0]?.id === activeHeatMapId;
    const limitReachedFuture = heatMaps[heatMaps.length - 1]?.id === activeHeatMapId;

    const activeHeatMap = heatMaps.find((h) => h.id === activeHeatMapId);

    const handlePastHeatMap = () => {
        const currentIndex = heatMaps.findIndex((h) => h.id === activeHeatMapId);
        if (currentIndex > -1 && !limitReachedPast) {
            onActiveHeatMapChange(heatMaps[currentIndex - 1].id);
        }
    };
    const handleFutureHeatMap = () => {
        const currentIndex = heatMaps.findIndex((h) => h.id === activeHeatMapId);
        if (currentIndex > -1 && !limitReachedFuture) {
            onActiveHeatMapChange(heatMaps[currentIndex + 1].id);
        }
    };

    const handleClose = () => {
        onSelectedSequenceIdChange(null);
    };

    if (!activeHeatMap) {
        return (
            <div className={styles.imageGallery}>
                <HeatMapPlaceholder isLoading={false} noDataText={previewImageFallbackText} />
            </div>
        );
    }

    if (selectedSequenceId) {
        return (
            <div className={styles.imageGallery}>
                <div className={c([styles.imageContainer, styles.sizeGivingContainer])}>
                    <ActivityImageGallery
                        key={selectedSequenceId}
                        activityId={selectedSequenceId}
                        previewImageFallbackText={previewImageFallbackText}
                    />

                    <Button
                        aria-label={t("ariaLabelCloseActivitySequence", "close activity sequence")}
                        onClick={handleClose}
                        className={styles.btnClose}
                        icon="close"
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.imageGallery}>
            <div className={c([styles.scaleContainer, styles.sizeGivingContainer])}>
                <div className={styles.imageContainer}>
                    <HeatMapImage heatMap={activeHeatMap} />

                    <Button
                        aria-label={t("heatMapsGallery.ariaLabel.showPastHeatmap", "show past heatmap")}
                        onClick={handlePastHeatMap}
                        icon="left"
                        className={styles.btnHeatMapArrowBack}
                        disabled={limitReachedPast}
                    />

                    <Button
                        aria-label={t("heatMapsGallery.ariaLabel.showNextHeatmap", "show next heatmap")}
                        onClick={handleFutureHeatMap}
                        icon="right"
                        className={styles.btnHeatMapArrowNext}
                        disabled={limitReachedFuture}
                    />
                </div>
                <div className={styles.scale}>
                    <ColorScale />
                </div>
            </div>
        </div>
    );
};
