import { useMutation } from "react-query";
import { IAuthenticationDetailsData } from "amazon-cognito-identity-js";
import { getCognitoUserFromAuthDetails } from "./helpers";

interface ConfirmPasswordOptions {
    authenticationData: IAuthenticationDetailsData;
    verificationCode: string;
    newPassword: string;
}
export const useConfirmPassword = () => {
    return useMutation<void, string, ConfirmPasswordOptions>("reset-password", (data) => {
        return confirmPassword(data.authenticationData, data.verificationCode, data.newPassword);
    });
};

const confirmPassword = (
    authenticationData: IAuthenticationDetailsData,
    verificationCode: string,
    newPassword: string
) =>
    new Promise<void>((resolve, reject) => {
        const cognitoUser = getCognitoUserFromAuthDetails(authenticationData);
        cognitoUser.confirmPassword(verificationCode, newPassword, {
            onSuccess: () => {
                resolve();
            },
            onFailure: (err) => {
                reject(err.message || JSON.stringify(err));
            },
        });
    });
