import React, { useEffect } from "react";
import { EventModalImageGallery } from "./EventModalImageGallery";
import { EventModalHeader } from "./EventModalHeader";
import { EventModalFooter } from "./EventModalFooter";
import { Event } from "../../../api_v2/hooks/useEvents";
import { AreaRoom } from "../../../api_v2/types/custom";
import { Modal, ModalContent } from "../../Modal/Modal";

interface EventModalProps {
    event: Event;
    room: AreaRoom;
    onClose: () => void;
    onButtonPrev?: () => void;
    onButtonNext?: () => void;
}

export const EventModal = ({ event, room, onClose, onButtonPrev, onButtonNext }: EventModalProps) => {
    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.code === "ArrowRight") {
            onButtonNext?.();
        } else if (e.code === "ArrowLeft") {
            onButtonPrev?.();
        }
    };

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [handleKeyDown]);

    return (
        <Modal onClose={handleClose} size="visualization" withHeader={true} withFooter={true} disableScrollbar={true}>
            <EventModalHeader onButtonNext={onButtonNext} onButtonPrev={onButtonPrev} onClose={handleClose} />
            <ModalContent>
                <EventModalImageGallery key={event.id} event={event} />
            </ModalContent>
            <EventModalFooter event={event} room={room} />
        </Modal>
    );
};
