import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeviceWithStatus } from "../../views/RoomsView/helper/Nodes";
import { Modal, ModalContent } from "../Modal/Modal";
import { DeviceDetailsModalHeader, RoomInfo } from "./DeviceDetailsModalHeader";
import { BedMonitoringTab } from "./Tabs/BedMonitoringTab/BedMonitoringTab";
import { FallDetectionTab } from "./Tabs/FallDetectionTab/FallDetectionTab";
import { MiscTab } from "./Tabs/MiscTab/MiscTab";
import { TabProps } from "./Tabs/TabProps";
import { DeviceDetailsModalFooter } from "./DeviceDetailsModalFooter";
import {
    useDeviceSettings,
    useDeviceSettingsSchema,
    useInvalidateDeviceSettings,
    useSaveDeviceSettings,
} from "../../api_v2/hooks/useDeviceSettings";
import { useListSceneLabels } from "../../api_v2/hooks/useSceneLabels";
import { Loading } from "../UI/Loading";
import { Id, Settings } from "../../api_v2/types/custom";
import { useHasPermission } from "../../hooks/useHasPermission";
import { callAfter } from "../../api_v2/hooks/common/useDelayedInvalidateQueries";
import { Alert } from "../UI/Alerts/Alert";
import { usePollDeviceStatus } from "../../api_v2/hooks/useDevices";
import { minutes, seconds } from "../../utils/IntervalHelper";
import { usePrompts } from "../Prompts/usePrompts";
import { ActivityMonitoringTab } from "./Tabs/ActivityMonitoringTab/ActivityMonitoringTab";
import { useHasFeatureFlags } from "../../hooks/useHasFeatureFlags";
import { useDetectChangedSettings } from "../useDetectChangedSettings";
import { toast } from "react-toastify";
import Ajv from "ajv";
import addFormats from "ajv-formats";
import _ from "lodash";
import { Toast } from "../UI/Toast/Toast";

export interface DeviceDetailsModalProps {
    facilityId: Id;
    device: DeviceWithStatus;
    roomInfo: RoomInfo;
    onClose: () => void;
}

export const DeviceDetailsModal = ({ device, roomInfo, onClose, facilityId }: DeviceDetailsModalProps) => {
    const { t } = useTranslation();
    const hasPermission = useHasPermission();
    const checkFeatureFlag = useHasFeatureFlags();

    const [prompts, showPrompt] = usePrompts();

    const anyActivityModuleInstalled =
        device.analysisModuleStatuses.filter((v) => v.module === "bed-activity" || v.module === "room-activity")
            .length > 0;

    const tabs: Record<string, (props: TabProps) => JSX.Element> = {
        [t("labelFallDetection", "Fall detection")]: (props: TabProps) => <FallDetectionTab {...props} />,
        [t("labelMonitoring", "Bed management")]: (props: TabProps) => <BedMonitoringTab {...props} />,
        ...(checkFeatureFlag(["enable-bed-activity", "enable-room-activity"], "any") &&
            anyActivityModuleInstalled && {
                [t("labelActivityMonitoring", "Activity monitoring")]: (props: TabProps) => (
                    <ActivityMonitoringTab {...props} />
                ),
            }),
        [t("labelMisc", "General")]: (props: TabProps) => <MiscTab {...props} />,
    };

    const tabNames = Array.from(Object.keys(tabs));

    const deviceStatusQuery = usePollDeviceStatus(device.device.id, true, seconds(10));

    // The device and its status, that are given as props, do not update as frequently as the status does in e.g.
    // the label selector component. So we load the device status here separately and more frequently and overwrite the
    // potentially outdated device status from the props.
    if (deviceStatusQuery.isSuccess) {
        device = { ...device, deviceStatus: deviceStatusQuery.data };
    }

    const invalidateSettings = useInvalidateDeviceSettings();

    const settingsSchemaQuery = useDeviceSettingsSchema(device.device.id);
    const settingsQuery = useDeviceSettings(device.device.id, {
        refetchOnWindowFocus: true,
        refetchInterval: minutes(2),
    });

    const sceneLabelsQuery = useListSceneLabels(device.device.id);

    const saveSettingsMutation = useSaveDeviceSettings(facilityId);

    const [selectedTab, setSelectedTab] = useState<string>(tabNames[0]);
    const [isDirty, setIsDirty] = useState(false);
    const [sceneLabelEditModeActive, setSceneLabelEditModeActive] = useState(false);
    const [currentSettings, setCurrentSettings] = useState<Settings | null>(null);

    const { reset: resetSettingsChangedDetector, hasChanged: hasSettingsChanged } = useDetectChangedSettings(
        settingsQuery.data,
        async (reset) => {
            // If the user has already made changes, show the warning and reset the changes
            if (isDirty) {
                await showPrompt({
                    title: t("titleSettingsChangedWarning", "The sensor settings have changed"),
                    message: t("textSettingsChangedWarning", "Please check the settings and adjust them again."),
                    options: [{ label: t("btnSettingsChangedWarning_OK", "OK"), primary: true, value: true }],
                });
                setCurrentSettings(null);
                setIsDirty(false);
            } else if (!_.isEqual(settingsQuery.data?.settings, currentSettings)) {
                // If the user has not yet made changes, just show a more subtle info

                toast.info(
                    <Toast
                        title={t("titleSettingsChangedInfo", "Settings changed")}
                        description={t("textSettingsChangedInfo", "The sensor settings have changed")}
                    />
                );
            }

            reset();
        }
    );

    const readonly = !hasPermission("w:devices");

    const defaultSettings: Settings = useMemo(() => {
        const ajv = new Ajv({ strict: "log", useDefaults: true });
        addFormats(ajv);
        const generate = ajv.compile(settingsSchemaQuery.data ?? {});
        const data = {};
        generate(data);
        return data;
    }, [settingsSchemaQuery.data]);

    const settings = _.merge(_.cloneDeep(defaultSettings), currentSettings ?? settingsQuery.data?.settings ?? {});

    const handleTabChange = async (tab: string) => {
        setSelectedTab(tab);
        setSceneLabelEditModeActive(false);
    };

    const handleClose = async () => {
        if (!readonly && isDirty) {
            const accept = (await showPrompt({
                title: t("titleCloseSettingsWarning", "Do you really want to close the settings?"),
                message: t("textCloseSettingsWarning", "If you close the dialog, your unsaved changes will get lost!"),
                options: [
                    { label: t("genericNo", "No"), value: false, primary: false },
                    { label: t("genericYes", "Yes"), value: true, primary: true },
                ],
            })) as boolean;

            if (!accept) return;
        }

        onClose();
    };

    const handleSave = async (onSuccess?: () => void, onError?: () => void) => {
        if (currentSettings === null) {
            // We want to keep this console.warn statement
            // eslint-disable-next-line no-console
            console.warn("Can not save settings without changes!");
            return;
        }

        const { data } = await settingsQuery.refetch();
        if (hasSettingsChanged(data)) {
            return;
        }

        saveSettingsMutation.mutate(
            {
                deviceId: device.device.id,
                data: {
                    settings: currentSettings,
                },
            },
            {
                onError: (err) => {
                    toast.error(
                        <Toast
                            title={t("errorConfigSavedTitle", "Error while saving!")}
                            description={err.response.data.message}
                        />
                    );

                    onError?.();
                },
                onSuccess: () => {
                    setIsDirty(false);
                    toast.success(
                        <Toast
                            title={t("successConfigSavedTitle", "Successfully saved")}
                            description={t("successConfigSavedMessage", "Your settings have been saved successfully!")}
                        />
                    );
                    onSuccess?.();
                    setCurrentSettings(null);
                    resetSettingsChangedDetector();
                },
            }
        );
    };

    const handleSettingsChange = (settings: Settings) => {
        setCurrentSettings(settings);
        setIsDirty(true);
    };

    const handleChangeSceneLabelEditModeActive = async (state: boolean) => {
        // We only allow opening the scene label view, when the settings are saved
        if (state && isDirty) {
            const option = (await showPrompt({
                title: t("titleEnterSceneLabelEditMode_DirtySettings", "Settings changed"),
                message: t("textEnterSceneLabelEditMode_DirtySettings", "Do you want to save or discard the settings?"),
                options: [
                    {
                        label: t("btnEnterSceneLabelEditMode_DirtySettings_Cancel", "Cancel"),
                        primary: false,
                        value: "cancel",
                    },
                    {
                        label: t("btnEnterSceneLabelEditMode_DirtySettings_Discard", "Discard"),
                        primary: false,
                        value: "discard",
                    },
                    { label: t("btnEnterSceneLabelEditMode_DirtySettings_Save", "Save"), primary: true, value: "save" },
                ],
            })) as "cancel" | "discard" | "save";

            if (option === "save") {
                await handleSave(() => {
                    setSceneLabelEditModeActive(true);
                });
            } else if (option === "discard") {
                setCurrentSettings(null);
                setIsDirty(false);
                setSceneLabelEditModeActive(true);
            } else {
                return;
            }
        } else {
            setSceneLabelEditModeActive(state);
        }
    };

    /**
     * When a scene label is deleted, and the scene label has settings, then the backend asynchronously deletes the
     * settings for this scene label (this can take a maximum of 3 seconds). This might also change some other
     * settings in the future. Therefore, we need to invalidate the current settings and fetch the settings, until
     * we get the new version (based on the time property).
     *
     * @param deletedSceneLabelId
     */
    const handleSceneLabelDelete = async (deletedSceneLabelId: Id) => {
        // We need to look into the settings query data directly, because adding a bed point will automatically add the
        // default settings. So newly added bed points will always be included, although server side their settings
        // do not yet exist
        const settings = settingsQuery.data?.settings;

        const isInvalidationNecessary =
            settings?.bedRails?.find((r) => r.id === deletedSceneLabelId) !== undefined ||
            settings?.absenceDetectionLocations?.find((l) => l.id === deletedSceneLabelId) !== undefined ||
            settings?.getupDetectionLocations?.find((l) => l.id === deletedSceneLabelId) !== undefined;

        if (isInvalidationNecessary) {
            setCurrentSettings(null);
            // TODO stop invalidation if time has changed
            callAfter(() => invalidateSettings(device.device.id), [1500, 3000]);
        }
    };

    return (
        <Modal onClose={handleClose} withHeader={true} withFooter={true}>
            <DeviceDetailsModalHeader
                device={device}
                roomInfo={roomInfo}
                tabs={tabNames}
                selectedTab={selectedTab}
                onTabSelect={handleTabChange}
            />
            <ModalContent>
                <>
                    {settingsQuery.isSuccess && settingsSchemaQuery.isSuccess && sceneLabelsQuery.isSuccess ? (
                        tabs[selectedTab]({
                            device: device,
                            settings: settings,
                            onSettingsChange: handleSettingsChange,
                            settingsSchema: settingsSchemaQuery.data,
                            sceneLabels: sceneLabelsQuery.data,
                            sceneLabelEditModeActive,
                            setSceneLabelEditModeActive: handleChangeSceneLabelEditModeActive,
                            readonly: readonly,
                            onSceneLabelDelete: handleSceneLabelDelete,
                            roomInfo: roomInfo,
                        })
                    ) : settingsQuery.isError || settingsSchemaQuery.isError || sceneLabelsQuery.isError ? (
                        <Alert type="error">
                            {t("errorLoadingConfiguration", "Error while loading configuration")}
                        </Alert>
                    ) : (
                        <Loading text={t("loadingConfiguration", "Loading configuration")} />
                    )}
                    {prompts}
                </>
            </ModalContent>
            <DeviceDetailsModalFooter
                isDirty={isDirty}
                isSaving={saveSettingsMutation.isLoading}
                onClose={handleClose}
                onSave={readonly ? undefined : handleSave}
                sceneLabelEditModeActive={sceneLabelEditModeActive}
                setSceneLabelEditModeActive={setSceneLabelEditModeActive}
            />
        </Modal>
    );
};
