import React, { useState } from "react";
import { c } from "../../../utils/ClassesHelper";
import { CardTitle } from "../../../components/UI/Card/CardTitle";
import { CardDescription } from "../../../components/UI/Card/CardDescription";
import { CardContent } from "../../../components/UI/Card/CardContent";
import { BarsAndAreasChart } from "../Charts/BarsAndAreasChart";
import { Card } from "../../../components/UI/Card/Card";
import { Id } from "../../../api_v2/types/custom";
import { Trans, useTranslation } from "react-i18next";
import { DateInterval } from "../../../utils/DateInterval";
import { colors } from "../../../utils/colors";
import { useDailyAlertData } from "../data/useAlertData";
import { useDailyStatusChangeCounts } from "../../../api_v2/hooks/useStatistics";
import { isSameDay } from "date-fns";
import { DataLabel } from "../common/DataLabel";
import { useFormat } from "../../../hooks/useFormat";
import { CardsTitlesAndTooltips } from "../CardsTitlesAndTooltips";
import styles from "../DashboardView.module.scss";
import cardStyles from "../Container/CardGrid.module.scss";

export interface MonthlyAlertsAndPresenceButtonCardProps {
    deviceId: Id;
    interval: DateInterval;
}

export const MonthlyAlertsAndPresenceButtonCard = ({ deviceId, interval }: MonthlyAlertsAndPresenceButtonCardProps) => {
    const format = useFormat();
    const { t } = useTranslation();

    const [includeFallDetection, setIncludeFallDetection] = useState<boolean>(true);
    const [includeEmergencyPrevention, setIncludeEmergencyPrevention] = useState<boolean>(true);
    const [includePresenceButton, setIncludePresenceButton] = useState<boolean>(true);

    const numberOfDaysForMonthlyChart = 30;
    const dailyAlertData = useDailyAlertData(deviceId, interval, numberOfDaysForMonthlyChart);
    const presenceButtonQuery = useDailyStatusChangeCounts(deviceId, { days: numberOfDaysForMonthlyChart });
    const presenceButtonData = presenceButtonQuery.data?.map((p) => {
        return {
            name: new Date(p.time),
            count: p.count,
        };
    });

    // Combine the data to be able to use all of it in the chart
    const combinedData = dailyAlertData.map((d, i, arr) => {
        const presenceButton = presenceButtonData?.find((p) => isSameDay(p.name, new Date(d.name)));
        const dateFormat = i === 0 || i === arr.length - 1 ? "P-no-year" : "EEEEEE";
        return {
            ...d,
            fall: includeFallDetection ? d.fall : undefined,
            emergency: includeEmergencyPrevention ? d.emergency : undefined,
            name: format(new Date(d.name), dateFormat),
            pauseSwitch: includePresenceButton ? presenceButton?.count ?? 0 : 0,
        };
    });

    const maxValue =
        Math.ceil(
            Math.max(
                dailyAlertData.reduce((max, data) => Math.max(max, data.emergency + data.fall), 0),
                presenceButtonData?.reduce((max, data) => Math.max(max, data.count), 0) ?? 0
            ) / 5
        ) * 5;

    return (
        <Card className={c([styles.card, cardStyles.md2, cardStyles.lg3, cardStyles.xl4])}>
            <CardTitle>
                <CardsTitlesAndTooltips
                    title={t("titleMonthlyAlertsAndPauseSwitchCard", "Alerts and presence button events")}
                    tooltip={t(
                        "tooltipMonthlyAlertsAndPresenceButtonCard",
                        "Shows all events that have alerted caregivers and the number of times the presence button is pressed."
                    )}
                />
            </CardTitle>
            <CardDescription>
                <Trans
                    // t("descriptionMonthlyAlertsAndPauseSwitchCard", "Last 4 weeks <FallDetectionLabel>fall detection</FallDetectionLabel>, <EmergencyPreventionLabel>emergency prevention</EmergencyPreventionLabel> and <PresenceButtonLabel>presence button</PresenceButtonLabel> events")
                    i18nKey="descriptionMonthlyAlertsAndPauseSwitchCard"
                    components={{
                        FallDetectionLabel: (
                            <DataLabel
                                type="fallDetection"
                                active={includeFallDetection}
                                onClick={() => setIncludeFallDetection(!includeFallDetection)}
                            />
                        ),
                        EmergencyPreventionLabel: (
                            <DataLabel
                                type="emergencyPrevention"
                                active={includeEmergencyPrevention}
                                onClick={() => setIncludeEmergencyPrevention(!includeEmergencyPrevention)}
                            />
                        ),
                        PresenceButtonLabel: (
                            <DataLabel
                                type="presenceButton"
                                active={includePresenceButton}
                                onClick={() => setIncludePresenceButton(!includePresenceButton)}
                            />
                        ),
                    }}
                />
            </CardDescription>
            <CardContent>
                <BarsAndAreasChart
                    data={combinedData}
                    xAxisDataKey="name"
                    yAxisLabel={t("yAxisLabelNumber", "Number")}
                    barDataKey={["emergency", "fall"]}
                    areaDataKey={"pauseSwitch"}
                    barColor={[colors.emergencyPrevention, colors.fallDetection]}
                    areaColor={colors.presenceButton}
                    stackId="a"
                    showBarLabel={true}
                    rangeMax={maxValue}
                />
            </CardContent>
        </Card>
    );
};
