import { useAuth } from "../AuthContextProvider";
import { useMutation } from "react-query";
import { AuthenticateError } from "./AuthenticateError";
import { CognitoUser } from "amazon-cognito-identity-js";

export interface VerifySoftwareTokenOptions {
    token: string;
    deviceName: string;
}
export const useVerifySoftwareToken = () => {
    const { user } = useAuth();

    return useMutation<void, AuthenticateError, VerifySoftwareTokenOptions>("associateSoftwareToken", (data) => {
        return verifySoftwareToken(user, data.token, data.deviceName);
    });
};

const verifySoftwareToken = (user: CognitoUser, token: string, deviceName: string): Promise<void> =>
    new Promise((resolve, reject) => {
        user.verifySoftwareToken(token, deviceName, {
            onSuccess: () => resolve(),
            onFailure: () => reject(),
        });
    });
