import { ActivityHeatMap } from "../../../../api_v2/hooks/useHeatMap";
import {
    addHours,
    subHours,
    subDays,
    addDays,
    differenceInDays,
    differenceInHours,
    subWeeks,
    isSameDay,
} from "date-fns";
import { useFormat } from "../../../../hooks/useFormat";
import { DateRange } from "../../common/DateRange";
import { findOutliers } from "./Statistics/findOutliers";
import { getStandardDeviation } from "./Statistics/getStandardDeviation";

export interface ActivityIdListItem extends ActivityHeatMap {
    hasSequences: boolean;
    startTimeForLabel: string;
    fill: string;
    levelAWeekBefore: number;
    dayIsOutlier: boolean;
    transparentBarLevel: number;
    baseBarLevel: number;
}

const getCurrentDate = (dateToChange: Date) => {
    const t = new Date(dateToChange);
    const date = ("0" + t.getDate()).slice(-2);
    const month = ("0" + (t.getMonth() + 1)).slice(-2);
    const year = t.getFullYear();
    return `${date}/${month}/${year}`;
};

export const useTransformHeatmaps = (
    heatMaps: ActivityHeatMap[],
    selectedDate: DateRange,
    mode: string,
    colorsForBars: string[],
    heatMapsOneWeekBefore?: ActivityHeatMap[],
    activeHeatMap?: ActivityHeatMap,
    heatMapsWithSequences?: string[],
    showStatistics?: boolean
) => {
    const format = useFormat();

    const outliers = findOutliers(heatMaps.map((l) => l.level));
    const mean = heatMaps.reduce((acc, curr) => acc + curr.level, 0) / heatMaps.length;

    const standardDeviation = getStandardDeviation(heatMaps, mean);
    const twoStdDevDiff = mean + 2 * standardDeviation;
    const oneStdDevDiff = mean + standardDeviation;

    // adding hour to dataForBars for naming the x-axis + fill for coloring the bars
    const dataToBeTransformed: Array<ActivityIdListItem> = heatMaps.map((datapoint) => {
        const localStartTimeOrDate = new Date(datapoint.startTime);
        const startTimeForLabel = format(localStartTimeOrDate, mode === "month" ? "dd.MMM" : "p");

        let fill;
        let dayIsOutlier = false;
        let transparentBarLevel = 0;
        let baseBarLevel = 0;

        if (mode === "month" && showStatistics && outliers.includes(datapoint.level)) {
            dayIsOutlier = true;
        }

        if (datapoint.level <= 7) {
            //if the level of activity is very low, a transparent bar is shown,
            // so that the user can still click on the bar and if the level is zero, there should
            // be at least a small line to know, that there is a bar, as the sensor was on
            transparentBarLevel = 5;
            if (datapoint.level === 0) {
                baseBarLevel = 1.5;
            }
        }

        if (mode === "month") {
            if (datapoint.level > twoStdDevDiff) {
                fill = colorsForBars[0];
            } else if (datapoint.level > oneStdDevDiff && datapoint.level <= twoStdDevDiff) {
                fill = colorsForBars[1];
            } else if (datapoint.level <= oneStdDevDiff && datapoint.level !== 0) {
                fill = colorsForBars[2];
            } else {
                fill = colorsForBars[2];
            }
        } else {
            if (datapoint.level > 50) {
                fill = colorsForBars[0];
            } else if (datapoint.level > 15) {
                fill = colorsForBars[1];
            } else {
                fill = colorsForBars[2];
            }
        }

        const levelAWeekBefore = 0;

        return {
            ...datapoint,
            startTimeForLabel: startTimeForLabel,
            fill: fill,
            hasSequences: heatMapsWithSequences?.includes(datapoint.id) ?? false,
            levelAWeekBefore: levelAWeekBefore,
            dayIsOutlier: dayIsOutlier,
            transparentBarLevel: transparentBarLevel,
            baseBarLevel: baseBarLevel,
        };
    });

    const arrayLength =
        mode === "month"
            ? differenceInDays(selectedDate.end, selectedDate.start)
            : differenceInHours(selectedDate.end, selectedDate.start);

    const lastEntriesOfDataArray = Array.from({ length: arrayLength }, (_, index): ActivityIdListItem => {
        const startTimeOrDateFixed =
            mode === "month" ? subDays(selectedDate.end, index + 1) : subHours(selectedDate.end, index + 1);
        const endTimeOrDateFixed =
            mode === "month" ? addDays(startTimeOrDateFixed, 1) : addHours(startTimeOrDateFixed, 1);

        const dateFormat = index === 0 || index === arrayLength - 1 ? "P-no-year" : "EEEEEE";
        const labelForAXis = format(startTimeOrDateFixed, mode === "month" ? dateFormat : "p");

        return {
            id: "",
            startTime: startTimeOrDateFixed.toString(),
            endTime: endTimeOrDateFixed.toString(),
            level: 0,
            fill: "",
            startTimeForLabel: labelForAXis,
            hasSequences: false,
            levelAWeekBefore: 0,
            dayIsOutlier: false,
            transparentBarLevel: 0,
            baseBarLevel: 0,
        };
    });

    //if there is data in a datapoint from the backend, add it to the last24Hours array
    const mappedEntries = lastEntriesOfDataArray.map((datapoint) => {
        const correspondingDataPoint = dataToBeTransformed.find((dp) => {
            return mode === "month"
                ? getCurrentDate(new Date(dp.startTime)) === getCurrentDate(new Date(datapoint.startTime))
                : new Date(dp.startTime) >= new Date(datapoint.startTime) &&
                      new Date(dp.startTime) < new Date(datapoint.endTime);
        });

        if (correspondingDataPoint) {
            return {
                ...correspondingDataPoint,
                startTime: datapoint.startTime,
                endTime: datapoint.endTime,
                startTimeForLabel: datapoint.startTimeForLabel,
                transparentBarLevel: correspondingDataPoint.transparentBarLevel,
                baseBarLevel: correspondingDataPoint.baseBarLevel,
            };
        }

        return datapoint;
    });

    const lastEntriesReversed = mappedEntries.reverse();

    //data for the last week line has to be added after buidling an array with zero values,
    //to make sure the line is shown even if there is no data on the selected day

    if (mode === "day" && heatMapsOneWeekBefore) {
        lastEntriesReversed.forEach((datapoint) => {
            const datapointStartTime = new Date(datapoint.startTime).getHours();

            const heatMapsOfSpecifiedDayOfLastWeek = heatMapsOneWeekBefore.filter((item) => {
                //filter for the same day (but one week before) as the current datapoint of the last week
                return isSameDay(new Date(item.startTime), subWeeks(new Date(datapoint.startTime), 1));
            });

            const foundItem = heatMapsOfSpecifiedDayOfLastWeek.find((item) => {
                const datapointOneWeekBeforeStartTime = new Date(item.startTime).getHours();
                const datapointOneWeekBeforeEndTime = new Date(item.endTime).getHours();
                return (
                    datapointOneWeekBeforeStartTime <= datapointStartTime &&
                    datapointOneWeekBeforeEndTime > datapointStartTime
                );
            });

            if (foundItem && foundItem.level !== 0) {
                datapoint.levelAWeekBefore = foundItem.level;
            }
        });
    }

    return lastEntriesReversed;
};
