import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { colors } from "../../../../utils/colors";
import { ClockFace } from "./ClockFace";
import chartStyles from "../../DashboardView.module.scss";

const innerRadius = 55;
const outerRadius = 100;

const paddingAngle = 2;

const avgLineThickness = 2;

export interface ClockFaceChartProps {
    data: Array<{ hour: number; average: number | null; today: number | null }>;
}

const getRadiusFor = (value: number | null) => {
    if (value === null) {
        return innerRadius;
    }

    const range = outerRadius - innerRadius;
    const percentage = value / 100;

    return innerRadius + range * percentage;
};

/**
 * Renders the clock face chart.
 *
 * Since recharts natively doesn't know radial bar charts (in the way we want to use it),
 * this abuses pie charts. It works by layering multiple pie charts on top of each other.
 * All of them have exactly 12 data points with a value of 1 each (this leads to 12 equal slices in the chart)
 * On top of that, 12 charts will be rendered, one for each datapoint. Each chart only displays a single cell,
 * with each charts having an outer radius based on the rendered cells value.
 * The top layer works exactly as the previous layer, except using the current data for the outer radius.
 *
 * Performance is okay since this will only produce 25 visible SVGs in a canvas after all.
 */
export const ClockFaceChart = (props: ClockFaceChartProps) => {
    const data = props.data.map((d) => ({ ...d, val: 1 }));

    return (
        <div>
            <ResponsiveContainer className={chartStyles.responsiveChartContainer}>
                <PieChart data={data} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
                    {/* Outline */}
                    <Pie
                        data={data}
                        innerRadius={outerRadius}
                        outerRadius={outerRadius}
                        fill="none"
                        paddingAngle={0}
                        dataKey="val"
                        isAnimationActive={false}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`bg-cell-${index}`} style={{ pointerEvents: "none", outline: "none" }} />
                        ))}
                    </Pie>

                    {data.map((hour, hourIndex) => (
                        <Pie
                            key={`cur-${hour.hour}`}
                            data={data}
                            innerRadius={innerRadius}
                            outerRadius={getRadiusFor(hour.today)}
                            fill="transparent"
                            stroke="transparent"
                            paddingAngle={paddingAngle}
                            dataKey="val"
                            isAnimationActive={false}
                            startAngle={90}
                            endAngle={360 + 90}
                        >
                            {data.map((entry, entryIndex) => (
                                <Cell
                                    key={`cur-cell-${entryIndex}`}
                                    fill={colors.clock.fg}
                                    display={entryIndex === hourIndex && entry.today !== null ? "block" : "none"}
                                    style={{ outline: "none" }}
                                />
                            ))}
                        </Pie>
                    ))}

                    {data.map((hour, hourIndex) => (
                        <Pie
                            key={`avg-${hour.hour}`}
                            data={data}
                            innerRadius={getRadiusFor(hour.average) - avgLineThickness}
                            outerRadius={getRadiusFor(hour.average)}
                            fill="transparent"
                            stroke="transparent"
                            paddingAngle={paddingAngle}
                            dataKey="val"
                            isAnimationActive={false}
                            startAngle={90}
                            endAngle={360 + 90}
                        >
                            {data.map((entry, entryIndex) => (
                                <Cell
                                    key={`avg-cell-${entryIndex}`}
                                    fill={colors.clock.bg}
                                    display={entryIndex === hourIndex && entry.average !== null ? "block" : "none"}
                                    style={{ outline: "none" }}
                                />
                            ))}
                        </Pie>
                    ))}
                </PieChart>
            </ResponsiveContainer>
            <ClockFace />
        </div>
    );
};
