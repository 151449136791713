import { ActivityHeatMap } from "../../../api_v2/hooks/useHeatMap";
import { useTranslation } from "react-i18next";
import { BedActivity } from "../../../api_v2/hooks/useBedActivities";
import { useFormat } from "../../../hooks/useFormat";
import styles from "../ActivityMonitoring/ActivityMonitoring.module.css";

export interface MainTitleProps {
    heatmap: ActivityHeatMap | null;
    sequence: BedActivity | null;
    selectedBedOrRoomView: "bedView" | "roomView";
}

/**
 * Title used for above the heatmaps of bed mobility and room activity
 */
export const MainTitle = ({ heatmap, sequence, selectedBedOrRoomView }: MainTitleProps) => {
    const { t } = useTranslation();
    const format = useFormat();

    if (heatmap === null) {
        return (
            <strong className={styles.title}>
                {selectedBedOrRoomView === "bedView"
                    ? t("labelBedMobility", "Bed rest mobility")
                    : t("labelRoomActivity", "Room activity")}
            </strong>
        );
    }
    const heatmapStartTime = format(new Date(heatmap.startTime), "p");

    if (!sequence) {
        return (
            <strong className={styles.title}>
                {selectedBedOrRoomView === "bedView"
                    ? t("labelTimePeriodOfHeatMapForBedView", "Bed rest mobility from {{heatmapStartTime}}", {
                          heatmapStartTime: heatmapStartTime,
                      })
                    : t("labelTimePeriodOfHeatMap", "Room activity from {{heatmapStartTime}}", {
                          heatmapStartTime: heatmapStartTime,
                      })}
            </strong>
        );
    }

    const sequenceStartTime = sequence ? format(new Date(sequence.startTime), "p") : "?";

    return (
        <strong className={styles.title}>
            {t("labelTimePeriodOfSequence", "Bed rest mobility at {{seqStartTime}}", {
                seqStartTime: sequenceStartTime,
            })}
        </strong>
    );
};
