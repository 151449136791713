import React from "react";
import { TimeDisplay } from "../../UI/TimeDisplay/TimeDisplay";
import { Event } from "../../../api_v2/hooks/useEvents";
import { EventImage } from "../EventImage";
import { AreaRoom } from "../../../api_v2/types/custom";
import { useTranslation } from "react-i18next";
import { EventIcon } from "../EventIcon";
import styles from "./EventsList.module.css";

export interface EventsListItemProps {
    event: Event;
    room: AreaRoom | undefined;
    onSelect: () => void;
}

export const EventsListItem = ({ event, room, onSelect }: EventsListItemProps) => {
    const { t } = useTranslation();

    return (
        <li className={styles.alert} onClick={onSelect}>
            <div>
                <EventImage eventId={event.id} className={styles.image} />
            </div>
            <div className={styles.details}>
                <strong className={styles.locationName}>{room?.name ?? t("infoUnknownRoom", "Unknown room")}</strong>
                <span className={styles.time}>
                    <TimeDisplay time={event.time} relative={true} />
                </span>
                <div>
                    <EventIcon type={event.type} colored={true} includeLabel={true} />
                </div>
            </div>
        </li>
    );
};
