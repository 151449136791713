import { Bar, BarChart, CartesianGrid, Cell, Label, LabelList, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { c } from "../../../utils/ClassesHelper";
import { colors as fixedColors } from "../../../utils/colors";
import { useTranslation } from "react-i18next";
import styles from "../DashboardView.module.scss";

export interface StackedBarChartProps<T> {
    data: T[];
    colors: string[];
    dataKey?: string[]; // defaults to value
    labelKey?: string[]; // defaults to dataKey
    labelVertical?: boolean;
    valuesForStackedBar: string[];
    XAxisWidth?: number;
    xAxisDataKey?: string;
    yAxisDomainMax?: number;
}

/**
 * Abstracts the stacked bar chart as used in multiple cards.
 */
export const StackedBarChart = <T,>({
    data,
    colors,
    dataKey,
    labelKey,
    labelVertical,
    valuesForStackedBar,
    XAxisWidth,
    xAxisDataKey,
    yAxisDomainMax,
}: StackedBarChartProps<T>) => {
    const { t } = useTranslation();

    return (
        <ResponsiveContainer className={styles.responsiveChartContainer}>
            <BarChart data={data} margin={{ top: 0, left: 0, right: 0, bottom: 10 }}>
                <XAxis dataKey={xAxisDataKey ?? "name"} fontSize={12} axisLine={false} tickLine={true}>
                    <Label value={t("yAxisLabelDay", "Day")} className={styles.label} dy={15} />
                </XAxis>
                <YAxis
                    width={XAxisWidth ?? 40}
                    fontSize={12}
                    axisLine={false}
                    tickLine={false}
                    domain={[0, yAxisDomainMax ?? ((dataMax: number) => Math.max(4, dataMax))]}
                    allowDecimals={false}
                >
                    <Label
                        value={t("yAxisLabelNumber", "Number")}
                        angle={-90}
                        className={c([styles.label, styles.labelY])}
                        dx={-13}
                    />
                </YAxis>
                <CartesianGrid stroke={fixedColors.lightGrey} vertical={false} />

                {valuesForStackedBar.map((valueForStackedBar, index) => (
                    <Bar
                        key={`bar-${index}`}
                        dataKey={valueForStackedBar}
                        stackId="a"
                        fill={colors[index % colors.length]}
                        isAnimationActive={false}
                        barSize={20}
                    >
                        {data.map((entry, dataIndex) => (
                            <Cell key={`cell-${index}-${dataIndex}`} fill={colors[index % colors.length]} />
                        ))}
                        <LabelList
                            dataKey={labelKey?.[index] ?? dataKey?.[index] ?? valueForStackedBar}
                            position="inside"
                            fill={fixedColors.white}
                            fontSize="0.75rem"
                            angle={labelVertical ? -90 : 0}
                            /* Hide labels with a value of 0, which means no bar present */
                            formatter={(label: number) => (label == 0 ? null : label)}
                        />
                    </Bar>
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};
