import { TabProps } from "../TabProps";
import { useTranslation } from "react-i18next";
import { InputGroup } from "../../../UI/InputGroup";
import { ToggleSwitch } from "../../../UI/ToggleSwitch";
import { Label } from "../../../UI/Label";
import { SensitivitySliderLabelCombo } from "../../../UI/SensitivitySliderLabelCombo";
import { TimeWindowsList } from "../../../TimeWindows/TimeWindowsList";
import styles from "./FallDetection.module.css";
import _ from "lodash";
import { canAccessSettings, getValueForFlag } from "../../settingsSchemaUtils";

export const FallDetectionTab = (props: TabProps) => {
    const { t } = useTranslation();

    const fallDetectionEnabled = props.settings.fallDetectionEnable ?? false;
    const canAccessFallDetectionAnalysisSettings = canAccessSettings("fallDetectionAnalysis", props.settingsSchema);
    const canAccessFallLightSettings = canAccessSettings("fallLight", props.settingsSchema);

    return (
        <div>
            <InputGroup>
                <Label text={t("labelFallDetection", "Fall detection")}>
                    <ToggleSwitch
                        checkedLabel={t("labelActivated", "Activated")}
                        uncheckedLabel={t("labelDeactivated", "Deactivated")}
                        state={fallDetectionEnabled}
                        onChange={(s) => {
                            const newSettings = _.cloneDeep(props.settings);
                            newSettings.fallDetectionEnable = s;
                            props.onSettingsChange(newSettings);
                        }}
                        disabled={false}
                        readonly={props.readonly}
                        id={"FallDetectionEnableToggle"}
                    />
                </Label>
                <p>{t("fallDetectionDescription", "Through fall detection, falls within a room can be detected. If this module is activated, an alarm is triggered in the event of a fall. Its main focus is the occurring floor area within the sensor's field of view.")}</p>
            </InputGroup>

            <InputGroup>
                <SensitivitySliderLabelCombo
                    text={t("labelSensitivity", "Sensitivity")}
                    sensitivity={props.settings.fallDetectionSensitivity ?? "medium"}
                    readonly={props.readonly}
                    disabled={!fallDetectionEnabled}
                    onChange={(s) => {
                        const newSettings = _.cloneDeep(props.settings);
                        newSettings.fallDetectionSensitivity = s;
                        props.onSettingsChange(newSettings);
                    }}
                    id="FallDetectionSensitivitySlider"
                />
            </InputGroup>

            {canAccessFallDetectionAnalysisSettings ? (
                <InputGroup>
                    <Label text={t("labelFallAnalysis", "Fall analysis")}>
                        <ToggleSwitch
                            checkedLabel={t("labelActivated", "Activated")}
                            uncheckedLabel={t("labelDeactivated", "Deactivated")}
                            state={getValueForFlag("fallDetectionAnalysisEnable", props.settings, props.settingsSchema)}
                            onChange={(s) => {
                                const newSettings = _.cloneDeep(props.settings);
                                newSettings.fallDetectionAnalysisEnable = s;
                                props.onSettingsChange(newSettings);
                            }}
                            disabled={!fallDetectionEnabled}
                            readonly={props.readonly}
                            id={"FallDetectionAnalysisEnableToggle"}
                        />
                    </Label>
                    <p>{t("fallGenesisDescription", "The fall analysis allows the reconstruction of critical fall events and helps in eliminating repetitive causes of falls. Once this module is activated, a visualized fall sequence is available for every detected fall in the alarm overview. ")}</p>
                </InputGroup>
            ) : null}

            {canAccessFallLightSettings ? (
                <InputGroup>
                    <Label text={t("labelFallLight", "Fall light")}>
                        <ToggleSwitch
                            checkedLabel={t("labelActivated", "Activated")}
                            uncheckedLabel={t("labelDeactivated", "Deactivated")}
                            state={getValueForFlag("fallLightEnable", props.settings, props.settingsSchema)}
                            onChange={(s) => {
                                const newSettings = _.cloneDeep(props.settings);
                                newSettings.fallLightEnable = s;
                                props.onSettingsChange(newSettings);
                            }}
                            disabled={!fallDetectionEnabled}
                            readonly={props.readonly}
                            id={"FallLightEnableToggle"}
                        />
                    </Label>
                    <p>{t("fallLightDescription", "Activate the fall light to automatically turn on the lights in the room in the event of a fall.")}</p>
                </InputGroup>
            ) : null}

            <InputGroup>
                <Label text={t("labelFallDetectionSchedule", "Alarm period")} additionalClassNames={styles.label}>
                    <TimeWindowsList
                        timeWindows={props.settings.fallDetectionTimeWindows ?? []}
                        onChange={(timeWindows) => {
                            const newSettings = _.cloneDeep(props.settings);
                            newSettings.fallDetectionTimeWindows = timeWindows;
                            props.onSettingsChange(newSettings);
                        }}
                        disabled={!fallDetectionEnabled}
                        readonly={props.readonly}
                        id="FallDetectionTimeWindows"
                    />
                </Label>
            </InputGroup>
        </div>
    );
};
