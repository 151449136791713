import { HTMLProps, PropsWithChildren } from "react";
import styles from "./Card.module.css";
import { c } from "../../../utils/ClassesHelper";

export const CardContent = ({ children, className, ...props }: PropsWithChildren<HTMLProps<HTMLDivElement>>) => {
    return (
        <div className={c([className, styles.content])} {...props}>
            {children}
        </div>
    );
};
