import { Button } from "../../../UI/Button/Button";
import { Alert } from "../../../UI/Alerts/Alert";
import { Settings } from "../../../../api_v2/types/custom";
import { useTranslation } from "react-i18next";

export interface NoSettingsErrorMessageProps {
    settings: Settings;
    onSettingsChange: (settings: Settings) => void;
    readonly: boolean;
    addSettingsHandler: (settings: Settings) => Settings;
}

export const NoSettingsErrorMessage = (props: NoSettingsErrorMessageProps) => {
    const { t } = useTranslation();

    const handleClick = () => {
        const newSettings = props.addSettingsHandler(props.settings);
        props.onSettingsChange(newSettings);
    };

    return (
        <>
            <Alert type="info">
                {t(
                    "warningSceneLabelInactiveDueToMissingSettings",
                    "This room setup is inactive due to missing settings."
                )}
            </Alert>
            {!props.readonly ? (
                <div style={{ textAlign: "center" }}>
                    <Button onClick={handleClick}>
                        {t("labelAddDefaultSettingsToSceneLabel", "Add settings and activate room setup.")}
                    </Button>
                </div>
            ) : null}
        </>
    );
};
