import { PropsWithChildren, useState } from "react";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import styles from "./CardGroup.module.scss";
import { c } from "../../../utils/ClassesHelper";

export interface CardGroupProps extends PropsWithChildren {
    title: string;
    info: string;
    initialOpen?: boolean;
}

export const CardGroup = ({ title, info, initialOpen, children }: CardGroupProps) => {
    const [open, setOpen] = useState(initialOpen ?? true);

    const handleToggle = () => setOpen((s) => !s);

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <button className={styles.toggleBtn} onClick={handleToggle}>
                    {open ? <BiChevronUp /> : <BiChevronDown />}
                </button>
                <h2 className={styles.title}>
                    {title} <small className={styles.info}>{info}</small>
                </h2>
            </div>
            {open ? <div className={c([styles.content], { [styles.open]: open })}>{children}</div> : null}
        </div>
    );
};
