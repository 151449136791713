import React from "react";
import { Link } from "react-router-dom";
import { TimeDisplay } from "../../UI/TimeDisplay/TimeDisplay";
import { Event } from "../../../api_v2/hooks/useEvents";
import { AreaRoom } from "../../../api_v2/types/custom";
import { EventIcon } from "../EventIcon";
import { EventId } from "../EventId";
import styles from "./EventModal.module.scss";
import { ModalFooter } from "../../Modal/Modal";
import { route } from "../../../utils/RouteHelper";

export interface AlertModalFooterProps {
    event: Event;
    room: AreaRoom;
}

export const EventModalFooter = ({ event, room }: AlertModalFooterProps) => {
    const linkToDevice = event.deviceId
        ? route({
              to: "rooms",
              view: { type: "settings", deviceId: event.deviceId },
          })
        : null;

    return (
        <ModalFooter>
            <div className={styles.info}>
                <div>
                    {linkToDevice !== null ? (
                        <Link to={linkToDevice} className={styles.roomNameLink}>
                            {room.name}
                        </Link>
                    ) : (
                        <div className={styles.roomName}>{room.name}</div>
                    )}
                    <div className={styles.time}>
                        <TimeDisplay time={event.time} relative={false} />
                    </div>
                </div>
                <div>
                    <div className={styles.type}>
                        <EventIcon type={event.type} colored={true} includeLabel={true} />
                    </div>
                    <div className={styles.id}>
                        <EventId event={event} />
                    </div>
                </div>
            </div>
        </ModalFooter>
    );
};
