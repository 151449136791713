import { useTranslation } from "react-i18next";
import { useHasFeatureFlags } from "../../../../hooks/useHasFeatureFlags";
import { MonitoringView } from "../Common/MonitoringView";
import { TabProps } from "../TabProps";
import { BedActivityMonitoringSettings } from "./BedActivityMonitoringSettings";
import { RoomActivityMonitoringSettings } from "./RoomActivityMonitoringSettings";

export const ActivityMonitoringTab = (props: TabProps) => {
    const hasFeatureFlag = useHasFeatureFlags();
    const { t } = useTranslation();

    const roomActivityEnabled = hasFeatureFlag("enable-room-activity");

    return (
        <div>
            <MonitoringView {...props} enableRoomSettings={roomActivityEnabled}>
                {(props, selectedBedLocation) => {
                    const selectedBedLocationOnlyBed = selectedBedLocation?.type === "bed-point";
                    if (!selectedBedLocationOnlyBed && roomActivityEnabled)
                        return <RoomActivityMonitoringSettings {...props} />;
                    else if (selectedBedLocationOnlyBed)
                        return <BedActivityMonitoringSettings {...props} selectedBedLocation={selectedBedLocation} />;
                    else
                        return (
                            <p>
                                {t(
                                    "infoMonitoringNoPointsSet",
                                    "No bed available, please switch to the bed management tab to set bed points. Bed monitoring can then be activated for the respective bed."
                                )}
                            </p>
                        );
                }}
            </MonitoringView>
        </div>
    );
};
