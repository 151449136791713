import { Label as UiLabel } from "../../../UI/Label";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { TbTrendingUp } from "react-icons/tb";
import { TbTrendingDown } from "react-icons/tb";
import { MdTrendingFlat } from "react-icons/md";

import styles from "./ActivityGraph.module.css";

interface GraphStatisticsLegendProps {
    trendLineProperty: "up" | "down" | "flat" | undefined;
    trendColor: string | undefined;
}

export const GraphStatisticsLegend = (props: GraphStatisticsLegendProps) => {
    const { t } = useTranslation();

    if (!props.trendLineProperty) {
        return null;
    }

    const trendIcon =
        props.trendLineProperty === "up" ? (
            <TbTrendingUp style={{ color: props.trendColor }} />
        ) : props.trendLineProperty === "down" ? (
            <TbTrendingDown style={{ color: props.trendColor }} />
        ) : (
            <MdTrendingFlat style={{ color: props.trendColor }} />
        );

    return (
        <div className={styles.legendFlexBox}>
            <div className={styles.legendInnerBox}>
                <BsFillInfoCircleFill className={styles.outlierIcon} />
                <UiLabel text={t("outlierLegend", "Unusual activity pattern")} additionalClassNames={styles.legendLabel} />
            </div>
            <div className={styles.legendInnerBox}>
                <UiLabel text={"---"} additionalClassNames={styles.legendIcon} />
                <UiLabel text={t("averageLegend", "Average activity")} additionalClassNames={styles.legendLabel} />
            </div>
            <div className={styles.legendInnerBox}>
                <UiLabel text={trendIcon} additionalClassNames={styles.legendIcon} />
                <UiLabel text={t("trendLegend", "Trend")} additionalClassNames={styles.legendLabel} />
            </div>
        </div>
    );
};
