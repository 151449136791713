import React, { useEffect, useState } from "react";
import { Device, usePollDeviceStatus } from "../../api_v2/hooks/useDevices";
import { c } from "../../utils/ClassesHelper";
import { SceneLabel, useListSceneLabels } from "../../api_v2/hooks/useSceneLabels";
import { useGetSceneVisualization } from "../../api_v2/hooks/useSceneVisualizations";
import { Loading } from "../UI/Loading";
import { Canvas } from "./Canvas";
import { AugmentedSceneLabel } from "./AugmentedSceneLabel";
import { Legend } from "./Legend";
import { makeAugmentedSceneLabels } from "./AugmentedSceneLabelHelper";
import { useTranslation } from "react-i18next";
import { Alert } from "../UI/Alerts/Alert";
import styles from "./SceneLabelSelector.module.css";
import { Id, Settings } from "../../api_v2/types/custom";
import { differenceInSeconds } from "date-fns";

interface SceneLabelSelectorProps {
    device: Device;
    onStatusChange?: (isDirty: boolean, isSaving: boolean) => void;
    readonly?: boolean;
    selectedSceneLabelId?: string;
    onSelect?: (sceneLabelId: string) => void;
    onNewSceneLabel?: (newSceneLabel: SceneLabel) => void;
    hideBedPoints?: boolean;
    hideBedRails?: boolean;
    hideLegend?: boolean;
    onSceneLabelDelete?: (deletedSceneLabelId: Id) => void;
    onClick?: () => void;

    // Settings are read only used to display a warning icon, if a scene label has no settings
    // and show/hide the bed rail direction arrow, depending on the directional setting
    settings?: Settings;
}

// SceneLabels can only be edited while the device has on of these statuses
const availableDeviceStatuses = ["healthy", "paused"];

export const SceneLabelSelector = ({
    device,
    readonly,
    onStatusChange,
    selectedSceneLabelId,
    onSelect,
    onNewSceneLabel,
    hideBedPoints,
    hideBedRails,
    hideLegend,
    onSceneLabelDelete,
    onClick,
    settings,
}: SceneLabelSelectorProps) => {
    const { t } = useTranslation();

    const classNames = c([styles.container], {
        [styles.edit]: !readonly,
    });

    const deviceStatusQuery = usePollDeviceStatus(device.id, readonly !== true, (data) => {
        // Decrease interval if sensor is offline or no data has been fetched yet
        if (data === undefined || !availableDeviceStatuses.includes(data.status)) {
            return 5000;
        }

        return 30000;
    });
    const sceneLabelsQuery = useListSceneLabels(device.id);
    const sceneVisualizationQuery = useGetSceneVisualization(device.id);

    const [lastUpdate, setLastUpdate] = useState<Date | null>(null);
    const [sceneLabels, setSceneLabels] = useState<Array<AugmentedSceneLabel> | null>(null);

    const vertical = [90, 270].includes(settings?.orientation ?? 0);
    const [width, height] = vertical ? [480, 640] : [640, 480];

    // Set scene labels once query is finished
    useEffect(() => {
        if (sceneLabelsQuery.isSuccess && sceneLabels === null) {
            setSceneLabels(sceneLabelsQuery.data.flatMap(makeAugmentedSceneLabels));
        }
    }, [sceneLabelsQuery.isSuccess, sceneLabels]);

    useEffect(() => {
        if (onStatusChange) {
            const isDirty = sceneLabels?.some((s) => !s.isValid) ?? false;
            const isSaving = sceneLabels?.some((s) => s.isLoading) ?? false;
            onStatusChange(isDirty, isSaving);
        }
    }, [sceneLabels, onStatusChange]);

    const handleSetSceneLabels: React.Dispatch<React.SetStateAction<AugmentedSceneLabel[]>> = (s) => {
        if (Array.isArray(s)) {
            setSceneLabels(s);
        } else {
            setSceneLabels((currentSceneLabels) => {
                if (currentSceneLabels === null) {
                    return currentSceneLabels;
                }

                return s(currentSceneLabels);
            });
        }
    };

    const onAugmentedSceneLabelChange = (augmentedSceneLabel: AugmentedSceneLabel, newlyCreated = false) => {
        setSceneLabels(
            (sceneLabels) =>
                sceneLabels?.map((sceneLabel) =>
                    sceneLabel.id === augmentedSceneLabel.id ? augmentedSceneLabel : sceneLabel
                ) ?? []
        );

        if (newlyCreated) {
            onNewSceneLabel?.(augmentedSceneLabel.sceneLabel);
        }

        if (augmentedSceneLabel.isValid) {
            setLastUpdate(new Date());
        }
    };

    if (sceneLabelsQuery.isError || sceneVisualizationQuery.isError) {
        return (
            <p className={styles.error}>
                {t(
                    "errorLoadingSceneLabels",
                    "An error occurred while loading the visualization. Please try again later!"
                )}
            </p>
        );
    }

    if (!sceneLabelsQuery.isSuccess || !sceneVisualizationQuery.isSuccess || sceneLabels === null) {
        return <Loading text={t("labelLoadingVisualization", "Loading visualization")} />;
    }

    const deviceAvailable =
        deviceStatusQuery.isSuccess && availableDeviceStatuses.includes(deviceStatusQuery.data.status);

    // Whenever we change a scene label, the device will be inactive for a few seconds
    // To prevent flashing an error message to the user, we check if we changed any of the scene labels within the last few seconds
    // and show a nicer info instead
    const deviceReloadTimeInSeconds = 20;
    const deviceIsLoadingChanges =
        !deviceAvailable &&
        ((lastUpdate !== null && differenceInSeconds(new Date(), lastUpdate) < deviceReloadTimeInSeconds) ||
            sceneLabels.some(
                (s) => differenceInSeconds(new Date(), new Date(s.sceneLabel.time)) < deviceReloadTimeInSeconds
            ));

    const saving = sceneLabels.some((s) => s.isLoading);
    const disabled = saving || !deviceAvailable;

    const filteredSceneLabels = sceneLabels.filter((sceneLabel) => {
        if (hideBedPoints === true && sceneLabel.sceneLabel.type === "bed-point") {
            return false;
        }

        if (hideBedRails === true && sceneLabel.sceneLabel.type === "line-2d") {
            return false;
        }

        return true;
    });

    return (
        <>
            {!deviceAvailable && !readonly ? (
                deviceIsLoadingChanges ? (
                    <Alert type="loading">
                        {t(
                            "infoEditSceneLabelsDeviceNotAvailableDueToRecentChanges",
                            "Sensor is currently loading, please wait."
                        )}
                    </Alert>
                ) : (
                    <Alert type="error">
                        {t(
                            "errorEditSceneLabelsDeviceNotAvailable",
                            "Settings cannot be changed if the sensor is not available."
                        )}
                    </Alert>
                )
            ) : null}

            <div className={classNames}>
                <div
                    className={c({
                        [styles.canvasWrapper]: true,
                        [styles.vertical]: vertical,
                        [styles.cursorPointer]: onClick !== undefined,
                    })}
                    onClick={onClick}
                >
                    <Canvas
                        deviceId={device.id}
                        augmentedSceneLabels={filteredSceneLabels}
                        onAugmentedSceneLabelChange={onAugmentedSceneLabelChange}
                        visualization={sceneVisualizationQuery.data}
                        canvasWidth={width}
                        canvasHeight={height}
                        readonly={readonly || disabled}
                        settings={settings}
                    />
                </div>
                <div className={styles.legend}>
                    {hideLegend !== true ? (
                        <Legend
                            deviceId={device.id}
                            sceneLabels={filteredSceneLabels}
                            onAugmentedSceneLabelChange={onAugmentedSceneLabelChange}
                            setSceneLabels={handleSetSceneLabels}
                            readonly={readonly}
                            disabled={disabled}
                            selectedSceneLabelId={selectedSceneLabelId}
                            onSelect={onSelect}
                            hideBedPoints={hideBedPoints}
                            hideBedRails={hideBedRails}
                            onSceneLabelDelete={onSceneLabelDelete}
                            settings={settings}
                        />
                    ) : null}
                </div>
                {!readonly ? (
                    <div className={styles.errorContainer}>
                        <ul className={styles.errorList}>
                            {filteredSceneLabels
                                .filter((s) => !s.isValid)
                                .filter((s) => !Object.prototype.hasOwnProperty.call(s, "lineTo"))
                                .map((sceneLabel) => (
                                    <li key={sceneLabel.id} className={styles.error}>
                                        {sceneLabel.sceneLabel.type === "bed-point"
                                            ? t("tooltipErrorTitleInvalidPoint", "Point invalid")
                                            : t(
                                                  "tooltipErrorDescriptionInvalidPoint",
                                                  "One or more points are invalid."
                                              )}
                                    </li>
                                ))}
                        </ul>
                    </div>
                ) : null}
            </div>
        </>
    );
};
