import { ActivityHeatMap, useListDailyRoomActivities } from "../../../../api_v2/hooks/useHeatMap";
import { Id } from "../../../../api_v2/types/custom";
import { ActivityGraph } from "../Graph/ActivityGraph";
import { MonthFilter } from "../Graph/Filter/MonthFilter";
import { RoomActivityProps } from "./RoomActivity";
import { useState } from "react";
import { BubbleChart } from "../BubbleChart/BubbleChart";
import styles from "../ActivityMonitoring.module.css";

interface MonthlyRoomActivityProps extends RoomActivityProps {
    selectedHeatMapId: Id | null;
    onSelectedHeatMapId: (id: Id | null) => void;
    onChangeHeatmap: (activeHeatMapAfterBarClick: ActivityHeatMap | Date) => void;
    colorsForBars: string[];
}

export const MonthlyRoomActivity = ({
    device,
    dateRange,
    selectedHeatMapId,
    onSelectedHeatMapId,
    onChangeHeatmap,
    onDateRangeChange,
    isSmallScreen,
    mode,
    showStatistics,
    onshowStatistics,
    colorsForBars,
}: MonthlyRoomActivityProps) => {
    const [timeWindow, setTimeWindow] = useState<[string, string]>(["00:00", "00:00"]);

    const heatMapsMonthQuery = useListDailyRoomActivities(
        device.device.id,
        { before: dateRange.end, window: timeWindow },
        {
            onSuccess: (data) => {
                // Only reset the state when the currently selected id is not included in the response data
                // If it still is included, a refresh has happened, not a change of the time window
                if (!data.find((d) => d.id === selectedHeatMapId)) {
                    onSelectedHeatMapId(null);
                }
            },
        }
    );

    const heatMaps: ActivityHeatMap[] = heatMapsMonthQuery?.data ?? [];
    const activeHeatMap =
        heatMaps.find((item) => item.id === selectedHeatMapId) ?? heatMaps[heatMaps.length - 1] ?? null;

    return (
        <div className={styles.mainContainer}>
            <div className={styles.graph}>
                <MonthFilter
                    selectedDateRange={dateRange}
                    onSelectedDateRangeChange={onDateRangeChange}
                    timeWindow={timeWindow}
                    onTimeWindowChange={setTimeWindow}
                    isSmallScreen={isSmallScreen}
                    showStatistics={showStatistics}
                    onshowStatistics={onshowStatistics}
                />
                <ActivityGraph
                    heatMaps={heatMaps}
                    activeHeatMap={activeHeatMap ? activeHeatMap : undefined}
                    dateRange={dateRange}
                    onHeatMapChange={onChangeHeatmap}
                    isSmallScreen={isSmallScreen}
                    mode={mode}
                    showStatistics={showStatistics}
                    colorsForBars={colorsForBars}
                    dateLineFormat="MMM"
                />
            </div>
            <div className={styles.dividerRight}></div>
            <div className={styles.mainBubbleChart}>
                <BubbleChart heatMaps={heatMaps} dateRange={dateRange} onDaySelect={onChangeHeatmap} />
            </div>
        </div>
    );
};
