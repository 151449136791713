import React from "react";
import { TimeDisplay } from "../../components/UI/TimeDisplay/TimeDisplay";
import { useTranslation } from "react-i18next";
// import { useParams } from "react-router-dom"; // TODO
import { Loading } from "../../components/UI/Loading";
import { Event } from "../../api_v2/hooks/useEvents";
import { EventModalImageGallery } from "../../components/Events/EventModal/EventModalImageGallery";
import { useGetEventTypeLabel } from "../../components/Events/hooks/useGetEventTypeLabel";
import { LocationContextAwareView } from "../../components/UI/View/LocationContextAwareView";
import styles from "./EventDetailsView.module.css";
import { useCurrentFacility, useCurrentRooms } from "../../hooks/useCurrentFacilityStructure";
import { EventId } from "../../components/Events/EventId";

export const EventDetailsView = () => {
    const { t } = useTranslation();
    const getEventTypeLabel = useGetEventTypeLabel();
    // const { eventId } = useParams(); // TODO

    const facility = useCurrentFacility();
    const rooms = useCurrentRooms();

    // const eventQuery = useGetEvent(); // TODO

    if (!facility || !rooms) return <Loading />;

    // TODO replace with actual query
    const event: Event = {
        id: "test",
        time: Date.now().toString(),
        type: "fall",
        areaId: facility.areas[0].id,
        roomId: facility.areas[0].rooms[0].id,
        deviceId: "device-1",
    };

    const roomName = rooms.find((r) => r.id === event.roomId)?.name;

    const eventTypeLabel = getEventTypeLabel(event.type);

    return (
        <LocationContextAwareView>
            <div className={styles.container}>
                <div>
                    <dl className={styles.info}>
                        <dt>{t("labelRoom", "Room")}</dt>
                        <dd>{roomName ?? "?"}</dd>
                        <dt>{t("labelAlertsTableTime", "Date")}</dt>
                        <dd>
                            <TimeDisplay time={event.time} relative={false} />
                        </dd>
                        <dt>{t("labelAlertsTableType", "Alarm type")}</dt>
                        <dd>{eventTypeLabel}</dd>
                        <dt>{t("labelAlertsTableAlertId", "Alarm ID")}</dt>
                        <dd>
                            <EventId event={event} long={true} />
                        </dd>
                    </dl>
                </div>
                <EventModalImageGallery event={event} />
            </div>
        </LocationContextAwareView>
    );
};
