import React, { HTMLProps } from "react";
import { c } from "../../../utils/ClassesHelper";
import styles from "./Card.module.css";

export type CardProps = HTMLProps<HTMLDivElement>;

export const Card = ({ className, children, ...props }: CardProps) => {
    return (
        <div className={c([styles.card, className])} {...props}>
            {children}
        </div>
    );
};
