import { Popover } from "@headlessui/react";
import { c } from "../../utils/ClassesHelper";
import { usePopper } from "react-popper";
import { ReactElement, useState } from "react";
import { IconType } from "react-icons";
import styles from "./PopoverMenu.module.css";

export interface PopoverMenuProps {
    Icon: IconType;
    className?: string;
    itemList?: boolean;
    children: (close: () => void) => ReactElement;
    id?: string;
}

export const PopoverMenu = ({ Icon, children, className, itemList, id }: PopoverMenuProps) => {
    const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const popper = usePopper(referenceElement, popperElement, {
        placement: "bottom-end",
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, 10],
                },
            },
        ],
    });

    return (
        <Popover className={c([styles.container, className])} id={id}>
            <Popover.Button ref={setReferenceElement} className={c([styles.button])}>
                <Icon />
            </Popover.Button>

            <Popover.Panel
                ref={setPopperElement}
                style={popper.styles.popper}
                {...popper.attributes.popper}
                className={c([styles.panel], { [styles.panelItemList]: itemList === true })}
            >
                {({ close }) => children(close)}
            </Popover.Panel>
        </Popover>
    );
};
