import { c } from "../../../../utils/ClassesHelper";
import { Id } from "../../../../api_v2/types/custom";
import { useTranslation } from "react-i18next";
import { BiCaretRightCircle } from "react-icons/bi";
import { IconContext } from "react-icons";
import { BedActivity } from "../../../../api_v2/hooks/useBedActivities";
import { useFormat } from "../../../../hooks/useFormat";
import styles from "./BedAndSequenceSelector.module.css";

interface SequenceSelectorProps {
    items: Array<BedActivity>;
    selectedId: Id | null;
    onSelectedIndexChange: (id: Id) => void;
}

/**
 * TODO move out of BedActivity folder!
 *
 * @param props
 * @constructor
 */
export const SequenceSelector = (props: SequenceSelectorProps) => {
    const { t } = useTranslation();
    const format = useFormat();

    if (props.items.length === 0) return null;

    const handleSelectSequence = (item: BedActivity) => {
        props.onSelectedIndexChange(item.id);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLLIElement>, item: BedActivity) => {
        if (event.key === "Enter") {
            handleSelectSequence(item);
        }
    };

    return (
        <>
            <strong
                aria-label={t("ariaLabelBedActivitySeqTitle", "bed activity sequences title")}
                className={styles.title}
            >
                {t("labelBedActivitySeqTitle", "Sequences")}
            </strong>

            <ul
                aria-label={t("ariaLabelBedActivitySequenceList", "List of bed activity sequences")}
                className={c([styles.list, styles.sequence])}
            >
                {props.items
                    .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
                    .map((item) => {
                        const isSelected = item.id === props.selectedId;
                        return (
                            <li
                                className={c({ [styles.itemSeq]: true })}
                                key={item.id}
                                tabIndex={0}
                                onClick={() => handleSelectSequence(item)}
                                onKeyDown={(event) => handleKeyDown(event, item)}
                                role="button"
                                aria-label={t(
                                    "ariaLabelBedActivityFrameSelect",
                                    "Select bed activity frame at time {{ariaStartTime, datetime}}",
                                    {
                                        ariaStartTime: new Date(item.startTime),
                                    }
                                )}
                            >
                                <IconContext.Provider
                                    value={{
                                        color: isSelected ? "#f15a40" : "#14325c",
                                    }}
                                >
                                    <BiCaretRightCircle fontSize={20} />
                                </IconContext.Provider>
                                <span
                                    aria-label={t(
                                        "sequenceSelector.ariaLabel.bedActivityTime",
                                        "button of bed activity at time {{ariaStartTime}}",
                                        {
                                            ariaStartTime: new Date(item.startTime),
                                        }
                                    )}
                                    className={styles.itemTime}
                                >
                                    {format(new Date(item.startTime), "p")}
                                </span>
                            </li>
                        );
                    })}
            </ul>
        </>
    );
};
