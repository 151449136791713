import { c } from "../../../../utils/ClassesHelper";
import { SceneLabel } from "../../../../api_v2/hooks/useSceneLabels";
import { Id } from "../../../../api_v2/types/custom";
import { useTranslation } from "react-i18next";
import { Label } from "../../../UI/Label";
import styles from "./BedAndSequenceSelector.module.css";

interface BedSelectorProps {
    items: Array<SceneLabel>;
    selected: Id | null;
    onSelect: (id: Id | null) => void;
    nullable?: boolean;
}

export const BedSelector = (props: BedSelectorProps) => {
    const { t } = useTranslation();

    const handleSelectBed = (sceneLabel: SceneLabel | null) => {
        props.onSelect(sceneLabel?.id ?? null);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLLIElement>, sceneLabel: SceneLabel | null) => {
        if (event.key === "Enter") {
            handleSelectBed(sceneLabel);
        }
    };

    return (
        <div className={styles.bedSelectorContainer}>
            <div className={styles.bedSelectorTitle}>
                <Label text={t("analysis-modal.select-bed-label", "Select:")} />
            </div>
            <ul
                aria-label={t("bedSelector.ariaLabel.listOfBeds", "list of beds")}
                className={c([styles.list, styles.bedOrRoomPoint])}
            >
                {props.nullable ? (
                    <li
                        tabIndex={0}
                        role="button"
                        onKeyDown={(event) => handleKeyDown(event, null)}
                        className={c({
                            [styles.item]: true,
                            [styles.active]: props.selected === null,
                        })}
                        key="room"
                        onClick={() => handleSelectBed(null)}
                    >
                        <span aria-label={t("bedSelector.ariaLabel.roomButton", "room button")}>
                            {t("labelRoom", "Room")}
                        </span>
                    </li>
                ) : null}
                {props.items?.map((sceneLabel, index) => (
                    <li
                        tabIndex={0}
                        role="button"
                        onKeyDown={(event) => handleKeyDown(event, sceneLabel)}
                        className={c({
                            [styles.item]: true,
                            [styles.active]: sceneLabel.id === props.selected,
                        })}
                        key={sceneLabel.id}
                        onClick={() => handleSelectBed(sceneLabel)}
                    >
                        <span
                            aria-label={t("bedSelector.ariaLabel.bedButton", "bed number {{index}} button", {
                                index: index + 1,
                            })}
                        >
                            {t("labelBedMonitoringBedNumber", "Bed {{index}}", { index: index + 1 })}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
};
