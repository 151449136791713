import QRCode, { QRCodeProps } from "react-qr-code";
import React, { useMemo } from "react";

export interface TotpQrCodeProps extends QRCodeProps {
    username: string;
    secret: string;
}

export const TotpQrCode = ({ secret, username, ...props }: Omit<TotpQrCodeProps, "value">) => {
    return useMemo(() => {
        const url = `otpauth://totp/${encodeURIComponent(`CogvisAI: ${username}`)}?secret=${encodeURIComponent(
            secret
        )}`;
        return <QRCode {...props} value={url} />;
    }, [secret, username]);
};
