import React from "react";
import { Label } from "../../components/UI/Label";
import { EventTimeFilter } from "../../components/Events/EventTimeFilter";
import { useTranslation } from "react-i18next";
import { AreaRoom, Id } from "../../api_v2/types/custom";
import { EventTypeFilter } from "../../components/Events/EventTypeFilter";
import { RoomsSelect } from "../../components/RoomsSelect";
import { EventType } from "../../api_v2/hooks/useEvents";
import styles from "./EventsView.module.css";

export interface EventsFilterProps {
    rooms: Array<AreaRoom>;
    selectedRoomId: Id | null;
    onSelectedRoomIdChange: (room: Id | null) => void;

    selectedEventType: EventType | null;
    onSelectedEventTypeChange: (type: EventType | null) => void;

    selectedStartTime: Date | null;
    onSelectedStartTimeChange: (date: Date | null) => void;
}

export const EventsFilter = ({
    rooms,
    selectedRoomId,
    onSelectedRoomIdChange,
    selectedEventType,
    onSelectedEventTypeChange,
    selectedStartTime,
    onSelectedStartTimeChange,
}: EventsFilterProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.filterBar}>
            <div className={styles.left}>
                <div className={styles.filter}>
                    <Label text={t("labelRoom", "Room")}>
                        <RoomsSelect
                            id="EventsFilter_RoomsSelect"
                            rooms={rooms}
                            selected={selectedRoomId}
                            onChange={onSelectedRoomIdChange}
                        />
                    </Label>

                    <EventTypeFilter
                        id="EventsFilter_TypeSelect"
                        selected={selectedEventType}
                        onSelect={onSelectedEventTypeChange}
                    />

                    <EventTimeFilter
                        id="EventsFilter__TimeSelect"
                        selected={selectedStartTime}
                        onSelect={onSelectedStartTimeChange}
                    />
                </div>
            </div>
        </div>
    );
};
