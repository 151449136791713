import { Bar, BarChart, CartesianGrid, Cell, Label, LabelList, ResponsiveContainer, XAxis, YAxis } from "recharts";
import styles from "../DashboardView.module.scss";
import { c } from "../../../utils/ClassesHelper";
import { colors } from "../../../utils/colors";

export interface SimpleBarChartProps<T extends { [key: string | number]: unknown }, K extends keyof T> {
    data: T[];
    color: string | ((entry: T) => string);
    dataKey?: K & string; // defaults to value
    labelKey?: (K & string) | ((entry: T) => string); // defaults to dataKey
    xAxisKey?: (K & string) | string; // defaults to time
    labelVertical?: boolean;
    xAxisWidth?: number;
    xAxisLabel?: string;
    yAxisLabel?: string;
    yAxisDomainMaxValue?: number;
    yAxisHideTicks?: boolean;
}

/**
 * Abstracts the simple bar chart as used in multiple cards.
 */
export const SimpleBarChart = <T extends { [key: string]: unknown }, K extends keyof T>({
    data,
    color,
    dataKey,
    labelKey,
    xAxisKey,
    labelVertical,
    xAxisWidth,
    xAxisLabel,
    yAxisLabel,
    yAxisDomainMaxValue,
    yAxisHideTicks,
}: SimpleBarChartProps<T, K>) => {
    return (
        <ResponsiveContainer className={styles.responsiveChartContainer}>
            <BarChart data={data} margin={{ top: 0, left: 0, right: 0, bottom: 10 }} barGap={0} barCategoryGap={"25%"}>
                <XAxis
                    dataKey={xAxisKey ?? "time"}
                    fontSize={12}
                    axisLine={false}
                    tickLine={true}
                    interval="preserveStartEnd"
                >
                    <Label value={xAxisLabel ?? "Day"} className={styles.label} dy={15} />
                </XAxis>
                <YAxis
                    width={xAxisWidth ?? 40}
                    fontSize={12}
                    axisLine={false}
                    tickLine={false}
                    domain={[0, (dataMax: number) => Math.max(yAxisDomainMaxValue ?? 4, dataMax)]}
                    allowDecimals={false}
                    tick={yAxisHideTicks === true ? { fontSize: 0 } : true}
                >
                    <Label
                        value={yAxisLabel ?? "Number"}
                        angle={-90}
                        className={c([styles.label, styles.labelY])}
                        dx={-13}
                    />
                </YAxis>
                <CartesianGrid stroke={colors.lightGrey} vertical={false} />
                <Bar dataKey={dataKey ?? "value"} isAnimationActive={false}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={typeof color === "string" ? color : color(entry)} />
                    ))}
                    <LabelList
                        dataKey={labelKey ?? dataKey ?? ""}
                        position="inside"
                        fill={colors.white}
                        fontSize="0.75rem"
                        angle={labelVertical ? -90 : 0}
                    />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};
