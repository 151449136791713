import { useAuth } from "../auth/AuthContextProvider";
import { AxiosConfigContext, AxiosRequestConfig } from "@bitperfect-packages/react-query-axios";
import { PropsWithChildren, useEffect, useState } from "react";
import { demoDataInterceptor } from "../utils/demoDataInterceptor";
import { useDemoDataContext } from "./DemoDataContext";
import axios from "axios";
import { demoDataRecorder } from "../utils/demoDataRecorder";
import { Loading } from "../components/UI/Loading";

export const AxiosConfigContextProvider = ({ children }: PropsWithChildren<unknown>) => {
    const { token } = useAuth();
    const demoDataContext = useDemoDataContext();

    const [interceptorsRegistered, setInterceptorsRegistered] = useState(
        !(demoDataContext.enabled || demoDataContext.enableRecording)
    );

    const defaultRequestConfig: AxiosRequestConfig = {
        baseURL: process.env.REACT_APP_API_BASE_URL,
        responseType: "json",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
        timeout: 10000,
    };

    useEffect(() => {
        let requestHandle: number | null = null;
        let responseHandle: number | null = null;

        if (demoDataContext.enabled) {
            const interceptor = demoDataInterceptor(demoDataContext.data);
            requestHandle = axios.interceptors.request.use(interceptor);
        }

        if (demoDataContext.enableRecording) {
            const recorder = demoDataRecorder(demoDataContext.record.current);
            responseHandle = axios.interceptors.response.use(recorder);
        }

        setInterceptorsRegistered(true);

        return () => {
            if (requestHandle) {
                axios.interceptors.request.eject(requestHandle);
            }

            if (responseHandle) {
                axios.interceptors.response.eject(responseHandle);
            }
        };
    }, []);

    if (!interceptorsRegistered) {
        return <Loading />;
    }

    return <AxiosConfigContext.Provider value={defaultRequestConfig}>{children}</AxiosConfigContext.Provider>;
};
