import { NavigateOptions, useLocation, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";

export const useQueryParameters = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
};

// When changing multiple search params in the same render cycle, only the last one would be applied.
// To prevent this from happening, we track all changes in this global parameter and base any changes off of this.
// This can and must be global, because the query parameters are global too
let currentQueryParameters = new URLSearchParams(window.location.search);

/**
 * @deprecated Use route() from RouteHelper instead
 * Setting multiple parameters one after the other will push each change to the history stack individually.
 */
export const useQueryParameter = <T extends string = string>(
    name: string,
    defaultValue: T | null = null
): [T | null, (value: T | null, options?: NavigateOptions) => void] => {
    const [searchParams, setSearchParams] = useSearchParams();

    // Whenever the parameters change, we need to update our local ref
    // Otherwise, we would have invalid data whenever the query params are changed outside of this hook
    useEffect(() => {
        currentQueryParameters = searchParams;
    }, [searchParams.toString()]);

    const set = useCallback(
        (value: string | null, options?: NavigateOptions) => {
            const s = new URLSearchParams(currentQueryParameters);

            if (value === null) {
                s.delete(name);
            } else {
                s.set(name, value);
            }

            // Prevent pushing the same state onto the history stack multiple times
            if (currentQueryParameters.toString() === s.toString()) {
                return;
            }

            currentQueryParameters = s;
            setSearchParams(s, options);
        },
        [searchParams]
    );

    return [(searchParams.get(name) as T) ?? defaultValue, set];
};
