import React from "react";
import { Label } from "../UI/Label";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import styles from "./EventTimeFilter.module.css";
import { useTimezone } from "../../hooks/useTimezone";
import dayjs from "dayjs";

export interface EventTimeFilterProps {
    selected: Date | null;
    onSelect: (date: Date | null) => void;
    id?: string;
}

export const EventTimeFilter = ({ selected, onSelect, id }: EventTimeFilterProps) => {
    const { t } = useTranslation();
    const timezone = useTimezone();

    // We want to show the date in the facilities time zone.
    // Native JS Dates are always kept/converted to the browsers time zone though
    // Therefore we need to convert the date to the facility timezone, drop the timezone information and
    // parse it in local browser time zone, to display it correctly
    const dateWithoutTimezone = dayjs(selected).tz(timezone).format("YYYY-MM-DD HH:mm");
    const dateInTimeZone = selected === null ? null : dayjs(dateWithoutTimezone).toDate();

    const handleChange = (date: Date | null) => {
        // When updating, we need to do the same thing in reverse
        // The date picker gives us a date object, which will always be in local browser time.
        // So we need to effectively drop the timezone and parse it in the facility time zone.
        const dateWithoutTimezone = dayjs(date).format("YYYY-MM-DD HH:mm");
        onSelect(date === null ? null : dayjs.tz(dateWithoutTimezone, "YYYY-MM-DD HH:mm", timezone).toDate());
    };

    return (
        <Label text={t("labelAlertsFilterTime", "Time period until")}>
            {/* @ts-ignore ReactDatePicker does not return a valid JSX element. This is an open issue with React 18: https://github.com/Hacker0x01/react-datepicker/issues/3784 */}
            <DatePicker
                wrapperClassName={styles.datePicker}
                selected={dateInTimeZone}
                showTimeSelect
                onChange={handleChange}
                timeCaption={t("labelAlertsFilterTimeTime", "Time")}
                timeFormat="HH:mm"
                dateFormat="dd.MM.yyyy HH:mm"
                placeholderText={t("labelAlertsFilterTimeAll", "All")}
                isClearable
                id={id}
            />
        </Label>
    );
};
