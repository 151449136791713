import { useLocationContext } from "../context/LocationContextProvider";
import { FacilityStructure, useGetFacility } from "../api_v2/hooks/useFacilities";
import { AreaRoom, FacilityArea } from "../api_v2/types/custom";

/**
 * Returns the current facility structure based on the location context
 */
export const useCurrentFacility = (): FacilityStructure | null => {
    const locationContext = useLocationContext();
    const facilityQuery = useGetFacility(locationContext.facilityId);
    return facilityQuery.data ?? null;
};

/**
 * Returns the current area selected in location context
 * or null if none is selected
 */
export const useCurrentArea = (): FacilityArea | null => {
    const locationContext = useLocationContext();
    const facilityQuery = useGetFacility(locationContext.facilityId);
    return facilityQuery.data?.areas.find((a) => a.id === locationContext.areaId) ?? null;
};

/**
 * Returns all rooms currently in scope.
 * If an area is selected, these are all rooms in the selected area.
 * If no area is selected, this list will contain all rooms in the entire facility.
 */
export const useCurrentRooms = (): Array<AreaRoom> | null => {
    const locationContext = useLocationContext();
    const facilityQuery = useGetFacility(locationContext.facilityId);

    if (!facilityQuery.isSuccess) return null;

    const area = facilityQuery.data?.areas.find((a) => a.id === locationContext.areaId);
    return area ? area.rooms : facilityQuery.data.areas.map((a) => a.rooms).flat();
};
