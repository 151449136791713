import { useTranslation } from "react-i18next";
import { ActivityIdListItem } from "./useTransformHeatmaps";
import styles from "./CustomTooltipForNoData.module.css";

interface CustomTooltipForNoDataProps {
    active?: boolean;
    payload?: Array<{
        payload: ActivityIdListItem;
    }>;
}

export const CustomTooltipForNoData = ({ active, payload }: CustomTooltipForNoDataProps) => {
    const { t } = useTranslation();

    if (active && payload && payload.length > 0) {
        if (payload[0].payload.id) {
            return null;
        } else
            return (
                <div className={styles.noDataTooltipWrapper}>
                    <text className={styles.noDataTooltipText}> {t("noData", "No data")}</text>
                </div>
            );
    }
    return null;
};
