import { AugmentedSceneLabel } from "./AugmentedSceneLabel";
import { SceneLabel } from "../../api_v2/hooks/useSceneLabels";

export const makeAugmentedSceneLabels = (s: SceneLabel): Array<AugmentedSceneLabel> => {
    const sceneLabel = {
        sceneLabel: s,
        isLoading: false,
        isValid: true,
        isFocused: false,
        isNew: false,
        isDragging: false,
    };

    switch (s.type) {
        case "bed-point":
            return [
                {
                    ...sceneLabel,
                    id: s.id,
                    currentX: s.properties.x,
                    currentY: s.properties.y,
                },
            ];

        case "line-2d":
            return [
                {
                    ...sceneLabel,
                    id: `${s.id}-1`,
                    currentX: s.properties.x1,
                    currentY: s.properties.y1,
                    lineTo: `${s.id}-2`,
                },
                {
                    ...sceneLabel,
                    id: `${s.id}-2`,
                    currentX: s.properties.x2,
                    currentY: s.properties.y2,
                },
            ];

        default:
            throw new Error("Invalid scene label type!");
    }
};

export const getAugmentedLabelsForId = (
    augmentedSceneLabels: Array<AugmentedSceneLabel>,
    id: string
): Array<AugmentedSceneLabel> => {
    return augmentedSceneLabels.filter((s) => s.sceneLabel.id === id);
};
