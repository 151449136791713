import { Id } from "../../../../api_v2/types/custom";
import { useState } from "react";
import { ActivityHeatMap } from "../../../../api_v2/hooks/useHeatMap";
import { ActivityMonitoringProps } from "../ActivityMonitoring";
import { MonthlyRoomActivity } from "./MonthlyRoomActivity";
import { DailyRoomActivity } from "./DailyRoomActivity";
import { addHours, startOfDay } from "date-fns";
import { useUserSpecificLocalStorage } from "../../../../hooks/useUserSpecificLocalStorage";
import { colors } from "../../../../utils/colors";

export interface RoomActivityProps extends ActivityMonitoringProps {
    showStatistics: boolean;
    onshowStatistics: (showStatistics: boolean) => void;
}

export const RoomActivity = (props: RoomActivityProps) => {
    const [selectedHeatMapId, setSelectedHeatMapId] = useState<Id | null>(null);
    const [lastWeekLineActive, setLastWeekLineActive] = useUserSpecificLocalStorage<boolean>(
        "lastWeekLineActive",
        false
    );

    const colorsForBars = colors.roomActivityBars;

    const handleChangeHeatmap = (heatMap: ActivityHeatMap | Date) => {
        const isHeatMap = "id" in heatMap;
        setSelectedHeatMapId(isHeatMap ? heatMap.id : null);
        props.onModeChange("day");
        if (props.mode === "month") {
            props.onDateRangeChange(
                {
                    start: startOfDay(isHeatMap ? new Date(heatMap.startTime) : heatMap),
                    end: addHours(startOfDay(isHeatMap ? new Date(heatMap.startTime) : heatMap), 24),
                },
                true // We do want to overwrite the mode change day from above, otherwise there would be two entries on the history stack
            );
        }
    };

    return (
        <>
            {props.mode === "month" ? (
                <MonthlyRoomActivity
                    {...props}
                    selectedHeatMapId={selectedHeatMapId}
                    onSelectedHeatMapId={setSelectedHeatMapId}
                    onChangeHeatmap={handleChangeHeatmap}
                    showStatistics={props.showStatistics}
                    onshowStatistics={props.onshowStatistics}
                    colorsForBars={colorsForBars}
                />
            ) : (
                <DailyRoomActivity
                    {...props}
                    selectedHeatMapId={selectedHeatMapId}
                    onSelectedHeatMapId={setSelectedHeatMapId}
                    onChangeHeatmap={handleChangeHeatmap}
                    lastWeekLineActive={lastWeekLineActive}
                    onLastWeekLineActive={setLastWeekLineActive}
                    colorsForBars={colorsForBars}
                />
            )}
        </>
    );
};
