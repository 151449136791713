import React from "react";
import { Button } from "../../components/UI/Button/Button";
import { Card } from "../../components/UI/Card/Card";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { EmailField } from "../fields/EmailField";
import { useRequestPasswordReset } from "../cognito/useRequestPasswordReset";
import styles from "../Auth.module.css";

type Inputs = {
    username: string;
};

export const RequestPasswordReset = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const requestPasswordReset = useRequestPasswordReset();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<Inputs>({ mode: "onTouched" });

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const username = data.username.toLowerCase();
        setValue("username", username, { shouldValidate: false, shouldDirty: false });
        requestPasswordReset.mutate(
            { Username: username },
            {
                onSuccess: () => {
                    navigate("/reset-password", {
                        state: {
                            Username: username,
                        },
                    });
                },
            }
        );
    };

    return (
        <Card className={styles.card}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <strong className={styles.title}>{t("titleRequestPasswordReset", "Reset your password")}</strong>

                {requestPasswordReset.isError ? <p className={styles.error}>{t(requestPasswordReset.error)}</p> : null}

                <div>
                    <EmailField
                        id="UsernameInput"
                        name="username"
                        register={register}
                        errors={errors}
                        disabled={requestPasswordReset.isLoading}
                    />
                </div>
                <div className={styles.bottom}>
                    <small className={styles.resetPassword}>
                        <Link to="/login">{t("promptBackToLogin", "Back to login")}</Link>
                    </small>
                    <Button
                        id="RequestPasswordResetButton"
                        primary={true}
                        type="submit"
                        className={styles.button}
                        disabled={requestPasswordReset.isLoading}
                    >
                        {t("labelRequestPasswordReset", "Send code")}
                    </Button>
                </div>
            </form>
        </Card>
    );
};
