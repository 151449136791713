import { format } from "date-fns-tz";
import { useLocale } from "./useLocale";

/**
 * Wraps around date-fns format but provides the users selected locale.
 * This also adds 2 new pattern options:
 * P-no-year, PP-no-year
 * They are basically the same as P and PP, but without the year.
 * See {@localizedDateWithoutYear} for explanation.
 */
export const useFormat = () => {
    const locale = useLocale();
    const customFormat = useCustomDateTimeFormat();

    // @deprecated - just use useCustomDateTimeFormat directly
    const specials: Record<string, Intl.DateTimeFormatOptions> = {
        "P-no-year": { month: "2-digit", day: "2-digit" },
        "PP-no-year": { month: "long", day: "numeric" },
        "p-no-minute": { hour: "numeric", hour12: true },
    };

    return (
        date: Date | number,
        pattern: string,
        options?: {
            locale?: Locale;
            weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
            firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
            useAdditionalWeekYearTokens?: boolean;
            useAdditionalDayOfYearTokens?: boolean;
            timeZone?: string;
        }
    ): string => {
        if (pattern in specials) {
            return customFormat(date, specials[pattern]);
        }

        return format(date, pattern, { locale, ...options });
    };
};

/**
 * Use this for all formats that date-fns can not produce.
 * This uses the Intl.DateTimeFormat directly.
 */
export const useCustomDateTimeFormat = () => {
    const locale = useLocale();

    return (
        date: Date | number,
        formatOptions: Intl.DateTimeFormatOptions,
        customLocale: Locale | undefined = undefined
    ) => new Intl.DateTimeFormat(customLocale?.code ?? locale.code, formatOptions).format(date);
};
