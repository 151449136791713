import { TabProps } from "../TabProps";
import { useTranslation } from "react-i18next";
import { ReactElement, useState } from "react";
import { SceneLabelSelector } from "../../../SceneLabelSelector/SceneLabelSelector";
import { Button } from "../../../UI/Button/Button";
import { canAccessSettings } from "../../settingsSchemaUtils";
import { BedSelector } from "../../../AnalysisModal/ActivityMonitoring/BedActivity/BedSelector";
import { SceneLabel } from "../../../../api_v2/hooks/useSceneLabels";
import { useHasFeatureFlags } from "../../../../hooks/useHasFeatureFlags";
import styles from "../BedMonitoringTab/BedMonitoringTab.module.css";

interface MonitoringViewProps extends TabProps {
    showBedRails?: boolean;
    allowEditMode?: boolean;
    enableRoomSettings: boolean;
    children: (props: MonitoringViewProps, selectedBedLocation: SceneLabel | null) => ReactElement;
}

export const MonitoringView = (props: MonitoringViewProps) => {
    const { t } = useTranslation();

    const checkFeatureFlag = useHasFeatureFlags();

    const bedActivityEnabled = checkFeatureFlag("enable-bed-activity");
    const roomActivityEnabled = checkFeatureFlag("enable-room-activity");

    const [selectedSceneLabelId, setSelectedSceneLabelId] = useState<string | undefined>(() => {
        // when opening a tab it should select the first item in the legend (room > bed > bedrail)
        if (props.enableRoomSettings) {
            return undefined;
        }

        return props.sceneLabels.find((s) => s.type === "bed-point")?.id ?? props.sceneLabels[0]?.id;
    });

    const selectedSceneLabel = props.sceneLabels.find((s) => s.id === selectedSceneLabelId);

    const canAccessBedLocations =
        canAccessSettings("absenceDetection", props.settingsSchema) ||
        canAccessSettings("getupDetection", props.settingsSchema);
    const canAccessBedRails = props.showBedRails ? canAccessSettings("bedRails", props.settingsSchema) : false;

    const handleImageClick = () => {
        props.setSceneLabelEditModeActive(true);
    };

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <SceneLabelSelector
                    device={props.device.device}
                    readonly={true}
                    onSelect={setSelectedSceneLabelId}
                    selectedSceneLabelId={selectedSceneLabel?.id}
                    hideBedPoints={!canAccessBedLocations}
                    hideBedRails={!canAccessBedRails}
                    hideLegend={props.enableRoomSettings}
                    onClick={props.allowEditMode ? handleImageClick : undefined}
                    settings={props.settings}
                />

                {props.enableRoomSettings ? (
                    <div className={styles.bedSelector}>
                        <BedSelector
                            items={
                                bedActivityEnabled && canAccessBedLocations
                                    ? props.sceneLabels.filter((l) => l.type === "bed-point")
                                    : []
                            }
                            selected={selectedSceneLabelId ?? null}
                            onSelect={(id) => setSelectedSceneLabelId(id ?? undefined)}
                            nullable={roomActivityEnabled}
                        />
                    </div>
                ) : null}

                {props.allowEditMode ? (
                    <div className={styles.editButtonContainer}>
                        {!props.readonly ? (
                            <Button
                                onClick={() => props.setSceneLabelEditModeActive(true)}
                                id="ActivateSceneLabelEditModeButton"
                            >
                                {t("labelPointSelectorEdit", "Edit points")}
                            </Button>
                        ) : null}
                    </div>
                ) : null}
            </div>

            <div className={styles.right}>
                {props.children(props, selectedSceneLabel ?? null)}

                {selectedSceneLabelId !== undefined && !selectedSceneLabel ? (
                    props.readonly ? (
                        <p>
                            {t(
                                "infoMonitoringNoPointsOrBarriersReadonly",
                                "No bed points and virtual bed rails are set."
                            )}
                        </p>
                    ) : (
                        <>
                            <p>
                                {t(
                                    "infoMonitoringNoPointsOrBarriers",
                                    'Please click on "Edit points" to add bed points and virtual bed rails.'
                                )}
                            </p>
                            <div className={styles.btnContainer}>
                                <Button onClick={() => props.setSceneLabelEditModeActive(true)}>
                                    {t("labelPointSelectorEdit", "Edit points")}
                                </Button>
                            </div>
                        </>
                    )
                ) : null}
            </div>
        </div>
    );
};
