import React from "react";
import { useCustomDateTimeFormat, useFormat } from "../../../hooks/useFormat";
import { differenceInHours, formatDistance, isToday, isYesterday } from "date-fns";
import { useNow } from "../../../hooks/useNow";
import { useLocale } from "../../../hooks/useLocale";
import { useTranslation } from "react-i18next";
import { useTimezone } from "../../../hooks/useTimezone";

type TimeProps = {
    time: string | Date | null;
    relative: boolean;
};

const useCreateRelativeTimeDisplay = () => {
    const { t } = useTranslation();
    const locale = useLocale();
    const format = useFormat();
    const createAbsoluteTimeDisplay = useCreateAbsoluteTimeDisplay();

    return (date: Date, now: Date, timezone: string) => {
        if (differenceInHours(date, now) > -1) {
            return `${t("relativeTimeLabel", "{{relativeTime}} ago", {
                relativeTime: formatDistance(date, now, { locale }),
            })}`;
        }

        if (isToday(date)) {
            return `${t("labelTimeToday", "Today")}, ${format(date, "p", { timeZone: timezone })}`;
        }

        if (isYesterday(date)) {
            return `${t("labelTimeYesterday", "Yesterday")}, ${format(date, "p", { timeZone: timezone })}`;
        }

        return createAbsoluteTimeDisplay(date, timezone);
    };
};

const useCreateAbsoluteTimeDisplay = () => {
    const format = useCustomDateTimeFormat();

    return (date: Date, timezone: string) =>
        format(date, {
            weekday: "short",
            year: "numeric",
            month: "long",
            day: "numeric",

            hour: "numeric",
            minute: "numeric",

            timeZone: timezone,
        });
};

export const TimeDisplay = ({ time, relative }: TimeProps) => {
    const now = useNow("minute");

    const timezone = useTimezone();
    const createAbsoluteTimeDisplay = useCreateAbsoluteTimeDisplay();
    const createRelativeTimeDisplay = useCreateRelativeTimeDisplay();

    const parsedTime = time ? new Date(time) : null;

    if (!parsedTime) return null;

    return (
        <>
            {relative
                ? createRelativeTimeDisplay(parsedTime, now, timezone)
                : createAbsoluteTimeDisplay(parsedTime, timezone)}
        </>
    );
};
