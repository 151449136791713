import React, { ReactNode } from "react";
import { SensitivitySlider, SensitivityValues } from "./SensitivitySlider";
import { Label } from "./Label";
import SensitivityLabel from "./SensitivityLabel";
import styles from "./SensitivitySlider.module.css";
import { c } from "../../utils/ClassesHelper";

interface SensitivitySliderLabelComboProps {
    text: ReactNode;
    sensitivity: SensitivityValues;
    onChange: (value: SensitivityValues) => void;
    disabled?: boolean;
    readonly?: boolean;
    className?: string;
    id?: string;
}

export const SensitivitySliderLabelCombo = (props: SensitivitySliderLabelComboProps) => {
    const sensitivityLabel = (
        <>
            {props.text}
            {!props.readonly ? (
                <>
                    : <SensitivityLabel sensitivity={props.sensitivity} disabled={props.disabled} />
                </>
            ) : null}
        </>
    );

    return (
        <Label text={sensitivityLabel} additionalClassNames={c([styles.sliderLabelCombo_label, props.className])}>
            {!props.readonly ? (
                <SensitivitySlider
                    value={props.sensitivity}
                    readonly={props.readonly}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    id={props.id}
                />
            ) : (
                <SensitivityLabel sensitivity={props.sensitivity} disabled={props.disabled} />
            )}
        </Label>
    );
};
