import { useTranslation } from "react-i18next";
import { TimeWindow, TimeWindowFullHours, TimeWindows } from "../../../../api_v2/types/custom";
import { Label } from "../../../UI/Label";
import { TimeWindowsList } from "../../../TimeWindows/TimeWindowsList";
import { ToggleSwitch } from "../../../UI/ToggleSwitch";
import _ from "lodash";
import { TabProps } from "../TabProps";
import styles from "./ActivityMonitoringSettings.module.css";
import { InputGroup } from "../../../UI/InputGroup";

export const RoomActivityMonitoringSettings = (props: TabProps) => {
    const { t } = useTranslation();

    const handleEnableChange = (enabled: boolean) => {
        const newBedActivitySettings = _.cloneDeep(props.settings);
        newBedActivitySettings.roomActivityEnable = enabled;
        props.onSettingsChange(newBedActivitySettings);
    };

    const handleTimeWindowChange = (timeWindows: TimeWindows) => {
        const newSettings = _.cloneDeep(props.settings);
        newSettings.roomActivityTimeWindows = timeWindows.map(
            (timeWindow): TimeWindowFullHours => ({
                from: parseInt(timeWindow.from.split(":")[0]),
                until: parseInt(timeWindow.until.split(":")[0]),
            })
        );
        props.onSettingsChange(newSettings);
    };

    return (
        <div>
            <strong className={styles.title}>{t("alertTypeRoomActivity", "Room activity")}</strong>
            <div>
                <InputGroup>
                    <Label text="" additionalClassNames={styles.block}>
                        <ToggleSwitch
                            state={props.settings.roomActivityEnable ?? false}
                            onChange={handleEnableChange}
                            checkedLabel={t("labelActivated", "Activated")}
                            uncheckedLabel={t("labelDeactivated", "Deactivated")}
                            id="RoomActivityEnableSwitch"
                        />
                    </Label>
                </InputGroup>

                <InputGroup>
                    <Label text={t("labelBedActivitySchedule", "Analysis period")} additionalClassNames={styles.block}>
                        <TimeWindowsList
                            timeWindows={(
                                props.settings.roomActivityTimeWindows ?? [
                                    {
                                        from: "00:00",
                                        until: "00:00",
                                    },
                                ]
                            ).map(
                                (timeWindowFullHour): TimeWindow => ({
                                    from: `${timeWindowFullHour.from}:00`,
                                    until: `${timeWindowFullHour.until}:00`,
                                })
                            )}
                            readonly={props.readonly}
                            onChange={handleTimeWindowChange}
                            id="RoomActivityTimeWindows"
                            sliderSteps={60}
                            sliderMax={60 * 60 * 24}
                            disabled={!props.settings.roomActivityEnable}
                        />
                    </Label>
                </InputGroup>
            </div>
        </div>
    );
};
