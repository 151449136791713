import { useMutation } from "react-query";
import { CodeDeliveryDetails, IAuthenticationDetailsData } from "amazon-cognito-identity-js";
import { getCognitoUserFromAuthDetails } from "./helpers";

export const useRequestPasswordReset = () => {
    return useMutation<CodeDeliveryDetails, string, IAuthenticationDetailsData>(
        "request-password-reset",
        (authData) => {
            return forgotPassword(authData);
        }
    );
};

const forgotPassword = (authenticationData: IAuthenticationDetailsData) =>
    new Promise<CodeDeliveryDetails>((resolve, reject) => {
        const cognitoUser = getCognitoUserFromAuthDetails(authenticationData);
        cognitoUser.forgotPassword({
            onSuccess: (data: CodeDeliveryDetails) => {
                resolve(data);
            },
            onFailure: (err) => {
                reject(err.message || JSON.stringify(err));
            },
        });
    });
