import { useTranslation } from "react-i18next";
import { View } from "../../components/View";
import { BiInfoCircle } from "react-icons/bi";
import { Id } from "../../api_v2/types/custom";
import { Select } from "../../components/UI/Select/Select";
import { c } from "../../utils/ClassesHelper";
import { useCurrentRoomsTree } from "../RoomsView/helper/useCurrentRoomsTree";
import { Loading } from "../../components/UI/Loading";
import { DeviceWithStatus } from "../RoomsView/helper/Nodes";
import { DailyDigest } from "./DailyDigest";
import { WeeklyDigest } from "./WeeklyDigest";
import { MonthlyDigest } from "./MonthlyDigest";
import { Tooltip } from "../../components/Tooltip/Tooltip";
import { useQueryParameters } from "../../utils/useQueryParameters";
import { useNavigate } from "react-router-dom";
import { isDockable, isDocked } from "../../utils/DeviceHelper";
import { DeviceNotDockedNotice } from "./DeviceNotDockedNotice";
import styles from "./DashboardView.module.scss";

export const DashboardView = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const queryParams = useQueryParameters();

    const selectedDeviceId = queryParams.get("device");
    const roomTree = useCurrentRoomsTree(null, false);
    const devices = roomTree.flatMap((r) => r.devices);

    const selectedDevice: DeviceWithStatus =
        devices.find((d) => d.device.id === selectedDeviceId) ?? devices[0] ?? null;

    // All devices that are either not dockable or that are dockable and docked are okay
    const canShowDashboard = (device: DeviceWithStatus) => !(isDockable(device.device) && !isDocked(device.device));

    const devicesMap = new Map(
        roomTree.flatMap((room) =>
            room.devices
                .filter(canShowDashboard)
                .map((device) => [device.device.id, `${room.room.name} - ${device.device.name ?? device.device.id}`])
        )
    );

    // replace:true, so that if the user clicks the back-button in the browser,
    // it is not the dashboard of the last selected sensor shown but instead the rooms page,
    // where the user is coming from
    const handleNavigate = (deviceId: Id) => {
        navigate(`/dashboard?device=${encodeURIComponent(deviceId)}`, { replace: true });
    };

    if (selectedDevice === null) {
        return <Loading />;
    }

    return (
        <View
            title={
                <>
                    <span>{t("titleDashboard", "Statistics")}</span>
                    <span className={styles.info_testphase}>
                        {" - "}
                        <Tooltip
                            panel={
                                <span className={styles.tooltip}>
                                    {t(
                                        "infoDashboardTestPhaseText",
                                        "Please note that the current view is in a testing phase and is subject to change. We cannot guarantee that this view will be available permanently."
                                    )}
                                </span>
                            }
                        >
                            {t("infoDashboardTestPhase", "in testing phase")} <BiInfoCircle />
                        </Tooltip>
                    </span>
                </>
            }
            className={c([styles.container, styles.dense])}
            headerChildren={
                <Select
                    nullable={false}
                    items={devicesMap}
                    selected={selectedDevice.device.id}
                    onSelect={handleNavigate}
                    width="300px"
                />
            }
            alignHeaderChildren="center"
            maxWidth={1800}
            standalone={true}
        >
            {!canShowDashboard(selectedDevice) ? (
                <DeviceNotDockedNotice device={selectedDevice.device} />
            ) : (
                <>
                    <DailyDigest selectedDeviceId={selectedDevice.device.id} roomId={selectedDevice.device.roomId} />
                    <WeeklyDigest selectedDevice={selectedDevice} roomId={selectedDevice.device.roomId} />
                    <MonthlyDigest selectedDeviceId={selectedDevice.device.id} />
                </>
            )}
        </View>
    );
};
