import React from "react";
import { CardGrid } from "./Container/CardGrid";
import { CardGroup } from "./Container/CardGroup";
import { Id } from "../../api_v2/types/custom";
import { useNow } from "../../hooks/useNow";
import { useFormat } from "../../hooks/useFormat";
import { subDays } from "date-fns";
import { MonthlyActivityCard } from "./Cards/MonthlyActivityCard/MonthlyActivityCard";
import { MonthlyCareAcuityCard } from "./Cards/MonthlyCareAcuityCard";
import { MonthlyAlertsAndPresenceButtonCard } from "./Cards/MonthlyAlertsAndPresenceButtonCard";
import { useTranslation } from "react-i18next";
import { useHasFeatureFlags } from "../../hooks/useHasFeatureFlags";

export interface MonthlyDigestProps {
    selectedDeviceId: Id;
}

export const MonthlyDigest = ({ selectedDeviceId }: MonthlyDigestProps) => {
    const { t } = useTranslation();
    const now = useNow("day");
    const format = useFormat();
    const start = subDays(now, 29);
    const hasFeatureFlag = useHasFeatureFlags();

    const enableRoomActivity = hasFeatureFlag("enable-room-activity");
    const enableBedActivity = hasFeatureFlag("enable-bed-activity");

    return (
        <CardGroup
            title={t("titleMonthlyDigestSection", "Monthly Digest")}
            info={`${format(start, "PPP")} - ${format(now, "PPP")}`}
            initialOpen={false}
        >
            <CardGrid>
                {enableRoomActivity || enableBedActivity ? (
                    <MonthlyActivityCard
                        deviceId={selectedDeviceId}
                        interval={{ start, end: now }}
                        key={selectedDeviceId}
                        enableBedActivity={enableBedActivity}
                        enableRoomActivity={enableRoomActivity}
                    />
                ) : null}

                <MonthlyCareAcuityCard deviceId={selectedDeviceId} interval={{ start, end: now }} />
                <MonthlyAlertsAndPresenceButtonCard deviceId={selectedDeviceId} interval={{ start, end: now }} />
            </CardGrid>
        </CardGroup>
    );
};
