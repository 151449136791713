import { PropsWithChildren } from "react";
import { c } from "../../../utils/ClassesHelper";
import styles from "./Alert.module.css";
import { BiInfoCircle, BiError, BiErrorAlt } from "react-icons/bi";
import { FaSpinner } from "react-icons/fa";

export interface AlertProps {
    type: "info" | "warning" | "error" | "loading";
    hideIcon?: boolean;
}

const AlertIcon: Record<AlertProps["type"], JSX.Element> = {
    info: <BiInfoCircle />,
    warning: <BiError />,
    error: <BiErrorAlt />,
    loading: <FaSpinner />,
};

export const Alert = ({ type, children, hideIcon }: PropsWithChildren<AlertProps>) => {
    return (
        <p className={c([styles.alert, styles[type]])}>
            {hideIcon !== true ? AlertIcon[type] : null} {children}
        </p>
    );
};
