import React from "react";
import { useTranslation } from "react-i18next";
import { c } from "../../utils/ClassesHelper";
import { Button } from "../../components/UI/Button/Button";
import { toast } from "react-toastify";
import { Toast } from "../../components/UI/Toast/Toast";
import { useSetMfa } from "../cognito/useSetMfa";
import styles from "../Auth.module.css";

export interface DisableMfaProps {
    onClose: () => void;
}

export const DisableMfa = ({ onClose }: DisableMfaProps) => {
    const { t } = useTranslation();
    const setMfa = useSetMfa();

    const onClick = async () => {
        await setMfa.mutateAsync({ enable: false });

        toast.success(
            <Toast
                title={t("mfa.titleDisabled", "Two-factor authentication disabled")}
                description={t("mfa.textDisabled", "Two-factor authentication has been successfully disabled.")}
            />
        );
        onClose();
    };

    return (
        <>
            <strong className={styles.mfaTitle}>{t("mfa.titleDisable", "Disable Two-factor authentication")}</strong>

            {setMfa.isError ? (
                <p className={c([styles.error, styles.errorMb])}>
                    {t(
                        "mfa.errorDisablingMfa",
                        "There was an error disabling two-factor authentication. Please try again later."
                    )}
                </p>
            ) : null}

            <p>
                {t(
                    "mfa.disableMfaDescription",
                    "Disabling two-factor authentication puts your account protection at risk. We recommend keeping two-factor authentication activated to protect your account from unauthorized access."
                )}
            </p>

            <div className={styles.btnContainer}>
                <Button id="DisableMfaButton" color="primary" onClick={onClose}>
                    {t("MFA.KeepBtn", "Keep 2FA enabled")}
                </Button>
                <Button id="DisableMfaButton" color="primary" variant="filled" onClick={onClick}>
                    {t("MFA.DisableBtn", "Disable 2FA")}
                </Button>
            </div>
        </>
    );
};
