import React, { ReactNode } from "react";

import styles from "./Select.module.css";

export type SelectProps<T> = {
    items: Map<T, string>;
    selected: T | null;
    default?: string;
    onSelect: (item: T | null) => void;
    placeholder?: string;
    id?: string;
};

type SelectState<T> = {
    itemMap: Map<T, number>;
};

/**
 * @deprecated Use Select/Select.tsx instead
 */
class Select<T> extends React.Component<SelectProps<T>, SelectState<T>> {
    constructor(props: SelectProps<T>) {
        super(props);

        this.state = {
            itemMap: new Map<T, number>(),
        };
    }

    render(): ReactNode {
        const options = Array.from(this.state.itemMap.entries()).map(([item, index]) => {
            return this.createOption(index, this.props.items.get(item) as string);
        });

        if (this.props.default) {
            options.unshift(this.createOption(-1, this.props.default));
        } else if (this.props.placeholder) {
            options.unshift(this.createOption(-1, this.props.placeholder, true));
        }

        const index =
            this.props.selected && this.state.itemMap.has(this.props.selected)
                ? this.state.itemMap.get(this.props.selected)
                : -1;

        return (
            <select
                className={styles.select}
                value={index}
                onChange={(e) => {
                    this.handleChange(e);
                }}
                id={this.props.id}
            >
                {options}
            </select>
        );
    }

    componentDidMount(): void {
        this.calculateItemMap();
    }

    componentDidUpdate(prevProps: Readonly<SelectProps<T>>): void {
        if (prevProps.items !== this.props.items) {
            this.calculateItemMap();
        }
    }

    private calculateItemMap() {
        this.setState({
            itemMap: new Map(Array.from(this.props.items.entries()).map(([item], index) => [item, index])),
        });
    }

    private getItemsArray() {
        return Array.from(this.props.items.entries());
    }

    private createOption(index: number, text: string, disabled = false) {
        return (
            <option key={index} value={index} disabled={disabled}>
                {text}
            </option>
        );
    }

    private handleChange(e: React.FormEvent<HTMLSelectElement>) {
        const value: number = parseInt(e.currentTarget.value);

        if (value == -1) {
            this.props.onSelect(null);
        } else {
            this.props.onSelect(this.getItemsArray()[value][0]);
        }
    }
}

export { Select };
