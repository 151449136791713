import { components } from "../types/schema";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useFetch, useMutate } from "@bitperfect-packages/react-query-axios";
import { Id, Settings } from "../types/custom";
import { useInvalidateAnalysisModuleStatuses, useInvalidateDeviceStatuses } from "./useDevices";
import { callAfter } from "./common/useDelayedInvalidateQueries";
import { UseQueryOptions } from "react-query/types/react/types";

export type DeviceSettingsSchema = components["schemas"]["DeviceConfigurationSchema"];
export type DeviceSettings = Omit<components["schemas"]["DeviceSettings"], "settings"> & { settings: Settings };
export type DeviceSettingsWrite = Omit<components["schemas"]["UpsertDeviceSettingsProps"], "settings"> & {
    settings: Settings;
};

export const useInvalidateDeviceSettings = () => {
    const queryClient = useQueryClient();

    return (deviceId: Id) => queryClient.invalidateQueries({ queryKey: ["devices", deviceId, "settings"] });
};

export const useDeviceSettingsSchema = (deviceId: Id) => {
    return useQuery<DeviceSettingsSchema>(
        ["devices", deviceId, "configuration"],
        useFetch<DeviceSettingsSchema>(`/devices/${deviceId}/configuration`),
        { refetchOnWindowFocus: false }
    );
};

export const useDeviceSettings = (deviceId: Id | undefined, options?: Partial<UseQueryOptions<DeviceSettings>>) => {
    return useQuery<DeviceSettings>(
        ["devices", deviceId, "settings"],
        useFetch<DeviceSettings>(`/devices/${deviceId}/settings`),
        {
            refetchOnWindowFocus: false,
            enabled: deviceId !== undefined,
            keepPreviousData: false,
            ...options,
        }
    );
};

export interface SaveDeviceSettingsParams {
    deviceId: Id;
    data: DeviceSettingsWrite;
}

export interface SaveDeviceSettingsErrorResponse {
    response: {
        data: {
            message: string;
        };
    };
}

/**
 * @param facilityId If given, status and analysis module statutes for the current facility will be updated
 */
export const useSaveDeviceSettings = (facilityId?: Id) => {
    const invalidateSettings = useInvalidateDeviceSettings();
    const invalidateStatus = useInvalidateDeviceStatuses();
    const invalidateAnalysisModuleStatuses = useInvalidateAnalysisModuleStatuses();

    return useMutation<void, SaveDeviceSettingsErrorResponse, SaveDeviceSettingsParams>(
        ["save", "devices", "settings"],
        useMutate<void, SaveDeviceSettingsParams>("PUT", (params) => `/devices/${params.deviceId}/settings`, {
            requestConfigTransform: (params, config) => ({
                ...config,
                data: params.data,
            }),
        }),
        {
            onSuccess: (result, params) => {
                invalidateSettings(params.deviceId);
                if (facilityId) {
                    const intervals = [2500, 5000, 10000, 15000, 30000, 60000];
                    callAfter(() => invalidateStatus(facilityId), intervals);
                    callAfter(() => invalidateAnalysisModuleStatuses(facilityId), intervals);
                }
            },
        }
    );
};
