import React from "react";
import { useTranslation } from "react-i18next";
import { Id, Settings } from "../../api_v2/types/custom";
import { AugmentedSceneLabel } from "./AugmentedSceneLabel";
import { v4 as uuidv4 } from "uuid";
import { SceneLabel, useDeleteSceneLabel } from "../../api_v2/hooks/useSceneLabels";
import { formatISO } from "date-fns";
import { getAugmentedLabelsForId } from "./AugmentedSceneLabelHelper";
import { getFillForBedPoint } from "./Elements/SceneLabelBedPoint";
import { LegendSection } from "./Legend/LegendSection";
import styles from "./Legend.module.css";
import { usePrompts } from "../Prompts/usePrompts";

const maxBedPoints = 2;
const maxLightBarriers = 3;

export interface LegendProps {
    deviceId: Id;
    sceneLabels: Array<AugmentedSceneLabel>;
    setSceneLabels: React.Dispatch<React.SetStateAction<AugmentedSceneLabel[]>>;
    onAugmentedSceneLabelChange: (label: AugmentedSceneLabel, newlyCreated?: boolean) => void;
    readonly?: boolean;
    disabled?: boolean;
    selectedSceneLabelId?: string;
    onSelect?: (sceneLabelId: string) => void;
    hideBedPoints?: boolean;
    hideBedRails?: boolean;
    onSceneLabelDelete?: (deletedSceneLabelId: Id) => void;
    settings?: Settings;
}

export const Legend = ({
    deviceId,
    sceneLabels,
    setSceneLabels,
    readonly,
    disabled,
    onAugmentedSceneLabelChange,
    selectedSceneLabelId,
    onSelect,
    hideBedPoints,
    hideBedRails,
    onSceneLabelDelete,
    settings,
}: LegendProps) => {
    const { t } = useTranslation();
    const deleteSceneLabel = useDeleteSceneLabel(deviceId);

    const [prompts, showPrompt] = usePrompts();

    const handleAddBedPoint = () => {
        const uuid = uuidv4();

        setSceneLabels(
            (currentSceneLabels): Array<AugmentedSceneLabel> => [
                ...currentSceneLabels,
                {
                    id: uuid,
                    sceneLabel: {
                        id: uuid,
                        time: formatISO(new Date()),
                        type: "bed-point",
                        properties: {
                            x: 320,
                            y: 240,
                        },
                    },
                    isFocused: false,
                    isValid: true,
                    isLoading: false,
                    isNew: true,
                    isDragging: false,
                    currentX: 320,
                    currentY: 240,
                },
            ]
        );
    };

    const handleAddLightBarrier = () => {
        const uuid = uuidv4();

        const sceneLabel: SceneLabel = {
            id: uuid,
            time: formatISO(new Date()),
            type: "line-2d",
            properties: {
                x1: 210,
                y1: 240,
                x2: 430,
                y2: 240,
                // minLength and onFloor are simply hard coded
                minLength: 1000,
                onFloor: true,
            },
        };

        setSceneLabels(
            (currentSceneLabels): Array<AugmentedSceneLabel> => [
                ...currentSceneLabels,
                {
                    id: `${uuid}-1`,
                    sceneLabel: sceneLabel,
                    isFocused: false,
                    isValid: true,
                    isLoading: false,
                    isNew: true,
                    isDragging: false,
                    currentX: sceneLabel.properties.x1,
                    currentY: sceneLabel.properties.y1,
                    lineTo: `${uuid}-2`,
                },
                {
                    id: `${uuid}-2`,
                    sceneLabel: sceneLabel,
                    isFocused: false,
                    isValid: true,
                    isLoading: false,
                    isNew: true,
                    isDragging: false,
                    currentX: sceneLabel.properties.x2,
                    currentY: sceneLabel.properties.y2,
                },
            ]
        );
    };

    const handleDelete = async (s: AugmentedSceneLabel) => {
        const bedPoints = sceneLabels.filter((s) => s.sceneLabel.type === "bed-point");
        const bedRails = sceneLabels.filter((s) => s.sceneLabel.type === "line-2d" && "lineTo" in s);
        const indexOfBedPoints = bedPoints.findIndex((b) => b.id === s.id);
        const indexOfBedRails = bedRails.findIndex((b) => b.id === s.id);

        const activityId: Id | undefined = settings?.bedActivityLocations?.find((b) => b.id === s.sceneLabel.id)?.id;

        const title =
            indexOfBedPoints > -1
                ? t("Legend.titleDeleteBedPointDataWarning", "Do you really want to delete bed {{index}}?", {
                      index: indexOfBedPoints + 1,
                  })
                : t(
                      "Legend.titleDeleteBedRailDataWarning",
                      "Do you really want to delete virtual bed rail {{index}}?",
                      {
                          index: indexOfBedRails + 1,
                      }
                  );

        const accept = (await showPrompt({
            title: title,
            message: activityId
                ? t(
                      "Legend.textDeleteBedPointDataWarning",
                      "The associated activity data will be deleted in the process."
                  )
                : "",
            options: [
                { label: t("genericNo", "No"), value: false, primary: false },
                { label: t("genericYes", "Yes"), value: true, primary: true },
            ],
        })) as boolean;

        if (!accept) return;

        if (!s.isNew) {
            const sceneLabelId = s.sceneLabel.id;
            deleteSceneLabel.mutate(
                { sceneLabelId },
                {
                    onSuccess: () => onSceneLabelDelete?.(sceneLabelId),
                }
            );
        }

        const augmentedSceneLabelIds = getAugmentedLabelsForId(sceneLabels, s.sceneLabel.id).map((s) => s.id);
        setSceneLabels((currentSceneLabels) =>
            currentSceneLabels.filter((s) => !augmentedSceneLabelIds.includes(s.id))
        );
    };

    return (
        <div className={styles.container}>
            {hideBedPoints !== true ? (
                <LegendSection
                    sceneLabels={sceneLabels.filter((s) => s.sceneLabel.type === "bed-point")}
                    allSceneLabels={sceneLabels}
                    onAugmentedSceneLabelChange={onAugmentedSceneLabelChange}
                    onDelete={handleDelete}
                    addLabel={t("labelPointSelectorAddBed", "Add bed")}
                    getFill={getFillForBedPoint}
                    getLabelFor={(i, total) =>
                        total > 1
                            ? t("labelBedMonitoringBedNumber", {
                                  index: i + 1,
                              })
                            : t("labelBed", "Bed")
                    }
                    maxLabels={maxBedPoints}
                    onAdd={handleAddBedPoint}
                    title={t("titlePointSelectorBed", "Bed point")}
                    style={styles.points}
                    readonly={readonly}
                    disabled={disabled}
                    id="SceneLabelSelector_BedPoints"
                    selectedSceneLabelId={selectedSceneLabelId}
                    onSelect={onSelect}
                    settings={settings}
                />
            ) : null}
            {prompts}

            {hideBedRails !== true ? (
                <LegendSection
                    sceneLabels={sceneLabels.filter((s) => s.sceneLabel.type === "line-2d" && s.lineTo)}
                    allSceneLabels={sceneLabels}
                    onAugmentedSceneLabelChange={onAugmentedSceneLabelChange}
                    onDelete={handleDelete}
                    addLabel={t("labelPointSelectorAddBarrier", "Add virtual bed rail")}
                    getFill={getFillForBedPoint}
                    getLabelFor={(i, total) =>
                        total > 1
                            ? t("labelBedMonitoringBarrierNumber", {
                                  index: i + 1,
                              })
                            : t("labelBarrier", "Virtual bed rail")
                    }
                    maxLabels={maxLightBarriers}
                    onAdd={handleAddLightBarrier}
                    title={t("titlePointSelectorBarrier", "Virtual bed rail")}
                    style={styles.beams}
                    readonly={readonly}
                    disabled={disabled}
                    id="SceneLabelSelector_LightBarriers"
                    selectedSceneLabelId={selectedSceneLabelId}
                    onSelect={onSelect}
                    settings={settings}
                />
            ) : null}
        </div>
    );
};
