import { Arrow, Line } from "react-konva";
import React from "react";
import { AugmentedSceneLabel } from "../AugmentedSceneLabel";
import { getStrokeForLightBarrier } from "./SceneLabelLightBarrier";

export interface SceneLabelLineProps {
    from: AugmentedSceneLabel;
    to: AugmentedSceneLabel;
    showDirection: boolean;
}

export const SceneLabelLine = ({ from, to, showDirection }: SceneLabelLineProps) => {
    const isLoading = from.isLoading || to.isLoading;
    const isValid = from.isValid && to.isValid;
    const isFocused = from.isFocused || to.isFocused;

    const rotation = 90 + (Math.atan2(to.currentY - from.currentY, to.currentX - from.currentX) * 180) / Math.PI;

    return (
        <>
            <Line
                key={`${from.id}-${to.lineTo}`}
                id={`${from.id}-${to.lineTo}`}
                points={[from.currentX, from.currentY, to.currentX, to.currentY]}
                strokeWidth={3}
                stroke={getStrokeForLightBarrier({ ...from, isLoading, isValid, isFocused })}
            />
            {showDirection ? (
                <Arrow
                    key={`${from.id}-${to.lineTo}-arrow`}
                    points={[25, 0]}
                    pointerWidth={20}
                    pointerLength={20}
                    x={(from.currentX + to.currentX) / 2}
                    y={(from.currentY + to.currentY) / 2}
                    rotation={rotation}
                    fill={getStrokeForLightBarrier({ ...from, isLoading, isValid, isFocused })}
                />
            ) : null}
        </>
    );
};
