import { Device } from "../api_v2/hooks/useDevices";

export const sensorTypes = new Set<string>([
    "cai-sensor-v1.0",
    "cai-sensor-v1.5",
    "cai-sensor-v1.8",
    "cai-sensor-v1.9",
    "cai-sensor-v2.0",
]);

export const isDockable = (device: Device): boolean => {
    return device.dockSupport !== "no";
};

export const isDocked = (device: Device): boolean => {
    return device.dockedTo !== undefined;
};

export const isSensor = (device: Device): boolean => {
    return sensorTypes.has(device.typeName);
};
