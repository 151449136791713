import { BedLocationsSettingsProps } from "./BedLocationSettings";
import { useTranslation } from "react-i18next";
import { NoSettingsErrorMessage } from "../../Common/NoSettingsErrorMessage";
import { Settings, bedRestlessnessDuration } from "../../../../../api_v2/types/custom";
import { addDefaultSettingsForNewSceneLabel } from "../../Common/SceneLabelDefaultSettings";
import { IconButton } from "../../../../UI/Button/IconButton";
import _ from "lodash";
import { ButtonGroup } from "../../../../UI/Button/ButtonGroup";
import { getValueForFlag } from "../../../settingsSchemaUtils";
import { Alert } from "../../../../UI/Alerts/Alert";
import { InputGroup } from "../../../../UI/InputGroup";
import { Label } from "../../../../UI/Label";
import { SensitivityValues } from "../../../../UI/SensitivitySlider";
import { SensitivitySliderLabelCombo } from "../../../../UI/SensitivitySliderLabelCombo";
import { TimeWindowsList } from "../../../../TimeWindows/TimeWindowsList";
import {
    BiSolidHourglassTop as RestlessnessShortIcon,
    BiSolidHourglass as RestlessnessMediumIcon,
    BiSolidHourglassBottom as RestlessnessLongIcon,
} from "react-icons/bi";
import styles from "./BedRestlessnessSettings.module.css";

export const BedRestlessnessSettings = (props: BedLocationsSettingsProps) => {
    const { t } = useTranslation();

    const bedRestlessnessLocationSettings = props.settings.bedRestlessnessLocations?.find(
        (l) => l.id === props.selectedBedLocation.id
    );

    if (!bedRestlessnessLocationSettings) {
        return (
            <NoSettingsErrorMessage
                settings={props.settings}
                onSettingsChange={props.onSettingsChange}
                addSettingsHandler={(settings: Settings) =>
                    addDefaultSettingsForNewSceneLabel(
                        settings,
                        props.selectedBedLocation,
                        "bed-restlessness",
                        props.settingsSchema
                    )
                }
                readonly={props.readonly}
            />
        );
    }

    const handleSettingsChange = (
        updatedBedRestlessnessSettings: Exclude<Settings["bedRestlessnessLocations"], undefined>[0]
    ) => {
        const newSettings = _.cloneDeep(props.settings);

        if (!Array.isArray(newSettings.bedRestlessnessLocations)) {
            newSettings.bedRestlessnessLocations = [];
        }

        const index = props.settings.bedRestlessnessLocations?.indexOf(bedRestlessnessLocationSettings);
        if (index === undefined || index < 0) {
            throw new Error("Can not change bed location that does not exist"); //TODO not yet translated
        }

        newSettings.bedRestlessnessLocations[index] = updatedBedRestlessnessSettings;
        props.onSettingsChange(newSettings);
    };

    const handleChangeDurations = (durations: bedRestlessnessDuration) => {
        const newBedRestlessnessSettings = _.cloneDeep(bedRestlessnessLocationSettings);
        newBedRestlessnessSettings.durations = [durations];
        handleSettingsChange(newBedRestlessnessSettings);
    };

    const handleChangeSensitivity = (sensitivity: SensitivityValues) => {
        const newBedRestlessnessSettings = _.cloneDeep(bedRestlessnessLocationSettings);
        newBedRestlessnessSettings.sensitivity = sensitivity;
        handleSettingsChange(newBedRestlessnessSettings);
    };

    const durations = bedRestlessnessLocationSettings.durations;

    if (!getValueForFlag("bedRestlessnessEnable", props.settings, props.settingsSchema, true)) {
        return (
            <Alert type="info">{t("infoBedRestlessnessDisabled", "Bed restlessness detection is deactivated")}</Alert>
        );
    }

    return (
        <>
            <InputGroup>
                <Label text={t("titleBedRestlessnessDurations", "Bed restlessness duration:")} noLabelEl={true}>
                    {props.readonly ? (
                        t(durations)
                    ) : (
                        <ButtonGroup>
                            <IconButton
                                title={t("labelBedRestlessnessDurationButton.RestlessnessShort", "short")}
                                icon={<RestlessnessShortIcon />}
                                active={durations.includes("short")}
                                onClick={() => handleChangeDurations("short")}
                                id="SetBedRestlessnessDurationButton_RestlessnessShort"
                                disabled={props.readonly}
                            />
                            <IconButton
                                title={t("labelBedRestlessnessDurationButton.RestlessnessMedium", "medium")}
                                icon={<RestlessnessMediumIcon />}
                                active={durations.includes("medium")}
                                onClick={() => handleChangeDurations("medium")}
                                id="SetBedRestlessnessDurationButton_RestlessnessMedium"
                                disabled={props.readonly}
                            />
                            <IconButton
                                title={t("labelBedRestlessnessDurationButton.RestlessnessLong", "long")}
                                icon={<RestlessnessLongIcon />}
                                active={durations.includes("long")}
                                onClick={() => handleChangeDurations("long")}
                                id="SetBedRestlessnessDurationButton_RestlessnessLong"
                                disabled={props.readonly}
                            />
                        </ButtonGroup>
                    )}
                </Label>
            </InputGroup>
            <InputGroup>
                <SensitivitySliderLabelCombo
                    text={t("labelBedRestlessnessSensitivity", "Sensitivity")}
                    sensitivity={bedRestlessnessLocationSettings.sensitivity}
                    readonly={props.readonly}
                    onChange={(s) => handleChangeSensitivity(s)}
                    id="bedRestlessnessSensitivitySlider"
                />
            </InputGroup>

            <Label text={t("labelbedRestlessnessSchedule", "Alarm period")} additionalClassNames={styles.block}>
                <TimeWindowsList
                    timeWindows={bedRestlessnessLocationSettings.timeWindows}
                    readonly={props.readonly}
                    onChange={(timeWindows) => {
                        const newBedRestlessnessLocationSettings = _.cloneDeep(bedRestlessnessLocationSettings);
                        newBedRestlessnessLocationSettings.timeWindows = timeWindows;
                        handleSettingsChange(newBedRestlessnessLocationSettings);
                    }}
                    id="bedRestlessnessTimeWindows"
                />
            </Label>
        </>
    );
};
