import { config } from "../config";
import { setLogger } from "react-query";

const log =
    (severity: "log" | "warn" | "error") =>
    (...args: unknown[]) => {
        if (config.debug) {
            // eslint-disable-next-line no-console
            console[severity](...args);
        }
    };

setLogger({
    log: log("log"),
    warn: log("warn"),
    error: log("error"),
});
