import React from "react";
import { Button } from "./Button";
import { c } from "../../../utils/ClassesHelper";
import styles from "./Button.module.css";

type ButtonGroupProps = {
    className?: string;
    children: Array<React.ReactElement<typeof Button> | null> | React.ReactElement<typeof Button> | null;
};

export const ButtonGroup = (props: ButtonGroupProps) => {
    return <div className={c([styles.buttonGroup, props.className])}>{props.children}</div>;
};
