import React, { ReactElement } from "react";
import styles from "./DeviceSetupModal.module.css";
import { useNavigate } from "react-router-dom";

export interface Step {
    title: string;
    path: string;
}

export interface SteppedViewProps {
    steps: Step[];
    currentStep: number;
    children: ReactElement;
}

export const StepsWrapper = ({ steps, currentStep, children }: SteppedViewProps) => {
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <ol className={styles.steps}>
                {steps.map((step, index) => {
                    let className: string | undefined;
                    let isClickable = false;

                    if (index < currentStep) {
                        className = styles.stepDone;
                        isClickable = true;
                    } else if (index === currentStep) {
                        className = styles.stepCurrent;
                    }

                    return (
                        <li
                            className={className}
                            key={index}
                            onClick={() => {
                                if (isClickable) {
                                    navigate(step.path);
                                }
                            }}
                        >
                            {step.title}
                        </li>
                    );
                })}
            </ol>
            <div className={styles.content}>{children}</div>
        </div>
    );
};
