import { useTranslation } from "react-i18next";
import { heatmaps } from "../../../../config/images";
import { Loading } from "../../../UI/Loading";
import { c } from "../../../../utils/ClassesHelper";
import styles from "./HeatMap.module.scss";

export interface HeatMapPlaceholderProps {
    isLoading: boolean;
    noDataText: string;
}

export const HeatMapPlaceholder = ({ isLoading, noDataText }: HeatMapPlaceholderProps) => {
    const { t } = useTranslation();
    const { w, h } = heatmaps.resolution;

    return (
        <div
            className={c([styles.placeholder, styles.noDataContainer])}
            style={{ aspectRatio: `${w}/${h}`, maxWidth: w, maxHeight: h }}
        >
            {isLoading ? <Loading text={t("tooltipLoading", "Loading ...")} /> : <p>{noDataText}</p>}
        </div>
    );
};
