import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { ReactNode, useCallback } from "react";
import { PieLabelRenderProps } from "recharts/types/polar/Pie";
import styles from "../DashboardView.module.scss";
import { c } from "../../../utils/ClassesHelper";

export interface SimplePieChartProps<T extends { [key: string]: unknown }, K extends keyof T> {
    data: T[];
    colors: Array<string | [string, string]>;
    patterns?: ReactNode | ReactNode[];
    dataKey: K & string;
    labelKey: K & string;
    labelVertical?: boolean;
}

/**
 * Abstracts the simple pie bar chart as used in multiple cards.
 */
export const SimplePieChart = <T extends { [key: string]: unknown }, K extends keyof T>({
    data,
    colors,
    patterns,
    dataKey,
    labelKey,
}: SimplePieChartProps<T, K>) => {
    const renderLabel = useCallback(
        (entry: PieLabelRenderProps) => {
            const index = data.findIndex((d) => d[labelKey] === entry[labelKey]);
            const color = index > -1 ? colors[index] : undefined;

            return (
                <text
                    x={entry.x}
                    y={entry.y + 3}
                    textAnchor={entry.textAnchor}
                    fill={Array.isArray(color) ? color[0] : color}
                    className={styles.pieChartLabel}
                    lengthAdjust="spacingAndGlyphs"
                >
                    {entry.percent ? Math.round(entry.percent * 100) : entry[dataKey]}%{" "}
                    <tspan key={entry.name}>{entry.name}</tspan>
                </text>
            );
        },
        [data, colors, dataKey, labelKey]
    );

    return (
        <div className={c([styles.pieContainer])}>
            <ResponsiveContainer className={c([styles.responsiveChartContainer, styles.pie])}>
                <PieChart margin={{ top: 10, left: 0, right: 0, bottom: 0 }}>
                    {patterns ? <defs>{patterns}</defs> : null}
                    <Pie
                        data={data}
                        fill="#8884d8"
                        dataKey={dataKey}
                        label={renderLabel}
                        fontSize="12px"
                        isAnimationActive={false}
                        startAngle={90}
                        endAngle={360 + 90}
                        outerRadius="90%"
                    >
                        {data.map((entry, index) => {
                            let fillColor;

                            const color = colors[index];
                            if (Array.isArray(color)) {
                                fillColor = color[1];
                            } else {
                                fillColor = color;
                            }

                            return <Cell key={`cell-${entry}-${index}`} fill={fillColor} style={{ outline: "none" }} />;
                        })}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};
